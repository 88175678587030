<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Materi SKD CPNS</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_theory_cpns'}">Materi</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <input type="hidden" name="id" v-model="form.type_theory">
                                <div class="form-group col-md-6">                                    
                                    <label for="exampleFormControlSelect1">Kategori</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.category_id" :class="{ 'is-invalid': submitted && $v.form.category_id.$error }">
                                        <option value="">Pilih Kategori</option>
                                        <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.category_id.required" class="invalid-feedback">Kategori wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Judul</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Deskripsi Singkat</label>
                                    <textarea class="form-control" rows="4" placeholder="Masukan Deskripsi" v-model="form.description"></textarea>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Tipe</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                        <option value="">Pilih Tipe</option>
                                        <option value="youtube">Youtube</option>
                                        <option value="text">Text</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'youtube'">
                                    <label for="exampleInputEmail1">Url Youtube</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.content">
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'text'">
                                    <label for="exampleInputEmail1">File Tambahan (PDF)</label>
                                    <input type="file" class="form-control" name="attachment" id="attachment" @change="previewFiles" accept="application/pdf">
                                    <small v-if="form.previousAttachment" >File sebelumnya : <a :href="form.previousAttachment" target="blank">Lihat</a> </small>
                                </div>
                                <div class="form-group col-md-12" v-if="form.type == 'text'">
                                    <label for="exampleInputEmail1">Konten Materi</label>
                                    <ckeditor v-model="form.content" :config="editorConfig"></ckeditor>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover</label>
                                    <input type="file" class="form-control" name="cover" id="cover" @change="previewCover" accept="image/png, image/gif, image/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_image">
                                    <label for="exampleInputEmail1">Preview Cover</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_image" data-lightbox="1" target="blank">
                                                <img :src="preview_image" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'skd_theory_cpns'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Materi',
        metaInfo: {
            title: 'Form Materi',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getCategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                preview_image   : '',
                dataCategory    : [],
                form : {
                    id          : "",
                    type_theory : "cpns",
                    category_id : "",
                    title       : "",
                    type        : "",
                    content     : "",
                    status      : "draft",
                    description : "",
                    attachment  : "",
                    previousAttachment  : "",
                    cover  : "",
                    previousCover  : ""
                },
                submitted       : false,
                waiting         : false,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false                    
                }
            }
        },
        validations: {
            form: {
                category_id: {
                    required
                },
                title: {
                    required
                },
                type: {
                    required
                },
                status: {
                    required
                }
            }
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skd/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('category_id', this.form.category_id);
                    formData.append('title', this.form.title);
                    formData.append('type', this.form.type);
                    formData.append('content', this.form.content);
                    formData.append('status', this.form.status);
                    formData.append('description', this.form.description);
                    formData.append('attachment', this.form.attachment);
                    formData.append('type_theory', this.form.type_theory);
                    formData.append('cover', this.form.cover);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skd/theory/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'skd_theory_cpns' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/skd/theory/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type_theory : this.form.type_theory
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id                    = getResponse.id;
                            this.form.category_id           = getResponse.category_id;
                            this.form.content               = getResponse.content;
                            this.form.status                = getResponse.status;
                            this.form.title                 = getResponse.title;
                            this.form.type                  = getResponse.type;
                            this.form.description           = getResponse.description;
                            this.form.attachment            = getResponse.attachment;
                            this.form.previousAttachment    = getResponse.attachment;
                            this.form.cover                 = getResponse.cover;
                            this.preview_image              = getResponse.cover;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'skd_theory_cpns' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            },
            previewCover(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.cover = file;
                }
            },
        }
    }
</script>