<template>
    <div>
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Sedang memuat...
            </span>
        </div>
        
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="!dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Saat ini kamu terdaftar sebagai guru dari sekolah <b>{{ dataSchool.name }}</b> pada mata pelajaran:
                    <ul class="mb-0 mt-2">
                        <li class="mb-0" v-for="(itemSubject, indexSubject) in dataSubjects" :key="indexSubject">{{ itemSubject.name }}</li>
                    </ul>                
            </span>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Atur Jadwal Kelas</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'schedule_attendance_main'}">Jadwal Kelas</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Atur Jadwal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:-10px !important">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Atur Tanggal Penjadwalan</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="inputEmail3" class="col-sm-3 col-form-label">Dari Tanggal</label>
                                    <div class="col-sm-9">
                                        <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal awal..."  v-model="start_date">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="inputEmail3" class="col-sm-3 col-form-label">Hingga Tanggal</label>
                                    <div class="col-sm-9">
                                        <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal akhir..." v-model="end_date">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning alert-dismissible fade show d-flex" role="alert">
                            <strong><i class="fa fa-info-circle"></i></strong>
                                <span class="m-l-10">Pilih tanggal diatas (Range Tanggal Maximal 7 Hari) untuk menampilkan pengaturan penambahan jadwal kelas & matpel anda. Setelah itu tekan tombol 'Proses Tanggal' dibawah ini..</span>
                        </div>

                        <button v-on:click="scheduleProcess()" type="button" class="btn btn-outline-primary rounded btn-block" :disabled="waiting"> <i class="fa fa-calendar-check"></i> {{ waiting ? 'Sedang memproses...' : 'Proses Tanggal' }}</button>

                        <div v-if="show_schedule">
                            <div class="table-responsive mt-3">
                                <table class="table table-bordered rounded">
                                    <thead>
                                        <tr>
                                            <th style="width:10%; font-size:11px !important">Tanggal</th>
                                            <th style="width:12%; font-size:11px !important">Kelas</th>
                                            <th style="width:20%; font-size:11px !important">Mata Pelajaran</th>
                                            <th style="width:5%; font-size:11px !important" class="text-center">Jam Mulai</th>
                                            <th style="width:5%; font-size:11px !important" class="text-center">Jam Selesai</th>
                                            <th style="width:18%; font-size:11px !important">Jurnal/Materi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in data_schedule" :key="index">
                                            <td style="font-size: 12px !important;">
                                                <span>{{item.name}}</span>
                                                <br/>
                                                <br/>
                                                <a href="javascript:void(0)" v-on:click="addSchedule(item.code)"><i class="fa fa-plus"></i> Tambah Data</a>
                                            </td>

                                            <td style="font-size: 11px !important;">
                                                <div v-for="(itemAdd, indexAdd) in total_schedule[item.code]" :key="indexAdd">
                                                    <input type="hidden" v-model="form_schedule.id[item.code][itemAdd]">

                                                    <select class="form-control mt-2" v-model="form_schedule.school_class[item.code][itemAdd]">
                                                        <option value="">Pilih Kelas</option>
                                                        <option v-for="(itemClass, indexClass) in dataSchool.list_class" :key="indexClass" :value="itemClass">{{ itemClass }}</option>
                                                    </select>
                                                </div>
                                            </td>

                                            <td style="font-size: 11px !important;">
                                                <div v-for="(itemAdd, indexAdd) in total_schedule[item.code]" :key="indexAdd">
                                                    <select class="form-control mt-2" v-model="form_schedule.subjects[item.code][itemAdd]">
                                                        <option value="">Pilih Mata Pelajaran</option>
                                                        <option v-for="(itemSubject, indexSubject) in dataSubjects" :key="indexSubject" :value="itemSubject.id">{{ itemSubject.name }}</option>
                                                    </select>
                                                </div>
                                            </td>

                                            <td style="font-size: 11px !important;">
                                                <div v-for="(itemAdd, indexAdd) in total_schedule[item.code]" :key="indexAdd">
                                                    <div class="input-group mt-2">
                                                        <input type="time" class="form-control" v-model="form_schedule.start_time[item.code][itemAdd]">
                                                    </div>
                                                </div>
                                            </td>

                                            <td style="font-size: 11px !important;">
                                                <div v-for="(itemAdd, indexAdd) in total_schedule[item.code]" :key="indexAdd">
                                                    <div class="d-flex">
                                                        <div class="input-group mt-2">
                                                            <input type="time" class="form-control" v-model="form_schedule.end_time[item.code][itemAdd]">
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td style="font-size: 11px !important;">
                                                <div v-for="(itemAdd, indexAdd) in total_schedule[item.code]" :key="indexAdd">
                                                    <div class="d-flex">
                                                        <div class="input-group mt-2">
                                                            <input type="text" class="form-control" v-model="form_schedule.journal[item.code][itemAdd]" placeholder="Masukan jurnal/materi...">
                                                        </div>

                                                        <div v-if="form_schedule.id[item.code][itemAdd] == ''">
                                                            <button v-if="indexAdd != 0" class="btn btn-danger btn-sm rounded btn-block ml-2 mt-2" type="button" v-on:click="removeSchedule(item.code)"><i class="fa fa-trash"></i></button>
                                                        </div>

                                                        <div v-if="form_schedule.id[item.code][itemAdd] != ''">
                                                            <button class="btn btn-danger btn-sm rounded btn-block ml-2 mt-2" type="button" v-on:click="removeScheduleByID(item.code, itemAdd)"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr/>
                            <div class="form-check mt-2">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="form_schedule.loop_schedule" true-value="1" false-value="0">
                                <label for="exampleCheck1">Repeat Jadwal Otomatis</label>
                            </div>

                            <div class="row" v-if="form_schedule.loop_schedule == 1">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Dari Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal awal..."  v-model="form_schedule.loop_start">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hingga Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal akhir..." v-model="form_schedule.loop_end">
                                        </div>
                                    </div>
                                </div>
                            </div>                            

                            <button type="button" v-on:click="submitSchedule()" class="btn btn-primary rounded btn-block mt-3" :disabled="waiting_generate"> <i class="fa fa-save"></i> {{ waiting_generate ? 'Harap tunggu ...' : 'Simpan Penjadwalan Kelas' }}</button>
                            <button type="button" v-on:click="cancelSchedule()" class="btn btn-outline-primary rounded btn-block mt-2" >Batal</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Import',
        metaInfo: {
            title: 'Atur Jadwal Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getSchool();
            this.getSubjects();
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                show_schedule   : false,
                start_date      : '',
                end_date        : '',
                data_schedule   : {
                    subjects : [],
                    school_class : []
                },
                dataFetchSchool : false,
                dataSchool      : [],
                dataSubjects    : [],
                total_schedule  : {},
                form_schedule : {
                    id : {},
                    school_class : {},
                    subjects : {},
                    start_time : {},
                    end_time : {},
                    journal : {},
                    loop_schedule : 0,
                    loop_start : '',
                    loop_end : ''
                },
                list_code : [],
                waiting_generate : false
            }
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;
                });
            },
            getSubjects: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/subjects-list';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => { 
                    this.dataSubjects = res.data.data;
                });
            },
            scheduleProcess: function() {
                if(this.start_date && this.end_date) {
                    if(this.end_date < this.start_date) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Tanggal akhir tidak boleh kurang dari tanggal awal.',
                        });
                    } else {
                        this.waiting = true;
                        let formData = new FormData();

                        formData.append('start_date', this.start_date);
                        formData.append('end_date', this.end_date);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/date-process', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if(response.data.status) {
                                this.show_schedule = true;
                                this.data_schedule = response.data.schedule;

                                this.total_schedule = {}
                                this.form_schedule.school_class = {};
                                this.form_schedule.subjects = {};
                                this.form_schedule.start_time = {};
                                this.list_code = [];

                                this.data_schedule.forEach(element => {
                                    this.list_code.push(element.code)

                                    // set empty value
                                    if (!this.form_schedule.id[element.code]) {
                                        this.$set(this.form_schedule.id, element.code, {});
                                    }

                                    if (!this.form_schedule.school_class[element.code]) {
                                        this.$set(this.form_schedule.school_class, element.code, {});
                                    }

                                    if (!this.form_schedule.subjects[element.code]) {
                                        this.$set(this.form_schedule.subjects, element.code, {});
                                    }

                                    if (!this.form_schedule.start_time[element.code]) {
                                        this.$set(this.form_schedule.start_time, element.code, {});
                                    }

                                    if (!this.form_schedule.end_time[element.code]) {
                                        this.$set(this.form_schedule.end_time, element.code, {});
                                    }

                                    if (!this.form_schedule.journal[element.code]) {
                                        this.$set(this.form_schedule.journal, element.code, {});
                                    }
                                    // ini jika jadwal belum pernah dibuat sama sekali
                                    if(element.total_schedule < 1) {
                                        this.form_schedule.id[element.code][1] = ""
                                        this.form_schedule.school_class[element.code][1] = ""
                                        this.form_schedule.subjects[element.code][1] = ""
                                        this.form_schedule.start_time[element.code][1] = ""
                                        this.form_schedule.end_time[element.code][1] = ""
                                        this.form_schedule.journal[element.code][1] = ""

                                        this.$set(this.total_schedule, element.code, 1);
                                    } else {
                                        let number_index = 0;
                                        for (let index = 1; index <= element.total_schedule; index++) {                                            
                                            this.form_schedule.id[element.code][index] = element.id[number_index]
                                            this.form_schedule.school_class[element.code][index] = element.school_class[number_index]
                                            this.form_schedule.subjects[element.code][index] = element.subjects[number_index]
                                            this.form_schedule.start_time[element.code][index] = element.start_time[number_index]
                                            this.form_schedule.end_time[element.code][index] = element.end_time[number_index]
                                            this.form_schedule.journal[element.code][index] = element.journal[number_index]
                                            ++number_index;
                                        }

                                        this.$set(this.total_schedule, element.code, element.total_schedule);
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }

                            this.waiting = false;
                        })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });                        
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap masukan rentang tanggal!',
                    });
                }
            },
            addSchedule(code) {
                this.$set(this.total_schedule, code, (this.total_schedule[code] || 0) + 1);

                if (!this.form_schedule.id[code]) {
                    this.$set(this.form_schedule.id, code, {});
                }

                const nextIndexID = Object.keys(this.form_schedule.id[code]).length + 1;
                this.$set(this.form_schedule.id[code], nextIndexID, "");

                if (!this.form_schedule.school_class[code]) {
                    this.$set(this.form_schedule.school_class, code, {});
                }

                const nextIndexSchoolClass = Object.keys(this.form_schedule.school_class[code]).length + 1;
                this.$set(this.form_schedule.school_class[code], nextIndexSchoolClass, "");

                
                if (!this.form_schedule.subjects[code]) {
                    this.$set(this.form_schedule.subjects, code, {});
                }

                const nextIndexSubjects = Object.keys(this.form_schedule.subjects[code]).length + 1;
                this.$set(this.form_schedule.subjects[code], nextIndexSubjects, "");

                if (!this.form_schedule.start_time[code]) {
                    this.$set(this.form_schedule.start_time, code, {});
                }

                const nextIndexStartTime = Object.keys(this.form_schedule.start_time[code]).length + 1;
                this.$set(this.form_schedule.start_time[code], nextIndexStartTime, "");

                if (!this.form_schedule.end_time[code]) {
                    this.$set(this.form_schedule.end_time, code, {});
                }

                const nextIndexEndTime = Object.keys(this.form_schedule.end_time[code]).length + 1;
                this.$set(this.form_schedule.end_time[code], nextIndexEndTime, "");

                if (!this.form_schedule.journal[code]) {
                    this.$set(this.form_schedule.journal, code, {});
                }

                const nextIndexJournal = Object.keys(this.form_schedule.journal[code]).length + 1;
                this.$set(this.form_schedule.journal[code], nextIndexJournal, "");                
            },
            removeSchedule(code) {
                if (this.total_schedule[code] > 0) {
                    this.$set(this.total_schedule, code, this.total_schedule[code] - 1);
                }
            },
            removeScheduleByID(code, id) {
                let existing_id = this.form_schedule.id[code][id];
                
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data jadwal ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/delete-schedule', {
                        id: existing_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.scheduleProcess();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            cancelSchedule() {
                this.show_schedule = false;
                this.total_schedule = {};
            },
            submitSchedule: function() {
                this.waiting_generate = true;
                let formData = new FormData();

                formData.append('code', JSON.stringify(this.list_code));
                formData.append('schedule', JSON.stringify(this.form_schedule));
                formData.append('total_schedule', JSON.stringify(this.total_schedule));

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/save-schedule', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.waiting_generate = false;
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'schedule_attendance_main'
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            html: response.data.message,
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting_generate = false;
                    return;
                });                
            }
        }
    }
</script>