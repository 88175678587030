<template>
    <div>
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Sedang memuat...
            </span>
        </div>
        
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="!dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Saat ini kamu terdaftar sebagai guru dari sekolah <b>{{ dataSchool.name }}</b>.                
            </span>
        </div>

        <div class="row" v-if="!dataFetchSchool">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Atur Kelas & Tryout</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Kelas</label>
                                        <div class="col-sm-9">
                                            <select class="form-control" v-model="school_class">
                                                <option value="">Pilih Kelas</option>
                                                <option v-for="(itemClass, indexClass) in dataSchool.list_class" :key="indexClass" :value="itemClass">{{ itemClass }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Tryout</label>
                                        <div class="col-sm-9">
                                            <multiselect
                                                v-model="tryout" tag-placeholder="Add this as new tag"
                                                placeholder="Pilih Tryout" label="title" track-by="id" :options="dataTryout"
                                                :multiple="true" :taggable="true">
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">&nbsp;</label>
                                        <div class="col-sm-9">
                                            <div class="alert alert-warning alert-dismissible fade show d-flex" role="alert">                                                
                                                    <span class="m-l-10"><b>Catatan:</b><br/>Silahkan <b>Pilih Kelas dan Tryout</b> yang telah dibuat. Setelah itu tekan tombol 'Lihat Monitoring' dibawah ini..</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button v-on:click="monitoringProcess()" type="button" class="btn btn-primary rounded btn-block" :disabled="waiting"> <i class="fa fa-file"></i> {{ waiting ? 'Sedang memproses...' : 'Lihat Monitoring' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header" v-if="show_monitoring">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Monitoring Tryout Kelas {{school_class}}</h5>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Jumlah Tes</div>
                                        <div class="font-weight-600">{{ data_list_tryout.length }} Tes</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Total Siswa</div>
                                        <div class="font-weight-600">{{ data_users.length }} Orang</div>
                                    </div>

                                    <div class="d-flex align-items-top justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">
                                            Download Analisis Soal
                                            <br/>                                              
                                        </div>
                                        <div class="font-weight-600">
                                            <button data-toggle="modal" data-target="#modalReport" class="btn btn-primary rounded" type="button">
                                                Download Analisis Soal
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive" style="max-height: 450px; overflow-y: auto;">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width:5%; position: sticky; top: 0; background-color: #f1f1f1;" class="text-center">No</th>
                                                <th style="position: sticky; top: 0; background-color: #f1f1f1;">Data Peserta</th>
                                                <th style="position: sticky; top: 0; background-color: #f1f1f1;" class="text-center" v-for="(item, index) in data_list_tryout" :key="index">
                                                    {{ item.title }}
                                                </th>
                                                <th class="text-center" style="position: sticky; top: 0; background-color: #f1f1f1;">Skor Rata - rata</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in data_users" :key="index">
                                                <td style="vertical-align: middle;" class="text-center">{{ ++index }}</td>
                                                <td style="vertical-align: middle;">{{ item.fullname }}<br/><small>Email : {{ item.email }}</small></td>
                                                <td style="vertical-align: middle;" class="text-center" v-for="(itemDetail, indexDetail) in data_list_tryout" :key="indexDetail">
                                                    <small v-if="item.tryout[itemDetail.id].is_members == 0" class="text-danger">
                                                        (Belum Terdaftar)
                                                    </small>
                                                    
                                                    <div v-if="item.tryout[itemDetail.id].is_members == 1">
                                                        <span v-if="item.tryout[itemDetail.id].answer.length == 0" class="text-primary">
                                                            <b>(Belum Mengerjakan)</b>
                                                        </span>

                                                        <span v-if="(item.tryout[itemDetail.id].answer.length != 0 && item.tryout[itemDetail.id].answer.skor_akhir == null)" class="text-info">
                                                            <b>(Sedang Mengerjakan)</b>
                                                        </span>

                                                        <span v-if="(item.tryout[itemDetail.id].answer.length != 0 && item.tryout[itemDetail.id].answer.skor_akhir != null)">
                                                            <b>{{ item.tryout[itemDetail.id].answer.skor_akhir }}</b>
                                                        </span>
                                                    </div>
                                                    
                                                </td>
                                                <td style="vertical-align: middle;" class="text-center">
                                                    <span v-if="item.average_score == '-'"><small>(Belum tuntas)</small></span>
                                                    <span v-if="item.average_score != '-'"><b>{{ item.average_score }}</b></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modalReport" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Download Data</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12">
                                <div class="m-t-10">
                                    <label>Pilih Tes/Tryout</label>
                                    <select class="form-control" v-model="form.tryout_id">
                                        <option value="">Pilih Tes/Tryout</option>
                                        <option v-for="(item, index) in data_list_tryout" :key="index" :value="item.id">{{ item.title }}</option>
                                    </select>
                                </div>

                                <div class="m-t-10">
                                    <label>Guru Pengampu/Wali Kelas</label>
                                    <input type="text" placeholder="Masukan nama guru pengampu/ wali kelas..." class="form-control" v-model="form.teacher">
                                </div>

                                <button :disabled="isFetchAnalysisExcel" type="button" v-on:click="downloadAnalysis()" class="btn btn-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisExcel" class="fa fa-download"></i><i v-if="isFetchAnalysisExcel" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisExcel ? 'Harap tunggu' : 'Download Analisis Soal' }}</button>
                                <button :disabled="isFetchAnalysisDayaSerap" type="button" v-on:click="downloadDayaSerap()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisDayaSerap" class="fa fa-download"></i><i v-if="isFetchAnalysisDayaSerap" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisDayaSerap ? 'Harap tunggu' : 'Download Daya Serap' }}</button>
                                <button :disabled="isFetchAnalysisRemidi" type="button" v-on:click="downloadRemidi()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisRemidi" class="fa fa-download"></i><i v-if="isFetchAnalysisRemidi" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisRemidi ? 'Harap tunggu' : 'Download Nilai Remidi' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MonitoringClassTryout',
        metaInfo: {
            title: 'Monitoring Tryout Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getSchool();
            this.getTryout();
        },
        data() {
            return {
                submitted           : false,
                waiting             : false,
                dataFetchSchool     : false,
                dataSchool          : [],
                dataTryout       : [],
                school_class        : '',
                tryout           : [],
                data_users          : [],
                data_list_tryout : [],
                show_monitoring         : false,
                isFetchAnalysisExcel    : false,
                isFetchAnalysisRemidi    : false,
                isFetchAnalysisDayaSerap    : false,
                form : {
                    tryout_id : '',
                    teacher : '' 
                }
            }
        },
        methods: {
            getTryout: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/list?page=1&limit=10000';

                this.$http.get(uri).then(res => {
                    this.dataTryout      = res.data.data;
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;
                });
            },
            monitoringProcess: function() {
                if(this.school_class && this.tryout.length > 0) {
                    this.waiting = true;

                    let tryout_id = [];
                    this.tryout.forEach(element => {
                        tryout_id.push(element.id)
                    });

                    let tryout_id_str = tryout_id.join(',');

                    let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/monitoring-class';
                
                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            school_class    : this.school_class,
                            school_id       : this.dataSchool.id,
                            list_tryout_id    : tryout_id_str
                        }
                    })
                    .then(response => {
                        this.waiting                = false;
                        this.show_monitoring        = true;
                        let get_response            = response.data;
                        this.data_users             = get_response.data.data_users;
                        this.data_list_tryout    = get_response.data.data_tryout;

                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });                        
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih kelas & Tryout untuk memulai monitoring!',
                    });
                }
            },
            downloadAnalysis() {
                let teacher = this.form.teacher;
                let id      = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                    let title = "";
                    this.data_list_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisExcel = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/monitoring/download-analysis-cbt-tryout-excel', {
                        params : {
                            id      : id,
                            title   : title,
                            teacher : teacher,
                            school_id : this.dataSchool.id,
                            class   : this.school_class
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            this.isFetchAnalysisExcel = false;
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Analisis Soal Tryout - '+this.school_class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadRemidi() {
                let teacher = this.form.teacher;
                let id      = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                    let title = "";
                    this.data_list_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisRemidi = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/monitoring/download-remidi', {
                        params : {
                            id      : id,
                            title   : title,
                            teacher : teacher,
                            school_id : this.dataSchool.id,
                            class   : this.school_class
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            this.isFetchAnalysisRemidi = false;
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Nilai Remidi - '+this.school_class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadDayaSerap() {
                let teacher = this.form.teacher;
                let id      = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                    let title = "";
                    this.data_list_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisDayaSerap = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/monitoring/download-daya-serap', {
                        params : {
                            id      : id,
                            title   : title,
                            teacher : teacher,
                            school_id : this.dataSchool.id,
                            class   : this.school_class
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            this.isFetchAnalysisDayaSerap = false;
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Daya Serap - '+this.school_class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
        }
    }
</script>