<template>
    <div class="card card-theory-no-hover" style="border-top:none !important">
        <div class="card-body">
            <div class="d-block mb-1">
                <form v-on:submit.prevent="onSubmit">
                    <h5>Informasi Akun</h5>
                    <div v-if="form.disable_profile == 1" class="alert alert-warning text-center">
                        <span>Saat ini <b>pengaturan akun dinonaktifkan</b>. Silahkan hubungi sekolah untuk informasi lebih lanjut</span>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Email</label>
                            <input type="text" disabled class="form-control" id="exampleInputEmail1"
                                placeholder="Masukan data..." v-model="form.email">
                            <small><i>Alamat Email tidak dapat diubah</i></small>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Nama</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." v-model="form.fullname" :class="{ 'is-invalid': submitted && $v.form.fullname.$error }" :disabled="form.disable_profile == 1">
                            <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Nama wajib diisi!</div>    
                        </div>

                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Nomor Handphone</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }" :disabled="form.disable_profile == 1">
                            <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Nomor Handphone wajib diisi!</div>
                            <div v-if="submitted && !$v.form.phone.numeric" class="invalid-feedback"> Nomor Handphone harus berupa angka</div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Jenis Kelamin</label>
                            <select class="form-control" v-model="form.gender" :class="{ 'is-invalid': submitted && $v.form.gender.$error }" :disabled="form.disable_profile == 1">
                                <option value="">Pilih Jenis Kelamin</option>
                                <option value="L">Laki - laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                            <div v-if="submitted && !$v.form.gender.required" class="invalid-feedback">Harap pilih jenis kelamin!</div>
                        </div>
                    </div>
                    <h5 class="m-t-10">Informasi Lainnya</h5>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="exampleInputEmail1">Asal Provinsi</label>
                                <v-select
                                    placeholder="Pilih Provinsi"
                                    label="name"
                                    :options="listProvince"
                                    v-model="form.province"
                                    :class="{ 'is-invalid': submitted && $v.form.province.$error }"
                                    @input="getCity"
                                    :disabled="form.disable_profile == 1"
                                    >                                    
                                </v-select>
                                
                                <div v-if="submitted && !$v.form.province.required" class="invalid-feedback d-block">
                                Provinsi Asal Wajib Diisi</div>
                        </div>

                        <div class="form-group col-md-6">
                            <label class="font-weight-bold">Asal Kota</label>
                            <v-select
                                placeholder="Pilih Kota"
                                label="name"
                                :options="listCity"
                                v-model="form.city"
                                :class="{ 'is-invalid': submitted && $v.form.city.$error }"
                                :disabled="form.disable_profile == 1"
                                >                                    
                            </v-select>


                            <div v-if="submitted && !$v.form.city.required" class="invalid-feedback d-block">
                                Kota Asal Wajib Diisi</div>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Alamat Lengkap</label>
                            <textarea class="form-control" rows="4" placeholder="Masukan alamat" v-model="form.address" :disabled="form.disable_profile == 1"></textarea>
                        </div>
                    </div>

                    <div v-if="form.disable_profile == 0">
                        <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                        <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, numeric} from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'MyProfileAccount',
        metaInfo: {
            title: 'Profil Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            'v-select' : vSelect,
        },
        created() {
            this.getProvince();
        },
        data() {
            return {
                form : {
                    email       : this.$parent.$parent.dataUsers.email,
                    fullname    : this.$parent.$parent.dataUsers.fullname,
                    phone       : this.$parent.$parent.dataUsers.phone,
                    gender      : (this.$parent.$parent.dataUsers.gender? this.$parent.$parent.dataUsers.gender:''),
                    province    : (this.$parent.$parent.dataUsers.province.id != '' ? this.$parent.$parent.dataUsers.province:''),
                    city        : (this.$parent.$parent.dataUsers.city.id != '' ? this.$parent.$parent.dataUsers.city:''),
                    address     : this.$parent.$parent.dataUsers.address,
                    referral    : this.$parent.$parent.dataUsers.referral,
                    disable_profile : this.$parent.$parent.dataUsers.disable_profile
                },

                submitted       : false,
                waiting         : false,
                listProvince    : [],
                listCity        : [],
            }
        },
        validations: {
            form: {
                fullname: {
                    required
                },
                phone: {
                    required,
                    numeric
                },
                gender: {
                    required
                },
                province: {
                    required
                },
                city: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('fullname', this.form.fullname);
                    formData.append('phone', this.form.phone);
                    formData.append('gender', this.form.gender);
                    formData.append('province', JSON.stringify(this.form.province));
                    formData.append('city', JSON.stringify(this.form.city));
                    formData.append('address', this.form.address);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/account/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'signout' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getProvince: function () {
                let uri = 'https://passchendaele.oeltimacreation.com/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.province) {
                    if(this.form.province.id) {
                        let uri = 'https://passchendaele.oeltimacreation.com/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.province.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            }
        }
    }
</script>