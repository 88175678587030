import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VuePageTransition from 'vue-page-transition'
import CKEditor from 'ckeditor4-vue'
import Multiselect from 'vue-multiselect';
import Vuex from 'vuex'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import EmptyState from './components/EmptyState.vue'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueClipboard from 'vue-clipboard2'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip  = false
Vue.prototype.$http       = axios
Vue.prototype.$apiconfig  = process.env.VUE_APP_APIHOST
Vue.component('apexchart', VueApexCharts)

// Vue Use
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePageTransition)
Vue.use(CKEditor)
Vue.component('multiselect', Multiselect);
Vue.component('empty-state', EmptyState);
Vue.use(Vuex)
Vue.use(VuePlyr, {
  plyr: {}
})
Vue.use(VueCountdownTimer)
Vue.use(VueClipboard)

// Add Global Auth Token
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
