<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3 m-t-20">
                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                    <div class="input-group-append">
                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                class="fa fa-search"></i> Cari </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="dataFetch" class="row">
            <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                    <div class="d-block border rounded-lg p-3 m-t-10">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar text-center">
                                            <div class="sb-sm mb-2"></div>
                                            <hr/>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                            <div class="sb-lg"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div v-if="dataList.length > 0" class="row">
                <div class="col-md-12">
                    <div v-for="(item, index) in dataList" :key="index" class="card text-left card-theory">
                        <div class="card-body">
                            <h5 class="card-title">{{item.category}} - {{item.title}}</h5>
                            <p class="card-text">{{item.description}}</p>
                            <router-link v-if="item.type == 'text'" :to="{ name : 'member_materi_pppk_detail' , params : {id: $route.params.id, slug : item.code}}" class="btn btn-outline-primary rounded"><i class="fa fa-eye"></i> Lihat Materi</router-link>
                            <router-link v-if="item.type == 'youtube'" :to="{ name : 'member_materi_pppk_detail' , params : {id: $route.params.id, slug : item.code}}" class="btn btn-outline-primary rounded"><i class="fa fa-play"></i> Putar Video</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <nav aria-label="navigation">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>
                </div>
            </div>

            <div v-if="emptyState">
                <empty-state></empty-state>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'PaketPppkSaya',
        metaInfo: {
            title: 'Materi PPPK Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                keywords            :'',
                dataFetch           : true,
                dataList            : [],
                emptyState          : false,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/pppk/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
        }
    }
</script>