<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Beli Paket kedinasan</h5>
                        </div>
                        <div v-if="userSubscriberFetch" class="skeleton-bar text-center">
                            <div class="sb-lg sb-thin mb-2"></div>
                            <div class="sb-lg sb-thin mb-2"></div>
                            <div class="sb-lg sb-thin mb-4"></div>
                        </div>

                        <div v-if="!userSubscriberFetch" class="alert alert-primary text-center" role="alert">
                            <div v-if="dataSubscriber.status">
                                Saat ini anda sedang berlangganan<br/>
                                <span style="font-size:20px" class="m-t-10 m-b-10 badge badge-info">
                                    <i class="fa fa-package"></i> Paket {{dataSubscriber.data.name}}.
                                </span>
                                <br/>
                                Berlaku hingga, {{dataSubscriber.data.expired_text}}.
                            </div>

                            <div v-if="!dataSubscriber.status">
                                Saat ini anda sedang berlangganan<br/>
                                <span style="font-size:20px" class="m-t-10 m-b-10 badge badge-info">
                                    <i class="fa fa-package"></i> Paket Gratis.
                                </span>
                                <br/>
                                Berlaku hingga seterusnya.<br/>
                                Ayo upgrade akun dan nikmati berbagai benefit lainnya!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="dataFetch">
                <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                    <div class="d-block border rounded-lg p-3 m-t-10">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar text-center">
                                            <div class="sb-sm mb-2"></div>
                                            <hr/>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                            <div class="sb-lg"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!dataFetch">
                <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                    <div class="card card-theory">
                        <div class="card-body">
                            <h5 class="job-card-desc text-center">
                                {{item.name}}
                            </h5>
                            <div v-if="item.type_package == 'free'" class="text-center">
                                <h4>Rp. 0</h4>
                            </div>
                            <div v-if="item.type_package == 'premium'" class="text-center">
                                <b><s>{{item.text_price_out}}</s></b>
                                <h3><u>{{item.text_price}}</u></h3>
                            </div>
                            <div v-if="item.favorite" class="text-center m-b-10">
                                <span style="font-size:15px" class="badge badge-success text-center">PALING FAVORITE ! <i class="fa fa-thumbs-up"></i></span>
                            </div>

                            <div v-if="item.type_package == 'premium'" class="alert alert-secondary text-center" role="alert">
                               Dapatkan <b>Potongan harga</b> dengan Kode Promo / Referral.
                            </div>

                            <button v-if="item.type_package == 'free'" disabled type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-shopping-cart"></i> Gratis</button>

                            <div v-if="item.id != dataSubscriber.data.package_id">
                                <button v-if="item.type_package == 'premium'" type="button" class="btn btn-primary rounded btn-block m-t-10" data-toggle="modal" data-target="#modalBuyPackage" v-on:click="buyPackage(item.id)"><i class="fa fa-shopping-cart"></i> Beli Paket</button>
                            </div>
                            <div v-if="item.id == dataSubscriber.data.package_id">
                                <button disabled class="btn btn-outline-primary btn-block rounded"><i class="fa fa-check"></i> Sedang berlangganan</button>
                            </div>
                            <hr/>
                            <table style="width:100%">
                                <tr v-for="(itemDetail, index) in item.description_other" :key="index">
                                    <td style="vertical-align:top;width:8%;color:#6fd96f !important"><i class="fa fa-check"></i></td>
                                    <td>{{itemDetail}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch">
                <div v-if="emptyState">
                    <empty-state></empty-state>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalBuyPackage" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-shopping-cart"></i> Beli Paket</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                            <div>
                                <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Paket</div>
                                <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{form.name}}</div>
                                <div class="d-block h6 font-weight-600">Masa Aktif {{form.active_days}} Hari</div>
                            </div>
                        </div>
                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Harga</div>
                                <div class="font-weight-600"><s v-if="form.text_price_out" class="text-muted">{{form.text_price_out}}</s> {{form.text_price}}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Potongan Harga</div>
                                <div class="font-weight-600">
                                    <span v-if="!form.discount_voucher">Rp. 0</span>
                                    <span style="color:#43ab43" v-if="form.discount_voucher">{{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kode Referral / Voucher<br/>
                                <small>Gunakan Kode untuk mendapatkan potongan harga</small>
                                </div>
                                <div class="font-weight-600">
                                    <a v-if="!form.fix_voucher" v-on:click="showVoucher" href="javascript:void(0)">Masukan Kode</a>
                                    <span class="badge badge-info" v-if="form.fix_voucher" href="javascript:void(0)">{{form.fix_voucher}} - {{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="input-group mb-3 m-t-10" v-if="showInputVoucher">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Kode Referral / Voucher ..." v-model="form.voucher" v-on:keyup.enter="checkVoucher">
                                <div class="input-group-append">
                                    <button v-on:click="checkVoucher" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">Gunakan Kode</button>
                                </div>
                            </div>

                            <h5>Total Harga : <span class="float-right"><s style="color:red" v-if="form.total_price_out">{{form.total_price_out}}</s> {{form.total_price}}</span></h5>
                            <button :disabled="processFetch" v-on:click="checkout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-shopping-cart"></i>
                                {{ processFetch ? 'Sedang membuat invoice ...' : 'Beli Paket' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Materikedinasan',
        metaInfo: {
            title: 'Paket kedinasan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                processFetch        : false,
                dataFetch           : false,
                userSubscriberFetch : false,
                dataList            : [],
                dataSubscriber      : [],
                emptyState          : false,
                showInputVoucher    : false,
                form                : {
                    id : '',
                    name : '',
                    text_price_out : '',
                    text_price : '',
                    active_days : '',
                    voucher : '',
                    price : 0,
                    fix_voucher : '',
                    discount_voucher : 0,
                    total_price : 0,
                    total_price_out : 0,
                }
            }
        },
        created() {
            this.getSubscriber();
            this.getData();
        },
        methods: {
            getSubscriber: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/subscriber';

                this.userSubscriberFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'kedinasan'
                    }
                }).then(res => {
                    this.userSubscriberFetch    = false;                
                    this.dataSubscriber         = res.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/package/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'kedinasan'
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }

                    this.form.voucher = res.data.referral;
                });
            },
            showVoucher: function() {
                this.showInputVoucher = true;
            },
            buyPackage: function(id) {
                var item    = this.dataList.find(item => item.id === id);

                this.form.id                = item.id;
                this.form.name              =  item.name;
                this.form.text_price_out    = item.text_price_out;
                this.form.text_price        = item.text_price;
                this.form.price             = item.price;
                this.form.total_price       = item.text_price;
                this.form.active_days       = item.active_days;
                // this.form.voucher                = '';
                this.form.fix_voucher            = '';
                this.form.discount_voucher       = 0;
                this.form.total_price_out        = 0;

            },
            checkVoucher: function() {
                let formData    = new FormData();
                formData.append('category', 'kedinasan');
                formData.append('voucher', this.form.voucher);
                formData.append('id', this.form.id);
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/check-voucher', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    let getResponse             = response.data.data;
                                    let total_price             = getResponse.text_total_price;
                                    let total_price_out         = getResponse.text_before_price;

                                    this.form.fix_voucher       = this.form.voucher;
                                    this.form.discount_voucher  = getResponse.text_discount;
                                    this.form.total_price       = total_price;
                                    this.form.total_price_out   = total_price_out;
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    return;
                });
            },
            rupiahFormat: function(bilangan) {                        
                var	reverse = bilangan.toString().split('').reverse().join(''),
                ribuan 	= reverse.match(/\d{1,3}/g);
                ribuan	= ribuan.join('.').split('').reverse().join('');
                return ribuan;
            },
            checkout: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Pembayaran',
                    text: 'Apakah kamu yakin ingin melakukan proses pembayaran ?',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if(result.value) {
                        this.processFetch = true;
                        let formData    = new FormData();
                        formData.append('category', 'kedinasan');
                        formData.append('voucher', this.form.fix_voucher);
                        formData.append('id', this.form.id);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/checkout', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            document.getElementById("closeModal").click();
                                            this.$router.push({
                                                name: 'invoice_detail',
                                                params: {
                                                    invoice : response.data.data
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                                this.processFetch = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.processFetch = false;
                            return;
                        });
                    }
                });
            }
        }
    }
</script>