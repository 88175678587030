<template>
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="m-t-10">Statistik Hasil Tryout</h5>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 m-t-10">
                Lihat Statistik berdasarkan tryout :
                <select class="form-control" v-model="id_answer" v-on:change="getStatistic()">
                    <option v-for="(item, index) in dataTryout" :key="index" :value="item.id">{{item.name}}</option>
                </select>
                <hr/>
            </div>

            <div class="col-md-6 m-t-10">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Nilai
                        </h4>
                        <div class="d-block mt-4">
                            <div v-for="(item, index) in this.dataList.list" :key="index" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">{{item.name}} : </div>
                                <div class="font-weight-600">
                                    {{item.value}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-10">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Grafik Nilai
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Statistik Tryout English Academy',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch : true,
                chartOptions: [],
                series: [],
                dataTryout  : [],
                dataList    : {
                    list : []
                },
                id_answer   : this.$parent.$parent.dataDetail.id_answer
            }
        },
        created() {
            this.getResultList();
        },
        methods: {
            getStatistic: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/result/statistic';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        tryout_id : this.$parent.$parent.dataDetail.id,
                        id_answer : this.id_answer
                    }
                }).then(res => {
                    if(res.data.status) {
                        const getResponse = res.data.data;
                        this.dataList = getResponse;

                        this.chartOptions = {
                            chart: {
                                type: 'pie',
                            },
                            colors: ['#2ed8b6', '#ff5370', '#F78812', '#D77FA1', '#F6D860', '#F2FFE9', '#B1D0E0', "#97BFB4"],
                            labels: this.dataList.labels,
                            responsive: [{
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 200
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }]
                        };

                        this.series = this.dataList.series;
                    }

                    this.dataFetch = false;
                });
            },

            getResultList: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/result/list';
                this.$http.get(uri,{
                    params : {
                        tryout_id : this.$parent.$parent.dataDetail.id
                    }
                }).then(res => {
                    this.dataTryout = res.data.data;
                    this.getStatistic();
                });
            }
        }
    }
</script>