<template>
    <div>
        <div v-if="dataFetchSchool" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchSchool">
            <div v-if="SchoolInfo.status">
                <div class="page-header">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Data Tryout CBT</h5>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <button v-for="(item, index) in dataCategory" :key="index" class="mt-2 btn rounded m-r-10" :class="activeTabs == item.id ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id)" type="button">{{item.name}}</button>

                                        <div class="input-group mb-3 m-t-10">
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                            <div class="input-group-append">
                                                <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                        class="fa fa-search"></i> Cari </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        
                                <div v-if="dataFetch" class="row">
                                    <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                                        <div class="d-block border rounded-lg p-3 m-t-10">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="mr-3 w-100">
                                                    <div class="d-flex align-items-center">
                                                        <div class="w-100">
                                                            <div class="skeleton-bar text-center">
                                                                <div class="sb-sm mb-2"></div>
                                                                <hr/>
                                                                <div class="sb-lg sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-thin mb-4"></div>
                                                                <div class="sb-lg"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-block border rounded-lg p-3 m-t-10">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="mr-3 w-100">
                                                    <div class="d-flex align-items-center">
                                                        <div class="w-100">
                                                            <div class="skeleton-bar text-center">
                                                                <div class="sb-sm mb-2"></div>
                                                                <hr/>
                                                                <div class="sb-lg sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-thin mb-4"></div>
                                                                <div class="sb-lg"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="row">
                                        <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                                            <div class="card card-theory">
                                                <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                                <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-cbt.png" alt="cover">

                                                <div class="card-body">
                                                    <div class="d-block mb-1">
                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                                            <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                                        </div>
                                                    </div>

                                                    <div class="d-block mb-1">
                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                            <div class="mr-3">Waktu</div>
                                                            <div class="font-weight-600">{{item.timer}} Menit</div>
                                                        </div>

                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                            <div class="mr-3">Jumlah Soal</div>
                                                            <div class="font-weight-600">{{item.total_questions}} Soal</div>
                                                        </div>

                                                        <div v-if="item.text_start_date != ''" class="alert alert-info text-center" role="alert">
                                                            Waktu Mulai Pelaksanaan<br/><b>{{ item.text_start_date }}</b> WIB
                                                        </div>

                                                        <router-link :to="{name : 'view_naskah_tryout_cbt_detail', params: { id: item.id}}" class="btn btn-primary rounded btn-block m-t-10">
                                                            <i class="fa fa-bars"></i> Lihat Naskah
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <nav aria-label="navigation">
                                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                            </nav>
                                        </div>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'NaskahTryoutCBT',
        metaInfo: {
            title: 'Naskah Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataFetchSchool : false,
                dataClass       : [],
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                activeTabs      : "",
                dataCategory : [],
                dataSchool : [],
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        components: {
            vPagination
        },
        validations: {
            school: {
                required
            },
            classname: {
                required
            },
        },
        created() {
            this.schoolInfo();
            this.getSchool();
        },
        methods: {
            schoolInfo: function () {
                this.dataFetchSchool = true;
                let uri = this.$apiconfig + 'user/school';
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool = false;

                    let getResponse = res.data;
                    this.SchoolInfo = getResponse;

                    if(this.SchoolInfo.status) {
                        this.getCategory();
                        this.getData();
                        this.getDiscuss();
                    } else {
                        console.log("kosong");
                    }
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/cbt/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        school_id   : this.SchoolInfo.data.id,
                        cbt_category_id : this.activeTabs,
                        keywords        : this.keywords,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            showItem: function(id) {
                var item    = this.dataList.find(item => item.id === id);

                this.form.id                = item.id;
                this.form.title             = item.title;

                let uri         = process.env.VUE_APP_APIHOST+'member/check/requirement';
      
                this.$http.get(uri,{
                    params : {
                        entity_id   : this.form.id,
                        type : 'cbt'
                    }
                }).then(res => {
                    this.dataRequirement = res.data.data;
                    this.requirement_id  = this.dataRequirement.id;
                });

            },
            uploadOne: function () {
                document.getElementById("file_attachment_1").click()
            },
            previewFilesOne(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileOne      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_one', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cbt');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileOne = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadTwo: function () {
                document.getElementById("file_attachment_2").click()
            },
            previewFilesTwo(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileTwo      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_two', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cbt');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileTwo = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadThree: function () {
                document.getElementById("file_attachment_3").click()
            },
            previewFilesThree(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileThree      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_three', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'cbt');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileThree = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            viewImage: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            kirimBukti: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kirim bukti ?',
                    text: 'Apakah kamu yakin akan mengirim bukti persyaratan ? ',
                    confirmButtonText: 'Kirim',
                    showCancelButton: true,
                    cancelButtonText: 'Cek kembali',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/submit/requirement', {
                            id: this.requirement_id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.showItem(this.form.id);
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });                
            },
            getDiscuss: function () {
                this.dataFetchGroup = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'cbt'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            },
            addToCart: function(id) {
                this.waitingCart    = true;
                var item            = this.dataList.find(item => item.id === id);

                let formData    = new FormData();
                this.form.id    = item.id;

                formData.append('id', this.form.id);
                formData.append('type', 'cbt');

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/add-cart', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.$parent.$parent.getUsersCart();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waitingCart = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waitingCart = false;
                    return;
                });

            },
            deleteCart: function(id) {
                this.waitingCartDelete    = true;
                var item            = this.dataList.find(item => item.id === id);

                let formData    = new FormData();
                this.form.id    = item.id;

                formData.append('id', this.form.id);
                formData.append('type', 'cbt');

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/delete-cart', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.$parent.$parent.getUsersCart();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waitingCartDelete = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waitingCartDelete = false;
                    return;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        school_id : this.school.id,
                        school_class : this.classname,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.schoolInfo();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            changeSchool: function() {
                this.classname = "";
                if(this.school.id) {
                    this.dataSchool.forEach(element => {
                        if(this.school.id == element.id) {
                            this.dataClass = element.data_class;
                        }
                    });
                } else {                    
                    this.dataClass = [];
                }
            },
            inputCode: function(id) {
                this.$swal({
                    title: 'Masukan Kode Voucher',
                    input: 'text',
                    confirmButtonText: 'Redeem Voucher'
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'user/redeem-voucher/school', {
                            code        : result.value,
                            school_id   : this.SchoolInfo.data.id,
                            type        : 'cbt',
                            tryout_id   : id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Selamat!',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                    confirmButtonText: 'Ulang'
                                }).then((result) => {
                                    if (result.value) {
                                        this.inputCode(id);
                                    }
                                });
                            }
                        })
                        }
                });
            }
        }
    }
</script>
