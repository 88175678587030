<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Monitoring Latihan - {{dataExercises.title}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'cbt_exercises'}">Latihan CBT</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Monitoring</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Data Latihan</h5>
                    </div>
                    <div class="card-body">
                        <table class="table mb-0">
                            <tbody>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book m-r-5"></i> Kategori</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataExercises.category}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-book-open m-r-5"></i> Judul Latihan</td>
                                    <td class="text-right">{{dataExercises.title}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-clock m-r-5"></i> Durasi Soal</td>
                                    <td class="text-right">{{dataExercises.timer}} Menit</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-users m-r-5"></i> Peserta Terdaftar</td>
                                    <td class="text-right">{{dataExercises.total_members}} Orang</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Daftar Peserta</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div v-if="dataExercises.users_type == 'content'" class="col-md-4">
                    <label for="exampleFormControlSelect1">Kelas</label>
                    <select class="form-control" v-model="school_class" v-on:change="getData">
                        <option value="">Lihat Semua Kelas</option>
                        <option v-for="(item_class, index_class) in dataExercises.list_class" :key="index_class">{{ item_class }}</option>
                    </select>
                </div>
                <div :class="dataExercises.users_type == 'content' ? 'col-md-8':'col-md-12'">
                    <div class="form-group">
                        <label for="exampleFormControlSelect1">Kata kunci</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                    </div>
                </div>
            </div>

            <div class="row">
                    <div class="col-md-12">
                        <div v-if="dataFetch">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <!-- <div class="table-responsive" style="max-height: 750px; overflow-y: auto;"> -->
                        <div v-if="!dataFetch">
                            <div v-if="dataList.length > 0" class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width:5%" class="text-center">No</th>
                                            <th>Data Siswa</th>
                                            <th class="text-center">Benar</th>
                                            <th class="text-center">Salah</th>
                                            <th class="text-center">Skor Akhir</th>
                                            <th class="text-center">Waktu Mengerjakan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td>
                                                {{ item.fullname }}<br/>
                                                <small>Email: <b>{{ item.email }}</b></small><br/>
                                                <small>Asal Sekolah: <b>{{ item.school_name }}</b></small><br/>
                                                <small>Kelas: <b>{{ item.school_class }}</b></small>
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.total_correct }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.total_wrong }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.skor_akhir }}
                                            </td>
                                            <td v-if="item.answer.length != '0'" class="text-center">
                                                {{ item.answer.start_date }}
                                            </td>
                                            <td v-if="item.answer.length == '0'" colspan="4" class="text-center">
                                                Belum mengerjakan Latihan
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>            
        </div>

    </div>
</template>

<script>
    export default {
        name: 'MonitoringExercisesCBT',
        metaInfo: {
            title: 'Monitoring Latihan CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                dataFetch           : true,
                fetchExercises      : true,
                dataExercises       : [],
                keywords            : "",
                school_class        : "",
                dataList            : [],
                emptyState          : false

            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/cbt/exercises/detail';

                    this.fetchExercises  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            show_member: 1
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataExercises = getResponse;
                            this.getData();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'cbt_exercises' });
                                }
                            });
                        }
                    });
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/exercises/monitoring-users';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords,
                        school_class : this.school_class
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }            
        }
    }
</script>