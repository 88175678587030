<template>
    <div>
        <div v-if="dataFetch">
            <p>Sedang memuat data...</p>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Profil Website</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Nomor Handphone</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.phone" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
                                    <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Wajib diisi</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Nomor Whatsapps</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.whatsapp" :class="{ 'is-invalid': submitted && $v.form.whatsapp.$error }">
                                    <div v-if="submitted && !$v.form.whatsapp.required" class="invalid-feedback">Wajib diisi</div>    
                                    <small><i>Info : Untuk nomor Whatsapps harap di awali dengan 62. Contoh : (628999123123)</i></small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Alamat Email</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.email" :class="{ 'is-invalid': submitted && $v.form.email.$error }">
                                    <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">Wajib diisi</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleFormControlTextarea1">Alamat Website</label>
                                    <textarea placeholder="Masukan Alamat..." class="form-control" id="exampleFormControlTextarea1"
                                        rows="4" :class="{ 'is-invalid': submitted && $v.form.address.$error }" v-model="form.address"></textarea>
                                    <div v-if="submitted && !$v.form.address.required" class="invalid-feedback">Wajib diisi</div>    

                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleFormControlTextarea1">Tentang Kami</label>
                                    <textarea placeholder="Masukan Alamat..." class="form-control" id="exampleFormControlTextarea1"
                                        rows="4" v-model="form.about" :class="{ 'is-invalid': submitted && $v.form.about.$error }"></textarea>
                                    <div v-if="submitted && !$v.form.about.required" class="invalid-feedback">Wajib diisi</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Url Video Tentang Kami</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url youtube..." v-model="form.url_video_about">
                                    <small><i>Contoh : https://youtu.be/qBZ_2j_a_1g</i></small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Akun Youtube</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url youtube..." v-model="form.url_youtube">
                                    <small><i>Contoh : https://www.youtube.com/channel/UCGtxHWeD1YzI21m9XS8SXpw/featured</i></small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Akun Facebook</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url facebook..." v-model="form.url_facebook">
                                    <small><i>Contoh : https://www.facebook.com/NhStudyClub/?_rdc=1&_rdr</i></small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Url Instagram</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url instagram..." v-model="form.url_instagram">
                                    <small><i>Contoh : https://www.instagram.com/tv/CHFn_-cnNBL-P60s2Ador86ImBaTiQ9XRShfPg0/?utm_medium=copy_link</i></small>
                                </div>
                            </div>
                            <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        whatsapp: 'ProfileWeb',
        metaInfo: {
            title: 'Pengaturan Profil',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                form        : [],
                submitted   : false,
                waiting     : false,
                dataFetch   : true
            }
        },
        created() {
            this.getData();
        },
        validations: {
            form: {
                phone: {
                    required
                },
                whatsapp: {
                    required
                },
                email: {
                    required
                },
                address: {
                    required
                },
                about: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('phone', this.form.phone);
                    formData.append('whatsapp', this.form.whatsapp);
                    formData.append('email', this.form.email);
                    formData.append('address', this.form.address);
                    formData.append('about', this.form.about);
                    formData.append('url_video_about', this.form.url_video_about);
                    formData.append('url_youtube', this.form.url_youtube);
                    formData.append('url_facebook', this.form.url_facebook);
                    formData.append('url_instagram', this.form.url_instagram);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/profile/web/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/profile/web';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                    }
                });
            }
        }
    }
</script>