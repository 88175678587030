<template>
    <div>
        <h5>Dashboard</h5>
        <hr />
        <div v-if="!userdata.fullname" class="d-block p-4 bg-primary-light rounded mb-4 griya-welcome-banner">
            <div class="d-flex align-items-center justify-content-between">
                <div class="w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-md m-b-2"></div>
                                <div class="sb-lg mt-2"></div>
                                <div class="sb-lg sb-thin mt-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="userdata.fullname" class="d-block p-4 bg-primary-light rounded griya-welcome-banner">
            <h4 class="m-t-10">Hallo {{userdata.fullname}}, Selamat datang di Griya Belajar!</h4>
            <div v-if="userdata.type != 'headmaster'" class="d-block h1 mb-2">Sudah siap belajar apa hari ini?</div>
            <div v-if="userdata.type == 'headmaster'" class="d-block h3 mb-2">Siap memantau perkembangan sekolah anda hari ini?</div>
            <p v-if="userdata.type != 'headmaster'" class="">Jangan lupa semangat! Karena banyak latihan dan tryout yang masih menunggu untuk
                diselesaikan.</p>
            <p v-if="userdata.type == 'headmaster'" class="">Akses informasi terkini mengenai aktivitas siswa dan guru yang siap dipantau. Terus berikan dukungan dan pantauan terbaik untuk keberhasilan siswa dan para guru.</p>
        </div>

        <div v-if="dataFetch && !userdata.fullname">
            <p>Sedang mengambil data...</p>
        </div>

        <div v-if="!dataFetch  && userdata.fullname">
            <div v-if="userdata.type != 'proctor' && userdata.type != 'content' && userdata.type != 'headmaster'">
                <h5 class="m-t-30">Program dari Griya Belajar</h5>
                <hr />

                <div class="row">
                    <div v-for="(item, index) in dataList" :key="index" class="col-xl-4 col-md-4 col-lg-6">
                        <div class="card prod-p-card" :class="item.bg">
                            <router-link :to="{name : item.url}">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                        <h6 class="m-b-5 text-white">{{item.name}}</h6>
                                        <h3 class="m-b-0 text-white">{{item.total}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-archive text-white"></i>
                                    </div>
                                </div>
                            </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.type == 'headmaster'">
                <h5 class="m-t-30">Statistik Data Pengguna</h5>
                <hr />
                <div class="row">
                    <div style="cursor:pointer" v-on:click="goToMenu('headmaster_student')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-success">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Siswa</h6>
                                    <h3 class="m-b-0 text-white">{{dataHeadmaster['member']['total']}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="cursor:pointer" v-on:click="goToMenu('headmaster_teacher')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-info">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Guru</h6>
                                    <h3 class="m-b-0 text-white">{{dataHeadmaster['content']['total']}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="cursor:pointer" v-on:click="goToMenu('headmaster_proctor')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-warning">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Proktor</h6>
                                    <h3 class="m-b-0 text-white">{{dataHeadmaster['proctor']['total']}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-primary">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Keseluruhan</h6>
                                    <h3 class="m-b-0 text-white">
                                        {{dataHeadmaster['member']['total']+dataHeadmaster['content']['total']+dataHeadmaster['proctor']['total']}}
                                    </h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card card-theory h-100">
                            <div class="card-body h-100">
                                <h6 class="job-card-desc">
                                    Grafik Jenis Kelamin (Siswa)
                                </h6>

                                <div v-if="dataHeadmaster['member']['total'] > 0">
                                    <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesSiswa"></apexchart>
                                </div>

                                <div class="text-center m-t-40" v-if="dataHeadmaster['member']['total'] < 1">
                                    <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                    <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card card-theory h-100">
                            <div class="card-body h-100">
                                <h6 class="job-card-desc">
                                    Grafik Jenis Kelamin (Guru)
                                </h6>

                                <div v-if="dataHeadmaster['content']['total'] > 0">
                                    <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesGuru"></apexchart>
                                </div>

                                <div class="text-center m-t-40" v-if="dataHeadmaster['content']['total'] < 1">
                                    <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                    <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card card-theory h-100">
                            <div class="card-body h-100">
                                <h6 class="job-card-desc">
                                    Grafik Jenis Kelamin (Proktor)
                                </h6>

                                <div v-if="dataHeadmaster['proctor']['total'] > 0">
                                    <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesProktor"></apexchart>
                                </div>

                                <div class="text-center m-t-40" v-if="dataHeadmaster['proctor']['total'] < 1">
                                    <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                    <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.type == 'proctor'">
                <h5 class="m-t-30">Statistik Data</h5>
                <hr />
                <div class="row">
                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-success">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                    <h6 class="m-b-5 text-white">Total Peserta</h6>
                                    <h3 class="m-b-0 text-white">{{dataProctor.total_user}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-users text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-info">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout SNBT</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_utbk}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-warning">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout Kedinasan</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_kedinasan}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                        <div class="card prod-p-card bg-danger">
                            <div class="card-body">
                                <div class="row align-items-center m-b-0">
                                    <div class="col">
                                <h6 class="m-b-5 text-white">Tryout AKM</h6>
                                <h3 class="m-b-0 text-white">{{dataProctor.total_akm}}</h3>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-file text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Glide,
        GlideSlide
    } from 'vue-glide-js'
    import 'vue-glide-js/dist/vue-glide.css'
    import {
        mapState
    } from 'vuex'

    export default {
        name: 'Members',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            [Glide.name]: Glide,
            [GlideSlide.name]: GlideSlide
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                dataProctor : [],
                dataHeadmaster : [],
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019'],
                    xaxis: {
                        categories: ['Jenis Kelamin']
                    },
                },
                seriesSiswa: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
                seriesGuru: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
                seriesProktor: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData');
        },
        computed: mapState(['userdata']),
        created() {
            this.getData();
        },
        methods: {
            goToMenu: function(menu=null) {
                this.$router.push({ name: menu });
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'dashboard/package';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataList = res.data.data;
                        this.getStatistic(res.data.type);
                    }
                    this.dataFetch = false;                
                });
            },
            getStatistic: function(type="") {
                if(type == 'proctor') {
                    let uri     = process.env.VUE_APP_APIHOST+'dashboard/statistic';
                    this.dataFetch  = true;
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dataProctor = res.data.data;
                        }
                        this.dataFetch = false;                
                    });
                }

                if(type == 'headmaster') {
                    let uri     = process.env.VUE_APP_APIHOST+'dashboard/statistic-headmaster';
                    this.dataFetch  = true;
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dataHeadmaster = res.data.data;

                            if(this.dataHeadmaster['member']['total']) {
                                this.seriesSiswa = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['member']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['member']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['member']['gender']['X']]
                                    }
                                ];
                            }

                            if(this.dataHeadmaster['content']['total']) {
                                this.seriesGuru = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['content']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['content']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['content']['gender']['X']]
                                    }
                                ];
                            }

                            if(this.dataHeadmaster['proctor']['total']) {
                                this.seriesProktor = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['proctor']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['proctor']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['proctor']['gender']['X']]
                                    }
                                ];
                            }
                        }
                        this.dataFetch = false;                
                    });
                }
            }
        },
    }
</script>