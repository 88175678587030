<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Kategori SKD</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:5%">No</th>
                                                <th>Kode</th>
                                                <th>Nama</th>
                                                <th>Passing Grade</th>
                                                <!-- <th>Aksi</th> -->
                                            </tr>
                                        </thead>
                                        <tbody v-if="dataList.length > 0">
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td class="text-center">{{++index}}</td>
                                                <td>{{item.code}}</td>
                                                <td>{{item.name}}</td>
                                                <td>{{item.passing_grade}}</td>
                                                <!-- <td>{{item.last_login}}</td> -->
                                            </tr>
                                        </tbody>
                                        <tbody v-if="dataList.length < 1">
                                            <tr colspan="5">
                                                <td>Data tidak ditemukan</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Category',
        metaInfo: {
            title: 'Data Kategori SKD',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                keywords    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skd/category/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            }            
        }
    }
</script>
