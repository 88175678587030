<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Konfirmasi Latihan SNBT Gratis</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button class="btn btn-primary rounded" type="button" data-toggle="modal" data-target="#modalConfirmRequirement">Konfirmasi Semua Persyaratan</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th style="width:10%">Email</th>
                                                        <th style="width:10%">Nama Lengkap</th>
                                                        <th style="width:10%">Nama Latihan</th>
                                                        <th class="text-center">Bukti 1</th>
                                                        <th class="text-center">Bukti 2</th>
                                                        <th class="text-center">Bukti 3</th>
                                                        <th class="text-center">Status</th>
                                                        <th class="text-center" style="width:15%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>{{item.email}}</td>
                                                        <td>{{item.fullname}}</td>
                                                        <td>{{item.exercises_name}}</td>
                                                        <td class="text-center">
                                                            <a v-if="item.attachment_one" target="_blank" :href="item.attachment_one">Lihat</a>
                                                            <span v-if="!item.attachment_one">
                                                                (Tidak ada)
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            <a v-if="item.attachment_two" target="_blank" :href="item.attachment_two">Lihat</a>
                                                            <span v-if="!item.attachment_two">
                                                                (Tidak ada)
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            <a v-if="item.attachment_three" target="_blank" :href="item.attachment_three">Lihat</a>
                                                            <span v-if="!item.attachment_three">
                                                                (Tidak ada)
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span v-if="item.status == 'waiting'" class="badge badge-secondary">Menunggu Konfirmasi</span>
                                                            <span v-if="item.status == 'accepted'" class="badge badge-success">Disetujui</span>
                                                            <span v-if="item.status == 'rejected'" class="badge badge-danger">Ditolak</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <div v-if="item.status == 'waiting'" class="btn-group mb-2 mr-2">
                                                                <button class="rounded btn btn-sm btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">Lainnya</button>
                                                                <div class="dropdown-menu" x-placement="top-start"
                                                                    style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -2px, 0px);">
                                                                    <a class="dropdown-item" v-on:click="changeStatus(item.id,'accepted')" href="javascript:void(0)"><i class="fa fa-thumbs-up"></i> Disetujui</a>
                                                                    <a class="dropdown-item" v-on:click="changeStatus(item.id,'rejected')" href="javascript:void(0)"><i class="fa fa-times"></i> Ditolak</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalConfirmRequirement" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm text-center">
                            <div class="font-weight-600" style="font-size:20px">Silahkan Pilih Latihan</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="m-t-10">
                                                <label>Pilih Latihan</label>
                                                <select class="form-control" id="exampleFormControlSelect1" v-model="form.entity" :class="{ 'is-invalid': submitted && $v.form.entity.$error }" v-on:change="changeExercises">
                                                    <option value="">Pilih Latihan</option>
                                                    <option v-for="(item, index) in listEntity" :key="index" :value="item">{{item.exercises_name}}</option>
                                                </select>

                                                <div v-if="submitted && !$v.form.entity.required" class="invalid-feedback">Latihan wajib diisi!</div>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block"> Konfirmasi Semua Persyaratan<i class="m-l-10 fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ConfirmExercisesFreeUtbk',
        metaInfo: {
            title: 'Konfirmasi Latihan Gratis SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        validations: {
            form: {
                entity: {
                    required
                }
            }
        },
        data() {
            return {
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 8,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                submitted : false,
                waiting : false,
                form : {
                    entity : ''
                },
                listEntity : [],
                exercises_name : ''
            }
        },
        created() {
            this.getData();
            this.getEntity();
        },
        methods: {
            changeExercises: function (){
                this.exercises_name = this.form.entity.exercises_name;
            },
            getEntity: function () {
                let uri = process.env.VUE_APP_APIHOST+'admin/list/exercises/list-category';
                
                this.$http.get(uri, {
                    params : {
                        type : 'utbk'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.listEntity = getResponse.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/list/exercises/free';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        type     : 'utbk',
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Informasi',
                        html: 'Apakah anda yakin akan melakukan semua konfirmasi untuk <br>'+this.exercises_name+'? ',
                        confirmButtonText: 'Konfirmasi',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                        }).then((result) => {
                        if (result.value) {
                            this.$http.post(process.env.VUE_APP_APIHOST + 'admin/exercises/free/confirm-all-free', {
                                entity_id   : this.form.entity_id,
                                type        : 'utbk',
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            document.getElementById("btnCloseModal").click();
                                            this.getData();
                                            this.getEntity();

                                            this.form.entity = "";
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                        }
                    });
                }
            },
            changeStatus: function (id, status) {
                let text = "";
                if(status == 'rejected') {
                    text = "Apakah kamu yakin akan menolak data ini ?";
                } else {
                    text = "Apakah kamu yakin akan menkonfirmasi data ini ?";
                }
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Status',
                    text: text,
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/exercises/free/confirm', {
                        id      : id,
                        status  : status
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }            
        }
    }
</script>
