<template>
    <div>
        <div v-if="dataOwned" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataOwned" >
            <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
                <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">{{dataGroup.data.text_link}}</span>
                <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                    Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
            </div>

            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Materi Text Sekolah</h5>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button v-for="(item, index) in dataCategory" :key="index" class="mt-2 btn rounded m-r-10" :class="activeTabs == item.id_category ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id_category)" type="button">{{item.category}}</button>

                                    <div class="input-group mb-3 m-t-20">
                                        <select class="form-control col-md-2 m-r-10" v-model="type" @change="getData">
                                            <option value="">Semua Tipe Materi</option>
                                            <option value="text">Text</option>
                                            <option value="youtube">Video</option>
                                        </select>
                                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                        <div class="input-group-append">
                                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                    class="fa fa-search"></i> Cari </button>
                                        </div>
                                    </div>
                                    
                                    <div v-if="dataFetch">
                                        <div v-for="(item,index) in [1,2,3]" :key="index" class="d-block border rounded-lg p-3 m-t-10">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="mr-3 w-100">
                                                    <div class="d-flex align-items-center">
                                                        <div class="w-100">
                                                            <div class="skeleton-bar">
                                                                <div class="sb-sm mb-2"></div>
                                                                <hr/>
                                                                <div class="sb-lg sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-thin mb-4"></div>
                                                                <div class="sb-xs"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!dataFetch">
                                        <div v-if="dataList.length > 0">
                                            <div v-for="(item, index) in dataList" :key="index" class="card text-left card-theory">
                                                <div class="card-body">
                                                    <h5 class="card-title">{{item.category}} - {{item.title}}</h5>
                                                    <p class="card-text">{{item.description}}</p>
                                                    <router-link v-if="item.type == 'text'" :to="{ name : 'member_materi_akm_detail' , params : {id: activeTabs, slug : item.code}}" class="btn btn-outline-primary rounded"><i class="fa fa-eye"></i> Lihat Materi</router-link>
                                                    <router-link v-if="item.type == 'youtube'" :to="{ name : 'member_materi_akm_detail' , params : {id: activeTabs, slug : item.code}}" class="btn btn-outline-primary rounded"><i class="fa fa-play"></i> Putar Video</router-link>
                                                </div>
                                            </div>

                                            <nav aria-label="navigation">
                                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                            </nav>
                                        </div>

                                        <div v-if="emptyState">
                                            <empty-state></empty-state>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MateriAkm',
        metaInfo: {
            title: 'Materi Sekolah',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataOwned       : true,
                dataFetch       : true,
                dataList        : [],
                emptyState      : false,
                dataCategory    : [],
                dataGroup       : [],
                keywords        : "",
                type            : "",
                activeTabs      : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getSubscriber();
        },
        methods: {
            getSubscriber: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/subscriber_akm';

                this.userSubscriberFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataOwned      = false;
                        this.dataCategory   = res.data.data;
                        this.activeTabs     = this.dataCategory[0].id_category;
                        this.getData();
                        this.getDiscuss();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Halaman ini tidak bisa di akses oleh pengguna gratis !',
                        });
                        this.$router.push({ name: 'upgrade_package', params: { type: 'akm' } });
                    }
                });
            },
            getDiscuss: function () {
                this.dataFetch = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'akm'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/akm/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.activeTabs,
                        type        : this.type,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>
