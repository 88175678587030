<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Voucher Sekolah (Perkelas)</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li v-if="users.type != 'proctor'" class="breadcrumb-item">
                                <router-link :to="{name : 'voucher_school_sessions'}">Voucher Sekolah (Perkelas)</router-link>
                            </li>
                            <li v-if="users.type == 'proctor'" class="breadcrumb-item">
                                <router-link :to="{name : 'monitoring_voucher'}">Voucher Sekolah (Perkelas)</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header" v-if="fetch_users">
            <div class="page-block">
                <div class="row align-text-center">
                    <div class="col-md-12">
                        <p>Loading...</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-header" v-if="!fetch_users">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Kode Voucher</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.code" :class="{ 'is-invalid': submitted && $v.form.code.$error }">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Kode Voucher wajib diisi!</div>    
                                    <small><i>Kode voucher tidak boleh sama.</i></small>
                                </div>
                                
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Berlaku Hingga (Masukan Waktu Awal)</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.expired_at" :class="{ 'is-invalid': submitted && $v.form.expired_at.$error }">
                                    <div v-if="submitted && !$v.form.expired_at.required" class="invalid-feedback">Masukan batas berlaku!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Berlaku Hingga (Masukan Waktu Akhir)</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.expired_end" :class="{ 'is-invalid': submitted && $v.form.expired_end.$error }">
                                    <div v-if="submitted && !$v.form.expired_end.required" class="invalid-feedback">Masukan batas berlaku!</div>    
                                </div>

                                <div class="form-group col-md-6" :class="users.type != 'proctor' ? '':'d-none'">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.school_id" :class="{ 'is-invalid': submitted && $v.form.school_id.$error }" v-on:change="getClass();getTryout()" :disabled="form.id != ''">
                                        <option value="">Pilih Sekolah</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.school_id.required" class="invalid-feedback">Sekolah wajib dipilih!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Kelas</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.class" :class="{ 'is-invalid': submitted && $v.form.type.$error }" :disabled="form.id != ''">
                                        <option value="">Pilih Kelas</option>
                                        <option v-if="dataClass.length > 0" value="all">Semua Kelas</option>
                                        <option v-for="(itemDetail, indexDetail) in dataClass" :key="indexDetail" :value="itemDetail">{{itemDetail}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.class.required" class="invalid-feedback">Kelas wajib dipilih!</div>    
                                </div>

                                <div class="form-group col-md-6" :class="users.type != 'proctor' ? '':'d-none'">
                                    <label for="exampleFormControlSelect1">Paket</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }" v-on:change="getTryout">
                                        <option value="">Pilih Paket</option>
                                        <option value="utbk">SNBT</option>
                                        <option value="kedinasan">Kedinasan</option>
                                        <option value="psikotest">Psikotest</option>
                                        <option value="akm">Sekolah (AKM)</option>
                                        <option value="cbt">CBT</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Paket wajib dipilih!</div>    
                                </div>

                                <div class="form-group col-md-6" v-if="form.type != 'cbt'">
                                    <label for="exampleFormControlSelect1">{{form.type == 'psikotest' ? 'Paket Psikotest':'Tryout'}}</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.tryout_id" :class="{ 'is-invalid': submitted && $v.form.tryout_id.$error }">
                                        <option value="">Pilih {{form.type == 'psikotest' ? 'Paket Psikotest':'Tryout'}}</option>
                                        <option v-for="(item, index) in dataTryout" :key="index" :value="item.id">{{item.title}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.tryout_id.required" class="invalid-feedback">Tryout wajib dipilih!</div>    
                                </div>

                                <div class="form-group col-md-6" v-if="form.type == 'cbt'">
                                    <label for="exampleInputEmail1">Pilih Tryout</label>
                                    <multiselect
                                        v-model="form.tryout_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih data" label="title" track-by="id" :options="dataTryout"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>

                                <div class="form-group col-md-6" v-if="form.type == 'cbt' && users.type != 'proctor'">
                                    <label for="exampleInputEmail1">Pilih Materi</label>
                                    <multiselect
                                        v-model="form.theory_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih data" label="title" track-by="id" :options="dataTheory"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>

                                <div class="form-group col-md-6" v-if="form.type == 'cbt' && users.type != 'proctor'">
                                    <label for="exampleInputEmail1">Pilih Latihan</label>
                                    <multiselect
                                        v-model="form.exercises_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih data" label="title" track-by="id" :options="dataExercises"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>
                            </div>
                            <div class="row" v-if="form.id">
                                <div class="col-md-12">
                                    <div class="alert alert-warning" role="alert">
                                        <b>Keterangan : <i>Pilihan sekolah dan kelas hanya bisa dipilih 1x</i></b>.
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link v-if="users.type != 'proctor'" :to="{name : 'voucher_school_sessions'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                            <router-link v-if="users.type == 'proctor'" :to="{name : 'monitoring_voucher'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'VoucherSchool',
        metaInfo: {
            title: 'Form Voucher Sekolah (Perkelas)',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getUsers();
        },
        data() {
            return {
                label   : 'Tambah',
                form : {
                    id          : "",
                    school_id   : "",
                    type        : "",
                    code        : "",
                    class       : "",
                    expired_at  : "",
                    expired_end : "",
                    tryout_id   : "",
                    theory_id   : "",
                    exercises_id   : "",
                },
                dataSchool      : [],
                dataClass      : [],
                dataTryout      : [],
                dataTheory      : [],
                dataExercises   : [],
                submitted       : false,
                waiting         : false,
                users           : [],
                fetch_users     : true
            }
        },
        validations: {
            form: {
                code: {
                    required
                },
                class: {
                    required
                },
                expired_at: {
                    required
                },
                expired_end: {
                    required
                },
                school_id: {
                    required
                },
                type: {
                    required
                },
                tryout_id: {
                    required
                }
            }
        },
        methods: {
            getUsers: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/info';
                
                this.fetch_users = true;
                this.$http.get(uri).then(res => {
                    this.users = res.data;
                    this.fetch_users = false;
                    this.getSchool();
                    this.getDataByID(this.$route.params.id);

                    if(this.users.school_id && this.users.type == 'proctor') {
                        this.form.school_id = this.users.school_id;
                        this.form.type      = 'cbt'
                        setTimeout(() => {
                            this.getClass();
                            this.getTryout();
                        }, 500);
                    } else {
                        this.getTryout();
                    }

                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getClass: function() {
                if(this.form.school_id) {
                    this.dataSchool.forEach(element => {
                        if(this.form.school_id == element.id) {
                            if(element.data_class) {
                                this.dataClass = element.data_class;
                            } else {
                                this.dataClass = [];
                            }
                        }
                    });
                } else {
                    this.dataClass = [];
                }
            },
            getTryout: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/tryout';
                this.$http.get(uri, {
                    params : {
                        type : this.form.type,
                        school_id: this.form.school_id
                    }
                }).then(res => {
                    this.dataTryout   = res.data.data;

                    if(this.form.type == 'cbt') {
                        this.getTheory();
                        this.getExercises();
                    }
                });
            },
            getTheory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/theory';
                this.$http.get(uri, {
                    params : {
                        type : this.form.type
                    }
                }).then(res => {
                    this.dataTheory   = res.data.data;
                });
            },
            getExercises: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/exercises';
                this.$http.get(uri, {
                    params : {
                        type : this.form.type
                    }
                }).then(res => {
                    this.dataExercises   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('school_id', this.form.school_id);
                    formData.append('type', this.form.type);
                    formData.append('code', this.form.code);
                    formData.append('class', this.form.class);
                    formData.append('expired_at', this.form.expired_at);
                    formData.append('expired_end', this.form.expired_end);
                    if(this.form.type == 'cbt') {
                        formData.append('tryout_id', JSON.stringify(this.form.tryout_id));
                        formData.append('theory_id', JSON.stringify(this.form.theory_id));
                        formData.append('exercises_id', JSON.stringify(this.form.exercises_id));
                    } else {
                        formData.append('tryout_id', this.form.tryout_id);
                    }
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    if(this.users.type != 'proctor') {
                                        this.$router.push({ name: 'voucher_school_sessions' });
                                    } else {
                                        this.$router.push({ name: 'monitoring_voucher' });
                                    }
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            
                            this.form.id            = getResponse.id;
                            this.form.code          = getResponse.code;
                            this.form.school_id     = getResponse.school_id;
                            this.form.type          = getResponse.type;
                            this.form.class         = getResponse.class;
                            this.form.expired_at    = getResponse.expired_at;
                            this.form.expired_end   = getResponse.expired_end;

                            this.getTryout();
                            this.getClass();

                            if(this.form.type == 'cbt') {
                                this.form.tryout_id     = getResponse.list_tryout;
                                this.form.theory_id     = getResponse.list_theory;
                                this.form.exercises_id     = getResponse.list_exercises;
                            } else {
                                this.form.tryout_id     = getResponse.tryout_id;                                                                
                            }
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    if(this.users.type != 'proctor') {
                                        this.$router.push({ name: 'voucher_school_sessions' });
                                    } else {
                                        this.$router.push({ name: 'monitoring_voucher' });
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>