<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Kelola Soal - {{dataTryout.pppk_tryout.title}}</h5>
                        </div>

                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'pppk_tryout'}">Tryout PPPK</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Kelola Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Data Tryout</h5>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i> Judul Tryout</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.pppk_tryout.title}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-list m-r-5"></i> Total Soal</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.list_questions.length}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-list m-r-5"></i> Total Durasi</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.pppk_tryout.timer}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-list m-r-5"></i> Total Passing Grade</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.pppk_tryout.passing_grade}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-thermometer-half m-r-5"></i> Status:</td>
                                    <td style="border-top:0px !important" class="text-right">
                                        <span v-if="dataTryout.pppk_tryout.status == 'publish' " class="badge badge-success">Publish</span>
                                        <span v-if="dataTryout.pppk_tryout.status == 'draft' " class="badge badge-secondary">Draft</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Komponen Soal</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <td style="width:5%;text-align:center">No</td>
                                    <td>Nama Kategori</td>
                                    <td style="width:5%;text-align:center">Passing Grade</td>
                                    <td style="width:5%;text-align:center">Durasi</td>
                                    <td style="width:5%;text-align:center">Soal</td>
                                    <td>Aksi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataTryout.pppk_tryout.pppk_category" :key="index">
                                    <td style="text-align:center">{{++index}}</td>
                                    <td>{{item.name}}</td>
                                    <td style="width:5%;text-align:center">{{item.passing_grade}}</td>
                                    <td style="width:5%;text-align:center">{{item.timer}} Menit</td>
                                    <td style="width:5%;text-align:center">{{item.total_questions}} Soal</td>
                                    <td style="width:15%;text-align:center">
                                        <button type="button" v-on:click="aturDurasi(item.name, item.id)" class="btn btn-sm btn-outline-primary rounded"><i class="fa fa-clock"></i> Atur Ulang Durasi</button>
                                        <button type="button" v-on:click="aturPassingGrade(item.name, item.id)" class="btn btn-sm btn-outline-primary rounded m-l-10"><i class="fa fa-edit"></i> Atur Ulang Passing Grade</button>
                                        <button type="button" v-on:click="kelolaSoal(item.id)" class="btn btn-sm btn-primary rounded m-l-10"><i class="fas fa-list"></i> Kelola Soal</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>         
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Questions',
        metaInfo: {
            title: 'Kelola Tryout PPPK',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label               : 'Tambah',
                currentQuestions    : '',
                showQuestions       : false,
                dataTryout          : {
                    pppk_tryout : [],
                    list_questions : []
                },
                dataCategory        : [],
                dataSubCategory     : [],
                form : {
                    id                  : "",
                    pppk_category_id    : "",
                    pppk_tryout_id      : "",
                    point               : "",
                    question            : "",
                    options_a           : "",
                    options_b           : "",
                    options_c           : "",
                    options_d           : "",
                    options_e           : "",
                    answer              : "",
                    discussion          : "",
                    point_a           : "",
                    point_b           : "",
                    point_c           : "",
                    point_d           : "",
                    point_e           : ""
                },
                submitted       : false,
                waiting         : false
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/pppk/tryout/manage_questions';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataTryout = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'pppk_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            aturDurasi: function (title, id) {
                this.$swal({
                    input: 'number',
                    title: 'Atur Ulang Durasi',
                    text: title,
                    confirmButtonText: 'Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    inputPlaceholder: "Masukan menit"
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/pppk/tryout/reset_timer', {
                            id    : id,
                            timer : result.value
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((resultDetail) => {
                                    if (resultDetail.value) {
                                        this.getDataByID(this.$route.params.id);                                        
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })                        
                    }
                });
            },
            aturPassingGrade: function (title, id) {
                this.$swal({
                    input: 'number',
                    title: 'Atur Ulang Passing Grade',
                    text: title,
                    confirmButtonText: 'Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    inputPlaceholder: "Masukan menit"
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/pppk/tryout/reset_passing_grade', {
                            id    : id,
                            passing_grade : result.value
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((resultDetail) => {
                                    if (resultDetail.value) {
                                        this.getDataByID(this.$route.params.id);                                        
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })                        
                    }
                });
            },
            kelolaSoal: function(id) {
                this.$router.push({
                    name : 'pppk_tryout_questions',
                    params : {
                        pppk_tryout_id : this.$route.params.id,
                        id: id
                    }
                });
            },
        }
    }
</script>