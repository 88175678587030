
<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <p>Sedang memuat data soal...</p>
        </div>
        <div v-if="!dataFetch">
            <div class="alert alert-primary alert-dismissible fade show" role="alert">
                Saat ini kamu sedang mengerjakan <strong>{{dataList.tryout.title}}</strong>.
                <button style="margin-top:-3px" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="page-header nocopy">
                        <div class="page-block">
                            <div class="page-header-title">                                
                                <b style="font-size:18px" class="m-t-10">Soal Nomor #{{number_questions}} </b>
                                <hr/>
                                <div class="m-b-20" v-html="form.questions"></div>

                                <div class="form-check" v-if="form.options_a">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_a" value="a" :checked="currentSaveAnswer[currentQuestions]  == 'a'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('a')">
                                    <label class="form-check-label" for="options_a">
                                        <div v-html="form.options_a"></div>
                                    </label>
                                </div>

                                <div class="form-check" v-if="form.options_b">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_b" value="b" :checked="currentSaveAnswer[currentQuestions]  ==  'b'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('b')">
                                    <label class="form-check-label" for="options_b">
                                        <div v-html="form.options_b"></div>
                                    </label>
                                </div>

                                <div class="form-check" v-if="form.options_c">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_c" value="c" :checked="currentSaveAnswer[currentQuestions]  ==  'c'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('c')">
                                    <label class="form-check-label" for="options_c">
                                        <div v-html="form.options_c"></div>
                                    </label>
                                </div>

                                <div class="form-check" v-if="form.options_d">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_d" value="d" :checked="currentSaveAnswer[currentQuestions]  ==  'd'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('d')">
                                    <label class="form-check-label" for="options_d">
                                        <div v-html="form.options_d"></div>
                                    </label>
                                </div>

                                <div class="form-check" v-if="form.options_e">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_e" value="e" :checked="currentSaveAnswer[currentQuestions]  ==  'e'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('e')">
                                    <label class="form-check-label" for="options_e">
                                        <div v-html="form.options_e"></div>
                                    </label>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-center flex-wrap vertical-align-center">
                                        <button class="btn btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                        <button class="btn btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Soal Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:15px" class="m-t-10">Sisa Waktu</b>
                                <hr/>
                                <vue-countdown-timer
                                    @start_callback="startCallBack"
                                    @end_callback="endCallBack"
                                    :start-time="startTime"
                                    :end-time="endTime"
                                    :interval="1000">

                                    <div class="text-center" slot="countdown" slot-scope="scope">
                                        <span style="font-size:25px">{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>
                                    </div>
                                </vue-countdown-timer>                        

                            </div>
                        </div>
                    </div>

                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:15px" class="m-t-10">Nomor Soal</b>
                                <hr/>
                                <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                    <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataList.tryout.list_questions" :key="index"
                                        type="button"
                                        class="text-center btn rounded m-l-10 m-t-10" v-on:click="setQuestions(item.id, item.number)"
                                        :class="currentSaveQuestions[item.id] == true ? 'btn-success': item.status == 'true' ? 'btn-success':item.id == currentQuestions ? 'btn-primary':'btn-outline-primary' "
                                        >{{item.number}} 
                                    </button>                                    
                                </div>
                                <button class="m-t-10 btn btn-primary rounded btn-block" type="button" v-on:click="finishQuiz">Selesai</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #04bd04 !important;
        border-color: #000 !important;
    }
</style>

<script>
    export default {
        name: 'StartQuiz',
        metaInfo: {
            title: 'Mengerjakan Soal SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                tabFocus    : false,
                dataFetch   : true,
                dataList    : {
                    tryout : {
                        title : ''
                    }
                }, 
                hours       : 0,
                minutes     : 0,
                seconds     : 0,
                startTime   : '',
                endTime     : '',
                currentQuestions : '',
                number_questions : 1,
                form : {
                    tryout_answer : '',
                    questions     : '',
                    options_a     : '',
                    options_b     : '',
                    options_c     : '',
                    options_d     : '',
                    options_e     : '',
                    your_answer   : '',
                    tryout_question_id   : ''
                },
                currentSaveQuestions : [],
                currentSaveAnswer    : [],
                changeAnswer         : [],
                countDown            : 0,
                timer                : []
            }
        },
        created() {
            this.startQuiz();
            this.timer[this.number_questions] = 0;
            this.countDownTimer();
        },
        methods: {
            countDownTimer() {
                setTimeout(() => {    
                    let waktu_mengerjakan = 0;

                    if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                        waktu_mengerjakan += this.timer[this.number_questions];
                    } else {
                        this.timer[this.number_questions] += waktu_mengerjakan;
                    }

                    this.countDown += 1
                    this.timer[this.number_questions] = this.countDown
                    this.countDownTimer()
                }, 1000)
            },            
            startQuiz: function() {
                let formData = new FormData();

                formData.append('code', this.$route.params.slug);
                formData.append('id', this.$route.params.id);
                formData.append('psikotest_id', this.$route.params.psikotest_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/ist/tryout/start_detail', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        let getResponse = response.data;
                        this.startTime  = getResponse.data.tryout.currentTime;
                        this.endTime    = getResponse.data.tryout.expireDate;
                        this.dataList   = getResponse.data;
                        this.tryout_answer = this.dataList.tryout.tryout_answer;

                        this.setQuestions(this.dataList.tryout.list_questions[0].id, 1);

                        this.dataFetch = false;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_psikotest_ist_overview',
                                    params : {
                                        id : this.$route.params.psikotest_id,
                                        slug : this.$route.params.slug
                                    }
                                });
                            }
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            startCallBack: function () {
                console.log('waktu dimulai');
            },
            endCallBack: function () {
                this.stop();
            },
            setQuestions: function(id, number) {
                var item                 = this.dataList.tryout.list_questions.find(item => item.id === id);
                this.currentQuestions    = id;
                this.number_questions    = number;

                this.form.tryout_question_id    = item.id;
                this.form.questions             = item.question;
                this.form.options_a             = item.options.a;
                this.form.options_b             = item.options.b;
                this.form.options_c             = item.options.c;
                this.form.options_d             = item.options.d;
                this.form.options_e             = item.options.e;                

                if(this.changeAnswer[item.id]) {
                    this.changeAnswer[item.id]  = this.currentSaveAnswer[item.id];
                    this.form.your_answer       = this.currentSaveAnswer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.currentSaveQuestions[item.id]  = true;
                    this.currentSaveAnswer[item.id]     = this.form.your_answer;
                }

                this.timer[this.number_questions] = item.timer;
                
                if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                    this.countDown = this.timer[this.number_questions];                
                } else {
                    this.timer[this.number_questions] = 0;
                    this.countDown = 0;
                }

                this.scrollToTop();
            },
            stop: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/ist/tryout/stop_detail', {
                    tryout_answer_id    : this.tryout_answer,
                    title               : this.dataList.tryout.title,
                    ist_category_id     : this.dataList.tryout.ist_category_id,
                    ist_sub_category_id : this.dataList.tryout.ist_sub_category_id,
                    slug                : this.$route.params.slug,
                    psikotest_id        : this.$route.params.psikotest_id
                })
                .then(response => {
                    if (response.data.status) {
                        let btnMessage = 'Lanjut ke soal berikutnya <i class="fa fa-arrow-right"></i>';
                        if(response.data.data.finished) {
                            btnMessage = "Lihat Hasil";
                        }
                        this.$swal({
                            icon: 'success',
                            title: 'Berhasil',
                            html: response.data.message,
                            confirmButtonText: btnMessage
                        }).then((result) => {
                            if (result.value) {
                                if(response.data.data.break) {
                                    this.$router.push({
                                        name: 'member_psikotest_ist_tryout_break',
                                        params : {
                                            psikotest_id    : this.$route.params.psikotest_id,
                                            slug            : this.$route.params.slug
                                        }
                                    });
                                } else {
                                    if(response.data.data.finished) {
                                        this.$router.push({
                                            name: 'member_psikotest_overview',
                                            params : {
                                                id : this.$route.params.psikotest_id                                            }
                                        });
                                    } else {
                                        this.$router.push({
                                            name: 'member_psikotest_ist_overview',
                                            params : {
                                                id : this.$route.params.psikotest_id,
                                                slug : this.$route.params.slug
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataList.tryout.list_questions.length;

                if(next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id                  = this.dataList.tryout.list_questions[number].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id                  = this.dataList.tryout.list_questions[next_number-1].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveAnswer : function(your_answer="") {                
                this.form.your_answer = your_answer;
                let formData = new FormData();
                formData.append('answer', this.form.your_answer);
                formData.append('tryout_question_id', this.form.tryout_question_id);
                formData.append('tryout_answer_id', this.tryout_answer);
                formData.append('timer', this.timer[this.number_questions]);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/ist/tryout/answer/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.currentSaveQuestions[this.form.tryout_question_id] = true;
                        this.currentSaveAnswer[this.form.tryout_question_id]    = your_answer;
                        this.changeAnswer[this.form.tryout_question_id]         = true;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            finishQuiz: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Selesai mengerjakan ?',
                    html: 'Jawaban yang telah di submit tidak dapat diubah.<br/>Harap periksa kembali jawaban yang telah di simpan',
                    confirmButtonText: 'Selesai',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        this.stop();
                    }
                });
            },
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
    }
</script>