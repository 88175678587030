<template>
    <div>
        <div v-if="userSubscriberFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!userSubscriberFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Latihan CPNS</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button v-for="(item, index) in dataCategory" :key="index" class="mt-2 btn rounded m-r-10 m-t-10" :class="activeTabs == item.id ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id)" type="button">{{item.name}}</button>

                                <div class="input-group mb-3 m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        <div v-if="dataFetch" class="row">
                            <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                                    <div class="d-block border rounded-lg p-3 m-t-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar text-center">
                                                            <div class="sb-sm mb-2"></div>
                                                            <hr/>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-4"></div>
                                                            <div class="sb-lg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div v-if="!dataFetch">
                            <div v-if="dataList.length > 0" class="row">
                                <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                                    <div class="card card-theory">
                                        <div class="card-body">
                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                                </div>
                                            </div>

                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Kategori</div>
                                                    <div class="font-weight-600">{{item.category}}</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Waktu</div>
                                                    <div class="font-weight-600">{{item.timer}} Menit</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Jumlah Soal</div>
                                                    <div class="font-weight-600">{{item.total_questions}} Soal</div>
                                                </div>
                                                <router-link :to="{name : 'member_exercises_skd_overview', params: { type_exercises: 'cpns', slug: item.code }}" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Kerjakan Soal</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <nav aria-label="navigation">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                </div>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'exercisesFreeCpns',
        metaInfo: {
            title: 'exercises Gratis CPNS',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                userSubscriberFetch : false,
                dataFetch       : false,
                dataList        : [],
                dataCategory        : [],
                emptyState      : false,
                keywords        : "",
                activeTabs      : "",
                form            : {
                    id : '',
                    title : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                dataRequirement:[],
                requirement_id:""
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getSubscriber();
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                    this.activeTabs     = this.dataCategory[0].id;
                    this.getData();
                });
            },
            getSubscriber: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/subscriber';

                this.userSubscriberFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'cpns'
                    }
                }).then(res => {
                    this.dataSubscriber         = res.data;

                    if(this.dataSubscriber.status) {
                        this.userSubscriberFetch    = false;
                        this.getCategory();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Halaman ini tidak bisa di akses oleh pengguna gratis !',
                        });
                        this.$router.push({ name: 'upgrade_package', params: { type: 'cpns' } });
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/exercises/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type_exercises : 'cpns',
                        category_id : this.activeTabs,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            }
        }
    }
</script>
