<template>
    <div class="auth-wrapper">
        <div class="auth-content">
            <div class="card">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center">
                        <div class="card-body">
                            <img src="assets/images/logo.png" alt="" class="img-fluid mb-4">
                            <h3 class="mb-3 f-w-400">Login</h3>
                            <hr />
                            <form v-on:submit.prevent="onSubmit">

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-mail"></i></span>
                                    </div>
                                    <input type="email" class="form-control" :class="{ 'is-invalid': submitted && $v.email.$error }" v-model="email" placeholder="Masukan email">
                                    <div v-if="submitted && !$v.email.required" class="invalid-feedback">Email wajib diisi!</div>    
                                </div>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                    </div>
                                    <input :type="showPassword ? 'text' : 'password'" class="form-control" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Masukan password">
                                    <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div> 

                                    <div class="input-group-append">
                                        <button class="btn btn-primary" @click="showPassword = !showPassword" type="button">
                                            <i :class="[showPassword ? 'feather icon-eye-off' : 'feather icon-eye']"></i>
                                        </button>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-block btn-primary mb-4" :disabled="waiting">
                                    {{ waiting ? 'Harap tunggu ...' : 'Masuk Ke Aplikasi' }}</button>
                            </form>

                            <p class="mb-2 text-muted">Belum Punya Akun? <router-link :to="{name: 'signup'}"
                                    class="f-w-400">Daftar Sekarang!</router-link>
                            </p>

                            <p class="mb-0 text-muted">Lupa Password? <router-link :to="{name: 'forgot_password'}"
                                    class="f-w-400">Klik Disini!</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Login',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                email: "",
                password: "",
                submitted: false,
                waiting: false,
                showPassword: false
            }
        },
        validations: {
            email: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST+'auth/signin', {
                        email    : this.email,
                        password : this.password
                    })
                    .then(response => {
                        if(response.data.status) {
                          const token = response.data.user.key;
                          localStorage.setItem('auth_token', token);
                          location.href = location.origin + location.pathname + location.search
                        } else {
                            this.waiting = false;
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }
        }
    }
</script>