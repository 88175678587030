<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{name : 'invoice_list'}" href="javascript:void(0)" ><i class="fa fa-arrow-left m-r-10"></i></router-link> Tagihan - {{invoice}}</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="table-responsive">
                            <table class="table invoice-detail-table">
                                <thead>
                                    <tr class="thead-default">
                                        <th>Nama Item</th>
                                        <th style="width:15%" class="text-right">Harga</th>
                                    </tr>
                                </thead>
                                <tbody v-if="dataList.list_detail.length > 0">
                                    <tr v-for="(item, index) in dataList.list_detail" :key="index">
                                        <td>
                                            <h6>{{item.item_name}}</h6>
                                            <p class="m-0">Program : {{item.item_type.toUpperCase()}}</p>
                                        </td>
                                        <td class="text-right">
                                            <s class='text-muted' v-if="item.text_price_out">{{item.text_price_out}}<br/></s>
                                            {{item.text_price}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr/>
                            <table class="table rounded table-responsive invoice-table invoice-total">
                                <tbody>
                                    <tr>
                                        <th>Sub Total :</th>
                                        <td>{{dataList.total_price}}</td>
                                    </tr>
                                    <tr>
                                        <th>Diskon :</th>
                                        <td>{{dataList.discount}}</td>
                                    </tr>
                                    <tr class="text-info">
                                        <td>
                                            <hr>
                                            <h4 class="text-primary m-r-10">Total Harga:</h4>
                                        </td>
                                        <td>
                                            <hr>
                                            <h4 class="text-primary">{{dataList.total_overall_price}}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">No Invoice</div>
                                <div class="font-weight-600">{{dataList.invoice}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Batas Pembayaran</div>
                                <div class="font-weight-600">{{dataList.expired_at}}</div>
                            </div>

                            <div v-if="dataList.transaction_status == 'pending'" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Waktu Tersisa</div>
                                <div class="font-weight-600">
                                    <vue-countdown-timer
                                        @start_callback="startCallBack"
                                        @end_callback="endCallBack"
                                        :start-time="startTime"
                                        :end-time="endTime"
                                        :interval="1000">

                                        <div class="text-center" slot="countdown" slot-scope="scope">
                                            <span>{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>  
                                        </div>
                                    </vue-countdown-timer>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status Pembayaran</div>
                                <div class="font-weight-600">
                                    <span v-if="dataList.transaction_status == 'cancel'" class="badge badge-yellow">
                                        Dibatalkan
                                    </span>

                                    <span v-if="dataList.transaction_status == 'expire'" class="badge badge-danger">
                                        Kadaluarsa
                                    </span>

                                    <span v-if="dataList.transaction_status == 'pending'" class="badge badge-secondary">
                                        Menunggu Pembayaran
                                    </span>

                                    <span v-if="dataList.transaction_status == 'settlement'" class="badge badge-success">
                                        Lunas
                                    </span>
                                </div>
                            </div>
                        </div>

                        <button v-if="dataList.transaction_status == 'pending'" type="button" v-on:click="bayarTagihan(dataList.token_midtrans)" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-shopping-cart"></i> Bayar Tagihan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InvoiceDetail',
        metaInfo: {
            title: 'Invoice',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                invoice             : this.$route.params.invoice,
                waitingCartDelete   : false,
                processFetch        : false,
                startTime           : '1',
                endTime             : '5',
                emptyState   : false,
                dataFetch   : false,
                dataList    : {
                    list_detail : []
                }
            }
        },
        mounted() {
            let externalScript = document.createElement('script')
            if( process.env.VUE_APP_APIHOST == 'https://api.griyabelajar.com/') {
                externalScript.setAttribute('src', 'https://app.midtrans.com/snap/snap.js')
                externalScript.setAttribute('data-client-key', 'Mid-client-Z5gdU4TBkMrsGSJe')
            } else {
                externalScript.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js')
                externalScript.setAttribute('data-client-key', 'SB-Mid-client-la1lYcpld7SRz2mI')
            }
            externalScript.setAttribute('async', true)
            document.head.appendChild(externalScript)
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/invoice/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        invoice : this.invoice
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;                
                        this.emptyState = false;
                        this.dataList   = res.data.data;

                        if(this.dataList.list_detail.length < 1) {
                            this.emptyState = true;
                        }

                        this.startTime = this.dataList.current_date;
                        this.endTime = this.dataList.expired_at;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'invoice_list' });
                            }
                        });
                    }
                });
            },
            bayarTagihan: function(token) {
                window.snap.pay(token, {
                    onSuccess: function () {
                        location.reload();
                    },
                    // Optional
                    onPending: function () {
                        location.reload();
                    },
                    // Optional
                    onError: function () {
                        location.reload();
                    }
                });
            },
            startCallBack: function () {
                console.log("pembayaran di mulai");
            },
            endCallBack: function () {
                let formData    = new FormData();
                formData.append('id', this.dataList.id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'user/transactions/expired', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        console.log("Transaksi sudah kadaluarsa");
                        this.getData();
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.processFetch = false;
                    return;
                });
            },
        }
    }
</script>