<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Kelola Soal - {{dataTryout.tryout.title}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_tryout_kedinasan'}">Tryout SKD</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Kelola Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-7">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Data Tryout</h5>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i> Judul Tryout</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.tryout.title}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-tag m-r-5"></i> Tipe</td>
                                    <td class="text-right">
                                        <span v-if="dataTryout.tryout.type == 'free' " class="badge badge-info">Free (Gratis)</span>
                                        <span v-if="dataTryout.tryout.type == 'premium' " class="badge badge-success">Premium (Berbayar)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-money-bill m-r-5"></i> Harga</td>
                                    <td class="text-right" v-if="dataTryout.tryout.type == 'free'">
                                        Rp. 0
                                    </td>
                                    <td class="text-right" v-if="dataTryout.tryout.type != 'free'">
                                        <s>{{dataTryout.tryout.text_price_out}}</s> {{dataTryout.tryout.text_price}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-clock m-r-5"></i> Durasi Soal</td>
                                    <td class="text-right">{{dataTryout.tryout.timer}} Menit</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-thermometer-half m-r-5"></i> Status:</td>
                                    <td class="text-right">
                                        <span v-if="dataTryout.tryout.status == 'publish' " class="badge badge-success">Publish</span>
                                        <span v-if="dataTryout.tryout.status == 'draft' " class="badge badge-secondary">Draft</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Nomor Soal</h5>
                    </div>
                    <div class="card-body">
                        <div v-if="dataTryout.list_questions.length == 0">
                            <div class="alert alert-secondary text-center" role="alert">
                                Anda belum membuat soal pada tryout ini
                            </div>
                        </div>

                        <div v-if="dataTryout.list_questions.length > 0" class="text-center" style="padding-top:0px !important;overflow: auto;max-height: 230px;padding-bottom:5px;scrollbar-width: thin;">
                            <button style="width:100%;max-width: 60px;"  type="button" v-on:click="setQuestions(item.id, index)" v-for="(item, index) in dataTryout.list_questions" :key="index" class="text-center btn rounded m-l-10 m-t-10" :class="currentQuestions == item.id ? 'btn-primary':'btn-outline-primary'"> {{++index}}</button>
                        </div>
                        <hr/>
                        <button type="button" v-on:click="addQuestions" class="btn btn-primary btn-block"><i class="fa fa-plus"></i> Tambah Soal</button>
                        <button v-if="form.id" type="button" class="btn btn-danger btn-block" v-on:click="deleteQuestions"><i class="fa fa-trash"></i> Hapus Soal</button>
                        <div class="btn-group btn-block">
                            <button type="button" class="btn btn-light btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-list"></i> Lainnya</button>
                            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 35px, 0px);">
                                <router-link class="dropdown-item" :to="{name : 'skd_tryout_kedinasan_import' , params: { id: dataTryout.tryout.id }}"><i class="fa fa-upload"></i> Import Data Soal</router-link>
                                <a href="javascript:void(0)" class="dropdown-item" v-on:click="resetQuestions"><i class="fa fa-trash"></i> Reset Soal </a>
                                <router-link class="dropdown-item" :to="{name : 'skd_tryout_kedinasan_generate' , params: { id: dataTryout.tryout.id }}"><i class="fa fa-copy"></i> Salin Soal</router-link>                                
                            </div>
                        </div>
                        <div v-if="dataTryout.list_questions.length > 0" class="btn-group btn-block">
                            <button :disabled="isFetchDownload" type="button" class="btn btn-primary rounded btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i v-if="isFetchDownload" class="fa fa-spin fa-spinner"></i>
                                <i v-if="!isFetchDownload" class="fa fa-list"></i>    
                                
                                {{ isFetchDownload ? 'Loading...' : 'Download Soal' }}
                            </button>
                            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 35px, 0px);">
                                <a href="javascript:void(0)" class="dropdown-item" v-on:click="downloadQuestions(1)"><i class="fa fa-diwnload"></i> Soal & Kunci</a>
                                <a href="javascript:void(0)" class="dropdown-item" v-on:click="downloadQuestions(0)"><i class="fa fa-diwnload"></i> Hanya Soal </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12" v-if="showQuestions">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>{{form_title}}</h5>
                    </div>
                    <div class="card-body">
                            <form v-on:submit.prevent="onSubmit">
                                <input type="hidden" class="form-control" id="exampleInputEmail1" placeholder="Masukan poin..." v-model="form.id">
                                <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Kategori</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.category_id" :class="{ 'is-invalid': submitted && $v.form.category_id.$error }" v-on:change="getSubCategory">
                                        <option value="">Pilih Kategori</option>
                                        <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.category_id.required" class="invalid-feedback">Kategori wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Sub Kategori Soal</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.sub_category_id">
                                        <option value="">Pilih Sub Kategori</option>
                                        <option v-for="(item, index) in dataSubCategory" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12" v-if="form.category_id != '3'">
                                    <label for="exampleInputEmail1">Poin</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan poin..." v-model="form.point">
                                </div>

                                <div class="col-md-12 row" v-if="form.category_id == '3'">
                                    <div class="form-group col-md-4" >
                                        <label for="exampleInputEmail1">Poin A</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_a">
                                    </div>
                                    <div class="form-group col-md-4" >
                                        <label for="exampleInputEmail1">Poin B</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_b">
                                    </div>
                                    <div class="form-group col-md-4" >
                                        <label for="exampleInputEmail1">Poin C</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_c">
                                    </div>
                                    <div class="form-group col-md-4" >
                                        <label for="exampleInputEmail1">Poin D</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_d">
                                    </div>
                                    <div class="form-group col-md-4" >
                                        <label for="exampleInputEmail1">Poin E</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1"
                                            placeholder="Masukan poin..." v-model="form.point_e">
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Pertanyaan</label>
                                    <ckeditor :config="editorConfig" v-model="form.question"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi A</label>
                                    <ckeditor :config="editorConfig" v-model="form.options_a"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi B</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_b"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi C</label>
                                    <ckeditor :config="editorConfig" v-model="form.options_c"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi D</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_d"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi E</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_e"></ckeditor>
                                </div>

                                <div class="form-group col-md-12" v-if="form.category_id != '3'">
                                    <label for="exampleFormControlSelect1">Jawaban Benar</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.answer">
                                        <option value="">Pilih Jawaban</option>
                                        <option value="a">A</option>
                                        <option value="b">B</option>
                                        <option value="c">C</option>
                                        <option value="d">D</option>
                                        <option value="e">E</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Pembahasan</label>
                                    <ckeditor :config="editorConfig" v-model="form.discussion"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Video Pembahasan</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url youtube..." v-model="form.discussion_video">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Soal' }}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Questions',
        metaInfo: {
            title: 'Kelola Soal Tryout kedinasan',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getCategory();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label               : 'Tambah',
                form_title          : 'Tambah Soal',
                currentQuestions    : '',
                showQuestions       : false,
                dataTryout          : {
                    tryout : [],
                    list_questions : []
                },
                dataCategory        : [],
                dataSubCategory     : [],
                form : {
                    id                  : "",
                    tryout_id           : "",
                    category_id         : "",
                    sub_category_id     : "",
                    point               : "",
                    question            : "",
                    options_a           : "",
                    options_b           : "",
                    options_c           : "",
                    options_d           : "",
                    options_e           : "",
                    answer              : "",
                    discussion          : "",
                    discussion_video          : "",
                    point_a           : "",
                    point_b           : "",
                    point_c           : "",
                    point_d           : "",
                    point_e           : "",
                },
                submitted       : false,
                waiting         : false,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false                    
                },
                isFetchDownload   : false,
            }
        },
        validations: {
            form: {
                category_id: {
                    required
                }
            }
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skd/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            getSubCategory: function() {
                if(this.form.category_id != '') {
                    let uri         = process.env.VUE_APP_APIHOST+'admin/skd/sub_category/list';
                    
                    this.$http.get(uri,{
                        params : {
                            category_id : this.form.category_id
                        }
                    }).then(res => {
                        this.dataSubCategory   = res.data.data;
                    });
                } else {
                    this.dataSubCategory   = [];
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();
                    
                    formData.append('id', this.form.id);
                    formData.append('tryout_id', this.dataTryout.tryout.id);
                    formData.append('category_id', this.form.category_id);
                    formData.append('sub_category_id', this.form.sub_category_id);
                    formData.append('point', this.form.point);
                    formData.append('question', this.form.question);
                    formData.append('options_a', this.form.options_a);
                    formData.append('options_b', this.form.options_b);
                    formData.append('options_c', this.form.options_c);
                    formData.append('options_d', this.form.options_d);
                    formData.append('options_e', this.form.options_e);
                    formData.append('answer', this.form.answer);
                    formData.append('discussion', this.form.discussion);
                    formData.append('discussion_video', this.form.discussion_video);
                    formData.append('point_a', this.form.point_a);
                    formData.append('point_b', this.form.point_b);
                    formData.append('point_c', this.form.point_c);
                    formData.append('point_d', this.form.point_d);
                    formData.append('point_e', this.form.point_e);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skd/tryout/save_questions', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/skd/tryout/manage_questions';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            'type_tryout' : 'kedinasan'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataTryout = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'skd_tryout_kedinasan' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.attachment = file;
                }
            },
            addQuestions : function() {
                this.form_title = "Tambah Soal";
                this.showQuestions = true;
                this.form.id                  = "";
                this.form.category_id         = "";
                this.form.sub_category_id     = "";
                this.form.point               = "";
                this.form.question            = "";
                this.form.options_a           = "";
                this.form.options_b           = "";
                this.form.options_c           = "";
                this.form.options_d           = "";
                this.form.options_e           = "";
                this.form.answer              = "";
                this.form.discussion          = "";
                this.form.discussion_video          = "";
                this.form.point_a           = "";
                this.form.point_b           = "";
                this.form.point_c           = "";
                this.form.point_d           = "";
                this.form.point_e           = "";

            },
            setQuestions: function(id, index) {
                this.form_title         = "Edit Soal - Nomor "+index;
                this.currentQuestions   = id;

                var item = this.dataTryout.list_questions.find(item => item.id === id);

                this.form.id                  = item.id;
                this.form.category_id         = item.category_id;

                this.getSubCategory(item.category_id);

                setTimeout(() => {
                    if (typeof (item.sub_category_id) !== 'undefined' && item.sub_category_id !== null) {
                        this.form.sub_category_id     = item.sub_category_id;                    
                    } else {
                        this.form.sub_category_id     = '';
                    }
                }, 100);
                
                this.form.point               = item.point;
                this.form.question            = item.question;
                this.form.options_a           = item.options_a;
                this.form.options_b           = item.options_b;
                this.form.options_c           = item.options_c;
                this.form.options_d           = item.options_d;
                this.form.options_e           = item.options_e;
                this.form.answer              = item.answer;
                this.form.discussion        = item.discussion;
                this.form.discussion_video        = item.discussion_video;
                this.form.point_a           = item.point_a;
                this.form.point_b           = item.point_b;
                this.form.point_c           = item.point_c;
                this.form.point_d           = item.point_d;
                this.form.point_e           = item.point_e;

                this.form.id = id;
                this.showQuestions = true;
            },
            deleteQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus soal ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skd/tryout/delete_questions', {
                            id: this.form.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
            resetQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Reset Soal',
                    text: 'Apakah kamu yakin ingin melakukan reset soal ? Semua data yang sudah di reset tidak dapat dikembalikan',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skd/tryout/reset_questions', {
                            id: this.dataTryout.tryout.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
            downloadQuestions(params=0) {
                this.isFetchDownload = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/skd/tryout/download_questions', {
                    params : {
                        id     : this.$route.params.id,
                        show_answer : params,
                        type: 'kedinasan'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchDownload = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Lembar Soal gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        let show_answer = "";
                        if(params === 0) {
                            show_answer = "[Hanya Soal]";
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Lembar Soal - '+this.dataTryout.tryout.title+" "+show_answer}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            }
        }
    }
</script>