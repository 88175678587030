<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Import Soal - {{tryout_name}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_tryout_cpns'}">Tryout SKD</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_tryout_cpns_questions', params: { id: tryout_id }}">Kelola Soal</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Import Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-7">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Upload File</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                    <small><i>Max upload : 5 MB</i></small>
                                </div>
                            </div>
                            <hr style="margin-top:-10px"/>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-upload"></i> {{ waiting ? 'Harap tunggu ...' : 'Upload' }}</button>
                            <router-link :to="{name : 'skd_tryout_cpns_questions', params: { id: tryout_id }}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Ketentuan Import</h5>
                    </div>
                    <div class="card-body">
                        <ol>
                            <li>Pastikan koneksi internet anda aman.</li>
                            <li>Gunakan Format import yang sudah disediakan oleh sistem.</li>
                            <li>Jika anda belum memiliki format import<br/>Silahkan klik link dibawah ini </li>
                            <li><a href="assets/download/format-import-soal-tryout.xlsx" target="blank">Download Format</a></li>
                            <li>Pastikan format yang anda import telah sesuai.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Import',
        metaInfo: {
            title: 'Import Soal Tryout CPNS',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                tryout_id       : this.$route.params.id,
                tryout_name     : "",
                form : {
                    file : ""
                },
                submitted       : false,
                waiting         : false
            }
        },
        methods: {
            onSubmit: function () {
                if(this.form.file == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan file import",
                    });
                } else {
                    this.waiting    = true;
                    this.submitted  = true;

                    let formData = new FormData();

                    formData.append('tryout_id', this.tryout_id);
                    formData.append('file', this.form.file);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/import/tryout/skd', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({name : 'skd_tryout_cpns_questions', params: { id: this.tryout_id }});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/skd/tryout/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type_tryout : 'cpns'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data[0];                            
                            this.tryout_name    = getResponse.title;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name : 'skd_tryout_cpns_questions', params: { id: this.tryout_id }});
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.file = file;
                }
            }
        }
    }
</script>