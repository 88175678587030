<template>
    <div>
        <div v-if="dataFetchSchool" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchSchool">
            <div v-if="!SchoolInfo.status">
                <div class="page-header">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Ayo bergabung dan dapatkan materi dari sekolah! </h5>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="alert alert-info" role="alert">
                                    <b>Informasi : </b>
                                    <ul class="m-t-10">
                                        <li>Harap pilih sekolah sesuai dengan asal sekolah kamu pada inputan dibawah ini.</li>
                                        <li>Asal Sekolah hanya bisa dipilih <b>1x</b>, maka pilihlah sesuai dengan asal sekolah kamu.</li>
                                        <li>Harap hubungi admin untuk info lebih lanjut.
                                            <a href="https://web.whatsapp.com/send?phone=6287837850461" class="font-weight-bold" target="blank">Hubungi Admin</a>
                                        </li>
                                        <li>Good luck! 😊</li>
                                    </ul>
                                </div>
                            </div>                        

                        </div>
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <v-select
                                            placeholder="Pilih Sekolah"
                                            label="name"
                                            :options="dataSchool"
                                            v-model="school"
                                            :class="{ 'is-invalid': submitted && $v.school.$error }"
                                            @input="changeSchool"
                                            >                                    
                                        </v-select>

                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">&nbsp;</label>
                                        <button type="submit" class="btn btn-primary btn-block rounded"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>                
                </div>
            </div>
            <div v-if="SchoolInfo.status">
                <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="SchoolInfo.data.name">
                    <strong><i class="fa fa-info-circle"></i></strong>
                    <span class="m-l-10">Saat ini kamu terdaftar sebagai siswa dari sekolah <b>{{SchoolInfo.data.name}}</b>.<br/>
                    Untuk mengubah asal sekolah, silahkan <a href="javascript:void(0)" data-toggle="modal" data-target="#modalForm" class="font-weight-bold" target="blank">klik disini</a></span>
                </div>

                <div class="page-header">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Data Materi {{SchoolInfo.data.cbt_text ? SchoolInfo.data.cbt_text:'CBT'}}</h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <select class="form-control" v-model="subjects_id" v-on:change="getData()">
                                            <option v-for="(item, index) in dataSubjects" :key="index" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <select class="form-control" v-model="cbt_category_id" v-on:change="getData()">
                                            <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <select class="form-control" v-model="type" v-on:change="getData()">
                                            <option value="">Semua Materi</option>
                                            <option value="youtube">Materi Video</option>
                                            <option value="text">Materi File</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12">
                                        <button v-for="(item, index) in dataSubCategory" :key="index" class="mt-2 btn rounded m-r-10" :class="activeTabs == item.id ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id)" type="button">{{item.name}}</button>

                                        <div class="input-group mb-3 m-t-10">
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                            <div class="input-group-append">
                                                <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                        class="fa fa-search"></i> Cari </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        
                                <div v-if="dataFetch" class="row">
                                    <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                                            <div class="d-block border rounded-lg p-3 m-t-10">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="mr-3 w-100">
                                                        <div class="d-flex align-items-center">
                                                            <div class="w-100">
                                                                <div class="skeleton-bar text-center">
                                                                    <div class="sb-sm mb-2"></div>
                                                                    <hr/>
                                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                                    <div class="sb-lg sb-thin mb-2"></div>
                                                                    <div class="sb-lg sb-thin mb-4"></div>
                                                                    <div class="sb-lg"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" class="row">
                                        <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                                            <div class="card card-theory">
                                                <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                                <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-cbt.png" alt="cover">

                                                <div class="card-body">
                                                    <div class="d-block mb-1">
                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                                            <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                                        </div>
                                                    </div>

                                                    <div class="d-block mb-1">
                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                            <div class="mr-3">Kategori</div>
                                                            <div class="font-weight-600">
                                                                {{item.category ? item.category : '-'}}<br/>
                                                                {{item.sub_category ? item.sub_category : ''}}
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                            <div class="mr-3">Matpel</div>
                                                            <div class="font-weight-600">
                                                                {{item.subjects ? item.subjects : '-'}}
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                            <div class="mr-3">Tipe Materi</div>
                                                            <div class="font-weight-600">
                                                                <span v-if="item.type == 'youtube' " class="badge badge-danger">Youtube / Video</span>
                                                                <span v-if="item.type == 'text'" class="badge badge-info">Text / {{ item.extension.toUpperCase() }}</span>
                                                            </div>
                                                        </div>

                                                        <p class="card-text mt-2">
                                                            {{ item.description }}
                                                        </p>

                                                        <div v-if="!item.owned">
                                                            <button type="button" class="btn btn-outline-primary rounded btn-block m-t-10" v-on:click="inputCode(item.id)">
                                                                <i class="fa fa-tags"></i> Masukan Kode Voucher
                                                            </button>                                                            
                                                        </div>

                                                        <div v-if="item.owned">
                                                            <button v-if="item.type == 'youtube'" data-toggle="modal" data-target="#modalView" v-on:click="goToViewVideo(item.content)" type="button" class="btn btn-primary rounded btn-block">
                                                                <i class="fa fa-play"></i> Putar Video
                                                            </button>

                                                            <button v-if="item.type == 'text'" data-toggle="modal" data-target="#modalAttachment" v-on:click="goToView(item.preview_url)" type="button" class="btn btn-primary rounded btn-block">
                                                                <i class="fas fa-file-pdf"></i> Lihat Materi
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <nav aria-label="navigation">
                                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                            </nav>
                                        </div>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Asal Sekolah</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <select class="form-control" v-model="school" :class="{ 'is-invalid': submitted && $v.school.$error }" v-on:change="changeSchool">
                                            <option value="">Pilih Asal Sekolah</option>
                                            <option v-for="(item, index) in dataSchool" :key="index" :value="item">{{ item.name }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                    </form>
                </div> 
            </div>
        </div>

        <!-- Modal Attachment -->
        <div class="modal" id="modalAttachment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchFile">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>

                <div class="modal-content" v-if="!fetchFile">
                    <div v-if="!dataDetail.attachment">
                        <p class="text-center mb-3 mt-3">File tidak tersedia</p>
                    </div>
                    <object v-if="dataDetail.attachment" type="application/pdf" :data="dataDetail.attachment" width="100%" height="500" style="height: 85vh;">
                        <p class="text-center mt-3">
                            <embed :src="dataDetail.attachment" width="100%" height="600px">
                        </p>                        
                    </object>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal">Tutup</button>
                </div>
            </div>
        </div>

        <!-- Modal Content -->
        <div class="modal" id="modalView" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchModal">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>

                <div class="modal-content" v-if="!fetchModal">
                    <div v-if="!dataDetail.content">
                        <p class="text-center mb-3 mt-3">Video tidak tersedia</p>
                    </div>
                    <vue-plyr v-if="dataDetail.content">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="dataDetail.content"
                            allowfullscreen
                            allowtransparency
                            allow="autoplay"
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal" v-on:click="closeModalView">Tutup</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'MateriCBT',
        metaInfo: {
            title: 'Materi CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                fetchFile : false,
                fetchModal : false,
                dataDetail : {
                    content : ''
                },
                submitted       : false,
                waiting         : false,
                dataFetch       : false,
                dataFetchGroup  : false,
                dataFetchSchool : false,
                dataClass       : [],
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                activeTabs      : "",
                cbt_category_id : "",
                subjects_id : "",
                type : "",
                form            : {
                    id : '',
                    title : ''
                },
                dataCategory : [],
                dataSubjects : [],
                dataSubCategory : [],
                dataSchool : [],
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                school : '',
                classname : ''
            }
        },
        components: {
            vPagination,
            'v-select' : vSelect
        },
        validations: {
            school: {
                required
            },
            classname: {
                required
            },
        },
        created() {
            this.schoolInfo();
            this.getSchool();
        },
        methods: {
            schoolInfo: function () {
                this.dataFetchSchool = true;
                let uri = this.$apiconfig + 'user/school';
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool = false;

                    let getResponse = res.data;
                    this.SchoolInfo = getResponse;

                    if(this.SchoolInfo.status) {
                        this.getSubjects();
                        this.getCategory();
                        this.getSubCategory();
                        this.getData();
                    }
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getSubjects: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/subjects/list';
                this.$http.get(uri).then(res => {
                    this.dataSubjects   = res.data.data;
                });
            },
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/category/list';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            getSubCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/sub_category/list';
                this.$http.get(uri).then(res => {
                    this.dataSubCategory   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        school_id       : this.SchoolInfo.data.id,
                        cbt_category_id : this.cbt_category_id,
                        cbt_sub_category_id : this.activeTabs,
                        subjects_id     : this.subjects_id,
                        type            : this.type,
                        keywords        : this.keywords,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        school_id : this.school.id,
                        school_class : this.classname,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.schoolInfo();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            changeSchool: function() {
                this.classname = "";
                if(this.school.id) {
                    this.dataSchool.forEach(element => {
                        if(this.school.id == element.id) {
                            this.dataClass = element.data_class;
                        }
                    });
                } else {                    
                    this.dataClass = [];
                }
            },
            inputCode: function(id) {
                this.$swal({
                    title: 'Masukan Kode Voucher',
                    input: 'text',
                    confirmButtonText: 'Redeem Voucher'
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'user/redeem-voucher/school', {
                            code        : result.value,
                            school_id   : this.SchoolInfo.data.id,
                            type        : 'cbt_theory',
                            tryout_id   : id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Selamat!',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                    confirmButtonText: 'Ulang'
                                }).then((result) => {
                                    if (result.value) {
                                        this.inputCode(id);
                                    }
                                });
                            }
                        })
                        }
                });
            },
            goToView: function(attachment="") {
                this.fetchFile = true;
                setTimeout(() => {
                    this.dataDetail.attachment = attachment;
                    this.fetchFile = false;
                }, 1000);
            },
            goToViewVideo: function(content="") {
                this.fetchModal = true;
                setTimeout(() => {
                    this.dataDetail.content = content;
                    this.fetchModal = false;
                }, 500);
            },
            closeModalView: function() {
                setTimeout(() => {
                    this.dataDetail.content = '';
                }, 500);
            }
        }
    }
</script>
