<template>
    <div>
        <div v-if="dataFetchTryout" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchTryout" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{ name : 'monitoring_tryout_akm'}"><i class="fa fa-arrow-left m-r-10"></i></router-link> Monitoring Tryout - {{dataTryout.title}}</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button
                                    :disabled="isFetchDownload"
                                    v-on:click="exportExcel" type="button" class="m-l-10 btn waves-effect waves-light btn-primary btn-icon btn-msg-send">
                                    <i v-if="isFetchDownload" class="fa fa-spin fa-spinner"></i>
                                    <i v-if="!isFetchDownload" class="fas fa-file-export"></i>
                                    {{ isFetchDownload ? 'Loading...' : 'Download hasil ke Excel' }}
                                </button>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status Mengerjakan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="status" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option value="sudah">Sudah Mengerjakan</option>
                                        <option value="sedang">Sedang Mengerjakan</option>
                                        <option value="belum">Belum Mengerjakan</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama</th>
                                                    <th>Email</th>
                                                    <th class="text-center" style="width:10%">Status</th>
                                                    <th class="text-center" style="width:15%">Waktu Mengerjakan</th>
                                                    <th class="text-center" style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.fullname}}</td>
                                                    <td>{{item.email}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.working_status == 'notyet'" class="badge badge-danger">Belum Mengerjakan</span>
                                                        <span v-if="item.working_status == 'ongoing'" class="badge badge-warning">Sedang Mengerjakan</span>
                                                        <span v-if="item.working_status == 'finished'" class="badge badge-success">Sudah Mengerjakan</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="!item.start">-</span>
                                                        <span v-if="item.start">{{item.start}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.working_status == 'finished'">
                                                            <button
                                                                :disabled="isFetchCertificate"
                                                                v-on:click="downloadCertificate(item.result_id, item.id, item.fullname)" type="button" class="btn btn-primary rounded btn-block text-left btn rounded btn-block">
                                                                <i v-if="isFetchCertificate" class="fa fa-spin fa-spinner"></i>
                                                                <i v-if="!isFetchCertificate" class="fa fa-download"></i>
                                                                {{ isFetchCertificate ? 'Loading...' : 'Unduh Hasil Assesment' }}
                                                            </button>

                                                        </span>
                                                        <span v-if="item.working_status != 'finished'">-</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                isFetchCertificate  : false,
                isFetchDownload  : false,
                emptyState          : false,
                dataFetch           : false,
                dataFetchTryout     : true,
                dataList            : [],
                dataTryout          : [],
                keywords            : "",
                status     : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getTryout();
        },
        methods: {
            getTryout: function() {
                this.dataFetchTryout = true;
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/detail-akm';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataTryout         = res.data.data;
                        this.dataFetchTryout    = false;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'monitoring_tryout_akm'
                                });
                            }
                        });                        
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-status-akm';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        status      : this.status,
                        keywords    : this.keywords,
                        id          : this.$route.params.id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            downloadCertificate(result_id, user_id, fullname) {
                this.isFetchCertificate = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-result-akm', {
                    params : {
                        result_id : result_id,
                        user_id : user_id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchCertificate = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Hasil Assesment - '+fullname}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            onProgress(progress) {
                this.progress = progress;
                console.log(`PDF generation progress: ${progress}%`)
            },
            exportExcel: function () {        
                this.isFetchDownload = true;            
                let formData = new FormData();

                formData.append('id', this.$route.params.id);
                formData.append('tryout_name', this.dataTryout.title);

                this.waiting    = true;
                this.submitted  = true;

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-result-akm',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchDownload = false;
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `hasil-tes-assesment-${this.dataTryout.title}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.waiting    = false;
                    this.submitted  = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            }
        }
    }
</script>
