<template>
    <div>
        <div v-if="dataFetchTryout" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchTryout" class="page-header">
            <div v-if="!this.dataDetail.show_result" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4>Hasil Tryout masih ditutup untuk saat ini</h4>
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h4 class="m-t-10">
                                Hasil dapat dilihat pada tanggal
                            </h4>
                            <h4 class="m-t-10">
                                {{dataDetail.text_opened}}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            

            <div v-if="this.dataDetail.show_result" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h4 class="m-t-10">
                                Rangking - {{dataDetail.title}}
                            </h4>
                            <p>Berikut adalah rangking hasil <b>{{dataDetail.title}}</b> yang telah dikerjakan oleh pengguna griyabelajar.</p>
                            <hr/>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Waktu</div>
                                        <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Jumlah Soal</div>
                                        <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Passing Grade Saat ini</div>
                                        <div class="font-weight-600">{{dataDetail.passing_grade}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="exampleInputEmail1">Periode Waktu</label>
                                <select class="form-control" v-model="form.periode" v-on:change="getData">
                                    <option value="3">3 Hari Terakhir</option>
                                    <option value="7">7 Hari Terakhir</option>
                                    <option value="14">14 Hari Terakhir</option>
                                    <option value="30">30 Hari Terakhir</option>
                                </select>
                            </div>

                            <div class="col-md-8">
                                <label for="exampleInputEmail1">&nbsp;</label>
                                <div class="input-group mb-3">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <b>Periode Ranking : </b>
                                    <p class="pull-right">{{periode}}</p>
                                    <div v-if="dataList.length > 0" class="table-responsive m-t-10">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="font-size:11px !important" class="text-center">No</th>
                                                    <th style="font-size:11px !important">Nama</th>
                                                    <th style="font-size:11px !important" class="text-center">Skor Akhir</th>
                                                    <th style="font-size:11px !important" class="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center" style="width:5%">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td style="width:65%">{{item.fullname}}</td>
                                                    <td class="text-center">{{item.skor_akhir}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.skor_akhir < dataDetail.passing_grade" class="badge badge-danger">Tidak Lulus</span>
                                                        <span v-if="item.skor_akhir >= dataDetail.passing_grade" class="badge badge-success">Lulus</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                    
                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'RankTryout',
        metaInfo: {
            title: 'Ranking Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        created() {
            this.getTryout();
        },
        data() {
            return {
                dataDetail      : [],
                form : {
                    periode     : '3'
                },
                listProvince    : [],
                listCity        : [],
                keywords        : '',
                dataFetch       : false,
                dataFetchTryout : false,
                dataList        : [],
                emptyState      : false,
                periode     : '',
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/tryout/rank';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit,
                        periode     : this.form.periode,
                        id          : this.dataDetail.id
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.periode                = res.data.periode;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            getTryout: function() {
                let uri               = process.env.VUE_APP_APIHOST+'member/cbt/tryout/detail';
                this.dataFetchTryout  = true;                
                this.$http.get(uri,{
                    params : {
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;

                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            if(this.dataDetail.token_quiz) {
                                this.$router.push({
                                    name: 'member_tryout_skd_start',
                                    params: {
                                        type_tryout : this.$route.params.type_tryout,
                                        slug        : this.$route.params.slug,
                                        token       : this.dataDetail.token_quiz
                                    }
                                });
                            } else {
                                this.dataFetchTryout  = false;         

                                if(this.dataDetail.show_result == 1) {
                                    this.getData();
                                }
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            }
        }
    }
</script>