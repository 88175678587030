<template>
    <div>
        <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
            <strong><i class="fa fa-info-circle"></i></strong>
            <span class="m-l-10">{{dataGroup.data.text_link}}</span>
            <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <img style="max-width:50%" src="assets/images/landing-upgrade.jpg" alt=""
                                class="img-fluid mb-4">
                            <h4 class="m-t-10">Halaman ini bisa diakses bagi peserta yang<br/>membeli paket <u>paket silver</u> atau <u>paket gold</u></h4>
                            <p>
                                Silahkan upgrade paket anda untuk mendapatkan benefit yang lebih baik.
                            </p>
                            <button class="btn btn-outline-primary rounded btn-block" v-on:click="upgrade"
                                type="button"><i class="fa fa-rocket"></i> Upgrade Paket</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UpgradePackage',
        metaInfo: {
            title: 'Upgrade Akun',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                currentTabComponent: 'MyProfile',
                dataGroup: []
            }
        },
        created() {
            this.getDiscuss();
        },
        methods: {
            upgrade: function () {
                const type = this.$route.params.type;
                
                if (type == 'cpns') {
                    this.$router.push({
                        name: 'member_paket_cpns'
                    });
                }

                if (type == 'kedinasan') {
                    this.$router.push({
                        name: 'member_paket_kedinasan'
                    });
                }

                if (type == 'utbk') {
                    this.$router.push({
                        name: 'member_paket_utbk'
                    });
                }

                if (type == 'akm') {
                    this.$router.push({
                        name: 'member_paket_akm'
                    });
                }
            },
            getDiscuss: function () {
                this.dataFetch = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: this.$route.params.type
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            }
        }
    }
</script>