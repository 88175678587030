<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div v-if="!this.dataDetail.tryout.show_result" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4>Hasil Tryout masih ditutup untuk saat ini</h4>
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h4 class="m-t-10">
                                Hasil dapat dilihat pada tanggal
                            </h4>
                            <h4 class="m-t-10">
                                {{dataDetail.tryout.result_date}}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="dataDetail.tryout.show_result" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:history.back()" ><i class="fa fa-arrow-left m-r-10"></i></a> Hasil - {{dataDetail.tryout.title}}</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kategori</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{dataDetail.tryout.category}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{dataDetail.tryout.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{dataDetail.tryout.fullname}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Provinsi</div>
                                <div class="font-weight-600">{{dataDetail.tryout.province_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Kota</div>
                                <div class="font-weight-600">{{dataDetail.tryout.city_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Mengerjakan</div>
                                <div class="font-weight-600">{{dataDetail.tryout.created_at}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <div class="card card-theory">
                            <div class="card-body">
                                <h5 class="job-card-desc text-center">
                                    Skor Akhir
                                </h5>

                                <div class="text-center m-t-10">
                                    <h3>{{dataDetail.tryout.skor_akhir}}</h3>
                                </div>

                                <h4 class="job-card-desc text-center">
                                    Keterangan
                                </h4>
                                <div class="text-center m-t-10" v-if="!dataDetail.tryout.lulus">
                                    <span style="font-size:15px" class="badge badge-danger text-center">Tidak Lulus</span>
                                </div>

                                <div v-if="dataDetail.tryout.lulus" class="alert alert-success m-t-10 mb-0" role="alert">
                                    <b>Selamat kamu telah berhasil menyelesaikan tryout ini! </b><br/>Jangan sampai berpuas diri ya! tetap konsisten & coba lagi kembali ya...<br/>Silahkan untuk melihat hasil dan statistik tryout kamu 
                                </div>
                            </div>
                        </div>

                        <button v-on:click="changeTab('StatisticTryout')" type="button"  :class="currentTabComponent == 'StatisticTryout' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-bars"></i> Lihat Nilai & Statistik</button>
                        <button v-on:click="changeTab('PembahasanTryout')" type="button" :class="currentTabComponent == 'PembahasanTryout' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-file"></i> Lihat Pembahasan</button>
                        <button
                            v-if="dataDetail.tryout.activate_download_result_cbt == '1'"
                            :disabled="isFetchCertificate"
                            v-on:click="downloadCertificate" type="button" class="btn btn-primary rounded btn-block text-left btn rounded btn-block m-t-10">
                            <i v-if="isFetchCertificate" class="fa fa-spin fa-spinner"></i>
                            <i v-if="!isFetchCertificate" class="fa fa-download"></i>
                            {{ isFetchCertificate ? 'Loading...' : 'Unduh Hasil Assesment' }}                        
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="dataDetail.tryout.show_result">
            <div v-if="!dataFetch">
                <vue-page-transition>
                    <keep-alive>
                            <component :is="currentTabComponent"></component>
                    </keep-alive>
                </vue-page-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import StatisticTryout from "@/views/member/cbt/tryout/StatisticTryout.vue"
    import PembahasanTryout from "@/views/member/cbt/tryout/PembahasanTryout.vue"

    export default {
        name: 'ResultTryoutCBTDetail',
        metaInfo: {
            title: 'Hasil CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            StatisticTryout,
            PembahasanTryout
        },
        data() {
            return {
                currentTabComponent : 'StatisticTryout',
                isFetchCertificate   : false,
                dataFetch       : false,
                dataDetail      : {
                    statistic : {
                        recomendation_category : []
                    },
                    tryout : {
                        show_result : ''
                    }
                },
                dataSubscriber  : [],
                emptyState      : false,
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019','#35858B', '#04293A', '#E9B2BC','#95CD41', '#AE431E','#B4C6A6'],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                },
                series: [],
            }
        },
        created() {
            this.getData()
        },
        methods: {
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/tryout/result/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        result_id : this.$route.params.result_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;
                        if(!res.data.status) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! kamu belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            this.dataFetch  = false;     
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            downloadCertificate() {
                if(this.dataDetail.tryout.activate_download_result_cbt == '1') {
                    this.isFetchCertificate = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'member/cbt/tryout/result/download-result', {
                        params : {
                            result_id : this.$route.params.result_id,
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchCertificate = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Assesment - '+this.dataDetail.tryout.fullname}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            onProgress(progress) {
                this.progress = progress;
                console.log(`PDF generation progress: ${progress}%`)
            }
        }
    }
</script>