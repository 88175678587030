<template>
    <div class="container" style="margin-top:20px !important">
        <div class="row" style="margin-left:20px">
            <div v-for="(item, index) in dataList.data_voucher" :key="index" class="col-md-6"
                style="margin-top: 10px; margin-bottom: 10px;">
                <div class="kartu-peserta-seleksi-wrapper">
                    <div class="kartu-peserta-seleksi">
                        <div class="head-wrapper">
                            <img v-if="dataList.letterhead" :src="dataList.letterhead" alt="Kop NHSC" class="center" />
                            <img v-if="!dataList.letterhead" src="https://api.griyabelajar.com/assets/kop-nhsc.png" alt="Kop NHSC" class="center" />
                        </div>
                        <p style="font-size:7pt;text-align:center;margin-left:3pt;margin-bottom:0pt;margin-top:3pt">
                            <b>
                                KARTU TANDA PESERTA<br />
                                COMPUTER BASED TEST (CBT)
                            </b>
                        </p>
                        <div class="content-wrapper">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style="font-size:7pt; ">No. Pendaftaran</td>
                                        <td style="font-size:7pt; ">:</td>
                                        <td style="font-size:7pt; "><strong>{{item.user_id}}-{{index+1}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td style="font-size:7pt; ">Email</td>
                                        <td style="font-size:7pt; ">:</td>
                                        <td style="font-size:7pt; "><strong>{{ item.email }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td style="font-size:7pt; ">Nama</td>
                                        <td style="font-size:7pt; ">:</td>
                                        <td style="font-size:7pt; color:#000 !important;"><strong>{{ item.fullname }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td style="font-size:7pt; ">Sekolah/Kelas</td>
                                        <td style="font-size:7pt; ">:</td>
                                        <td style="font-size:7pt; color:#000 !important;"><strong>{{dataList.school_name}}/{{item.school_class}}</strong></td>
                                    </tr>
                                </tbody>
                            </table>

                            <div
                                style="border: 1px solid #000;padding: 3px;border-radius: 1px;font-size:7pt; width:250px; margin-top:3pt; margin-bottom:3pt; text-align:center; width:100%; border-style: dotted;">
                                Kode Voucher : <b style="font-family: times">{{ item.code }}</b>
                            </div>

                            <table style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th
                                            style="font-size:5pt; padding: 1.5pt; text-align:left; border: 0.3px solid black; width: 50%; border-right: none;">
                                            Waktu Pelaksanaan</th>
                                        <th
                                            style="font-size:5pt; padding: 1.5pt; text-align:left; border: 0.3px solid black; width: 25;">
                                            Nama TryOut</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(itemDetail, indexDetail) in dataList.list_date" :key="indexDetail">
                                        <td
                                            style="font-size:5pt; padding: 1.5pt; text-align:left; border: 0.3px solid black; width: 25%; border-right: none;border-top: none">
                                            <span v-for="(itemSchedule, indexSchedule) in dataList.list_schedule[itemDetail]" :key="indexSchedule">
                                                {{ itemSchedule.time }}<br/>
                                            </span>
                                        </td>
                                        <td
                                            style="font-size:5pt; padding: 1.5pt; text-align:left; border: 0.3px solid black; width: 25%; border-top: none; color:#000 !important;">
                                            <span v-for="(itemSchedule, indexSchedule) in dataList.list_schedule[itemDetail]" :key="indexSchedule">
                                                {{ itemSchedule.title }}<br/>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p style="font-size:5pt;color:black;margin-top:2pt;margin-left:1pt">Perlengkapan yang harus dibawa saat tryout</p>
                            <ul style="font-size:5pt;color:black;margin-top:-10pt;margin-left:-20pt">
                                <li>Kartu tanda peserta ini.</li>
                                <li>Alat tulis yang dibutuhkan.</li>
                                <li>Perangkat TryOut yang dibutuhkan, Dekstop PC, Laptop, Chromebook, Smartphone.</li>
                                <li>Url login: {{dataList.url_login}} atau bisa scan barcode pada kartu ini.</li>
                            </ul>

                                <div v-html="dataList.qr_code"></div>
                        </div>
                        <div class="footer-wrapper" style="margin-top:-80pt">
                            <p>Kebumen, {{dataList.text_current_date}}</p>
                            <p>Kepala Madrasah</p>
                            <br><br>
                            <p><strong>{{dataList.headmaster_name ? dataList.headmaster_name : '......................'}}</strong></p>
                        </div>
                    </div>
                </div>

                <div v-if="(index+1) % 4 == 0" class="html2pdf__page-break" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrintKartuPeserta',
        props: {
            id: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                dataList: []
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            loadData: function() {
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/download-card-template', {
                    params : {
                        id     : this.id,
                    },
                })
                .then(response => {
                    this.dataList = response.data.data
                })
            },
        },
    }
</script>
