<template>
    <h3 class="text-center mt-5">
        page testing - Mobile Mantep<br/>
        <button type="button" class="mt-5 btn btn-primary rounded" v-on:click="testButton" id="testing_button">Testing Page Button</button>
    </h3>
</template>

<script>
    export default {
        methods: {
            testButton: function() {
                alert("wkwkwk");
            }
        },
    }
</script>