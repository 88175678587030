<template>
    <div>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="page-header-title">
                        <h5 class="m-t-10">Nilai & Statistik Hasil Tryout</h5>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 m-t-10">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc text-center">
                                Statistik Jawaban
                            </h4>
                            <div class="d-block mt-4">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                                    <div class="font-weight-600">
                                        {{this.$parent.$parent.dataDetail.statistic.correct}}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                                    <div class="font-weight-600">
                                        {{this.$parent.$parent.dataDetail.statistic.wrong}}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                                    <div class="font-weight-600">
                                        {{this.$parent.$parent.dataDetail.statistic.empty}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 m-t-10">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc text-center">
                                Grafik Nilai
                            </h4>

                            <div>
                                <apexchart type="pie" :options="chartOptionsPie" :series="this.$parent.$parent.dataDetail.statistic.series"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatisticTryoutCBT',
        metaInfo: {
            title: 'Nilai dan Statistik Nilai CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                chartOptionsPie: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#2ed8b6', '#ff5370', '#F78812'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            }
        }
    }
</script>