<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Beli Paket PPPK</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                        <div class="input-group-append">
                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                    class="fa fa-search"></i> Cari </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="dataFetch">
                <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                    <div class="d-block border rounded-lg p-3 m-t-10">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar text-center">
                                            <div class="sb-sm mb-2"></div>
                                            <hr/>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                            <div class="sb-lg"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!dataFetch">
                <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                    <div class="card card-theory">
                        <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                        <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-pppk-new.png" alt="cover">
                        <div class="card-body">
                            <h5 class="job-card-desc text-center">
                                {{item.name}}
                            </h5>
                            <div v-if="item.type_package == 'free'" class="text-center">
                                <h4>Rp. 0</h4>
                            </div>
                            <div v-if="item.type_package == 'premium'" class="text-center">
                                <b><s>{{item.text_price_out}}</s></b>
                                <h3>{{item.text_price}}</h3>
                            </div>

                            <router-link :to="{name: 'member_paket_pppk_detail', params : {code : item.code}}" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-book"></i> Lihat Paket</router-link>

                            <hr/>
                            <table style="width:100%">
                                <tr v-for="(itemDetail, index) in item.description_other.slice(0, 4)" :key="index">
                                    <td style="vertical-align:top;width:8%;color:#6fd96f !important"><i class="fa fa-check"></i></td>
                                    <td>{{itemDetail}}</td>
                                </tr>
                                <tr>
                                    <td style="vertical-align:top;width:8%;color:#6fd96f !important">&nbsp;</td>
                                    <td><router-link :to="{name: 'member_paket_pppk_detail', params : {code : item.code}}">Lihat Selengkapnya...</router-link></td>
                                </tr>
                            </table>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch">
                <div v-if="emptyState">
                    <empty-state></empty-state>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaketPPPK',
        metaInfo: {
            title: 'Paket PPPK',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                processFetch        : false,
                dataFetch           : false,
                dataList            : [],
                emptyState          : false,
                showInputVoucher    : false,
                form                : {
                    id : '',
                    name : '',
                    text_price_out : '',
                    text_price : '',
                    active_days : '',
                    voucher : '',
                    price : 0,
                    fix_voucher : '',
                    discount_voucher : 0,
                    total_price : 0,
                    total_price_out : 0,
                },
                keywords:''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/package/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'pppk',
                        keywords    : this.keywords
                    }
                }).then(res => {
                    this.emptyState = false;
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>