<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Materi CBT</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'cbt_theory_add'}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <div v-if="dataList.length > 0">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:10%">Kategori</th>
                                                    <th style="width:10%">Judul</th>
                                                    <th>Tipe</th>
                                                    <th class="text-center">Status</th>
                                                    <th>Cover</th>
                                                    <th style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        {{item.category}}<br/>
                                                        <small>Dibuat oleh: {{ item.created_by }}</small>
                                                    </td>
                                                    <td>{{item.title}}</td>
                                                    <td>
                                                        <span v-if="item.type == 'youtube' " class="badge badge-danger">Youtube / Video</span>
                                                        <span v-if="item.type == 'text' " class="badge badge-info">Text</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                                        <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                                    </td>
                                                    <td v-if="!item.cover">-</td>
                                                    <td v-if="item.cover">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <a :href="item.cover" data-lightbox="1" target="blank">
                                                                    <img :src="item.cover" alt="" class="img-fluid img-thumbnail" style="width:100px">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link v-if="item.owned == 1" :to="{name : 'cbt_theory_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-if="item.owned == 1" v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                        <button v-if="item.type == 'youtube'" data-toggle="modal" data-target="#modalView" v-on:click="goToViewVideo(item.content)" type="button" class="btn btn-primary rounded btn-sm">
                                                            <i class="fa fa-play"></i> Putar Video
                                                        </button>

                                                        <button v-if="item.type == 'text'" data-toggle="modal" data-target="#modalAttachment" v-on:click="goToView(item.preview_url)" type="button" class="btn btn-primary rounded btn-sm">
                                                            <i class="fas fa-file-pdf"></i> Lihat Materi
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataList.length < 1">
                                                <tr colspan="6">
                                                    <td>Data tidak ditemukan</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>

                                <nav v-if="dataList.length > 0" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Attachment -->
        <div class="modal" id="modalAttachment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchFile">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>

                <div class="modal-content" v-if="!fetchFile">
                    <div v-if="!dataDetail.attachment">
                        <p class="text-center mb-3 mt-3">File tidak tersedia</p>
                    </div>
                    <object v-if="dataDetail.attachment" type="application/pdf" :data="dataDetail.attachment" width="100%" height="500" style="height: 85vh;">
                        <p class="text-center mt-3">
                            <embed :src="dataDetail.attachment" width="100%" height="600px">
                        </p>                        
                    </object>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal">Tutup</button>
                </div>
            </div>
        </div>

        <!-- Modal Content -->
        <div class="modal" id="modalView" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchModal">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>

                <div class="modal-content" v-if="!fetchModal">
                    <div v-if="!dataDetail.content">
                        <p class="text-center mb-3 mt-3">Video tidak tersedia</p>
                    </div>
                    <vue-plyr v-if="dataDetail.content">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="dataDetail.content"
                            allowfullscreen
                            allowtransparency
                            allow="autoplay"
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal" v-on:click="closeModalView">Tutup</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'CbtTheory',
        metaInfo: {
            title: 'Data Materi CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                fetchFile : false,
                fetchModal : false,
                dataDetail : {
                    content : ''
                },

                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 8,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/theory/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            goToView: function(attachment="") {
                this.fetchFile = true;
                setTimeout(() => {
                    this.dataDetail.attachment = attachment;
                    this.fetchFile = false;
                }, 1000);
            },
            goToViewVideo: function(content="") {
                this.fetchModal = true;
                setTimeout(() => {
                    this.dataDetail.content = content;
                    this.fetchModal = false;
                }, 500);
            },
            closeModalView: function() {
                setTimeout(() => {
                    this.dataDetail.content = '';
                }, 500);
            }            
        }
    }
</script>
