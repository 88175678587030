<template>
    <div>
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="page-header-title">
                        <h5 class="m-t-10">Rasionalisasi Nilai</h5>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!allow_access">            
                <div class="page-header-title text-center">
                    <img style="max-width:50%" src="assets/images/landing-upgrade.jpg" alt=""
                        class="img-fluid mb-4">
                    <h4 class="m-t-10">Halaman ini hanya bisa diakses <br/>bagi peserta yang membeli tryout.</h4>
                    <p>
                        Silahkan upgrade paket anda untuk mendapatkan benefit yang lebih baik.
                    </p>
                    <button class="btn btn-outline-primary rounded btn-block" v-on:click="upgrade"
                        type="button"><i class="fa fa-rocket"></i> Upgrade Paket</button>
                </div>
            </div>

            <div class="row" v-if="allow_access && dataFetch">
                <div class="col-md-12 m-t-10">
                    <p>Sedang mengambil data...</p>
                </div>
            </div>    
                        
            <div class="row" v-if="allow_access && !dataFetch">                
                <div class="col-md-12 m-t-10">
                    <div v-if="dataList.collage_one.id && dataList.collage_two.id">
                        <div v-if="dataList.status_lulus" class="alert alert-success text-center" role="alert">
                            Selamat <b>{{dataList.fullname}}</b>!
                            <br/>
                            Nilai Kamu telah <b>Memenuhi Passing Grade</b> di pilihan <b>{{dataList.option}}</b><br/><br/>
                            Perguruan Tinggi<br/><b>{{dataList.ptn}}</b><br/>
                            Program Studi <br/> <b>{{dataList.prodi}}</b><br/><br/>
                            Jangan sampai berpuas diri ya! tetap konsisten & coba lagi kembali ya...
                        </div>

                        <div v-if="!dataList.status_lulus" class="alert alert-danger" role="alert">
                            Hallo <b>{{dataList.fullname}}</b>!
                            <br/>
                            Untuk saat ini nilai kamu <b>Belum Memenuhi Passing Grade</b> di Program Studi Pilihan <b>PERTAMA</b> dan <b>KEDUA</b><br/>
                            Tetap semangat dan coba lagi kembali ya...
                        </div>
                    </div>

                    <div v-if="!dataList.collage_one.id && !dataList.collage_two.id">
                        <div class="alert alert-warning" role="alert">
                            Hallo <b>{{dataList.fullname}}</b>!
                            <br/>
                            Untuk saat ini nilai kamu <b>Belum Memilih</b> Program Studi Pilihan <b>PERTAMA</b> dan <b>KEDUA</b><br/>
                            Silahkan pilih Program Studi Pilihan untuk melihat rasionalisasi nilai kamu.
                        </div>                    
                    </div>

                    <div class="text-center">
                        <button type="button" data-toggle="modal" data-target="#chooseTarget" class="btn btn-outline-primary rounded"
                            ><i class="fa fa-edit"></i> Update Analisis</button>
                    </div>
                    <hr/>
                    <div class="text-center">
                        <small >
                            *Prediksi ini mungkin akan berbeda dengan hasil SNBT-mu yang sebenarnya.<br/>
                            Jangan berhenti berjuang!
                        </small>
                    </div>
                </div>

                <div class="m-t-20" v-if="dataList.collage_one.id" :class="dataList.collage_two ? 'col-md-6':'col-md-12'">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc">
                                <b><small class="font-weight-600">Program Studi pilihan kamu (Utama)</small></b>
                            </h4>

                            <div class="text-center mt-4" v-if="dataList.collage_one.logo">
                                <img style="width:100px" :src="dataList.collage_one.logo"/>
                            </div>

                            <div class="text-center mt-4" v-if="!dataList.collage_one.logo">
                                <img style="width:100px" :src="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'"/>
                            </div>

                            <hr />
                            <div class="d-block mt-4">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">PTN</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_one.ptn}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">Prodi</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_one.prodi}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:25%">Passing Grade</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_one.passing_grade}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3 font-weight-600" style="width:25%">Url Website</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_one.website}}
                                        <a class="ml-2" v-if="dataList.collage_one.website" :href="dataList.collage_one.website" target="blank">
                                            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 m-t-20" v-if="dataList.collage_two.id">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc">
                                <b><small class="font-weight-600">Program Studi pilihan kamu (Kedua)</small></b>
                            </h4>

                            <div class="text-center mt-4" v-if="dataList.collage_two.logo">
                                <img style="width:100px" :src="dataList.collage_two.logo"/>
                            </div>

                            <div class="text-center mt-4" v-if="!dataList.collage_two.logo">
                                <img style="width:100px" :src="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'"/>
                            </div>

                            <hr />
                            <div class="d-block mt-4">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">PTN</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_two.ptn}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">Prodi</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_two.prodi}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:25%">Passing Grade</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_two.passing_grade}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3 font-weight-600" style="width:25%">Url Website</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_two.website}}
                                        <a class="ml-2" v-if="dataList.collage_two.website" :href="dataList.collage_two.website" target="blank">
                                            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 m-t-20" v-if="dataList.collage_three.id">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc">
                                <b><small class="font-weight-600">Program Studi pilihan kamu (Ketiga)</small></b>
                            </h4>

                            <div class="text-center mt-4" v-if="dataList.collage_three.logo">
                                <img style="width:100px" :src="dataList.collage_three.logo"/>
                            </div>

                            <div class="text-center mt-4" v-if="!dataList.collage_three.logo">
                                <img style="width:100px" :src="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'"/>
                            </div>

                            <hr />
                            <div class="d-block mt-4">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">PTN</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_three.ptn}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">Prodi</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_three.prodi}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:25%">Passing Grade</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_three.passing_grade}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3 font-weight-600" style="width:25%">Url Website</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_three.website}}
                                        <a class="ml-2" v-if="dataList.collage_three.website" :href="dataList.collage_three.website" target="blank">
                                            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 m-t-20" v-if="dataList.collage_four.id">
                    <div class="card card-theory h-100">
                        <div class="card-body h-100">
                            <h4 class="job-card-desc">
                                <b><small class="font-weight-600">Program Studi pilihan kamu (Keempat)</small></b>
                            </h4>

                            <div class="text-center mt-4" v-if="dataList.collage_four.logo">
                                <img style="width:100px" :src="dataList.collage_four.logo"/>
                            </div>

                            <div class="text-center mt-4" v-if="!dataList.collage_four.logo">
                                <img style="width:100px" :src="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'"/>
                            </div>

                            <hr />
                            <div class="d-block mt-4">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">PTN</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_four.ptn}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:20%">Prodi</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_four.prodi}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3 font-weight-600" style="width:25%">Passing Grade</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_four.passing_grade}}
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2">
                                    <div class="mr-3 font-weight-600" style="width:25%">Url Website</div>
                                    <div class="font-weight-600 text-right" style="width:80%">
                                        {{dataList.collage_four.website}}
                                        <a class="ml-2" v-if="dataList.collage_four.website" :href="dataList.collage_four.website" target="blank">
                                            <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div style="margin-left: 10px;" class="modal" id="chooseTarget" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm text-center">
                            <div class="font-weight-600" style="font-size:20px">Pilih Target PTN & Prodi</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Perguruan Tinggi Pertama</label>
                                                <v-select
                                                    placeholder="Pilih Perguruan Tinggi"
                                                    label="name"
                                                    :options="listCollage"
                                                    v-model="form.collage"
                                                    :class="{ 'is-invalid': submitted && $v.form.collage.$error }"
                                                    @input="getProgramStudy"
                                                    >                                    
                                                </v-select>
                                                
                                                <div v-if="submitted && !$v.form.collage.required" class="invalid-feedback d-block">
                                                Perguruan Tinggi Pertama Wajib Diisi</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Program Studi Pertama</label>
                                                <v-select
                                                    placeholder="Pilih Prodi Pertama"
                                                    label="name"
                                                    :options="listProgramStudy"
                                                    v-model="form.program_study"
                                                    :class="{ 'is-invalid': submitted && $v.form.program_study.$error }"
                                                    >                                    
                                                </v-select>


                                                <div v-if="submitted && !$v.form.program_study.required" class="invalid-feedback d-block">
                                                    Program Studi Pertama Wajib Diisi</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Perguruan Tinggi Kedua</label>
                                                <v-select
                                                    placeholder="Pilih Perguruan Tinggi Kedua"
                                                    label="name"
                                                    :options="listCollage"
                                                    v-model="form.collage_two"
                                                    @input="getProgramStudyTwo"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Program Studi Kedua</label>
                                                <v-select
                                                    placeholder="Pilih Prodi Kedua"
                                                    label="name"
                                                    :options="listProgramStudyTwo"
                                                    v-model="form.program_study_two"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Perguruan Tinggi Ketiga</label>
                                                <v-select
                                                    placeholder="Pilih Perguruan Tinggi Ketiga"
                                                    label="name"
                                                    :options="listCollage"
                                                    v-model="form.collage_three"
                                                    @input="getProgramStudyThree"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Program Studi Ketiga</label>
                                                <v-select
                                                    placeholder="Pilih Prodi Ketiga"
                                                    label="name"
                                                    :options="listProgramStudyThree"
                                                    v-model="form.program_study_three"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Perguruan Tinggi Keempat</label>
                                                <v-select
                                                    placeholder="Pilih Perguruan Tinggi Keempat"
                                                    label="name"
                                                    :options="listCollage"
                                                    v-model="form.collage_four"
                                                    @input="getProgramStudyFour"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="m-t-10">
                                                <label>Program Studi Keempat</label>
                                                <v-select
                                                    placeholder="Pilih Prodi Keempat"
                                                    label="name"
                                                    :options="listProgramStudyFour"
                                                    v-model="form.program_study_four"
                                                    >                                    
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <hr/>
                                    <small><i>Keterangan : Perguruan tinggi & Program Studi Pilihan Pertama <b>Wajib dipilih</b>.</i></small>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block"> Simpan Perubahan <i class="m-l-10 fas fa-save"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'RasionalisasiNilai',
        metaInfo: {
            title: 'Rasionalisasi Nilai',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            'v-select' : vSelect,
        },
        data() {
            return {
                allow_access : false,
                dataFetch    : true,
                dataList            : {
                    collage_one : {
                        id  : ''
                    },
                    collage_two : {
                        id  : ''
                    },
                    collage_three : {
                        id  : ''
                    },
                    collage_four : {
                        id  : ''
                    },
                },
                listCollage         : [],
                listProgramStudy    : [],
                listProgramStudyTwo : [],
                listProgramStudyThree : [],
                listProgramStudyFour : [],
                form : {
                    collage : '',
                    collage_two : '',
                    program_study : '',
                    program_study_two : '',
                    collage_three : '',
                    program_study_three : '',
                    collage_four : '',
                    program_study_four : ''
                },
                submitted : false,
                waiting : false,
            }
        },
        created() {
            this.getData();
        },
        validations: {
            form: {
                collage: {
                    required
                },
                program_study: {
                    required
                }
            }
        },
        methods : {
            getData: function() {
                if(this.$parent.$parent.dataDetail.tryout.allow_access == '1') {
                    let uri         = process.env.VUE_APP_APIHOST+'member/utbk/tryout/result/rasionalisasi';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            result_id : this.$route.params.result_id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data;
                            this.dataList       = getResponse.data;
                            this.allow_access   = true;
                            
                            if(getResponse.data.collage_one == null) {
                                this.dataList.collage_one = {
                                    id : ''
                                }
                            }

                            if(getResponse.data.collage_two == null) {
                                this.dataList.collage_two = {
                                    id : ''
                                }
                            }

                            if(getResponse.data.collage_three == null) {
                                this.dataList.collage_three = {
                                    id : ''
                                }
                            }

                            if(getResponse.data.collage_four == null) {
                                this.dataList.collage_four = {
                                    id : ''
                                }
                            }

                            this.getCollage();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        }

                        this.dataFetch = false;
                    });
                }
            },
            upgrade: function () {
                this.$router.push({
                    name: 'member_paket_utbk'
                });
            },
            getCollage: function () {
                let uri = process.env.VUE_APP_APIHOST+'member/collage/list';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listCollage = getResponse.data;
                    this.getProgramStudy();
                    this.getProgramStudyTwo();
                    this.getProgramStudyThree();
                    this.getProgramStudyFour();
                });
            },
            getProgramStudy: function() {
                this.listProgramStudy = [];
                
                if(this.form.collage) {
                    if(this.form.collage.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage.id,
                                program : this.$parent.$parent.dataDetail.tryout.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudy = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyTwo: function() {
                this.listProgramStudyTwo = [];
                
                if(this.form.collage_two) {
                    if(this.form.collage_two.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_two.id,
                                program : this.$parent.$parent.dataDetail.tryout.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyTwo = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyThree: function() {
                this.listProgramStudyThree = [];
                
                if(this.form.collage_three) {
                    if(this.form.collage_three.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_three.id,
                                program : this.$parent.$parent.dataDetail.tryout.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyThree = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyFour: function() {
                this.listProgramStudyFour = [];
                
                if(this.form.collage_four) {
                    if(this.form.collage_four.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_four.id,
                                program : this.$parent.$parent.dataDetail.tryout.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyFour = getResponse.data;
                        });
                    }
                }
            },
            onSubmit: function () {
                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Konfirmasi',
                        text: 'Apakah anda yakin akan merubah target ptn dan program studi?',
                        confirmButtonText: 'Yakin',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                        }).then((result) => {
                        if (result.value) {
                            this.waiting    = true;
                            this.submitted  = true;

                            this.$http.post(process.env.VUE_APP_APIHOST + 'member/utbk/tryout/result/update_prodi', {
                                id : this.dataList.answer_id,
                                collage         : this.form.collage,
                                program_study   : this.form.program_study,
                                collage_two     : this.form.collage_two,
                                program_study_two   : this.form.program_study_two,
                                collage_three     : this.form.collage_three,
                                program_study_three   : this.form.program_study_three,
                                collage_four     : this.form.collage_four,
                                program_study_four   : this.form.program_study_four
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            document.getElementById("btnCloseModal").click();
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                        }
                    });
                }
            }
        }

    }
</script>