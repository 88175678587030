<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" v-on:click="goToBack"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Mengerjakan Soal</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kategori</div>
                                <div class="font-weight-600">{{dataDetail.category}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama</div>
                                <div class="font-weight-600">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tipe</div>
                                <div class="font-weight-600">{{dataDetail.type}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                <div class="mr-3">Jumlah Soal</div>
                                <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                            </div>
                        </div>

                        <div class="alert alert-info m-t-10" role="alert">
                            <b>Perhatian !</b>
                            <hr/>
                            <ol>
                                <li>Gunakan browser versi terbaru supaya website dapat diakses dengan lancar tanpa masalah.</li>
                                <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout</li>
                                <li>Kerjakan dengan <b>teliti</b> dan <b>fokus</b>.</li>
                                <li>Selamat mengerjakan!</li>
                            </ol>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button type="button" v-on:click="confirmStart" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        <a href="javascript:void(0)" v-on:click="goToBack" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-back"></i> Kembali</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TryoutOverviewEnglishAcademy',
        metaInfo: {
            title: 'Persiapan Mengerjakan Soal',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                token       : (Math.random() + 1).toString(36).substring(2)
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/overview';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id      : this.$route.params.id,
                        code    : this.$route.params.code
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        this.dataDetail   = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goToBack();
                            }
                        });
                    }
                });
            },
            goToBack: function() {
                this.$router.push(
                    {
                        name: 'member_paket_saya_english_academy_detail' ,
                        params : {id : this.$route.params.id}
                    }
                );
            },
            startTryout: function() {
                this.$router.push({ name: 'member_tryout_start_english_academy' , params: {
                    id      : this.$route.params.id,
                    code    : this.$route.params.code,
                    token   : this.token
                }});
            },
            confirmStart: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    html: 'Apakah kamu yakin akan mulai mengerjakan soal?',
                    confirmButtonText: 'Mulai',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.startTryout();
                    }
                });
            }
        }
    }
</script>