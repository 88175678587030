<template>
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="m-t-10">Statistik Hasil Tryout</h5>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-4 m-t-20">
                <div class="card card-theory">
                    <div class="card-body">
                        <h4 class="job-card-desc text-center">
                            Skor Akhir  
                        </h4>

                        <div class="text-center m-t-10">
                            <h3>{{this.$parent.$parent.dataDetail.statistik.skor_akhir}}</h3>
                        </div>

                        <div class="text-center">
                            <h5>dari {{this.$parent.$parent.dataDetail.statistik.total_skor}}</h5>
                        </div>

                        <h4 class="job-card-desc text-center">
                            Keterangan
                        </h4>

                        <div class="text-center m-t-10" v-if="this.$parent.$parent.dataDetail.statistik.status == 'lulus'">
                            <span style="font-size:15px" class="badge badge-success text-center">Lulus</span>
                        </div>

                        <div class="text-center m-t-10" v-if="this.$parent.$parent.dataDetail.statistik.status == 'tidak'">
                            <span style="font-size:15px" class="badge badge-danger text-center">Tidak Lulus</span>
                        </div>
                    </div>
                </div>
                <hr class="m-t-30"/>
                <div class="card card-theory m-t-30">
                    <div class="card-body">
                        <h4 class="job-card-desc text-center">
                            Passing Grade 
                        </h4>
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Nilai TWK / Passing Grade</div>
                                <div class="font-weight-600">
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_twk >= this.$parent.$parent.dataDetail.statistik.pg_twk" style="color:green">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_twk}}
                                    </span>
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_twk < this.$parent.$parent.dataDetail.statistik.pg_twk" style="color:red">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_twk}}
                                    </span>
                                    / {{this.$parent.$parent.dataDetail.statistik.pg_twk}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Nilai TIU / Passing Grade</div>
                                <div class="font-weight-600">
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_tiu >= this.$parent.$parent.dataDetail.statistik.pg_tiu" style="color:green">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_tiu}}
                                    </span>
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_tiu < this.$parent.$parent.dataDetail.statistik.pg_tiu" style="color:red">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_tiu}}
                                    </span>
                                    / {{this.$parent.$parent.dataDetail.statistik.pg_tiu}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 font-weight-bold">Nilai TKP / Passing Grade</div>
                                <div class="font-weight-600">
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_tkp >= this.$parent.$parent.dataDetail.statistik.pg_tkp" style="color:green">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_tkp}}
                                    </span>
                                    <span v-if="this.$parent.$parent.dataDetail.statistik.nilai_tkp < this.$parent.$parent.dataDetail.statistik.pg_tkp" style="color:red">
                                        {{this.$parent.$parent.dataDetail.statistik.nilai_tkp}}
                                    </span>
                                    / {{this.$parent.$parent.dataDetail.statistik.pg_tkp}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-8 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Grafik Hasil Tryout
                        </h4>

                        <div>
                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <hr/>

        <div class="row">
            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Nilai<br/>
                            <small>Tes Wawasan Kebangsaan (TWK)</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Benar : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.twk.correct}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Salah : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.twk.wrong}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 font-weight-bold">Tidak Mengisi : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.twk.empty}}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h4 class="job-card-desc text-center">
                            Grafik<br/>
                            <small>Tes Wawasan Kebangsaan (TWK)</small>
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsPie" :series="seriesTWK"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Nilai<br/>
                            <small>Tes Intelejensia Umum (TIU)</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Benar : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.tiu.correct}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Salah : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.tiu.wrong}}
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 font-weight-bold">Tidak Mengisi : </div>
                                <div class="font-weight-600">
                                    {{this.$parent.$parent.dataDetail.statistik_nilai.tiu.empty}}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h4 class="job-card-desc text-center">
                            Grafik<br/>
                            <small>Tes Intelejensia Umum (TIU)</small>
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsPie" :series="seriesTIU"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Nilai<br/>
                            <small>Tes Karakteristik Pribadi (TKP)</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div v-for="(item, index) in this.$parent.$parent.dataDetail.statistik_nilai.tkp.list_data" :key="index" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">{{item.name}} : </div>
                                <div class="font-weight-600">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Grafik Nilai<br/>
                            <small>Tes Karakteristik Pribadi (TKP)</small>
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsTKP" :series="seriesTKP"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row text-center" v-if="!this.$parent.$parent.dataSubscriber.status">
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <h4 class="job-card-desc text-center">
                                Analisis Waktu Mengerjakaan
                            </h4>

                            <div class="page-header-title text-center">
                                <img style="max-width:50%" src="assets/images/landing-upgrade.jpg" alt=""
                                    class="img-fluid mb-4">
                                <h4 class="m-t-10">Hanya bisa diakses bagi peserta yang<br/>membeli paket <u>paket silver</u> atau <u>paket gold</u></h4>
                                <p>
                                    Silahkan upgrade paket anda untuk mendapatkan benefit yang lebih baik.
                                </p>
                                <button class="btn btn-outline-primary rounded btn-block" v-on:click="upgrade"
                                    type="button"><i class="fa fa-rocket"></i> Upgrade Paket</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr v-if="this.$parent.$parent.dataSubscriber.status" />

        <div class="row" v-if="this.$parent.$parent.dataSubscriber.status">
            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Analisis Waktu Mengerjakaan<br/>
                            <small>Total Waktu</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div v-for="(item, index) in this.$parent.$parent.dataDetail.analisis_waktu.total_timer" :key="index" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">{{item.category}} : </div>
                                <div class="font-weight-600">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>
                        <h4 class="job-card-desc text-center">
                            Grafik<br/>
                            <small>Total Waktu</small>
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsTimer" :series="seriesTimer"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Analisis Waktu Mengerjakaan<br/>
                            <small>Rata - Rata Waktu</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div v-for="(item, index) in this.$parent.$parent.dataDetail.analisis_waktu.average" :key="index" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">{{item.category}} : </div>
                                <div class="font-weight-600">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>
                        <h4 class="job-card-desc text-center">
                            Grafik<br/>
                            <small>Rata - Rata Waktu</small>
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsTimer" :series="seriesAverage"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Statistik Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                currentTabComponent : 'StatisticTryout',
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019'],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                },
                series: [
                    {
                        name: 'Nilai TWK',
                        data: [this.$parent.$parent.dataDetail.statistik.nilai_twk]
                    },
                    {
                        name: 'Nilai TIU',
                        data: [this.$parent.$parent.dataDetail.statistik.nilai_tiu]
                    },
                    {
                        name: 'Nilai TKP',
                        data: [this.$parent.$parent.dataDetail.statistik.nilai_tkp]
                    }
                ],
                seriesTWK: [this.$parent.$parent.dataDetail.statistik_nilai.twk.correct, this.$parent.$parent.dataDetail.statistik_nilai.twk.wrong, this.$parent.$parent.dataDetail.statistik_nilai.twk.empty],
                seriesTIU: [this.$parent.$parent.dataDetail.statistik_nilai.tiu.correct, this.$parent.$parent.dataDetail.statistik_nilai.tiu.wrong, this.$parent.$parent.dataDetail.statistik_nilai.tiu.empty],
                seriesTKP: this.$parent.$parent.dataDetail.statistik_nilai.tkp.series,
                chartOptionsPie: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#2ed8b6', '#ff5370', '#F78812'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                chartOptionsTKP: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#2ed8b6', '#ff5370', '#F78812', '#D77FA1', '#F6D860', '#F2FFE9', '#B1D0E0', "#97BFB4"],
                    labels: this.$parent.$parent.dataDetail.statistik_nilai.tkp.labels,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                chartOptionsTimer: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#008FFB', '#00E396', '#FEB019'],
                    labels: this.$parent.$parent.dataDetail.analisis_waktu.labels,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                seriesTimer: this.$parent.$parent.dataDetail.analisis_waktu.value_total_timer,
                seriesAverage: this.$parent.$parent.dataDetail.analisis_waktu.value_average
            }
        },
        methods: {
            upgrade: function () {
                const type = this.$parent.$parent.dataDetail.statistik.type_tryout;
                if (type == 'cpns') {
                    this.$router.push({
                        name: 'member_paket_cpns'
                    });
                }
            },
        }
    }
</script>