<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div v-if="!dataDetail.complete"  class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <img style="width:40%" src="assets/images/small-study.jpg" alt="" class="img-fluid mb-4">
                            <h5 class="m-t-10">
                                Sertifikat belum tersedia<br/>
                                Pastikan semua tryout yang kamu kerjakan sudah <u style="color:green">Lulus</u>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="dataDetail.complete" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div v-if="!dataDetail.certificate" class="page-header-title text-center">
                            <img style="width:40%" src="assets/images/horay.jpg" alt="" class="img-fluid mb-4">
                            <h5 class="m-t-10">
                                Selamat, {{fullname}}!<br/>
                                Kamu telah berhasil mengerjakan semua tryout.
                            </h5>
                            <div>
                                <p>Silahkan klik tombol dibawah ini untuk mendapatkan sertifikat</p>
                                <button class="btn btn-outline-primary rounded" type="button" data-toggle="modal" data-target="#modalCertificate"><i class="fa fa-certificate"></i> Dapatkan Sertifikat</button>
                            </div>
                        </div>

                        <div v-if="dataDetail.certificate" class="page-header-title text-center">
                            <div v-if="dataDetail.certificate">
                                <div class="alert alert-info" v-if="dataDetail.certificate.status == 'waiting'">
                                    Pengajuan sertifikat telah terkirim.<br/>
                                    Proses Pengajuan sertifikat memakan waktu 1x24 jam.<br/>
                                    Untuk informasi lebih lanjut, Harap hubungi admin. <a href="https://api.whatsapp.com/send?phone=6287837850461" target="blank">Hubungi admin</a>
                                </div>

                                <div v-if="dataDetail.certificate.status == 'confirm'">
                                    <object type="application/pdf" :data="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+dataDetail.certificate.attachment" width="100%" height="500" style="height: 85vh;width:100%">No
                                        Support</object>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>

            <!-- Modal -->
            <div class="modal" id="modalCertificate" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Form Pengajuan Sertifikat</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Nama di sertifikat : </label>
                                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama kamu..." v-model="form.fullname"
                                        :class="{ 'is-invalid': submitted && $v.form.fullname.$error }"
                                        >
                                        <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback d-block">
                                        Nama Wajib Diisi</div>
                                    </div>

                                    <div class="alert alert-warning m-t-10" role="alert">
                                        <b>Perhatian : </b> Pastikan nama yang dimasukan telah sesuai.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fas fa-certificate"></i> {{ waiting ? 'Harap tunggu ...' : 'Dapatkan Sertifikat' }}</button>
                        </form>
                    </div> 
                </div>
            </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'EnglishAcademyCertificate',
        metaInfo: {
            title: 'Cetak Sertifikat',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : true,
                dataDetail  : [],
                fullname    : '',
                form : {
                    fullname    : ''
                },
                submitted   : false,
                waiting     : false
            }
        },
        validations: {
            form: {
                fullname: {
                    required
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/check-complete';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                    }
                }).then(res => {
                    this.dataFetch      = false;                
                    this.dataDetail     = res.data.data;
                    this.fullname       = res.data.fullname;
                    this.form.fullname  = this.fullname;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'member/english_academy/request/certificate', {
                        id  : this.$route.params.id,
                        fullname : this.form.fullname,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            }
        }
    }
</script>