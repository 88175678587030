<template>
    <div class="page-block">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title text-center">
                    <img style="width:100%" src="assets/images/referral.jpg" alt="" class="img-fluid mb-4">
                    <h5 class="m-t-10">Halaman ini hanya dapat diakses menggunakan Aplikasi Griya Belajar Exam.<br/>
                        Silahkan download aplikasinya di google playstore</h5>
                    <a href="https://api.griyabelajar.com/assets/GriyaBelajarExam%20v1.2.1.apk" class="btn btn-outline-primary rounded d-none"><i
                            class="fa fa-download" target="_blank"></i> Download Aplikasi Griya Belajar Exam</a>
                    <div style="margin-top: 20px;">
                        <a href="https://play.google.com/store/apps/details?id=com.application.griyabelajarexam&hl=id&pcampaignid=website"
                            target="_blank">
                            <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                alt="Get it on Google Play" style="width:200px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GriyaExam',
        metaInfo: {
            title: 'Download Aplikasi Griya Belajar Exam',
            titleTemplate: '%s - Griya Belajar'
        }
    }
</script>