<template>
    <div>
        <p v-if="dataFetch" class="text-center">Loading...</p>
        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Pengumpulan Tugas {{dataList.title}} Kelas - {{ dataList.school_class }}</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link :to="{name : 'cbt_assignments_edit', params: { id: this.$route.params.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah Tugas</router-link>
                                <router-link class="btn btn-outline-primary rounded" :to="{name : 'cbt_assignments'}">Kembali</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status Mengerjakan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="status" v-on:change="getCollection">
                                        <option value="">Lihat Semua</option>
                                        <option value="done">Sudah Mengerjakan</option>
                                        <option value="late">Telat Mengerjakan</option>
                                        <option value="notyet">Belum Mengerjakan</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama/alamat email..." v-model="keywords" v-on:keyup.enter="getCollection">
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div v-if="dataFetchCollection">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetchCollection">
                                    <div v-if="dataCollection.length > 0" class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama Siswa</th>
                                                    <th style="width:15%">Skor Tugas</th>
                                                    <th style="width:10%" class="text-center">Waktu Mengerjakan</th>
                                                    <th style="width:15%" class="text-center">Lampiran Tugas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataCollection" :key="index">
                                                    <td class="text-center">{{++index}}</td>
                                                    <td>{{item.fullname}}<br/>
                                                        <small>Email: {{ item.email ? item.email : '-' }}</small>
                                                    </td>
                                                    <td v-if="item.id">
                                                        <div class="input-group" v-if="item.id">
                                                            <input type="number" class="form-control form-score" :disabled="disabled == 1" v-model="form.score[item.id]" />
                                                            <div class="input-group-append" v-if="disabled == 1">
                                                                <button class="btn btn-outline-primary btn-sm" type="button" v-on:click="removeDisabled()"><i class="fa fa-edit"></i></button>
                                                            </div>
                                                            <div class="input-group-append" v-if="disabled == 0">
                                                                <button class="btn btn-primary btn-sm" type="button" v-on:click="saveScore()"><i class="fa fa-save"></i></button>
                                                            </div>
                                                        </div>
                                                        <span v-if="!item.id">-</span>
                                                    </td>
                                                    <td class="text-center" v-if="item.id">
                                                        {{item.completed_at}}
                                                        <br v-if="(item.status == 'late' || item.status == 'done')"/>
                                                        <small v-if="item.status == 'late'" class="text-warning font-weight-bold">Telat Mengerjakan</small>
                                                        <small v-if="item.status == 'done'" class="text-success font-weight-bold">Tepat Waktu</small>
                                                    </td>
                                                    <td v-if="!item.id" class="text-center" colspan="3">
                                                        <span class="badge badge-danger">Belum Mengerjakan</span>
                                                    </td>
                                                    <td v-if="item.id" class="text-center">
                                                        <button class="btn btn-primary btn-sm rounded" type="button" v-on:click="viewAssignment(item)" data-toggle="modal" data-target="#modalViewAssignment"><i class="fa fa-list"></i> Lihat Tugas</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="dataCollection.length < 1">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalViewAssignment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file-alt"></i> Tugas - {{ dataAssignment.fullname }}</div>
                            <div>
                                <button id="btnCloseModal" v-on:click="closeModalView()" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12">
                                <div v-if="!dataAssignment.score" class="alert alert-warning m-t-10 mb-3 text-center" role="alert">
                                    <b>Belum ada skor/nilai yang ditetapkan untuk {{ dataAssignment.fullname }}</b>.
                                </div>

                                <div class="m-t-10">
                                    <label><strong>Catatan Siswa: </strong></label>
                                    <div v-html="dataAssignment.description">
                                    </div>
                                </div>

                                <div class="m-t-10">
                                    
                                    <label><strong>Lampiran File: </strong></label>
                                    <div v-if="dataAssignment.type == 'text'">
                                        <div v-if="!preview_url">
                                            <p class="text-center mb-3 mt-3">File tidak tersedia</p>
                                        </div>
                                        <object v-if="preview_url" type="application/pdf" :data="preview_url" width="100%" height="500" style="height: 85vh;">
                                            <p class="text-center mt-3">
                                                <embed :src="preview_url" width="100%" height="600px">
                                            </p>                        
                                        </object>
                                    </div>
                                    <div v-if="dataAssignment.type == 'youtube'">
                                        <div v-if="!youtube_url">
                                            <p class="text-center mb-3 mt-3">Video tidak tersedia</p>
                                        </div>
                                        <vue-plyr v-if="youtube_url">
                                            <div class="plyr__video-embed">
                                                <iframe
                                                :src="youtube_url"
                                                allowfullscreen
                                                allowtransparency
                                                allow="autoplay"
                                                ></iframe>
                                            </div>
                                        </vue-plyr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'CbtAssignmentsCollection',
        metaInfo: {
            title: 'Data Tugas SIswa',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataFetchCollection   : false,
                dataList    : [],
                dataCollection    : [],
                keywords    : "",
                status      : "",
                dataAssignment : [],
                disabled : 1,
                form : {
                    score : [],
                    old_score : []
                },
                preview_url : "",
                youtube_url : "",
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/assignments/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id       : this.$route.params.id
                    }
                }).then(res => {                    
                    if(res.data.status) {
                        this.dataFetch  = false;
                        this.dataList   = res.data.data;
                        this.getCollection();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'cbt_assignments' });
                            }
                        });
                    }
                });
            },
            getCollection: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/assignments/collection';

                this.dataFetchCollection  = true;                
                this.$http.get(uri,{
                    params : {
                        id       : this.$route.params.id,
                        status   : this.status,
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetchCollection    = false;                
                    this.dataCollection         = res.data.data;
                    this.disabled               = 1;
                    this.dataCollection.forEach(element => {
                        if(element.id != null) {
                            this.form.score[element.id] = element.score;
                            this.form.old_score[element.id] = element.score;
                        }
                    });
                });
            },
            viewAssignment: function(item) {
                this.preview_url = item.preview_url;
                this.youtube_url = item.content;

                this.dataAssignment = item;
            },
            removeDisabled: function() {
                this.disabled = 0;
            },
            closeModalView: function() {
                setTimeout(() => {
                    this.preview_url = "";
                    this.youtube_url = "";
                }, 500);
            },
            saveScore: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Perbarui Skor Nilai',
                    text: 'Apakah kamu yakin ingin menyimpan skor nilai ini?',
                    confirmButtonText: 'Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    let new_score = [];
                    let old_score = [];
                    this.form.score.forEach((element, index) => {
                        
                        let item = {
                            id: index,
                            score: element
                        };
                        
                        new_score.push(item);
                    });

                    this.form.old_score.forEach((element, index) => {
                        
                        let old_item = {
                            id: index,
                            score: element
                        };
                        
                        old_score.push(old_item);
                    });

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/assignments/save_score', {
                        new_score   : new_score,
                        old_score   : old_score
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getCollection();
                                }
                            });
                        }
                    })
                }
                });
            }
        }
    }
</script>
