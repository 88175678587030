<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Tryout SKB</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'skb_tryout_add'}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:5%">No</th>
                                                <th>Formasi</th>
                                                <th>Nama</th>
                                                <th>Tipe</th>
                                                <th>Harga</th>
                                                <th>Durasi</th>
                                                <th class="text-center">Status</th>
                                                <th style="width:10%">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="dataList.length > 0">
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td class="text-center">{{++index}}</td>
                                                <td>{{item.formasi}}</td>
                                                <td>{{item.title}}</td>
                                                <td>
                                                    <span v-if="item.type == 'free' " class="badge badge-info">Free (Gratis)</span>
                                                    <span v-if="item.type == 'premium' " class="badge badge-success">Premium (Berbayar)</span>
                                                </td>
                                                <td v-if="item.type == 'free'">
                                                    Rp. 0
                                                </td>
                                                <td v-if="item.type != 'free'">
                                                    <s>{{item.text_price_out}}</s> {{item.text_price}}
                                                </td>
                                                <td>{{item.timer}} Menit</td>
                                                <td class="text-center">
                                                    <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                                    <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                                </td>
                                                <td>
                                                    <router-link :to="{name : 'skb_tryout_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                    <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                    <router-link :to="{name : 'skb_tryout_questions', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Kelola Soal" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-list"></i> Kelola Soal</router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="dataList.length < 1">
                                            <tr colspan="5">
                                                <td>Data tidak ditemukan</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TryoutSKB',
        metaInfo: {
            title: 'Data Tryout SKB',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                keywords    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skb/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skb/tryout/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>
