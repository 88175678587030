<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Salin Materi SKD CPNS dari Kedinasan</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_theory_cpns'}">Materi</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Salin Materi</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Ketentuan Salin Materi</h5>
                    </div>
                    <div class="card-body">
                        <ol>
                            <li>Pastikan koneksi internet anda aman.</li>
                            <li>Silahkan pilih materi yang akan di salin pada tabel.</li>
                            <li>jika materi sudah dipilih, kemudian klik simpan untuk salin materi.</li>
                            <li>Materi yang sudah disalin tidak akan tersalin kembali.</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Silahkan pilih / checklist materi kedinasan yang akan disalin lalu klik simpan</h5>
                    </div>
                    <div class="card-body">
                        <div class="input-group mb-3 m-t-10">
                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                            <div class="input-group-append">
                                <button :disabled="waiting" v-on:click="copyData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                        class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }} </button>
                            </div>
                        </div>

                        <div v-if="dataFetch">
                            <p>Sedang mengambil data...</p>
                        </div>
                        <div v-if="!dataFetch" class="table-responsive m-t-20">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="width:5%" class="text-center">
                                            <input type="checkbox" name="checkbox_all" v-model="form.check_all" v-on:click="checkkAll">
                                        </th>
                                        <th style="width:10%">Kategori</th>
                                        <th>Judul</th>
                                        <th>Tipe</th>
                                        <th class="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody v-if="dataList.length > 0">
                                    <tr v-for="(item, index) in dataList" :key="index">
                                        <td class="text-center">
                                            <input type="checkbox" name="check_id" v-model="form.check_id[item.id]" :value="item.id">
                                        </td>
                                        <td>{{item.category}}</td>
                                        <td>{{item.title}}</td>
                                        <td>
                                            <span v-if="item.type == 'youtube' " class="badge badge-danger">Youtube / Video</span>
                                            <span v-if="item.type == 'text' " class="badge badge-info">Text</span>
                                        </td>
                                        <td class="text-center">
                                            <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                            <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="dataList.length < 1">
                                    <tr colspan="6">
                                        <td>Data tidak ditemukan</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Theory',
        metaInfo: {
            title: 'Data Materi SKD',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                form : {
                    check_all   : false,
                    check_id    : []
                },
                waiting    : false,
                submitted  : false
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skd/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        type_theory : 'kedinasan'
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;

                    this.dataList.forEach(element => {
                        this.form.check_id[element.id] = false;                        
                    });
                });
            },
            checkkAll: function() {
                const id = this.form.check_all;
                
                if (typeof (id) !== 'undefined' && id !== false) {
                    this.dataList.forEach(element => {
                        this.form.check_id[element.id] = false;                        
                    });
                } else {
                    this.dataList.forEach(element => {
                        this.form.check_id[element.id] = true;                        
                    });
                }


            },
            copyData: function() {
                let obj = [];

                this.dataList.forEach(element => {
                    let id  = this.form.check_id[element.id];
                    if (typeof (id) !== 'undefined' && id !== null && id !== false) {
                        obj.push(element.id);
                    }
                });
                
                if(obj.length> 0) {
                    this.$swal({
                        icon: 'info',
                        title: 'Salin Data',
                        text: 'Apakah kamu yakin ingin melakukan salin materi ini?',
                        confirmButtonText: 'Salin',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                    }).then((result) => {
                    if (result.value) {
                        this.waiting    = true;
                        this.submitted  = true;

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skd/theory/copy', {
                            id          : obj,
                            type_theory : 'cpns'
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({name : 'skd_theory_cpns'});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }

                            this.waiting    = false;
                            this.submitted  = false;
                        })
                    }
                    });
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih materi yang akan disalin!',
                    });
                }
            }
        }
    }
</script>