<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Tryout SKB</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skb_tryout'}">Tryout SKB</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">

                                <div class="form-group col-md-6">                                    
                                    <label for="exampleFormControlSelect1">Formasi</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.formation_id" :class="{ 'is-invalid': submitted && $v.form.formation_id.$error }">
                                        <option value="">Pilih Formasi</option>
                                        <option v-for="(item, index) in dataFormation" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.formation_id.required" class="invalid-feedback">Formasi wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Judul Tryout</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Tipe</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                        <option value="">Pilih Tipe</option>
                                        <option value="free">Free (Gratis)</option>
                                        <option value="premium">Premium (Berbayar)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Tipe wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'premium'">
                                    <label for="exampleInputEmail1">Harga Tryout</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price">
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'premium'">
                                    <label for="exampleInputEmail1">Harga Coret</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price_out">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Durasi Soal (Menit)</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan menit (Jika tidak disi, waktu default adalah 90 menit)" v-model="form.timer" :class="{ 'is-invalid': submitted && $v.form.timer.$error }">
                                    <div v-if="submitted && !$v.form.timer.required" class="invalid-feedback">Durasi wajib diisi!</div>    
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'skb_tryout'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Materi',
        metaInfo: {
            title: 'Form Tryout SKB',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getFormation();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                dataFormation   : [],
                form : {
                    id          : "",
                    title       : "",
                    type        : "",
                    status      : "draft",
                    price       : "",
                    price_out   : "",
                    timer       : "",
                    formation_id : ""

                },
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                formation_id: {
                    required
                },
                title: {
                    required
                },
                type: {
                    required
                },
                status: {
                    required
                },
                timer: {
                    required
                }
            }
        },
        methods: {
            getFormation: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skb/formation/list';
                this.$http.get(uri).then(res => {
                    this.dataFormation   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('type', this.form.type);
                    formData.append('status', this.form.status);
                    formData.append('formation_id', this.form.formation_id);
                    formData.append('price', this.form.price);
                    formData.append('price_out', this.form.price_out);
                    formData.append('timer', this.form.timer);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/skb/tryout/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'skb_tryout' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/skb/tryout/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data[0];
                            
                            this.form.id    = getResponse.id;
                            this.form.status    = getResponse.status;
                            this.form.title    = getResponse.title;
                            this.form.type    = getResponse.type;
                            this.form.formation_id    = getResponse.formation_id;
                            this.form.price    = getResponse.price;
                            this.form.price_out    = getResponse.price_out;
                            this.form.timer    = getResponse.timer;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'skb_tryout' });
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>