<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tryout</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Waktu</div>
                                <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Jumlah Soal</div>
                                <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Passing Grade</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">
                                    <ul>
                                        <li v-for="(item, index) in dataDetail.passing_grade" :key="index">{{item.name}} <i class="ml-2 fa fa-arrow-right mr-2"></i> <span style="color:green">{{item.passing_grade}}</span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="this.$route.params.type_tryout == 'cpns'">
                            <button v-if="!dataDetail.answer" type="button" data-toggle="modal" data-target="#instructionQuiz" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        </div>

                        <div v-if="this.$route.params.type_tryout == 'kedinasan'">
                            <button v-if="!dataDetail.answer" type="button" data-toggle="modal" data-target="#chooseProgram" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        </div>
                        
                        <router-link v-if="dataDetail.answer" :to="{name : 'member_tryout_skd_result_detail', params: { result_id: dataDetail.answer_id, tryout_answer_id: dataDetail.tryout_answer_id }}" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-bars"></i> Lihat Hasil</router-link>
                        <a href="javascript:void(0)" onclick="window.history.back();" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-back"></i> Kembali</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="instructionQuiz" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0 text-center">
                        <div class="align-items-center justify-content-between text-sm">
                            <img style="width: 30%; height: auto;" class="mb-5"
                                src="https://scolacdn.com/frontend/images/sign-warning-icon.png" />
                            <h5>Untuk lanjut ke pengerjaan soal mohon diperhatikan beberapa langkah berikut: </h5>
                        </div>
                    </div>
                    <div class="d-block p-3">
                    <ol class="mb-4">
                        <li>Pastikan internet stabil.</li>
                        <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout.</li>
                        <li>Disarankan menggunakan versi browser terbaru.</li>
                        <li>Kerjakan soal sesuai dengan waktu yang ditentukan.</li>
                        <li>Pastikan semua jawaban terisi</li>
                        <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                    </ol>
                        <button data-dismiss="modal" v-on:click="startTyout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="chooseProgram" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm text-center">
                            <div class="font-weight-600" style="font-size:20px">Pilih Target PTN & Prodi</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="m-t-10">
                                                <label>Perguruan Tinggi</label>
                                                <v-select
                                                    placeholder="Pilih Perguruan Tinggi"
                                                    label="name"
                                                    :options="listCollage"
                                                    v-model="form.collage"
                                                    :class="{ 'is-invalid': submitted && $v.form.collage.$error }"
                                                    @input="getProgramStudy"
                                                    >                                    
                                                </v-select>
                                                
                                                <div v-if="submitted && !$v.form.collage.required" class="invalid-feedback d-block">
                                                Perguruan Tinggi Wajib Diisi</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="m-t-10">
                                                <label>Program Studi</label>
                                                <v-select
                                                    placeholder="Pilih Prodi"
                                                    label="name"
                                                    :options="listProgramStudy"
                                                    v-model="form.program_study"
                                                    :class="{ 'is-invalid': submitted && $v.form.program_study.$error }"
                                                    >                                    
                                                </v-select>


                                                <div v-if="submitted && !$v.form.program_study.required" class="invalid-feedback d-block">
                                                    Program Studi Wajib Diisi</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="alert alert-primary m-t-20" role="alert">
                                        <b>Perhatian !</b>
                                        <hr/>
                                        <ol>
                                            <li>Sebelum memulai mengerjakan tryout, silahkan untuk memilih perguruan tinggi dan program studi impian kamu.</li>
                                            <li>Pastikan internet stabil.</li>
                                            <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout.</li>
                                            <li>Disarankan menggunakan versi browser terbaru.</li>
                                            <li>Kerjakan soal sesuai dengan waktu yang ditentukan.</li>
                                            <li>Pastikan semua jawaban terisi</li>
                                            <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block"> Simpan & Lanjut Mengerjakan Tryout <i class="m-l-10 fas fa-arrow-right"></i></button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'TryoutSKDOverview',
        metaInfo: {
            title: 'Persiapan Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
                randomCode      : (Math.random() + 1).toString(36).substring(2),
                listCollage         : [],
                listProgramStudy    : [],
                submitted : false,
                waiting : false,
                form : {
                    collage : '',
                    program_study : '',
                },
            }
        },
        components: {
            'v-select' : vSelect,
        },
        validations: {
            form: {
                collage: {
                    required
                },
                program_study: {
                    required
                }
            }
        },
        created() {
            this.getData();
            this.getCollage();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type_tryout : this.$route.params.type_tryout,
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data[0];

                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            if(this.dataDetail.token_quiz) {
                                this.$router.push({
                                    name: 'member_tryout_skd_start',
                                    params: {
                                        type_tryout : this.$route.params.type_tryout,
                                        slug        : this.$route.params.slug,
                                        token       : this.dataDetail.token_quiz
                                    }
                                });
                            } else {
                                this.dataFetch  = false;         
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            startTyout: function() {
                this.$router.push({
                    name: 'member_tryout_skd_start',
                    params: {
                        type_tryout : this.$route.params.type_tryout,
                        slug        : this.$route.params.slug,
                        token       : this.randomCode
                    }
                });
            },
            getCollage: function () {
                let uri = process.env.VUE_APP_APIHOST+'member/collage/list_skd';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listCollage = getResponse.data;
                    this.getProgramStudy();
                });
            },
            getProgramStudy: function() {
                this.listProgramStudy = [];                
                if(this.form.collage) {
                    if(this.form.collage.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail_skd';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudy = getResponse.data;
                        });
                    }
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Informasi',
                        text: 'Data Prodi yang kamu pilih akan disimpan. Silahkan klik mulai mengerjakan jika data yang kamu pilih telah sesuai',
                        confirmButtonText: 'Mulai Mengerjakan',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                        }).then((result) => {
                        if (result.value) {
                            this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/start', {
                                type_tryout     : this.$route.params.type_tryout,
                                code            : this.$route.params.slug,
                                token           : this.randomCode,
                                collage         : this.form.collage,
                                program_study   : this.form.program_study
                            })
                            .then(response => {
                                if (response.data.status) {
                                    document.getElementById("btnCloseModal").click();
                                    this.$router.push({
                                        name: 'member_tryout_skd_start',
                                        params: {
                                            type_tryout : this.$route.params.type_tryout,
                                            slug        : this.$route.params.slug,
                                            token       : this.randomCode
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                        }
                    });
                }
            }
        }
    }
</script>