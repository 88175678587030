<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card card-body card-theory-no-hover custom-rounded-medium">
                    <div class="page-header-title">
                        <h5 class="m-t-10">Monitoring Tryout <span v-if="type == 'school'">Sekolah</span>
                            <span v-if="type == 'free'">Gratis</span>
                            <span v-if="type == 'premium'">Premiun (Berbayar)</span>
                            Kedinasan</h5>
                    </div>
                    <hr/>
                    <ul class="nav nav-pills" role="tablist" style="gap: 0.7rem;">
                        <li class="nav-item waves-effect waves-light" role="presentation">
                            <button type="button" class="btn rounded" :class="type == 'school' ? 'btn-primary':'btn-outline-primary'" v-on:click="changeFilterTryout('school')">Tryout Sekolah</button>
                        </li>
                        <li class="nav-item waves-effect waves-light" role="presentation">
                            <button type="button" class="btn rounded" :class="type == 'free' ? 'btn-primary':'btn-outline-primary'" v-on:click="changeFilterTryout('free')">Tryout Gratis</button>
                        </li>
                        <li class="nav-item waves-effect waves-light" role="presentation">
                            <button type="button" class="btn rounded" :class="type == 'premium' ? 'btn-primary':'btn-outline-primary'" v-on:click="changeFilterTryout('premium')">Tryout Premium</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama Tryout</th>
                                                    <th class="text-center">Peserta Terdaftar</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">{{++index}}</td>
                                                    <td>{{item.title}}</td>
                                                    <td class="text-center">{{item.total_users}} Orang</td>
                                                    <td>
                                                        <router-link :to="{name : 'monitoring_tryout_kedinasan_detail', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fa fa-edit"></i> Status Pengerjaan</router-link>
                                                        <router-link :to="{name : 'member_tryout_kedinasan_rank_school', params: { slug: item.code }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-outline-primary btn-sm rounded"><i class="fa fa-trophy"></i> Rangking Peserta</router-link>
                                                        <!-- <router-link :to="{name : 'member_tryout_kedinasan_rank_school', params: { slug: item.code }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-outline-primary btn-sm rounded"><i class="fa fa-trophy"></i> Rangking Peserta</router-link> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout Kedinasan',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                program     : "kedinasan",
                type        : "school",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            changeFilterTryout: function(params) {
                this.type = params;
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-skd';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : this.type,
                        program     : this.program,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>
