<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h4 class="m-t-10">Akun</h4>
                            <p>Pengaturan Akun & Ubah Password</p>
                            <hr/>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mt-md-4">
                                <ul class="nav nav-tabs profile-tabs nav-fill" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link text-reset active" v-on:click="changeTab('MyProfile')" id="profile-tab" data-toggle="tab"
                                            href="javascript:void(0)" role="tab" aria-controls="profil saya"
                                            aria-selected="true"><i class="feather icon-user mr-2"></i>Akun Saya</a>
                                    </li>
                                    <li v-if="dataUsers.type == 'content'" class="nav-item">
                                        <a class="nav-link text-reset" v-on:click="changeTab('ProfilGuru')" id="guru-tab" data-toggle="tab"
                                            href="javascript:void(0)" role="tab" aria-controls="profil guru"
                                            aria-selected="false"><i class="feather icon-edit mr-2"></i>Profil Sekolah</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-reset" v-on:click="changeTab('ChangePassword')" id="password-tab" data-toggle="tab"
                                            href="javascript:void(0)" role="tab" aria-controls="ganti password"
                                            aria-selected="false"><i class="feather icon-lock mr-2"></i>Ganti Password</a>
                                    </li>
                                    <!--li class="nav-item">
                                        <a class="nav-link text-reset" v-on:click="changeTab('Referral')" data-toggle="tab" id="Referral-tab"
                                            href="javascript:void(0)" role="tab" aria-controls="referral"
                                            aria-selected="false"><i class="feather icon-tag mr-2"></i>Kode Referral</a>
                                    </li-->
                                </ul>
                            </div>

                            <div class="col-md-12">
                                <vue-page-transition>
                                    <keep-alive>
                                            <component :is="currentTabComponent"></component>
                                    </keep-alive>
                                </vue-page-transition>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MyProfile from "@/views/static/MyProfile.vue"
    import ChangePassword from "@/views/static/ChangePassword.vue"
    import Referral from "@/views/static/Referral.vue"
    import ProfilGuru from "@/views/static/ProfilGuru.vue"

    export default {
        name: 'Profile',
        metaInfo: {
            title: 'Profil Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            MyProfile,
            ChangePassword,
            Referral,
            ProfilGuru,
        },
        data() {
            return {
                currentTabComponent : 'MyProfile',
                dataFetch : false,
                dataUsers : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            },
            getData: function () {
                this.dataFetch = true;
                let uri = this.$apiconfig + 'user/account';
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.dataUsers = getResponse.data;
                    }

                    this.dataFetch = false;
                });
            }
        },
    }
</script>