<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <GriyaExam v-if="show_alert"></GriyaExam>

            <div v-if="!show_alert" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{name : 'member_exercises_cbt'}" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></router-link> Persiapan Latihan</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kategori</div>
                                <div class="font-weight-600">{{dataDetail.category}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Latihan</div>
                                <div class="font-weight-600">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Waktu</div>
                                <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Jumlah Soal</div>
                                <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                            </div>

                            <div class="alert alert-info" role="alert">
                                <b>Perhatian !</b>
                                <hr/>
                                <ol>
                                    <li>Gunakan browser versi terbaru supaya website dapat diakses dengan lancar tanpa masalah.</li>
                                    <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan latihan</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <button type="button" data-toggle="modal" data-target="#instructionQuiz" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        <router-link :to="{name : 'member_exercises_cbt'}" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-back"></i> Kembali</router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="instructionQuiz" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0 text-center">
                        <div class="align-items-center justify-content-between text-sm">
                            <img style="width: 30%; height: auto;" class="mb-5"
                                src="https://scolacdn.com/frontend/images/sign-warning-icon.png" />
                            <h5>Untuk lanjut ke pengerjaan soal mohon diperhatikan beberapa langkah berikut: </h5>
                        </div>
                    </div>
                    <div class="d-block p-3">
                    <ol class="mb-4">
                        <li>Pastikan internet stabil.</li>
                        <li>Disarankan menggunakan versi browser terbaru.</li>
                        <li>Kerjakan soal sesuai dengan waktu yang ditentukan.</li>
                        <li>Pastikan semua jawaban terisi</li>
                    </ol>
                        <button data-dismiss="modal" v-on:click="startQuiz" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GriyaExam from '../../../static/GriyaExam.vue';

    export default {
        name: 'ExercisesCbtOverview',
        metaInfo: {
            title: 'Persiapan Latihan',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            GriyaExam
        },
        data() {
            return {
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
                randomCode      : (Math.random() + 1).toString(36).substring(2),
                show_alert      : false,
                allow_exam      : 1,
                is_android      : false,
                check_web_view  : ''
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/exercises/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data[0];

                        if(this.dataDetail.safe_exam == '1') {                        
                            this.dataFetch  = false;
                            this.checkDevice();
                        }

                        if(this.allow_exam == 1) {                            
                            if(!this.dataDetail.owned) {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    html: "Akses tidak diperbolehkan!<br/>Anda belum berhak untuk mengakses latihan ini",
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({
                                            name: 'member_exercises_cbt'
                                        });
                                    }
                                });
                            } else {
                                if(this.dataDetail.finished) {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: "Kamu sudah mengerjakan latihan ini!",
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.$router.push({
                                                name: 'member_exercises_cbt_result_detail',
                                                params: {
                                                    result_id : this.dataDetail.result_id,
                                                    cbt_exercises_answer_id : this.dataDetail.answer_id
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    if(this.dataDetail.token_quiz) {
                                        this.$router.push({
                                            name: 'member_exercises_cbt_start',
                                            params: {
                                                slug        : this.$route.params.slug,
                                                token       : this.dataDetail.token_quiz
                                            }
                                        });
                                    } else {
                                        this.dataFetch  = false;         
                                    }
                                }
                            }
                        } else {
                            this.show_alert = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_exercises_cbt'
                                });
                            }
                        });
                    }
                });
            },
            startQuiz: function() {
                this.$router.push({
                    name: 'member_exercises_cbt_start',
                    params: {
                        slug        : this.$route.params.slug,
                        token       : this.randomCode
                    }
                });
            },
            checkDevice: function() {
                var isAndroid = /Android/.test(navigator.userAgent);
                
                if (isAndroid) {
                    this.is_android = true;
                    this.checkWebView();

                    if (this.check_web_view == 'iOS webview' || this.check_web_view == 'Android webview') {
                        this.allow_exam = 1;
                    } else {
                        this.allow_exam = 0;
                    }
                }
            },
            checkWebView: function() {
                var standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

                if (ios) {
                    if (!standalone && safari) {
                        this.check_web_view = 'Safari Browser'
                    } else if (!standalone && !safari) {
                        this.check_web_view = 'iOS webview';
                    }
                } else {
                    if (userAgent.includes('wv')) {
                        this.check_web_view = 'Android webview'
                    } else {
                        this.check_web_view = 'Chrome Browser'
                    }
                }
            }
        }
    }
</script>