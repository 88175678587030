<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" v-on:click="goBack()"><i class="fa fa-arrow-left m-r-10"></i></a> Hasil - {{dataDetail.title}}</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{dataDetail.fullname}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Provinsi</div>
                                <div class="font-weight-600">{{dataDetail.province_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Kota</div>
                                <div class="font-weight-600">{{dataDetail.city_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Mengerjakan</div>
                                <div class="font-weight-600">{{dataDetail.start}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <div v-if="dataDetail.complete == 0" class="alert alert-danger" role="alert">
                            <b>Kamu belum lulus pada tryout ini! </b><br/>Tetap semangat dan coba lagi kembali ya...
                        </div>
                        <div v-if="dataDetail.complete == 1" class="alert alert-success" role="alert">
                            <b>Selamat kamu lulus pada tryout ini! </b><br/>Jangan sampai berpuas diri ya!
                        </div>

                        <button v-on:click="changeTab('Statistic')" type="button"  :class="currentTabComponent == 'Statistic' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-bars"></i> Lihat Statistik</button>
                        <button v-on:click="changeTab('Pembahasan')" type="button" :class="currentTabComponent == 'Pembahasan' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-file"></i> Lihat Pembahasan</button>
                        <button v-if="dataDetail.complete == 0" v-on:click="goToOverview" type="button" class="btn btn-outline-primary text-left btn rounded btn-block m-t-10" ><i class="fa fa-edit"></i> Kerjakan ulang</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <vue-page-transition>
                <keep-alive>
                        <component :is="currentTabComponent"></component>
                </keep-alive>
            </vue-page-transition>
        </div>

    </div>
</template>

<script>
    import Statistic from "@/views/member/english_academy/tryout/Statistic.vue"
    import Pembahasan from "@/views/member/english_academy/tryout/Pembahasan.vue"

    export default {
        name: 'ResultTryoutDetail',
        metaInfo: {
            title: 'Hasil Tryout English Academy',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            Statistic,
            Pembahasan,
        },
        data() {
            return {
                currentTabComponent : 'Statistic',
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/result/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        code        : this.$route.params.code,
                        id_answer   : this.$route.params.id_answer
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data;
                        this.dataFetch  = false;     
                        this.changeTab(this.currentTabComponent);    
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            },
            goBack: function() {
                this.$router.push(
                    {
                        name: 'member_paket_saya_english_academy_detail' ,
                        params : {
                            id : this.$route.params.id
                        }
                    }
                );
            },
            goToOverview: function() {
                this.$router.push(
                    {
                        name: 'member_tryout_overview_english_academy' ,
                        params : {
                            id : this.$route.params.id,
                            code : this.$route.params.code,
                        }
                    }
                );
            }
        }
    }
</script>