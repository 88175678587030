<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Voucher</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button type="button" class="btn btn-primary rounded" data-toggle="modal" data-target="#modalGenerate" ><i class="fa fa-plus"></i> Generate Voucher</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th>Kode</th>
                                                        <th style="width:10%">Kategori</th>
                                                        <th style="width:10%">Tipe</th>
                                                        <th class="text-center" style="width:10%">Nominal</th>
                                                        <th style="width:10%" >Berlaku</th>
                                                        <th class="text-center" style="width:10%" >Kuota</th>
                                                        <th class="text-center" style="width:10%">Status</th>
                                                        <th class="text-center" style="width:15%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>
                                                            {{item.code}}

                                                            <button 
                                                                class="m-l-10 btn btn-outline-primary rounded btn-sm"
                                                                type="button"
                                                                v-clipboard:copy="item.code"
                                                                v-clipboard:success="onCopy"
                                                                v-clipboard:error="onError"
                                                            >
                                                                <i class="fa fa-copy"></i> Salin
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <span v-if="item.category == 'tryout'" class="badge bg-warning">Tryout</span>
                                                            <span v-if="item.category == 'cpns'" class="badge bg-info">Paket CPNS</span>
                                                            <span v-if="item.category == 'kedinasan'" class="badge bg-primary">Paket Kedinasan</span>
                                                            <span v-if="item.category == 'pppk'" class="badge bg-light">Paket PPPK</span>
                                                            <span v-if="item.category == 'olimpiade'" class="badge bg-light">Paket Olimpiade</span>
                                                            <span v-if="item.category == 'esai'" class="badge bg-light">Paket Esai</span>
                                                            <span v-if="item.category == 'lkti'" class="badge bg-light">Paket LKTI</span>
                                                        </td>
                                                        <td>
                                                            {{item.type == 'percentage'? 'Persentase':'Nominal'}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.type == 'percentage'? item.nominal+'%': item.nominal}}
                                                        </td>
                                                        <td>
                                                            {{item.apply == 'once'? 'Sekali Pakai':'Berulang'}}
                                                        </td>
                                                        <td class="text-center">{{item.quota}}</td>
                                                        <td class="text-center">
                                                            <span v-if="item.used > 0" class="badge badge-danger">Sudah terpakai</span>
                                                            <span v-if="item.used == 0" class="badge badge-success">Belum terpakai</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span v-if="item.used > 0">-</span>
                                                            <button v-if="item.used == 0" class="btn btn-danger rounded btn-sm" type="button" v-on:click="hapus(item.id)"><i class="fa fa-trash"></i> Hapus</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modalGenerate" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">Generate Voucher</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kode</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan kode voucher..." v-model="form.code" :class="{ 'is-invalid': submitted && $v.form.code.$error }" autocomplete="off">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Kode wajib diisi!</div>    
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kategori Voucher</label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="form.category" :class="{ 'is-invalid': submitted && $v.form.category.$error }">
                                        <option value="">Pilih Kategori</option>
                                        <option value="cpns">Paket CPNS</option>
                                        <option value="kedinasan">Paket Kedinasan</option>
                                        <option value="pppk">Paket PPPK</option>
                                        <option value="utbk">Paket SNBT</option>
                                        <option value="akm">Paket AKM</option>
                                        <option value="english_academy">Paket English Academy</option>
                                        <option value="tryout">Tryout</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.category" class="invalid-feedback">Harap pilih kategori voucher!</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Tipe Voucher</label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                                        <option value="">Pilih Tipe</option>
                                        <option value="percentage">Persentase</option>
                                        <option value="nominal">Nominal</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Harap pilih tipe voucher!</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Nominal</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan nominal..." v-model="form.nominal" :class="{ 'is-invalid': submitted && $v.form.nominal.$error }" autocomplete="off">
                                    <div v-if="submitted && !$v.form.nominal.required" class="invalid-feedback">Nominal wajib diisi!</div>
                                    <div v-if="submitted && !$v.form.nominal.numeric" class="invalid-feedback"> Nominal harus berupa angka</div>

                                    <div v-if="form.type == 'percentage'" class="alert alert-warning m-t-10 text-center" role="alert">
                                        Jika tipe adalah persentasi<br/>Maka range nominal harus 1 s/d 100
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Berlaku</label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="form.apply">
                                        <option value="once">Sekali Pakai</option>
                                        <option value="repeated">Berulang</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row" v-if="form.apply == 'repeated'">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kuota</label>
                                <div class="col-sm-9">
                                    <input type="number" class="form-control" id="inputEmail3" placeholder="Masukan kuota..." v-model="form.quota" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Berlaku hingga</label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan kuota..." v-model="form.expired_at" :class="{ 'is-invalid': submitted && $v.form.expired_at.$error }">
                                    <div v-if="submitted && !$v.form.expired_at.required" class="invalid-feedback">Masukan tanggal kadaluarsa!</div>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary rounded btn-block" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Generate' }}</button>
                         </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import {
        required, numeric
    } from "vuelidate/lib/validators";

    export default {
        name: 'AdminVoucher',
        metaInfo: {
            title: 'Voucher',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch   : false,
                waiting   : false,
                submitted   : false,
                dataList    : [],
                keywords    : "",
                form        : {
                    type        : '',
                    apply       : 'once',
                    quota       : 1,
                    category    : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        validations: {
            form: {
                code: {
                    required
                },
                category: {
                    required
                },
                type: {
                    required
                },
                expired_at: {
                    required
                },
                nominal: {
                    required,
                    numeric
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Generate Data',
                        text: "Apakah kamu yakin akan generate voucher ?",
                        confirmButtonText: 'Iya',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                    }).then((result) => {
                        if (result.value) {
                            this.waiting = true;

                            let formData = new FormData();

                            formData.append('code', this.form.code);
                            formData.append('category', this.form.category);
                            formData.append('type', this.form.type);
                            formData.append('nominal', this.form.nominal);
                            formData.append('expired_at', this.form.expired_at);
                            formData.append('apply', this.form.apply);
                            formData.append('quota', this.form.quota);

                            this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher/generate', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(response => {
                                    if (response.data.status) {
                                        this.$swal({
                                            icon: 'success',
                                            title: 'Success',
                                            text: response.data.message,
                                            confirmButtonText: 'OK'
                                        }).then((result) => {
                                            if (result.value) {
                                                document.getElementById("closeModal").click();
                                                this.getData();
                                            }
                                        });
                                    } else {
                                        this.$swal({
                                            icon: 'warning',
                                            title: 'Ups!',
                                            text: response.data.message,
                                        });
                                    }
                                    this.waiting = false;
                                })
                            .catch(function () {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: 'Please check your internet connection.',
                                });
                                this.waiting = false;
                                return;
                            });
                        }
                    });
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            hapus: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Hapus',
                    text: "Apakah kamu yakin akan menghapus voucher ini ?",
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher/delete', {
                        id      : id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            onCopy: function (e) {
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    html: 'Kode voucher berhasil di salin : <br/><b>'+e.text+'</b>',
                });
            },
            onError: function (e) {
                alert('Failed to copy the text to the clipboard')
                console.log(e);
            }            
        }
    }
</script>