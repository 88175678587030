<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <img style="max-width:50%" src="assets/images/payment.jpg" alt=""
                                class="img-fluid mb-4">
                            <h4 class="m-t-10" v-if="dataList.transaction_status == 'settlement'">Pembayaran Berhasil di proses</h4>
                            <h4 class="m-t-10" v-if="dataList.transaction_status == 'pending'">Pembayaran Sedang di proses</h4>
                            <p>
                                Silahkan klik button dibawah ini untuk melihat data invoice
                            </p>
                            <router-link class="btn btn-outline-primary rounded btn-block" :to="{name :'invoice_list'}"
                                type="button"><i class="fa fa-rocket"></i> Lihat Semua Invoice</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InvoiceDetail',
        metaInfo: {
            title: 'Invoice',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                order_id    : this.$route.params.order_id,
                emptyState  : false,
                dataFetch   : false,
                dataList    : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/invoice/info';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        order_id : this.order_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;                
                        this.emptyState = false;
                        this.dataList   = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'invoice_list' });
                            }
                        });
                    }
                });
            }
        }
    }
</script>