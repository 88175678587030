<template>
    <div>
        <nav class="pcoded-navbar menupos-fixed menu-light ">
            <div class="navbar-wrapper">
                <div class="navbar-content scroll-div d-flex align-items-center justify-content-center text-lg" v-if="$route.name == 'member_tryout_cbt_start'">
                    <h5><i class="fa fa-lock fa-lg"></i></h5>
                </div>
                <div class="navbar-content scroll-div " v-if="$route.name != 'member_tryout_cbt_start'">
                    <ul v-for="(item, index) in dataMenu" :key="index" class="nav pcoded-inner-navbar" :class="lastMenu == index ? 'mb-5':''">
                        <li class="nav-item pcoded-menu-caption">
                            <label>Menu {{index != 'CBT' ? index:users.cbt_text}}</label>
                        </li>

                        <li v-for="(menu, indexMenu) in item" :key="indexMenu" class="nav-item" :class="menu.parent == '1' ? 'pcoded-hasmenu':''">

                            <router-link v-if="!menu.parent" :to="{name : menu.url}" class="nav-link ">
                                <span class="pcoded-micon"><i :class="'feather '+menu.icon"></i></span>
                                <span class="pcoded-mtext">{{menu.title.replace('CBT', users.cbt_text)}} <span v-if="menu.url == 'member_tryout_cbt'" class="badge badge-danger text-white ml-2">New!</span></span>
                            </router-link>

                            <a v-if="menu.parent" href="javascript:void(0)" class="nav-link ">
                                <span class="pcoded-micon"><i class="feather icon-layout"></i></span>
                                <span class="pcoded-mtext">{{menu.title.replace('CBT', users.cbt_text)}} </span>
                            </a>

                            <ul v-if="menu.parent" class="pcoded-submenu">
                                <li v-for="(subMenu, indexSubMenu) in menu.sub_menu" :key="indexSubMenu"><router-link :to="{name : subMenu.url}" >{{subMenu.title.replace('CBT', users.cbt_text)}}</router-link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <header id="header" class="navbar pcoded-header navbar-expand-lg navbar-light headerpos-fixed header-blue">
            <div class="m-header">
                <a class="mobile-menu" id="mobile-collapse" href="javascript:void(0)"><span></span></a>
                <a href="javascript:void(0)" class="b-brand">
                    <h1 class="logo me-auto logo-text"><router-link :to="{ name: 'home' }">GriyaBelajar</router-link></h1>
                </a>
                <a href="javascript:void(0)" class="mob-toggler">
                    <i class="feather icon-more-vertical"></i>
                </a>
            </div>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <li>
                        <div class="dropdown drp-user">
                            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                                Hai, {{users.fullname}} <img src="assets/images/avatar.png" class="m-l-10 img-radius wid-35"
                                    alt="User-Profile-Image">
                            </a>
                            <div class="dropdown-menu dropdown-menu-right profile-notification">
                                <div class="pro-head">
                                    <span>Akun Saya</span>
                                </div>
                                <ul class="pro-body">
                                    <li :class="$route.name == 'member_tryout_cbt_start' ? 'd-none':''"><router-link :to="{name : 'profile'}" class="dropdown-item"><i class="feather icon-user"></i> Profil</router-link></li>
                                    <li :class="$route.name == 'member_tryout_cbt_start' ? 'd-none':''" v-if="users.type == 'member'"><router-link :to="{name : 'users_cart'}" class="dropdown-item"><i class="feather icon-shopping-cart"></i> Keranjang <span>({{cartList.data.list_cart.length}} item)</span> </router-link></li>
                                    <li :class="$route.name == 'member_tryout_cbt_start' ? 'd-none':''" v-if="users.type == 'member'"><router-link :to="{name : 'invoice_list'}" class="dropdown-item"><i class="feather icon-file"></i> Invoice</router-link></li>
                                    <li><router-link :to="{name : 'signout'}" class="dropdown-item"><i class="feather icon-log-out"></i> Keluar</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>

        <div class="pcoded-main-container">
            <div class="pcoded-content">
                <slot/>
            </div>
        </div>


        <!-- Modal -->
        <button type="button" class="d-none" id="btnModal" data-toggle="modal" data-target="#modalAccount">Klik</button>
        <div class="modal" id="modalAccount" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <div class="page-header-title text-center">
                                        <h4 class="m-t-10">Hai, {{users.fullname}} !<br/>Selamat datang di aplikasi Griya Belajar</h4>
                                        <img style="width:100%" src="assets/images/intro.jpg" alt="" class="img-fluid">
                                        <p style="font-size:15px">Sebelum lanjut ke aplikasi<br/>
                                        Yuk lengkapi profil terlebih dahulu!</p>

                                        <router-link data-dismiss="modal" :to="{name : 'profile'}" class="btn btn-primary rounded btn-block"><i class="feather icon-edit"></i> Lengkapi Profil</router-link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Main',
        data() {
            return {
                activeClass     : this.$route.name,
                activeMenu      : "",
                activeSubMenu   : "",
                lastMenu        : "",
                dataMenu        : [],
                users           : [],
                cartList        : {
                    data : {
                        list_cart : []
                    }
                }
            }
        },
        created() {
            this.getMenu();
            this.getUsers();
            this.getUsersCart();
        },
        methods: {
            setCurrent: function(params = "") {
                this.activeClass    = params;
                this.activeMenu     = "";
            },
            setMenu: function(params = "") {
                this.activeClass    = "";
                this.activeMenu     = 'sub-menu-'+params;
            },
            setSubMenu: function(params = "", sub_params="") {
                this.activeMenu     = 'sub-menu-'+params;
                this.activeSubMenu  = sub_params;
            },
            getMenu: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/menu';
                
                this.$http.get(uri).then(response => {
                    this.dataMenu   = response.data.list_menu;
                    this.lastMenu   = response.data.last_menu;
                    let externalScript = document.createElement('script')
                    externalScript.setAttribute('src', 'assets/js/pcoded.min.js')
                    document.head.appendChild(externalScript)
                });
            },
            getUsers: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/info';
                
                this.$http.get(uri).then(res => {
                    this.users = res.data;

                    if(this.users.complete_profile == 0 || this.users.complete_profile == '') {
                        if(this.$route.name != 'profile') {
                            if(this.users.type == 'member' || this.users.type == 'proctor') {
                                document.getElementById('btnModal').click();
                            }
                        }
                    }

                    if(this.users.cbt_active == 1 && this.users.type == 'member') {
                        this.$router.push({ name: 'member_tryout_cbt' });
                    }
                });
            },
            getUsersCart: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/cart';
                
                this.$http.get(uri).then(res => {
                    this.cartList = res.data;
                });
            }
        }
    }
</script>
