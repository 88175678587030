<template>
    <div>
        <div v-if="dataFetchSchool" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchSchool" class="page-header">
            <div v-if="(!this.dataTryout.show_result && !dataFetch)" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4>Rangking peserta masih ditutup untuk saat ini</h4>
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h4 class="m-t-10">
                                Rangking peserta dapat dilihat pada tanggal
                            </h4>
                            <h4 class="m-t-10">
                                {{dataTryout.result_date}}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="(this.dataTryout.show_result && !dataFetch)" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h4 class="m-t-10">
                                <a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Rangking - {{dataDetail.title}}
                            </h4>
                            <p>Berikut adalah rangking hasil <b>{{dataDetail.title}}</b> yang telah dikerjakan oleh pengguna dari sekolah <b>{{schoolInformation.name}}</b>.</p>
                            <hr/>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Waktu</div>
                                        <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Jumlah Soal</div>
                                        <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Total Peserta yang mengerjakan</div>
                                        <div class="font-weight-600">{{pagination.totalData}} Orang</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3 m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                    <button v-on:click="exportExcel" class="m-l-10 btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                            class="fas fa-file-export"></i> Export Ke Excel </button>
                                </div>

                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>
                                <div v-if="!dataFetch">
                                    <b>Daftar Ranking Peserta : </b>
                                    <div v-if="dataList.length > 0" class="table-responsive m-t-10">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="font-size:11px !important" class="text-center">No</th>
                                                    <th style="font-size:11px !important">Nama</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TWK</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TIU</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TKP</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>Akhir</th>
                                                    <th style="font-size:11px !important" class="text-center">Waktu <br/>Mengerjakan</th>
                                                    <th style="font-size:11px !important" class="text-center">Status</th>
                                                    <th style="font-size:11px !important">Asal</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.fullname}}</td>
                                                    <td class="text-center">{{item.nilai_twk}}</td>
                                                    <td class="text-center">{{item.nilai_tiu}}</td>
                                                    <td class="text-center">{{item.nilai_tkp}}</td>
                                                    <td class="text-center">{{item.skor_akhir}}</td>
                                                    <td class="text-center">{{item.waktu}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'tidak'" class="badge badge-danger">Tidak Lulus</span>
                                                        <span v-if="item.status == 'lulus'" class="badge badge-success">Lulus</span>
                                                    </td>
                                                    <td>
                                                        {{item.city_name}}, {{item.province_name}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                    
                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'RankTryout',
        metaInfo: {
            title: 'Ranking Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        created() {
            this.getSchool();
        },
        data() {
            return {
                dataDetail      : [],
                keywords        : '',
                dataFetch       : true,
                dataFetchTryout : false,
                dataList        : [],
                dataTryout      : {
                    show_result : 1
                },
                emptyState      : false,
                dataFetchSchool : true,
                schoolInformation : [],
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    totalData  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        methods: {
            getSchool: function () {
                this.dataFetchSchool = true;
                let uri = this.$apiconfig + 'user/school';
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;

                    if(getResponse.status) {
                        this.getTryout();                        
                        this.schoolInformation = getResponse.data;
                    } else {
                        this.$router.push({
                            name: 'member_tryout_kedinasan_school'
                        });
                    }
                });
            },
            getTryout: function() {
                let uri               = process.env.VUE_APP_APIHOST+'member/skd/tryout/detail';
                this.dataFetchSchool  = true;                
                this.$http.get(uri,{
                    params : {
                        type_tryout : 'kedinasan',
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data[0];

                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'member_tryout_kedinasan_school'
                                    });
                                }
                            });
                        } else {
                            this.dataFetchSchool  = false;         
                            this.getData();
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/rank-school';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type_tryout : 'kedinasan',
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit,
                        id          : this.dataDetail.id
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.dataTryout             = res.data.tryout;
                    this.pagination.totalPages  = res.data.total_page;
                    this.pagination.totalData   = res.data.total_data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            exportExcel: function() {
                let uri         = process.env.VUE_APP_APIHOST+'export/rank-school/skd';
                
                let type_tryout = encodeURIComponent('kedinasan');
                let keywords    = encodeURIComponent(this.keywords);
                let school_id   = encodeURIComponent(this.schoolInformation.id);
                let id          = encodeURIComponent(this.dataDetail.id);

                window.open(uri+"?keywords="+keywords+"&school_id="+school_id+"&id="+id+"&type_tryout="+type_tryout);
            }
        }
    }
</script>