
<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <p>Sedang memuat data soal...</p>
        </div>
        <div v-if="!dataFetch">
            <div class="page-header" v-if="show_alert">
                <GriyaExam></GriyaExam>
            </div>
            <div v-if="!show_alert"> 
                <div class="alert alert-primary alert-dismissible fade show" role="alert">
                    Saat ini kamu sedang mengerjakan <strong>{{dataList.tryout.title}}</strong>.
                    <button style="margin-top:-3px" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="page-header nocopy">
                            <div class="page-block">
                                <div class="page-header-title">                                
                                    <b style="font-size:18px" class="m-t-10">Soal Nomor #{{number_questions}} </b>
                                    <hr/>
                                    <div class="m-b-20" v-html="form.questions"></div>
                                    
                                    <!-- audio element -->
                                    <div v-if="form.attachment">
                                        <vue-plyr>
                                            <audio controls crossorigin playsinline>
                                                <source :src="form.attachment" type="audio/mp3" />
                                                <source :src="form.attachment" type="audio/ogg" />
                                            </audio>
                                        </vue-plyr>
                                        <hr />
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_complex'">
                                        <div class="table-responsive">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">{{form.description_column_one}}</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">{{form.description_column_two}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            <input type="radio" :name="'answerComplex_'+index" v-model="answerComplex[index]" value="1" style="height:20px; width:20px; vertical-align: middle;">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; ">
                                                            <input type="radio" :name="'answerComplex_'+index" v-model="answerComplex[index]" value="0" style="height:20px; width:20px; vertical-align: middle;">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-if="form.type_questions == 'match_making'">
                                        <div class="table-responsive">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">Pilihan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            <select class="form-control" v-model="answerComplex[index]">
                                                                <option value="">Pilih Jawaban</option>
                                                                <option v-for="(itemDetail, indexDetail) in answerOptions" :key="indexDetail">{{itemDetail}}</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_answer' && form.options_a" class="form-check">
                                        <input class="form-check-input input-primary" type="checkbox" id="options_a" name="options_a" true-value="a" false-value="" v-model="multipleAnswer" value="a">
                                        <label class="form-check-label" for="customCheckc1"><div v-html="form.options_a"></div>
                                        </label>
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_answer' && form.options_b" class="form-check">
                                        <input class="form-check-input input-primary" type="checkbox" id="options_b" name="options_b" true-value="b" false-value="" v-model="multipleAnswer" value="b">
                                        <label class="form-check-label" for="customCheckc1">
                                            <div v-html="form.options_b"></div>
                                        </label>
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_answer' && form.options_c" class="form-check">
                                        <input class="form-check-input input-primary" type="checkbox" id="options_c" name="options_c" true-value="c" false-value="" v-model="multipleAnswer" value="c">
                                        <label class="form-check-label" for="customCheckc1">
                                            <div v-html="form.options_c"></div>
                                        </label>
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_answer' && form.options_d" class="form-check">
                                        <input class="form-check-input input-primary" type="checkbox" id="options_d" name="options_d" true-value="d" false-value="" v-model="multipleAnswer" value="d">
                                        <label class="form-check-label" for="customCheckc1">
                                            <div v-html="form.options_d"></div>
                                        </label>
                                    </div>

                                    <div v-if="form.type_questions == 'multiple_answer' && form.options_e" class="form-check">
                                        <input class="form-check-input input-primary" type="checkbox" id="options_e" name="options_e" true-value="e" false-value="" v-model="multipleAnswer" value="e">
                                        <label class="form-check-label" for="customCheckc1">
                                            <div v-html="form.options_e"></div>
                                        </label>
                                    </div>

                                    <div v-if="(form.type_questions == 'multiple_choice' && form.options_a)" class="form-check" >
                                        <input class="form-check-input" type="radio" name="options_radio" id="options_a" value="a" :checked="currentSaveAnswer[currentQuestions]  == 'a'" v-model="currentSaveAnswer[currentQuestions]" v-on:click="saveAnswer('a')">
                                        <label class="form-check-label" for="options_a">
                                            <div v-html="form.options_a"></div>
                                        </label>
                                    </div>

                                    <div v-if="(form.type_questions == 'multiple_choice' && form.options_b)" class="form-check" >
                                        <input class="form-check-input" type="radio" name="options_radio" id="options_b" value="b" :checked="currentSaveAnswer[currentQuestions]  ==  'b'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('b')">
                                        <label class="form-check-label" for="options_b">
                                            <div v-html="form.options_b"></div>
                                        </label>
                                    </div>

                                    <div v-if="(form.type_questions == 'multiple_choice' && form.options_c)" class="form-check" >
                                        <input class="form-check-input" type="radio" name="options_radio" id="options_c" value="c" :checked="currentSaveAnswer[currentQuestions]  ==  'c'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('c')">
                                        <label class="form-check-label" for="options_c">
                                            <div v-html="form.options_c"></div>
                                        </label>
                                    </div>

                                    <div v-if="(form.type_questions == 'multiple_choice' && form.options_d)" class="form-check" >
                                        <input class="form-check-input" type="radio" name="options_radio" id="options_d" value="d" :checked="currentSaveAnswer[currentQuestions]  ==  'd'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('d')">
                                        <label class="form-check-label" for="options_d">
                                            <div v-html="form.options_d"></div>
                                        </label>
                                    </div>

                                    <div v-if="(form.type_questions == 'multiple_choice' && form.options_e)" class="form-check" >
                                        <input class="form-check-input" type="radio" name="options_radio" id="options_e" value="e" :checked="currentSaveAnswer[currentQuestions]  ==  'e'" v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('e')">
                                        <label class="form-check-label" for="options_e">
                                            <div v-html="form.options_e"></div>
                                        </label>
                                    </div>

                                    <div v-if="form.type_questions == 'match'" class="mt-3">
                                        <input type="text" class="form-control" v-model="form.your_answer" placeholder="Masukan jawaban kamu disini" v-on:blur="saveAnswer(form.your_answer)">
                                    </div>

                                    <div v-if="form.type_questions == 'essay'" class="mt-3">
                                        <textarea class="form-control" v-model="form.your_answer" rows="3" placeholder="Masukan jawaban kamu disini" v-on:blur="saveAnswer(form.your_answer)"></textarea>
                                    </div>

                                    <div v-if="form.type_questions == 'essay'" class="mt-3 d-none">
                                        <label><small>Masukan File pendukung (jika ada): </small></label>
                                        <input type="file" class="form-control">
                                    </div>

                                    <hr/>

                                    <div class="row" v-if="(form.type_questions != 'multiple_answer' && form.type_questions != 'multiple_complex' && form.type_questions != 'match_making')">
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <button class="text-left btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                            <button v-if="(form.type_questions == 'match' || form.type_questions == 'essay')" class="text-right btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="saveAnswer(form.your_answer)" :disabled="form.your_answer ==  ''">Simpan Jawaban <i class="fa fa-save"></i></button>
                                            <button class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Soal Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                        </div>
                                    </div>

                                    <div class="row" v-if="form.type_questions == 'multiple_answer'">
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <button class="text-left btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                            <button class="text-right btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="saveMultipleAnswer" :disabled="multipleAnswer.length < 1">Simpan Jawaban <i class="fa fa-save"></i></button>
                                            <button class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Soal Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                        </div>
                                    </div>

                                    <div class="row" v-if="form.type_questions == 'multiple_complex' || form.type_questions == 'match_making'">
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <button class="text-left btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                            <button class="text-right btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="saveComplexAnswer" :disabled="answerComplex.length < 1">Simpan Jawaban <i class="fa fa-save"></i></button>
                                            <button class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Soal Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="page-header">
                            <div class="page-block">
                                <div class="page-header-title">
                                    <b style="font-size:15px" class="m-t-10">Sisa Waktu</b>
                                    <hr/>
                                    <vue-countdown-timer
                                        @start_callback="startCallBack"
                                        @end_callback="endCallBack"
                                        :start-time="startTime"
                                        :end-time="endTime"
                                        :interval="1000">

                                        <div class="text-center" slot="countdown" slot-scope="scope">
                                            <span style="font-size:25px">{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>
                                        </div>
                                    </vue-countdown-timer>                        

                                </div>
                            </div>
                        </div>

                        <div class="page-header">
                            <div class="page-block">
                                <div class="page-header-title">
                                    <b style="font-size:15px" class="m-t-10">Nomor Soal</b>
                                    <hr/>
                                    <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                        <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataList.tryout.list_questions" :key="index"
                                            type="button"
                                            class="text-center btn rounded m-l-10 m-t-10" v-on:click="setQuestions(item.id, item.number)"
                                            :class="currentSaveQuestions[item.id] == true ? 'btn-success': item.status == 'true' ? 'btn-success':item.id == currentQuestions ? 'btn-primary':'btn-outline-primary' "
                                            >{{item.number}} 
                                        </button>                                    
                                    </div>
                                    <button class="m-t-10 btn btn-primary rounded btn-block" type="button" v-on:click="finishQuiz">Selesai</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="mt-5 btn btn-primary rounded d-none" v-on:click="blockUsers" id="block_users">Testing Page Button</button>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #04bd04 !important;
        border-color: #000 !important;
    }
</style>

<script>
    import GriyaExam from '../../../static/GriyaExam.vue';
    export default {
        name: 'StartQuiz',
        metaInfo: {
            title: 'Mengerjakan Soal',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            GriyaExam
        },
        data() {
            return {
                tabFocus    : false,
                dataFetch   : true,
                dataList    : [], 
                hours       : 0,
                minutes     : 0,
                seconds     : 0,
                startTime   : '',
                endTime     : '',
                currentQuestions : '',
                number_questions : 1,
                form : {
                    tryout_answer        : '',
                    questions            : '',
                    type_questions       : '',
                    options_a            : '',
                    options_b            : '',
                    options_c            : '',
                    options_d            : '',
                    options_e            : '',
                    your_answer          : '',
                    tryout_question_id   : '',
                    description_column_one : '',
                    description_column_two : '',
                    attachment : ''
                },
                currentSaveQuestions : [],
                currentSaveAnswer    : [],
                multipleAnswer       : [],
                optionsComplex       : [],
                answerComplex        : [],
                answerOptions        : [],
                changeAnswer         : [],
                countDown            : 0,
                timer                : [],
                data_tryout          : [],                
                show_alert      : false,
                allow_exam      : 1,
                is_android      : false,
                check_web_view  : ''
            }
        },
        created() {
            this.startQuiz();
            this.timer[this.number_questions] = 0;
            this.countDownTimer();
        },
        mounted() {
            window.addEventListener('blur', this.onWindowBlur);
            window.addEventListener('focus', this.onWindowFocus);
            this.detectFocusOut();
        },
        beforeDestroy() {
            window.removeEventListener('blur', this.onWindowBlur);
            window.removeEventListener('focus', this.onWindowFocus);
            this.detectFocusOut();
        },
        watch:{
            tabFocus(value) {
                if(value === true) {
                    this.$swal({
                        icon: 'error',
                        title: 'Ups!',
                        html: 'Kamu terdeteksi membuka halaman lain saat mengerjakan tryout di aplikasi griya belajar<br/>Silahkan login kembali',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.$http.post(process.env.VUE_APP_APIHOST + 'user/block_users', {
                                tryout_id : this.data_tryout.id,
                                type : 'cbt'
                            })
                            .then(response => {
                                if (response.data.status) {
                                    this.$router.push({
                                        name         : 'signout'
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        html: response.data.message,
                                    });
                                }
                            })
                        }
                    });

                    console.log("Kamu terdeteksi melakukan pindah halaman saat mengerjakan");
                } else {
                    console.log("Kamu kembali ke halaman kuis");
                } 
            },
        },
        methods: {
            blockUsers: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'user/block_users', {
                    tryout_id : this.data_tryout.id,
                    type : 'cbt'
                })
                .then(response => {
                    if (response.data.status) {
                        alert("Kamu telah keluar dari aplikasi")
                        this.$router.push({
                            name         : 'signout'
                        });
                    } else {
                        alert(response.data.message)
                    }
                })
            },
            onWindowBlur() {
                this.tabFocus = false;
            },
            onWindowFocus() {
                this.tabFocus = true;
            },
            detectFocusOut() {
                const onWindowFocusChange = () => {
                    this.tabFocus = !document.hidden;
                };

                document.addEventListener('visibilitychange', onWindowFocusChange);
            },
            countDownTimer() {
                setTimeout(() => {    
                    let waktu_mengerjakan = 0;

                    if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                        waktu_mengerjakan += this.timer[this.number_questions];
                    } else {
                        this.timer[this.number_questions] += waktu_mengerjakan;
                    }

                    this.countDown += 1
                    this.timer[this.number_questions] = this.countDown
                    this.countDownTimer()
                }, 1000)
            },            
            startQuiz: function() {
                let formData = new FormData();

                formData.append('code', this.$route.params.slug);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/cbt/tryout/start', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        let getResponse = response.data;

                        this.data_tryout = getResponse.data.tryout;

                        if(this.data_tryout.safe_exam == '1') {                        
                            this.dataFetch  = false;
                            this.checkDevice();
                        }

                        this.startTime  = getResponse.data.tryout.currentTime;
                        this.endTime    = getResponse.data.tryout.expireDate;

                        if(this.allow_exam == 1) {                            
                            this.dataList   = getResponse.data;
                            this.tryout_answer = this.dataList.tryout.tryout_answer;

                            this.setQuestions(this.dataList.tryout.list_questions[0].id, 1);
                        } else {
                            this.show_alert = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            html: response.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_tryout_cbt_overview',
                                    params : {
                                        slug        : this.$route.params.slug
                                    }
                                });
                            }
                        });                        
                    }

                    this.dataFetch = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            startCallBack: function () {
                console.log('waktu dimulai');
            },
            endCallBack: function () {
                this.stop();
            },
            setQuestions: function(id, number) {
                this.multipleAnswer      = [];
                this.answerComplex       = [];
                
                var item                 = this.dataList.tryout.list_questions.find(item => item.id === id);
                this.currentQuestions    = id;
                this.number_questions    = number;

                this.form.tryout_question_id    = item.id;
                this.form.type_questions        = item.type_questions;
                
                this.form.questions             = item.question;
                this.answerOptions              = [];

                if(this.form.type_questions == 'multiple_complex' || this.form.type_questions == 'match_making') {
                    if(this.form.type_questions == 'match_making') {
                        this.answerOptions = item.answer_options;
                    }

                    this.optionsComplex = item.options;
                } else {
                    this.form.options_a             = item.options.a;
                    this.form.options_b             = item.options.b;
                    this.form.options_c             = item.options.c;
                    this.form.options_d             = item.options.d;
                    this.form.options_e             = item.options.e;                
                }

                if(this.changeAnswer[item.id]) {
                    this.changeAnswer[item.id]  = this.currentSaveAnswer[item.id];
                    this.form.your_answer       = this.currentSaveAnswer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.currentSaveQuestions[item.id]  = true;
                    this.currentSaveAnswer[item.id]     = this.form.your_answer;
                    
                    if(item.type_questions == 'multiple_answer') {
                        if (Array.isArray(this.currentSaveAnswer[item.id])) {
                            this.multipleAnswer = this.currentSaveAnswer[item.id];
                        } else {
                            if(this.currentSaveAnswer[item.id]) {
                                this.multipleAnswer = [this.currentSaveAnswer[item.id]];
                            } else {
                                this.multipleAnswer = [];
                            }
                        }
                    }

                    if(item.type_questions == 'multiple_complex') {
                        if (Array.isArray(this.currentSaveAnswer[item.id]) && /^\d+$/.test(this.currentSaveAnswer[item.id].join(''))) {
                            this.answerComplex = this.currentSaveAnswer[item.id];
                        } else {
                            this.answerComplex = [];
                        }
                    }

                    if(item.type_questions == 'match_making') {
                        this.answerComplex = this.currentSaveAnswer[item.id];
                    }
                } else {
                    this.optionsComplex.forEach((element, index) => {
                        this.answerComplex[index] = "";
                    });
                }

                
                this.timer[this.number_questions] = item.timer;
                
                if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                    this.countDown = this.timer[this.number_questions];                
                } else {
                    this.timer[this.number_questions] = 0;
                    this.countDown = 0;
                }

                this.form.description_column_one    = item.description_column_one;
                this.form.description_column_two    = item.description_column_two;

                this.form.attachment = '';
                setTimeout(() => {
                    this.form.attachment = item.attachment;
                }, 100);

                this.scrollToTop();
            },
            stop: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/cbt/tryout/stop', {
                    tryout_answer_id: this.tryout_answer
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            html: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name : 'member_tryout_cbt_result',
                                });
                            }
                        });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        html: response.data.message,
                    });
                    }
                })
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataList.tryout.list_questions.length;

                if(next_number > total_questions) {
                    console.log("Kamu sudah berada di soal terakhir");
                } else {
                    let id                  = this.dataList.tryout.list_questions[number].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    console.log("Kamu sudah berada di soal pertama");
                } else {
                    let id                  = this.dataList.tryout.list_questions[next_number-1].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveAnswer : function(your_answer="") {
                this.form.your_answer = your_answer;

                let formData = new FormData();
                if(this.form.type_questions == 'multiple_answer' || this.form.type_questions == 'multiple_complex' || this.form.type_questions == 'match_making') {
                    formData.append('answer', JSON.stringify(this.form.your_answer));
                } else {
                    formData.append('answer', this.form.your_answer);
                }
                
                formData.append('type_questions', this.form.type_questions);
                formData.append('tryout_question_id', this.form.tryout_question_id);
                formData.append('tryout_answer_id', this.tryout_answer);
                formData.append('timer', this.timer[this.number_questions]);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/cbt/tryout/answer/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.currentSaveQuestions[this.form.tryout_question_id] = true;
                        this.currentSaveAnswer[this.form.tryout_question_id]    = your_answer;
                        this.changeAnswer[this.form.tryout_question_id]         = true;

                        if(this.form.type_questions == 'multiple_answer') {
                            this.multipleAnswer = your_answer
                        }

                        if(this.form.type_questions == 'multiple_complex' || this.form.type_questions == 'match_making') {
                            this.answerComplex = your_answer
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            saveMultipleAnswer: function() {
                this.currentSaveAnswer[this.currentQuestions]    = this.multipleAnswer;
                this.saveAnswer(this.currentSaveAnswer[this.currentQuestions])
            },
            finishQuiz: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Selesai mengerjakan ?',
                    html: 'Jawaban yang telah di submit tidak dapat diubah.<br/>Harap periksa kembali jawaban yang telah di simpan',
                    confirmButtonText: 'Selesai',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        this.stop();
                    }
                });
            },
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            saveComplexAnswer: function() {
                this.currentSaveAnswer[this.currentQuestions]    = this.answerComplex;
                this.saveAnswer(this.currentSaveAnswer[this.currentQuestions])
            },
            checkDevice: function() {
                var isAndroid = /Android/.test(navigator.userAgent);
                if (isAndroid) {
                    this.is_android = true;
                    this.checkWebView();

                    if (this.check_web_view == 'iOS webview' || this.check_web_view == 'Android webview') {
                        this.allow_exam = 1;
                    } else {
                        this.allow_exam = 0;
                    }
                }                
            },
            checkWebView: function() {
                var standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

                if (ios) {
                    if (!standalone && safari) {
                        this.check_web_view = 'Safari Browser'
                    } else if (!standalone && !safari) {
                        this.check_web_view = 'iOS webview';
                    }
                } else {
                    if (userAgent.includes('wv')) {
                        this.check_web_view = 'Android webview'
                    } else {
                        this.check_web_view = 'Chrome Browser'
                    }
                }
            }
        }
    }
</script>