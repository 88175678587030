import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userdata: {
        id          : '',
        fullname    : '',
        email       : '',
        logged_in   : false,
        type        : "",
        complete_profile  : "",
        referral        : "",
        fetchUsers  : true,
    }
  },
  mutations: {
    set_userdata(state, userdata) {
      state.userdata = userdata;
    }
  },
  actions: {
    loadUserData({commit}) {
      axios.get(process.env.VUE_APP_APIHOST + 'user/info').then(response => {
          commit('set_userdata', response.data);
      });
    }
  }}
)
