<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Voucher Sekolah (Perkelas)</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'voucher_school_sessions_add'}"><i class="fa fa-plus"></i> Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="voucher_school_sessions_id" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Paket</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="type" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option value="utbk">SNBT</option>
                                        <option value="kedinasan">Kedinasan</option>
                                        <option value="psikotest">Psikotest</option>
                                        <option value="akm">Sekolah (AKM)</option>
                                        <option value="cbt">CBT</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div class="table-responsive">
                                        <div v-if="dataList.length > 0" >
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th>Kode Voucher</th>
                                                        <th>Sekolah</th>
                                                        <th>Tryout</th>
                                                        <th>Berlaku Hingga</th>
                                                        <th class="text-center">Jumlah Voucher</th>
                                                        <th class="text-center">Status Voucher</th>
                                                        <th style="width:5%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>{{item.code}}</td>
                                                        <td>{{item.school}}</td>
                                                        <td>{{item.type}}
                                                            <span v-if="item.type != 'CBT'">
                                                                <br/>{{item.tryout}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <small>{{item.expired_at}}</small><br/>
                                                            <small>{{item.expired_end}}</small><br/>
                                                        </td>
                                                        <td class="text-center">
                                                            <a v-if="item.total_voucher > 0" href="javascript:void(0)" v-on:click="detailVoucher(item.id)">{{item.total_voucher}}</a>
                                                            <span v-if="item.total_voucher < 1">0</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span v-if="item.active == 1" class="badge badge-success">Aktif</span>
                                                            <span v-if="item.active == 0" class="badge badge-danger">Tidak Aktif</span>
                                                            <br/>
                                                            <a v-if="item.active == 0" href="javascript:void(0)" class="small" v-on:click="activated(item.id, 1)">(Aktifkan Voucher)</a>
                                                            <a v-if="item.active == 1" href="javascript:void(0)" class="small" v-on:click="activated(item.id, 0)">(Non Aktifkan Voucher)</a>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name : 'voucher_school_sessions_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                            <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                            <button v-if="item.total_voucher > 0" v-on:click="detailVoucher(item.id)" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-users"></i> Detail Voucher</button>
                                                            <!-- <button v-if="item.total_voucher > 0" v-on:click="logVoucher(item.id)" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-history"></i> Riwayat Aktif/Non Aktif</button> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <nav v-if="!emptyState" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Data Voucher Sekolah (Perkelas)',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                dataSchool  : [],
                keywords    : "",
                voucher_school_sessions_id   : "",
                type        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        voucher_school_sessions_id   : this.voucher_school_sessions_id,
                        type        : this.type,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            detailVoucher: function(id) {
                this.$router.push({
                    name: 'voucher_school_sessions_detail',
                    params : {
                        id : id
                    }
                });
            },
            logVoucher: function(id) {
                this.$router.push({
                    name: 'voucher_school_sessions_log',
                    params : {
                        id : id
                    }
                });
            },
            activated: function(id='', params='') {
                this.$swal({
                    icon: 'info',
                    title: 'Non Aktifkan Voucher',
                    html: 'Apakah kamu yakin ingin menonaktifkan kode voucher ini?',
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/update-activate', {
                        id: id,
                        active: params
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>
