<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h5 class="m-t-10">
                                Waktu istirahat Tersisa

                                <vue-countdown-timer
                                    @start_callback="startCallBack"
                                    @end_callback="endCallBack"
                                    :start-time="startTime"
                                    :end-time="endTime"
                                    :interval="1000">

                                    <div class="text-center m-t-10" slot="countdown" slot-scope="scope">
                                        <span>{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>  
                                    </div>
                                </vue-countdown-timer>
                            </h5>
                            <button class="btn btn-outline-primary rounded" v-on:click="skip" type="button">Lewati Waktu Istirahat <i class="m-l-10 fa fa-arrow-right"></i> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BreakQuiz',
        metaInfo: {
            title: 'Istirahat Mengerjakan Soal',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : true,
                startTime   : 1,
                endTime     : 5,
                id_start    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                this.dataFetch  = true;
                let uri         = process.env.VUE_APP_APIHOST+'member/utbk/tryout/check-break';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug        : this.$route.params.slug,
                    }
                }).then(res => {
                    const response = res.data;
                    if(response.status) {
                        this.dataFetch  = false;
                        this.startTime  = response.data.currentTime;
                        this.endTime    = response.data.expireDate;
                        this.id_start   = response.data.id_start;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_tryout_utbk_overview',
                                    params : {
                                        slug : this.$route.params.slug
                                    }
                                });
                            }
                        });                        
                    }
                });
            },
            skip: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Lewati Waktu Istirahat',
                    text: 'Apakah kamu yakin ingin melewati waktu istirahat ?',
                    confirmButtonText: 'Ya Lewati',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.stopBreak();
                    }
                });
            },
            startCallBack: function () {
                console.log("waktu di mulai");
            },
            endCallBack: function () {
                console.log("waktu di selesai");
                this.stopBreak();
            },
            stopBreak: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/utbk/tryout/stop-break', {
                    id: this.id_start
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Waktu istirahat selesai!',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_tryout_utbk_overview',
                                    params : {
                                        slug : this.$route.params.slug
                                    }
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            }
        }        
    }
</script>