<template>
    <div class="auth-wrapper">
        <div class="auth-content">
            <div class="card">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center">
                        <div class="card-body">
                            <img src="assets/images/logo.png" alt="" class="img-fluid mb-4">
                            <h3 class="mb-3 f-w-400">Konfirmasi<br/>Password Baru</h3>
                            <hr />
                            <form v-on:submit.prevent="onSubmit">

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                    </div>
                                    <input type="password" class="form-control" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Masukan password baru">
                                    <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div>    
                                </div>

                                <button type="submit" class="btn btn-block btn-primary mb-4" :disabled="waiting">
                                    {{ waiting ? 'Harap tunggu ...' : 'Ganti Password' }}</button>
                            </form>

                            <p class="mb-2 text-muted">Belum Punya Akun? <router-link :to="{name: 'signup'}"
                                    class="f-w-400">Daftar Sekarang!</router-link>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'ConfirmPassword',
        metaInfo: {
            title: 'Konfirmasi Password Baru',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                password    : "",
                token       :this.$route.params.token,
                submitted   : false,
                waiting     : false,
            }
        },
        validations: {
            password: {
                required
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST+'auth/changepassnew', {
                        password    : this.password,
                        token       : this.token
                    })
                    .then(response => {
                        this.waiting = false;

                        if(response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'signin' });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }
        }
    }
</script>