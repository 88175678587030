<template>
    <div>
        <div v-if="userSubscriberFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!userSubscriberFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h4 class="m-t-10">
                                Rangking - {{dataDetail.title}}
                            </h4>
                            <p>Berikut adalah rangking hasil <b>{{dataDetail.title}}</b> yang telah dikerjakan oleh pengguna griyabelajar.</p>
                            <hr/>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Waktu</div>
                                        <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Jumlah Soal</div>
                                        <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Passing Grade Saat ini</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">
                                            <ul>
                                                <li v-for="(item, index) in dataDetail.passing_grade" :key="index">{{item.name}} <i class="ml-2 fa fa-arrow-right mr-2"></i> <span style="color:green">{{item.passing_grade}}</span> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="exampleInputEmail1">Periode Waktu</label>
                                <select class="form-control" v-model="form.periode">
                                    <option value="3">3 Hari Terakhir</option>
                                    <option value="7">7 Hari Terakhir</option>
                                    <option value="14">14 Hari Terakhir</option>
                                    <option value="30">30 Hari Terakhir</option>
                                </select>
                            </div>

                            <div class="form-group col-md-4">
                                <label for="exampleInputEmail1">Asal Provinsi</label>
                                <v-select
                                    placeholder="Semua Provinsi"
                                    label="name"
                                    :options="listProvince"
                                    v-model="form.province"
                                    @input="getCity"
                                    >                                    
                                </v-select>
                            </div>

                            <div class="form-group col-md-4">
                                <label class="exampleInputEmail1">Asal Kota</label>
                                <v-select
                                    placeholder="Semua Kota"
                                    label="name"
                                    :options="listCity"
                                    v-model="form.city"
                                    >                                    
                                </v-select>
                            </div>

                            <div class="col-md-12">
                                <div class="input-group mb-3 m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>

                                <hr/>
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <b>Periode Ranking : </b>
                                    <p class="pull-right">{{periode}}</p>
                                    <div v-if="dataList.length > 0" class="table-responsive m-t-10">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="font-size:11px !important" class="text-center">No</th>
                                                    <th style="font-size:11px !important">Nama</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TWK</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TIU</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>TKP</th>
                                                    <th style="font-size:11px !important" class="text-center">nilai<br/>Akhir</th>
                                                    <th style="font-size:11px !important" class="text-center">Waktu <br/>Mengerjakan</th>
                                                    <th style="font-size:11px !important" class="text-center">Status</th>
                                                    <th style="font-size:11px !important">Asal</th>
                                                    <th v-if="this.$route.params.type_tryout == 'kedinasan'" style="font-size:11px !important">Program Studi Dipilih</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.fullname}}</td>
                                                    <td class="text-center">{{item.nilai_twk}}</td>
                                                    <td class="text-center">{{item.nilai_tiu}}</td>
                                                    <td class="text-center">{{item.nilai_tkp}}</td>
                                                    <td class="text-center">{{item.skor_akhir}}</td>
                                                    <td class="text-center">{{item.waktu}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'tidak'" class="badge badge-danger">Tidak Lulus</span>
                                                        <span v-if="item.status == 'lulus'" class="badge badge-success">Lulus</span>
                                                    </td>
                                                    <td>
                                                        {{item.city_name}}, {{item.province_name}}
                                                    </td>
                                                    <td v-if="item.type_tryout == 'kedinasan'">
                                                        <small>
                                                        {{item.collage}}<br/>
                                                        {{item.prody}}
                                                        </small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                    
                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'RankTryout',
        metaInfo: {
            title: 'Ranking Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            'v-select' : vSelect,
            vPagination
        },
        created() {
            this.getSubscriber();
        },
        data() {
            return {
                dataDetail      : [],
                form : {
                    periode     : '3',
                    province    : {
                        id  : '',
                        name : 'Semua Provinsi'
                    },
                    city        : {
                        id  : '',
                        name : 'Semua Kota'
                    },
                },
                listProvince    : [],
                listCity        : [],
                keywords        : '',
                dataFetch       : false,
                dataFetchTryout : false,
                dataList        : [],
                emptyState      : false,
                userSubscriberFetch : false,
                periode     : '',
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        methods: {
            getSubscriber: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/subscriber';

                this.userSubscriberFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : this.$route.params.type_tryout
                    }
                }).then(res => {
                    this.dataSubscriber         = res.data;
                    this.userSubscriberFetch    = false;
                    this.getProvince();
                    this.getTryout();
                });
            },
            getProvince: function () {
                let uri = 'https://passchendaele.oeltimacreation.com/region/province';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listProvince = getResponse.data;
                    this.getCity();
                });
            },
            getCity: function() {
                this.listCity = [];
                
                if(this.form.province) {
                    if(this.form.province.id) {
                        let uri = 'https://passchendaele.oeltimacreation.com/region/province_detail';
                        this.$http.get(uri, {
                            params: {
                                type: 'city',
                                id_province : this.form.province.id
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listCity = getResponse.data;
                        });
                    }
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/rank';

                let province_id = "";
                let city_id     = "";

                if (typeof (this.form.province) !== 'undefined' && this.form.province !== null) {
                    province_id = this.form.province.id;
                }

                if (typeof (this.form.city) !== 'undefined' && this.form.city !== null) {
                    city_id = this.form.city.id;
                }

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit,
                        periode     : this.form.periode,
                        province    : province_id,
                        city        : city_id,
                        type_tryout : this.$route.params.type_tryout,
                        id          : this.dataDetail.id
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.periode                = res.data.periode;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            getTryout: function() {
                let uri               = process.env.VUE_APP_APIHOST+'member/skd/tryout/detail';
                this.dataFetchTryout  = true;                
                this.$http.get(uri,{
                    params : {
                        type_tryout : this.$route.params.type_tryout,
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data[0];

                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            if(this.dataDetail.token_quiz) {
                                this.$router.push({
                                    name: 'member_tryout_skd_start',
                                    params: {
                                        type_tryout : this.$route.params.type_tryout,
                                        slug        : this.$route.params.slug,
                                        token       : this.dataDetail.token_quiz
                                    }
                                });
                            } else {
                                this.dataFetchTryout  = false;         
                                this.getData();
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
        }
    }
</script>