<template>
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="m-t-10">Nilai & Statistik Hasil Tryout</h5>
                </div>
            </div>
        </div>

        <div class="row" v-for="(item, indexOne) in this.$parent.$parent.dataDetail.list_component" :key="indexOne">
            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc">
                            <b><small class="font-weight-600">Nilai <b>{{item.name}}</b></small></b>
                        </h4>
                        <hr />
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <div class="mr-3 font-weight-600" style="width:60%">Skor Kamu</div>
                                <div v-if="item.status == 'Lulus'" class="font-weight-600 text-right" style="width:10%;color:green" >
                                    {{item.score}}
                                </div>

                                <div v-if="item.status != 'Lulus'" class="font-weight-600 text-right" style="width:10%;color:red" >
                                    {{item.score}}
                                </div>
                            </div>
                        </div>
                        <div class="d-block mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <div class="mr-3 font-weight-600" style="width:60%">Prediksi Passing Grade</div>
                                <div class="font-weight-600 text-right" style="width:10%">
                                    {{item.passing_grade}}
                                </div>
                            </div>
                        </div>
                        <div class="d-block mt-3">
                            <div v-if="item.status == 'Lulus'" class="alert alert-success text-center">
                                <b>Selamat nilai kamu memenuhi passing grade! </b><br/>
                                Jangan sampai berpuas diri ya!
                            </div>

                            <div v-if="item.status != 'Lulus'" class="alert alert-danger text-center">
                                <b>Nilai Kamu belum memenuhi passing grade! </b><br/>
                                Tetap semangat dan coba lagi kembali ya...
                            </div>
                        </div>
                        <hr/>
                        <div>
                            <apexchart type="bar" :options="chartOptions" :series="item.seriesPassingGrade"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc">
                            <b><small class="font-weight-600">Statistik Jawaban <b>{{item.name}}</b></small></b>
                        </h4>
                        <hr/>
                        <div class="d-block mt-4">
                        </div>
                        <div v-for="(staticData, index) in item.statistic" :key="index" class="d-block mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2" :class="staticData.name != 'Tidak Mengisi' ? 'border-bottom':''">
                                <div class="mr-3 font-weight-600" style="width:60%">{{staticData.name}}</div>
                                <div class="font-weight-600 text-right" style="width:10%;" >
                                    {{staticData.value}}
                                </div>
                            </div>
                        </div>

                        <div>
                            <apexchart type="pie" :options="item.grafik" :series="item.series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatisticTryoutPppk',
        metaInfo: {
            title: 'Nilai dan Statistik Nilai PPPK',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019', '#35858B', '#04293A', '#E9B2BC', '#95CD41', '#AE431E',
                        '#B4C6A6'
                    ],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                }
            }
        },
        methods: {
        }
    }
</script>