<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">                                    
                    <label for="exampleFormControlSelect1">Tipe</label>
                    <select class="form-control" id="exampleFormControlSelect1" v-model="type_id" v-on:change="getData">
                        <option value="">Pilih Tipe</option>
                        <option v-for="(item, index) in dataType" :key="index" :value="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-9">
                <label for="exampleFormControlSelect1">Kata kunci</label>
                <div class="input-group mb-3">
                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                    <div class="input-group-append">
                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                class="fa fa-search"></i> Cari </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="dataFetch" class="row">
            <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                    <div class="d-block border rounded-lg p-3 m-t-10">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar text-center">
                                            <div class="sb-sm mb-2"></div>
                                            <hr/>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                            <div class="sb-lg"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div v-if="dataList.length > 0" class="row">
                <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                    <div class="card card-theory">
                        <div class="card-body">
                            <div class="d-block mb-1">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                </div>
                            </div>

                            <div class="d-block mb-1">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Tipe</div>
                                    <div class="font-weight-600">{{item.type}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Jumlah Soal</div>
                                    <div class="font-weight-600">{{item.total_questions}} Soal</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                    <div class="mr-3">Status</div>
                                    <div class="font-weight-600">
                                        <span v-if="!item.complete" class="badge badge-danger">Belum lulus</span>
                                        <span v-if="item.complete" class="badge badge-success">Sudah lulus</span>
                                    </div>
                                </div>

                                <router-link v-if="!item.complete" :to="{
                                        name : 'member_tryout_overview_english_academy',
                                        params: { 
                                            id   : $route.params.id,
                                            code : item.code
                                        }
                                    }"
                                    class="btn btn-primary rounded btn-block m-t-20">
                                    <i class="fa fa-edit"></i> {{item.answer_id ? 'Kerjakan Ulang':'Kerjakan Soal'}}
                                </router-link>

                                <router-link v-if="item.answer_id" :to="{
                                        name : 'member_tryout_result_english_academy',
                                        params: { 
                                            id        : $route.params.id,
                                            code      : item.code,
                                            id_answer : item.answer_id
                                        }
                                    }"
                                    class="btn btn-outline-primary rounded btn-block m-t-20">
                                    <i class="fa fa-bars"></i> Lihat Hasil & Statistik
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <nav aria-label="navigation">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </nav>
                </div>
            </div>

            <div v-if="emptyState">
                <empty-state></empty-state>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'PaketEnglishAcademySaya',
        metaInfo: {
            title: 'Tryout English Academy Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                keywords            :'',
                type_id             :'',
                dataFetch           : true,
                dataList            : [],
                dataType            : [],
                emptyState          : false,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getType();
            this.getData();
        },
        methods: {
            getType: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/type/list';
                this.$http.get(uri).then(res => {
                    this.dataType   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/english_academy/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                        keywords    : this.keywords,
                        type_id     : this.type_id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
        }
    }
</script>