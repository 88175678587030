<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Informasi Voucher</h5>
                        </div>

                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Sekolah</div>
                                <div class="font-weight-600">{{ dataVoucher.school_name }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kelas</div>
                                <div class="font-weight-600">{{ dataVoucher.class == 'all' ? 'Semua Kelas': dataVoucher.class }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Berlaku Hingga (Waktu Mulai)</div>
                                <div class="font-weight-600">{{ dataVoucher.text_expired_at }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Berlaku Hingga (Waktu Akhir)</div>
                                <div class="font-weight-600">{{ dataVoucher.text_expired_end }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Voucher Peserta</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button
                                v-if="dataList.length > 0"
                                :disabled="isFetchResult"
                                class="btn btn-info rounded " type="button" v-on:click="DownloadCard">
                                    <i v-if="isFetchResult" class="fa fa-spin fa-spinner"></i>
                                    <i v-if="!isFetchResult" class="fa fa-download"></i>
                                    {{ isFetchResult ? 'Loading...' : 'Download Kartu Peserta' }}
                                </button>

                                <button
                                v-if="dataList.length > 0 && dataVoucher.type == 'cbt'"
                                :disabled="fetch_download"
                                class="btn btn-info rounded ml-2" type="button" v-on:click="donwloadCardNew">
                                    <i v-if="fetch_download" class="fa fa-spin fa-spinner"></i>
                                    <i v-if="!fetch_download" class="fa fa-download"></i>
                                    {{ fetch_download ? 'Loading...' : 'Download Kartu Terbaru' }}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="used" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option value="1">Sudah digunakan</option>
                                        <option value="0">Belum digunakan</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div class="table-responsive">
                                        <div v-if="dataList.length > 0" >
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th style="width:55%">Nama Lengkap</th>
                                                        <th class="text-center" style="width:15%">Kelas</th>
                                                        <th style="width:15%">Kode Voucher</th>
                                                        <th style="width:5%" class="text-center">Status</th>
                                                        <th style="width:5%" class="text-center">Aksi</th>
                                                        <th style="width:5%" class="text-center">Logout User</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>{{item.fullname}}<br/><small>email : {{ item.email }}</small></td>
                                                        <td class="text-center">{{ item.school_class }}</td>
                                                        <td>
                                                            <span class="badge badge-info">
                                                                {{ item.code }}
                                                            </span>

                                                            <button 
                                                                class="m-l-10 btn btn-outline-primary rounded btn-sm"
                                                                type="button"
                                                                v-clipboard:copy="item.code"
                                                                v-clipboard:success="onCopy"
                                                                v-clipboard:error="onError">
                                                                <i class="fa fa-copy"></i> Salin
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <span v-if="!item.used" class="badge badge-danger">Belum digunakan</span>
                                                            <span v-if="item.used" class="badge badge-success">Sudah digunakan</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <button v-if="item.active == 0" type="button" class="btn btn-primary btn-sm rounded" v-on:click="activated(item.id, 1)">Aktifkan Voucher</button>
                                                            <button v-if="item.active == 1" type="button" class="btn btn-danger btn-sm rounded" v-on:click="activated(item.id, 0)">Non Aktifkan Voucher</button>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-outline-primary btn-sm rounded" v-on:click="logoutUser(item.user_id)"><i class="fa fa-shutdown"></i> Logout Peserta</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <nav aria-label="navigation" class="m-t-20" v-if="dataList.length > 0">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>


                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :filename="'KARTU PESERTA '+dataVoucher.school_name+' - '+dataVoucher.class"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                pdf-format="A4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                :manual-pagination="false"
                :margin="0"
                @progress="onProgress($event)"
                @hasDownloaded="hasDownloaded($event)"
                ref="html2Pdf"
                >

                <ContentToPrint slot="pdf-content" :id="$route.params.id"/>
            </vue-html2pdf>
        </div>        
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    import VueHtml2pdf from 'vue-html2pdf'
    import ContentToPrint from "../../../components/print/kartu-peserta.vue";

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Detail Data Voucher Sekolah (Perkelas)',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination,
            VueHtml2pdf,
            ContentToPrint
        },
        data() {
            return {
                fetch_download  : false,
                isFetchResult   : false,
                emptyState      : false,
                dataFetch       : false,
                dataList        : [],
                dataVoucher     : [],
                dataListVoucher     : {},
                keywords        : "",
                used            : "",
                voucher_school_sessions_id   : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/detail';
                this.$http.get(uri, {
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    this.dataVoucher   = res.data.data;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/list-detail';

                this.dataFetch  = true;                

                if(this.pagination.currentPage == 0) {
                    this.pagination.currentPage = 1;
                }
                this.$http.get(uri,{
                    params : {
                        voucher_school_sessions_id   : this.$route.params.id,
                        used        : this.used,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onCopy: function (e) {
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    html: 'Kode voucher berhasil di salin : <br/><b>'+e.text+'</b>',
                });
            },
            onError: function (e) {
                alert('Failed to copy the text to the clipboard')
                console.log(e);
            },
            DownloadCard() {
                this.isFetchResult = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/download-card', {
                    params : {
                        id     : this.$route.params.id,
                        used        : this.used,
                        keywords    : this.keywords
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchResult = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Kartu gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Peserta - '+this.dataVoucher.school_name+' - '+this.dataVoucher.class}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            activated: function(id='', params='') {
                this.$swal({
                    icon: 'info',
                    title: 'Non Aktifkan Voucher',
                    html: 'Apakah kamu yakin ingin menonaktifkan kode voucher ini?',
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher-school-sessions/detail-update-activate', {
                        id: id,
                        active: params
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            logoutUser: function(id='') {
                this.$swal({
                    icon: 'info',
                    title: 'Logout Peserta',
                    html: 'Apakah kamu yakin ingin melogout/shutdown peserta ini?',
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/signout_by_user', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            donwloadCardNew: function() {
                this.$refs.html2Pdf.generatePdf();                
            },
            onProgress(event) {
                this.fetch_download = true;
                if(event >= 100) {
                    this.fetch_download = false;
                }                
            },
            hasDownloaded(event) {
                console.log(`Processed: ${event} / 100`);
            },
        }
    }
</script>
