<template>
    <div>
        <div v-if="dataFetchSchool" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchSchool">
            <div v-if="!SchoolInfo.status">
                <div class="alert alert-info" role="alert">
                    <b>Informasi : </b>
                    <ul class="m-t-10">
                        <li>Harap pilih sekolah sesuai dengan asal sekolah kamu pada inputan dibawah ini.</li>
                        <li>Asal Sekolah hanya bisa dipilih <b>1x</b>, maka pilihlah sesuai dengan asal sekolah kamu.</li>
                        <li>Harap hubungi admin untuk info lebih lanjut.
                            <a href="https://web.whatsapp.com/send?phone=6287837850461" class="font-weight-bold" target="blank">Hubungi Admin</a>
                        </li>
                        <li>Good luck! 😊</li>
                    </ul>
                </div>

                <div class="page-header mb-0">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Ayo bergabung dan dapatkan psikotest dari sekolah! </h5>
                                </div>
                            </div>
                        </div>

                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <v-select
                                            placeholder="Pilih Sekolah"
                                            label="name"
                                            :options="dataSchool"
                                            v-model="school"
                                            :class="{ 'is-invalid': submitted && $v.school.$error }"
                                            @input="changeSchool"
                                            >                                    
                                        </v-select>

                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">&nbsp;</label>
                                        <button type="submit" class="btn btn-primary btn-block rounded"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>                
                </div>
            </div>

            <div v-if="SchoolInfo.status">
                <h5>Data Psikotest</h5>
                <hr />
                <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="SchoolInfo.data.name">
                    <strong><i class="fa fa-info-circle"></i></strong>
                    <span class="m-l-10">Saat ini kamu terdaftar sebagai siswa dari sekolah <b>{{SchoolInfo.data.name}}</b>.<br/>
                    Untuk mengubah asal sekolah, silahkan <a href="javascript:void(0)" data-toggle="modal" data-target="#modalForm" class="font-weight-bold" target="blank">klik disini</a></span>
                </div>

                <div v-if="dataFetch" class="row">
                    <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                        <div class="card card-hover-none d-block border rounded-lg p-3 m-t-10">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="mr-3 w-100">
                                    <div class="d-flex align-items-center">
                                        <div class="w-100">
                                            <div class="skeleton-bar text-center">
                                                <div class="sb-sm mb-2"></div>
                                                <hr />
                                                <div class="sb-lg sb-thin mb-2"></div>
                                                <div class="sb-lg sb-thin mb-4"></div>
                                                <div class="sb-lg"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!dataFetch">
                    <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                        <div class="card card-hover-none d-block border rounded-lg m-t-10 p-3">
                            <img v-if="!item.cover" :src="'assets/images/cover-psikotest.png'" class="card-img-top" alt="...">
                            <img v-if="item.cover" :src="item.cover" class="card-img-top" alt="...">
                            <div class="card-body" style="margin-bottom: -20px;">
                                <h5 class="job-card-desc text-center">
                                    {{item.name}}
                                </h5>
                            </div>
                            <hr/>
                            <div class="d-grid" v-if="!item.owned">
                                <button type="button" class="btn btn-primary rounded btn-block" v-on:click="inputCode(item.id)"> 
                                    Daftar Psikotest
                                </button>
                            </div>

                            <div class="d-grid" v-if="item.owned">
                                <button v-if="!item.finished" type="button" class="btn btn-primary rounded btn-block" v-on:click="goToOverview(item.id)">
                                    Kerjakan Psikotest
                                </button>

                                <button v-if="item.finished" type="button" class="btn btn-outline-primary rounded btn-block" v-on:click="goToResult(item.id)">
                                    Lihat Hasil Tes
                                </button>
                            </div>

                            <div v-if="item.owned" class="alert alert-primary text-center mt-2 mb-0" role="alert">
                                Kamu sudah terdaftar sebagai
                                <strong>Peserta</strong>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Asal Sekolah</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <select class="form-control" v-model="school" :class="{ 'is-invalid': submitted && $v.school.$error }" v-on:change="changeSchool">
                                            <option value="">Pilih Asal Sekolah</option>
                                            <option v-for="(item, index) in dataSchool" :key="index" :value="item">{{ item.name }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                    </form>
                </div> 
            </div>
        </div>

    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'MemberPsikotest',
        metaInfo: {
            title: 'Psikotest',
            titleTemplate: '%s - Griya Belajar'
        },
        validations: {
            school: {
                required
            },
            classname: {
                required
            },
        },
        data() {
            return {
                dataFetch           : false,
                dataFetchSchool      : false,
                dataList            : [],
                SchoolInfo          : [],
                dataSchool          : [],
                dataClass           : [],
                emptyState          : false,
                keywords            : "",
                school              : "",
                classname           : "",
                form                : {
                    id : '',
                    title : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                submitted       : false,
                waiting         : false
            }
        },
        components: {
            'v-select' : vSelect
        },
        created() {
            this.schoolInfo();
            this.getSchool();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/psikotest/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            schoolInfo: function () {
                this.dataFetchSchool = true;
                let uri = this.$apiconfig + 'user/school';
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool = false;

                    let getResponse = res.data;
                    this.SchoolInfo = getResponse;

                    if(this.SchoolInfo.status) {
                        this.getData();
                    } else {
                        console.log("kosong");
                    }
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        school_id : this.school.id,
                        school_class : this.classname,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.schoolInfo();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            inputCode: function(id) {
                this.$swal({
                    title: 'Masukan Kode Voucher',
                    input: 'text',
                    confirmButtonText: 'Konfirmasi Kode'
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'user/redeem-voucher/school', {
                            code        : result.value,
                            school_id   : this.SchoolInfo.data.id,
                            type        : 'psikotest',
                            tryout_id   : id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Selamat!',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                    confirmButtonText: 'Ulang'
                                }).then((result) => {
                                    if (result.value) {
                                        this.inputCode(id);
                                    }
                                });
                            }
                        })
                        }
                });
            },
            goToOverview: function(id) {
                this.$router.push({
                    name: 'member_psikotest_overview',
                    params : {
                        id      : id
                    }
                });
            },
            goToResult: function(id) {
                this.$router.push({
                    name: 'member_psikotest_result',
                    params : {
                        id      : id
                    }
                });
            },
            changeSchool: function() {
                this.classname = "";
                if(this.school.id) {
                    this.dataSchool.forEach(element => {
                        if(this.school.id == element.id) {
                            this.dataClass = element.data_class;
                        }
                    });
                } else {                    
                    this.dataClass = [];
                }
            }
        }
    }
</script>