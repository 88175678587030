<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header mb-0">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">
                                <a href="javascript:void(0)" v-on:click="goToMain"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan - {{dataDetail.name}}
                            </h5>
                        </div>
                    </div>
                </div>

                <div class="row mb-0">
                    <div class="col-md-12" v-html="dataDetail.description">
                    </div>
                    <div class="col-md-12">
                        <button type="button" data-toggle="modal" data-target="#modalForm" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Harap lengkapi data terlebih dahulu</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                            <div class="d-block p-3">
                                <div class="d-flex align-items-center pb-3 row">
                                    <div class="col-md-12">
                                        <div class="m-t-10">
                                            <label>NISN: </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan NISN..." v-model="form.identity_number"
                                            :class="{ 'is-invalid': submitted && $v.form.identity_number.$error }"
                                            >
                                            <div v-if="submitted && !$v.form.identity_number.required" class="invalid-feedback d-block">
                                            NISN Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Kelas: </label>
                                            <select class="form-control" v-model="form.class" :class="{ 'is-invalid': submitted && $v.form.class.$error }">
                                                <option value="">Pilih Kelas</option>
                                                <option v-for="(item, index) in dataDetail.list_class" :key="index" :value="item">{{ item }}</option>
                                            </select>
                                            <div v-if="submitted && !$v.form.class.required" class="invalid-feedback d-block">
                                            Kelas Wajib Diisi</div>
                                        </div>

                                        <div class="alert alert-warning m-t-10" role="alert">
                                            <b>Perhatian : </b> Pastikan data yang dimasukan telah sesuai.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-edit"></i> {{ waiting ? 'Harap tunggu ...' : 'Mulai Mengerjakan' }}</button>
                        </form>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'MemberPsikotestOverview',
        metaInfo: {
            title: 'Persiapan Psikotest',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataDetail      : [],
                fullname    : '',
                form : {
                    fullname           : '',
                    gender             : '',
                    class              : '',
                    identity_number    : '',
                    department_plan    : '',
                },
                submitted   : false,
                waiting     : false
            }
        },
        validations: {
            form: {
                class: {
                    required
                },
                identity_number: {
                    required
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/psikotest/overview';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;

                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki akses ke halaman ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goToMain();
                                }
                            });
                        } else {
                            if(this.dataDetail.psikotest_answer) {
                                this.startPsikotest(this.dataDetail.psikotest_answer.last_work)
                            } else {
                                this.dataFetch  = false;                                
                            }                            
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            goToMain: function() {
                this.$router.push({
                    name: 'member_psikotest'
                });
            },
            confirmStart: function() {
                let message = 'Apakah kamu yakin ingin mulai mengerjakaan?<br/>Pastikan data yang kamu masukan telah sesuai.<br/>Psikotes yang akan kamu kerjakan adalah <b>'+this.dataDetail.users_work_order.toUpperCase()+'</b>';
                let confirm_button = "Mulai Mengerjakan";
                if(this.dataDetail.work_order.length > 1) {
                    message = 'Apakah kamu yakin ingin mulai mengerjakaan?<br/>Pastikan data yang kamu masukan telah sesuai.<br/>Psikotes yang pertama di kerjakan adalah <b>'+this.dataDetail.users_work_order.toUpperCase()+'</b>'
                } else {
                    if(this.dataDetail.users_work_order == 'ist') {
                        confirm_button = 'Lanjut';
                    }
                }

                console.log(this.dataDetail.work_order.length);
                this.$swal({
                    icon: 'info',
                    title: 'Mulai Mengerjakan',
                    html: message,
                    confirmButtonText: confirm_button,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        document.getElementById("btnCloseModal").click();
                        this.startPsikotest(this.dataDetail.users_work_order)
                    }
                });
            },
            startPsikotest: function(users_work_order="") {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/psikotest/start', {
                    id              : this.$route.params.id,
                    type_psikotest  : users_work_order,
                    class           : this.form.class,
                    identity_number : this.form.identity_number
                })
                .then(response => {
                    if (response.data.status) {
                        if(response.data.data.users_work_order) {                            
                            this.goToTryout(response.data.data.users_work_order);
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            },
            goToTryout: function(users_work_order="") {
                if(users_work_order == 'disc') {
                    this.$router.push({
                        name: 'member_psikotest_disc_tryout',
                        params : {
                            id      : this.$route.params.id,
                            slug    : this.dataDetail.disc_tryout_code
                        }
                    });
                }

                if(users_work_order == 'ist') {
                    this.$router.push({
                        name: 'member_psikotest_ist_overview',
                        params : {
                            id      : this.$route.params.id,
                            slug    : this.dataDetail.ist_tryout_code
                        }
                    });
                }

                if(users_work_order == 'finished') {
                    this.$router.push({
                        name: 'member_psikotest_result',
                        params : {
                            id      : this.$route.params.id
                        }
                    });
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.waiting    = false;
                    this.submitted  = false;
                    this.confirmStart();
                }
            }
        }
    }
</script>