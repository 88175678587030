import { render, staticRenderFns } from "./PembahasanTryout.vue?vue&type=template&id=a0a974f0&scoped=true&"
import script from "./PembahasanTryout.vue?vue&type=script&lang=js&"
export * from "./PembahasanTryout.vue?vue&type=script&lang=js&"
import style0 from "./PembahasanTryout.vue?vue&type=style&index=0&id=a0a974f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0a974f0",
  null
  
)

export default component.exports