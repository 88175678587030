<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Informasi Voucher</h5>
                        </div>

                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Sekolah</div>
                                <div class="font-weight-600">{{ dataVoucher.school_name }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kelas</div>
                                <div class="font-weight-600">{{ dataVoucher.class == 'all' ? 'Semua Kelas': dataVoucher.class }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Berlaku Hingga (Waktu Mulai)</div>
                                <div class="font-weight-600">{{ dataVoucher.text_expired_at }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Berlaku Hingga (Waktu Akhir)</div>
                                <div class="font-weight-600">{{ dataVoucher.text_expired_end }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Histori Voucher</h5>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <empty-state></empty-state>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Log Data Voucher Sekolah (Perkelas)',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                isFetchResult   : false,
                emptyState      : false,
                dataFetch       : false,
                dataList        : [],
                dataVoucher     : [],
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/detail';
                this.$http.get(uri, {
                    params : {
                        id : this.$route.params.id
                    }
                }).then(res => {
                    this.dataVoucher   = res.data.data;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school-sessions/log';

                this.dataFetch  = true;                

                if(this.pagination.currentPage == 0) {
                    this.pagination.currentPage = 1;
                }
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
        }
    }
</script>
