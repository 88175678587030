<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Grup Diskusi PPPK</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <input type="hidden" name="id" v-model="form.type">
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Link Free / Gratis</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.link_free" :class="{ 'is-invalid': submitted && $v.form.link_free.$error }">
                                    <div v-if="submitted && !$v.form.link_free.required" class="invalid-feedback">Wajib diisi</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Link Premium</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.link_premium" :class="{ 'is-invalid': submitted && $v.form.link_premium.$error }">
                                    <div v-if="submitted && !$v.form.link_premium.required" class="invalid-feedback">Wajib diisi</div>    
                                </div>
                            </div>
                            <button type="submit" class="rounded btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        link_premium: 'Formasi',
        metaInfo: {
            title: 'Group Diskusi',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                form : {
                    id              : "",
                    link_free       : "",
                    link_premium    : "",
                    type            : "pppk"
                },
                submitted       : false,
                waiting         : false
            }
        },
        created() {
            this.getDataByID('pppk');
        },
        validations: {
            form: {
                link_free: {
                    required
                },
                link_premium: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('link_free', this.form.link_free);
                    formData.append('link_premium', this.form.link_premium);
                    formData.append('type', this.form.type);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/group/discussion/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ link_premium: 'skb_formation' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/group/discussion/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            type : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.form.type          = getResponse.type;
                            this.form.link_free     = getResponse.link_free;
                            this.form.link_premium  = getResponse.link_premium;
                        }
                    });
                }
            }
        }
    }
</script>