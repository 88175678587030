<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Mata Pelajaran - {{ dataSchool.name }}</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'subjects_add', params: { school_id: $route.params.school_id }}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:80%">Nama Mata Pelajaran</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">{{++index}}</td>
                                                    <td>{{item.name}}
                                                        <br/>
                                                        <small>Kode: <b>{{ item.code }}</b></small>
                                                    </td>
                                                    <td>
                                                        <router-link :to="{name : 'subjects_edit', params: { school_id: item.school_id, id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'PsikotestSubject',
        metaInfo: {
            title: 'Data Paket Mata Pelajaran Sekolah',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch           : false,
                dataFetchSchool     : false,
                dataSchool          : [],
                dataList            : [],
                keywords            : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getSchool();
        },
        methods: {
            getSchool: function() {
                this.dataFetchSchool = true;
                this.label  = "Ubah";

                let uri     = process.env.VUE_APP_APIHOST+'admin/school/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.school_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data.data;
                        this.dataSchool = getResponse;
                        this.dataFetchSchool = false;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'school' });
                            }
                        });
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/subjects/list';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        school_id : this.$route.params.school_id,
                        keywords  : this.keywords,
                        page      : this.pagination.currentPage,
                        limit     : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/subjects/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }            
        }
    }
</script>
