<template>
    <div>
        <div v-if="fetchOverview" >
            <p>Harap tunggu...</p>
        </div>
        <div v-if="!fetchOverview" class="page-header">
            <div class="page-block" v-if="dataOverview.data_tryout.length == 0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4>Perhatian!</h4>
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h4 class="m-t-10">
                                Saat ini sekolah anda belum memiliki tryout CBT.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-block" v-if="dataOverview.data_tryout.length > 0">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Monitoring Tryout CBT</h5>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-block mb-1 mt-2">
                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Jumlah Tes</div>
                                        <div class="font-weight-600">{{ dataOverview.data_tryout.length }} Tes</div>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">Total Siswa</div>
                                        <div class="font-weight-600">{{ dataOverview.total_member }} Orang</div>
                                    </div>

                                    <div class="d-flex align-items-top justify-content-between pb-2 mb-3 border-bottom">
                                        <div class="mr-3">
                                            Download Analisis Soal & Ranking Peserta
                                            <br/>                                              
                                        </div>
                                        <div class="font-weight-600">
                                            <span v-if="isFetchAnalysis">Harap tunggu...</span>
                                            <button data-toggle="modal" data-target="#modalReport" class="btn btn-primary rounded" type="button" v-if="!isFetchAnalysis">
                                                Download Analisis Soal dan Ranking Peserta
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive" style="max-height: 450px; overflow-y: auto;">
                                    <div v-if="dataList.length > 0">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%; position: sticky; top: 0; background-color: #f1f1f1;" class="text-center">No</th>
                                                    <th style="position: sticky; top: 0; background-color: #f1f1f1;">Data Peserta</th>
                                                    <th style="position: sticky; top: 0; background-color: #f1f1f1;" class="text-center" v-for="(item, index) in dataOverview.data_tryout" :key="index">
                                                        {{ item.title }}
                                                    </th>
                                                    <th class="text-center" style="position: sticky; top: 0; background-color: #f1f1f1;">Skor Rata - rata</th>
                                                    <th class="text-center" style="position: sticky; top: 0; background-color: #f1f1f1;">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td style="vertical-align: middle;" class="text-center">{{ ++index }}</td>
                                                    <td style="vertical-align: middle;">{{ item.fullname }}<br/><small>Email : {{ item.username }}</small></td>
                                                    <td style="vertical-align: middle;" class="text-center" v-for="(itemDetail, indexDetail) in item.answer" :key="indexDetail">
                                                        <span v-if="itemDetail == '-'">
                                                            <small v-if="item.status_working[indexDetail] == '-'">(Belum Mengerjakan)</small>
                                                            <span style="color:tomato" v-if="item.status_working[indexDetail] == 'ongoing'"><b>(Sedang Mengerjakan)</b></span>
                                                        </span>
                                                        <span v-if="itemDetail != '-'">
                                                            <b>{{ itemDetail }}</b><br/>
                                                            <a href="javascript:void(0)" v-on:click="resetTest(dataOverview.data_tryout[indexDetail].id, item.users_id)">(Reset Ulang Tes)</a>
                                                        </span>
                                                    </td>
                                                    <td style="vertical-align: middle;" class="text-center">
                                                        <span v-if="item.averaged_scored == '-'"><small>(Belum tuntas)</small></span>
                                                        <span v-if="item.averaged_scored != '-'"><b>{{ item.averaged_scored }}</b></span>
                                                    </td>
                                                    <td style="vertical-align: middle;" class="text-center">
                                                        <span v-if="item.averaged_scored == '-'">-</span>
                                                        <button v-if="item.averaged_scored != '-'"
                                                            :disabled="isFetchScoreUsers"
                                                            v-on:click="downloadScoreUsers(item.users_id, item.fullname)" type="button" class="btn btn-primary rounded btn-block rounded btn-sm">
                                                            <i v-if="isFetchScoreUsers" class="fa fa-spin fa-spinner"></i>
                                                            <i v-if="!isFetchScoreUsers" class="fa fa-download"></i>
                                                            {{ isFetchScoreUsers ? 'Loading...' : 'Download Nilai (PDF)' }}
                                                        </button>
                                                        <button v-if="item.averaged_scored != '-'"
                                                            :disabled="isFetchScoreUsersExcel"
                                                            v-on:click="downloadScoreUsersExcel(item.users_id, item.fullname)" type="button" class="btn btn-primary rounded btn-block rounded btn-sm">
                                                            <i v-if="isFetchScoreUsersExcel" class="fa fa-spin fa-spinner"></i>
                                                            <i v-if="!isFetchScoreUsersExcel" class="fa fa-download"></i>
                                                            {{ isFetchScoreUsersExcel ? 'Loading...' : 'Download Nilai (Excel' }}
                                                        </button>
                                                        <button v-if="item.averaged_scored != '-'"
                                                            :disabled="isFetchUsersAnalysis"
                                                            data-toggle="modal" data-target="#modalAnalisisUser"
                                                            v-on:click="setUser(item.users_id, item.fullname)"
                                                            type="button" class="btn btn-primary rounded btn-block rounded btn-sm">
                                                            <i v-if="isFetchUsersAnalysis" class="fa fa-spin fa-spinner"></i>
                                                            <i v-if="!isFetchUsersAnalysis" class="fa fa-download"></i>
                                                            {{ isFetchUsersAnalysis ? 'Loading...' : 'Download Analisis' }}
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="modalReport" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px">Download Data</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Pilih Tes</label>
                                        <select class="form-control" v-model="form.tryout_id">
                                            <option value="">Pilih Tes</option>
                                            <option v-for="(item, index) in dataOverview.data_tryout" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>

                                    <div class="m-t-10">
                                        <label>Guru Pengampu/Wali Kelas</label>
                                        <input type="text" placeholder="Masukan nama guru pengampu/ wali kelas..." class="form-control" v-model="form.teacher">
                                    </div>

                                    <button :disabled="isFetchAnalysis" type="button" v-on:click="downloadAnalysis()" class="btn btn-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysis" class="fa fa-download"></i><i v-if="isFetchAnalysis" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysis ? 'Harap tunggu' : 'Download Analisis Soal' }}</button>
                                    <button :disabled="isFetchRank" type="button" v-on:click="downloadRank()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchRank" class="fa fa-users"></i><i v-if="isFetchRank" class="fa fa-spin fa-spinner"></i> {{ isFetchRank ? 'Harap tunggu' : 'Download Peringkat Peserta (PDF)' }}</button>
                                    <button :disabled="isFetchRankExcel" type="button" v-on:click="downloadRankExcel()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchRank" class="fa fa-users"></i><i v-if="isFetchRank" class="fa fa-spin fa-spinner"></i> {{ isFetchRankExcel ? 'Harap tunggu' : 'Download Peringkat Peserta (Excel)' }}</button>
                                    <button :disabled="isFetchRankMatpelExcel" type="button" v-on:click="downloadRankMatpelExcel()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchRankMatpelExcel" class="fa fa-users"></i><i v-if="isFetchRankMatpelExcel" class="fa fa-spin fa-spinner"></i> {{ isFetchRankMatpelExcel ? 'Harap tunggu' : 'Download Nilai Per Mata pelajaran Peserta (Excel)' }}</button>
                                    <button :disabled="isFetchAnalysisExcel" type="button" v-on:click="downloadAnalysisExcel()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisExcel" class="fa fa-users"></i><i v-if="isFetchAnalysisExcel" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisExcel ? 'Harap tunggu' : 'Download Analisis Soal Terbaru (Excel)' }}</button>
                                    <button :disabled="isFetchAnalysisDayaSerap" type="button" v-on:click="downloadDayaSerap()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisDayaSerap" class="fa fa-download"></i><i v-if="isFetchAnalysisDayaSerap" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisDayaSerap ? 'Harap tunggu' : 'Download Daya Serap' }}</button>
                                    <button :disabled="isFetchAnalysisRemidi" type="button" v-on:click="downloadRemidi()" class="btn btn-outline-primary btn-block m-t-10 mb-0"><i v-if="!isFetchAnalysisRemidi" class="fa fa-download"></i><i v-if="isFetchAnalysisRemidi" class="fa fa-spin fa-spinner"></i> {{ isFetchAnalysisRemidi ? 'Harap tunggu' : 'Download Nilai Remidi' }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" id="modalAnalisisUser" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px">Download Data</div>
                                <div>
                                    <button id="btnCloseModalUser" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Pilih Tes</label>
                                        <select class="form-control" v-model="form.tryout_id">
                                            <option value="">Pilih Tes</option>
                                            <option v-for="(item, index) in dataOverview.data_tryout" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>
                                    <button :disabled="isFetchUsersAnalysis"
                                    type="button"
                                    v-on:click="downloadAnalysisUsers(users_id, fullname)"
                                    class="btn btn-outline-primary btn-block m-t-10 mb-0">
                                    <i v-if="!isFetchUsersAnalysis" class="fa fa-users"></i>
                                    <i v-if="isFetchUsersAnalysis" class="fa fa-spin fa-spinner"></i>
                                    {{ isFetchUsersAnalysis ? 'Loading...' : 'Download Analisis' }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout akm',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                users_id : '',
                fullname : '',
                emptyState  : false,
                isFetchScoreUsers   : false,
                isFetchUsersAnalysis   : false,
                isFetchAnalysis   : false,
                isFetchRank   : false,
                isFetchRankExcel   : false,
                isFetchRankMatpelExcel   : false,
                isFetchScoreUsersExcel : false,
                isFetchAnalysisExcel : false,
                isFetchAnalysisRemidi    : false,
                isFetchAnalysisDayaSerap    : false,
                fetchOverview   : false,
                dataFetch   : false,
                dataList    : [],
                dataOverview : {
                    data_tryout : []
                },
                keywords    : "",
                form : {
                    tryout_id : '',
                    teacher : ''
                }
            }
        },
        created() {
            this.getOverview();
        },
        methods: {
            getOverview: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/overview-cbt';
                
                this.fetchOverview  = true;                
                this.$http.get(uri, {
                    params : {
                        class : this.$route.params.class,
                        grade : this.$route.query.grade
                    }
                }).then(res => {
                    this.fetchOverview  = false;
                    this.dataOverview   = res.data.data;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-cbt';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        class       : this.$route.params.class,
                        grade : this.$route.query.grade
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            setUser(users_id, fullname) {
                this.users_id = users_id;
                this.fullname = fullname;
            },
            downloadScoreUsers(users_id, fullname) {
                this.isFetchScoreUsers = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-score-users-cbt', {
                    params : {
                        class    : this.$route.params.class,
                        users_id : users_id,
                        grade : this.$route.query.grade
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchScoreUsers = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Hasil CBT - '+fullname}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            downloadScoreUsersExcel(users_id, fullname) {
                this.isFetchScoreUsersExcel = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-score-users-cbt-excel', {
                    params : {
                        class    : this.$route.params.class,
                        users_id : users_id,
                        grade : this.$route.query.grade
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchScoreUsersExcel = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Hasil CBT - '+fullname}.xlsx`);
                        document.body.appendChild(link);
                        link.click();

                    }
                })
            },
            downloadAnalysisUsers(users_id, fullname) {
                let id = this.form.tryout_id;

                if(id == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes untuk mendownload analisis soal!",
                    });
                }

                else {
                    let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchUsersAnalysis = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-analysis-users-cbt', {
                        params : {
                            class    : this.$route.params.class,
                            users_id : users_id,
                            cbt_tryout_id : id,
                            grade : this.$route.query.grade
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchUsersAnalysis = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Analisis Siswa - '+fullname+' - '+title}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadAnalysis() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysis = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-analysis-cbt', {
                        params : {
                            id : id,
                            title : title,
                            teacher : teacher,
                            class    : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchAnalysis = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Analisis CBT - '+title}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadRank() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchRank = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-rank-cbt', {
                        params : {
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchRank = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Ranking CBT - '+this.$route.params.class}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadRankExcel() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchRankExcel = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-rank-cbt-excel', {
                        params : {
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchRankExcel = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Ranking CBT - '+this.$route.params.class}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadRankMatpelExcel() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih mata pelajaran Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchRankMatpelExcel = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-rank-cbt-excel', {
                        params : {
                            id : id,
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchRankMatpelExcel = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Ranking CBT - '+this.$route.params.class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadAnalysisExcel() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih mata pelajaran Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisExcel = true;
                    // this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-analysis-cbt-excel-new-format', {
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-analysis-cbt-v2', {
                        params : {
                            id : id,
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        this.isFetchAnalysisExcel = false;
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Analisis Soal CBT - '+this.$route.params.class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            resetTest: function(cbt_tryout_id, user_id) {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi',
                    text: 'Apakah anda yakin akan mereset test siswa ini? Data yang sudah direset tidak dapat dikembalikan',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/reset-cbt-users', {
                            cbt_tryout_id : cbt_tryout_id,
                            user_id         : user_id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });
            },
            downloadRemidi() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                    let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisRemidi = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-remidi', {
                        params : {
                            id : id,
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            this.isFetchAnalysisRemidi = false;
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Nilai Remidi - '+this.$route.params.class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
            downloadDayaSerap() {
                let teacher = this.form.teacher;
                let id = this.form.tryout_id;

                if(id == '' || teacher == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap pilih tes dan Masukan nama guru untuk mendownload hasil!",
                    });
                }
                
                else {
                    let title = "";
                    this.dataOverview.data_tryout.forEach(element => {
                        if(element.id == id) {
                            title = element.title;
                        }
                    });

                    this.isFetchAnalysisDayaSerap = true;
                    this.$http.get(process.env.VUE_APP_APIHOST + 'proctor/monitoring-tryout/download-daya-serap', {
                        params : {
                            id : id,
                            title : title,
                            teacher : teacher,
                            class : this.$route.params.class,
                            grade : this.$route.query.grade
                        },
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        responseType: 'blob'
                    })
                    .then(response => {
                        if(response.data.type == 'application/json') {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                            });       
                        } else {
                            this.isFetchAnalysisDayaSerap = false;
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${'Hasil Daya Serap - '+this.$route.params.class+' - '+title}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                }
            },
        }
    }
</script>
