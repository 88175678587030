<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="alert alert-info" role="alert">
            Paket <b>{{dataList.name}}</b> ini berlaku hingga : <b>{{dataList.expired_text}}</b>
        </div>

        <div v-if="!dataFetch" class="page-header m-t-10">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{name : 'member_paket_saya_pppk'}"><i class="fa fa-arrow-left m-r-10"></i></router-link> Paket PGSD</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <button v-on:click="changeTab('tryout')" :class="activeTabs == 'tryout' ? 'btn-primary':'btn-outline-primary' " class="btn rounded m-r-10" type="button">Tryout</button>
                        <button v-on:click="changeTab('exercises')" :class="activeTabs == 'exercises' ? 'btn-primary':'btn-outline-primary' " class="btn rounded m-r-10" type="button">Latihan</button>
                        <button v-on:click="changeTab('theory')" :class="activeTabs == 'theory' ? 'btn-primary':'btn-outline-primary' " class="btn rounded m-r-10" type="button">Materi</button>
                    </div>
                </div>

                <vue-page-transition>
                    <keep-alive>
                            <component :is="currentTabComponent"></component>
                    </keep-alive>
                </vue-page-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import tryout from "@/views/member/pppk/tryout/Main.vue"
    import exercises from "@/views/member/pppk/exercises/Main.vue"
    import theory from "@/views/member/pppk/theory/Main.vue"

    export default {
        name: 'PaketPppkSaya',
        metaInfo: {
            title: 'Paket PPPK Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            tryout,
            exercises,
            theory
        },
        data() {
            return {
                keywords            :'',
                dataFetch           : true,
                dataList            : [],
                emptyState          : false,
                activeTabs          : 'tryout',
                currentTabComponent : 'tryout',
            }
        },
        created() {
            this.getData();
        },
        methods: {
            changeTab: function(id) {
                this.activeTabs = id;
                this.currentTabComponent = this.activeTabs;
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/package/my/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id  : this.$route.params.id
                    }
                }).then(res => {
                    this.emptyState = false;
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
        }
    }
</script>