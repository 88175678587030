<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Konfirmasi Pengajuan Sertifikat</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th style="width:10%">Email</th>
                                                        <th style="width:10%">Peserta</th>
                                                        <th style="width:10%">Nama di Sertifikat</th>
                                                        <th style="width:10%">File Sertifikat</th>
                                                        <th style="width:5%" class="text-center">Status</th>
                                                        <th style="width:5%" class="text-center">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>{{item.email}}</td>
                                                        <td>{{item.member_name}}</td>
                                                        <td>{{item.fullname}}</td>
                                                        <td>
                                                            <span v-if="!item.attachment">-</span>
                                                            <a :href="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+item.attachment" target="blank" v-if="item.attachment">Lihat</a>
                                                        </td>
                                                        <td class="text-center">
                                                            <span v-if="item.status == 'waiting'" class="badge badge-secondary">Menunggu Konfirmasi</span>
                                                            <span v-if="item.status == 'confirm'" class="badge badge-success">Dikonfirmasi</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <button v-on:click="setItem(item.id)" data-toggle="modal" data-target="#modalCertificate" type="button" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <!-- Modal -->
            <div class="modal" id="modalCertificate" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Form Pengajuan Sertifikat</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Status</label>
                                        <select class="form-control" v-model="form.status">
                                            <option value="waiting">Menunggu Konfirmasi</option>
                                            <option value="confirm">Dikonfirmasi</option>
                                        </select>
                                    </div>

                                    <div class="m-t-10">
                                        <label>File sertifikat</label>
                                        <input type="file" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama kamu..." :class="{ 'is-invalid': submitted && $v.form.attachment.$error }"
                                        @change="previewFiles" accept="application/pdf">
                                        <div v-if="submitted && !$v.form.attachment.required" class="invalid-feedback d-block">
                                        Harap pilih file sertifikat</div>
                                        <span v-if="!form.attachment">-</span>
                                        <span v-if="form.attachment">File sebelumnya : <a :href="'https://pdfviewer.oeltimacreation.com/web/viewer?file='+form.attachment" target="blank" v-if="form.attachment">Lihat</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-10px" type="submit" class="btn btn-primary btn-block"><i class="fas fa-paper-plane"></i> {{ waiting ? 'Harap tunggu ...' : 'Kirim Sertifikat' }}</button>
                        </form>
                    </div> 
                </div>
            </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vPagination from 'vue-plain-pagination';

    export default {
        name: 'ConfirmCertificateEnglishAcademy',
        metaInfo: {
            title: 'Konfirmasi Pengajuan Sertifikat',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    id          : '',
                    fullname    : '',
                    status      : 'waiting',
                    email       : '',
                    member_name : '',
                    package_name        : '',
                    attachment          : "",
                    previousAttachment  : ""
                },
                submitted   : false,
                waiting     : false
            }
        },
        created() {
            this.getData();
        },
        validations: {
            form: {
                attachment: {
                    required
                },
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/english_academy/certificate/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('fullname', this.form.fullname);
                    formData.append('status', this.form.status);
                    formData.append('email', this.form.email);
                    formData.append('attachment', this.form.attachment);
                    formData.append('package_name', this.form.package_name);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/english_academy/certificate/update', formData, )
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            },
            setItem: function(id) {
                var item = this.dataList.find(item => item.id === id);

                this.form.id            = item.id;
                this.form.attachment    = item.attachment;
                this.form.email         = item.email;
                this.form.fullname      = item.fullname;
                this.form.member_name   = item.member_name;
                this.form.package_name  = item.package_name;
                this.form.status        = item.status;
            }
        }
    }
</script>
