<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div class="page-header">            
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">
                                    <a href="javascript:void(0)" v-on:click="goToMain"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tes - IST (Intelligenz Structure Test)
                                </h5>                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Total Waktu</div>
                                    <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Jumlah Keseluruhan Soal</div>
                                    <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Komponen Soal</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-0 row">
                                    <div class="mr-3 col-md-12" v-for="(item, index) in dataDetail.list_component" :key="index">
                                        <b>{{item.name}}</b>
                                        <ol class="m-t-10">
                                            <li v-for="(itemDetail, indexDetail) in item.list" :key="indexDetail">{{itemDetail.sub_category}} <i class="ml-2 fa fa-arrow-right mr-2"></i> Waktu mengerjakan <b>{{itemDetail.timer}} Menit </b> ({{itemDetail.total_questions}} Soal)</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button type="button" v-on:click="confirmStart()" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert-info m-t-10" role="alert">
                <b>Perhatian !</b>
                <hr/>
                <ol>
                    <li>Gunakan browser versi terbaru supaya website dapat diakses dengan lancar tanpa masalah.</li>
                    <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout</li>
                    <li>Tes ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                </ol>
            </div>
        </div>

     </div>
</template>

<script>
    export default {
        name: 'TryoutIstOverview',
        metaInfo: {
            title: 'Persiapan Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                submitted : false,
                waiting : false,
                form : {
                    collage : '',
                    collage_two : '',
                    program_study : '',
                    program_study_two : ''
                },
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
                randomCode      : (Math.random() + 1).toString(36).substring(2),
                listCollage         : [],
                listProgramStudy    : [],
                listProgramStudyTwo : []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            goToMain: function() {
                this.$router.push({
                    name: 'member_psikotest'
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/ist/tryout/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug            : this.$route.params.slug,
                        psikotest_id    : this.$route.params.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;
                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki akses ke halaman ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goToMain();
                                }
                            });
                        } else {
                            if(this.dataDetail.ongoing) {
                                if(this.dataDetail.break) {
                                    this.$router.push({
                                        name: 'member_psikotest_ist_tryout_break',
                                        params : {
                                            psikotest_id    : this.$route.params.id,
                                            slug            : this.$route.params.slug
                                        }
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'member_psikotest_ist_tryout_start',
                                        params: {
                                            psikotest_id    : this.$route.params.id,
                                            slug            : this.$route.params.slug,
                                            id              : this.dataDetail.tryout_detail.data.id
                                        }
                                    });
                                }
                            } else {
                                this.dataFetch  = false;         
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goToMain();
                            }
                        });
                    }
                });
            },
            confirmStart: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Mulai Mengerjakan',
                    html: 'Apakah kamu yakin ingin mulai mengerjakaan?',
                    confirmButtonText: 'Mulai Mengerjakan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.startTyout();
                    }
                });
            },
            startTyout: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/ist/tryout/start', {
                    slug            : this.$route.params.slug,
                    psikotest_id    : this.$route.params.id
                })
                .then(response => {
                    if (response.data.status) {
                        const result = response.data.data;

                        this.$router.push({
                            name: 'member_psikotest_ist_tryout_start',
                            params: {
                                psikotest_id    : this.$route.params.id,
                                slug            : this.$route.params.slug,
                                id              : result.id
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            }
        }
    }
</script>