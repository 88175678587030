import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Maintenance from '../views/static/Maintenance.vue'
import Profile from '../views/static/Profile.vue'
import GriyaExam from '../views/static/GriyaExam.vue'
import Axios from 'axios'

// Authentication
import Signin from '../views/authentication/Signin.vue'
import Signup from '../views/authentication/Signup.vue'
import Signout from '../views/authentication/Signout.vue'
import ForgotPassword from '../views/authentication/ForgotPassword.vue'
import ConfirmPassword from '../views/authentication/ConfirmPassword.vue'
import SigninCBT from '../views/authentication/SigninCBT.vue'

// Proctor Monitoring
import MonitoringTryoutUtbk from '../views/proctor/monitoring/utbk/Main.vue'
import MonitoringTryoutUtbkDetail from '../views/proctor/monitoring/utbk/Detail.vue'
import MonitoringTryoutKedinasan from '../views/proctor/monitoring/kedinasan/Main.vue'
import MonitoringTryoutKedinasanDetail from '../views/proctor/monitoring/kedinasan/Detail.vue'
import MonitoringTryoutAKM from '../views/proctor/monitoring/akm/Main.vue'
import MonitoringTryoutAKMDetail from '../views/proctor/monitoring/akm/Detail.vue'
import MonitoringTryoutCBT from '../views/proctor/monitoring/cbt/Main.vue'
import MonitoringTryoutCBTOverview from '../views/proctor/monitoring/cbt/Overview.vue'
import MonitoringVoucher from '../views/proctor/monitoring/voucher/Main.vue'
import MonitoringVoucherSchool from '../views/proctor/monitoring/voucher/School.vue'
import MonitoringVoucherSchoolContent from '../views/proctor/monitoring/voucher/Content.vue'

// Proctor Naskah
import ViewNaskahTryoutCBT from '../views/proctor/naskah/cbt/Main.vue'
import ViewDetailNaskahTryoutCBT from '../views/proctor/naskah/cbt/Detail.vue'
import ViewDetailNaskahTryoutKedinasan from '../views/proctor/naskah/kedinasan/Detail.vue'
import ViewDetailNaskahTryoutUtbk from '../views/proctor/naskah/utbk/Detail.vue'

// Naskah untuk latihan
import ViewDetailNaskahExercisesCBT from '../views/proctor/naskah/cbt/DetailExercises.vue'

// Proctor Monitoring
import MonitoringPsikotest from '../views/proctor/monitoring/psikotest/Main.vue'
import MonitoringPsikotestDetail from '../views/proctor/monitoring/psikotest/Detail.vue'
import MonitoringPsikotestClass from '../views/proctor/monitoring/psikotest/Class.vue'
import MonitoringPsikotestClassDetail from '../views/proctor/monitoring/psikotest/ClassDetail.vue'
import MonitoringPsikotestDetailMembers from '../views/proctor/monitoring/psikotest/Members.vue'

// Admin Users
import UsersMain from '../views/admin/users/Main.vue'
import UsersForm from '../views/admin/users/Form.vue'
import UsersMainProctor from '../views/proctor/users/Main.vue'

// Admin School
import SchoolMain from '../views/admin/school/Main.vue'
import SchoolForm from '../views/admin/school/Form.vue'
import SchoolDiscMain from '../views/admin/school/disc/Main.vue'
import SchoolDiscForm from '../views/admin/school/disc/Form.vue'

// Admin School
import PsikotestSubjectMain from '../views/admin/psikotest/subject/Main.vue'
import PsikotestSubjectForm from '../views/admin/psikotest/subject/Form.vue'

// Subjects School
import SubjectsMain from '../views/admin/subjects/Main.vue'
import SubjectsGeneral from '../views/admin/subjects/General.vue'
import SubjectsForm from '../views/admin/subjects/Form.vue'

// Admin Voucher School
import VoucherSchoolMain from '../views/admin/voucher-school/Main.vue'
import VoucherSchoolForm from '../views/admin/voucher-school/Form.vue'

// Admin Voucher School Sessions
import VoucherSchoolSessionsMain from '../views/admin/voucher-school-sessions/Main.vue'
import VoucherSchoolSessionsDetail from '../views/admin/voucher-school-sessions/Detail.vue'
import VoucherSchoolSessionsLog from '../views/admin/voucher-school-sessions/Log.vue'
import VoucherSchoolSessionsForm from '../views/admin/voucher-school-sessions/Form.vue'

// SKD Category 
import SkdCategoryMain from '../views/admin/skd/category/Main.vue'

// SKD Sub Category 
import SkdSubCategoryMain from '../views/admin/skd/sub-category/Main.vue'

// SKD Theory CPNS
import SkdTheoryCpnsMain from '../views/admin/skd/cpns-theory/Main.vue'
import SkdTheoryCpnsForm from '../views/admin/skd/cpns-theory/Form.vue'
import SkdTheoryCpnsCopy from '../views/admin/skd/cpns-theory/Copy.vue'

// SKD Tryout CPNS
import SkdTryoutCpnsMain from '../views/admin/skd/cpns-tryout/Main.vue'
import SkdTryoutCpnsForm from '../views/admin/skd/cpns-tryout/Form.vue'
import SkdTryoutCpnsQuestions from '../views/admin/skd/cpns-tryout/Questions.vue'
import SkdTryoutCpnsImport from '../views/admin/skd/cpns-tryout/Import.vue'
import SkdTryoutCpnsGenerate from '../views/admin/skd/cpns-tryout/Generate.vue'

// SKD Package CPNS
import SkdPackageCpnsMain from '../views/admin/skd/cpns-package/Main.vue'
import SkdPackageCpnsForm from '../views/admin/skd/cpns-package/Form.vue'

// SKD Theory Kedinasan
import SkdTheoryKedinasanMain from '../views/admin/skd/kedinasan-theory/Main.vue'
import SkdTheoryKedinasanForm from '../views/admin/skd/kedinasan-theory/Form.vue'
import SkdTheoryKedinasanCopy from '../views/admin/skd/kedinasan-theory/Copy.vue'

// SKD Tryout Kedinasan
import SkdTryoutKedinasanMain from '../views/admin/skd/kedinasan-tryout/Main.vue'
import SkdTryoutKedinasanForm from '../views/admin/skd/kedinasan-tryout/Form.vue'
import SkdTryoutKedinasanQuestions from '../views/admin/skd/kedinasan-tryout/Questions.vue'
import SkdTryoutKedinasanImport from '../views/admin/skd/kedinasan-tryout/Import.vue'
import SkdTryoutKedinasanGenerate from '../views/admin/skd/kedinasan-tryout/Generate.vue'

// SKD Package Kedinasan
import SkdPackageKedinasanMain from '../views/admin/skd/kedinasan-package/Main.vue'
import SkdPackageKedinasanForm from '../views/admin/skd/kedinasan-package/Form.vue'

// SKB Formation
import SkbFormationMain from '../views/admin/skb/formation/Main.vue'
import SkbFormationForm from '../views/admin/skb/formation/Form.vue'

// SKB Theory 
import SkbTheoryMain from '../views/admin/skb/theory/Main.vue'
import SkbTheoryForm from '../views/admin/skb/theory/Form.vue'

// SKB Tryout 
import SkbTryoutMain from '../views/admin/skb/tryout/Main.vue'
import SkbTryoutForm from '../views/admin/skb/tryout/Form.vue'
import SkbTryoutQuestions from '../views/admin/skb/tryout/Questions.vue'
import SkbTryoutImport from '../views/admin/skb/tryout/Import.vue'

// SKB Package
import SkbPackageMain from '../views/admin/skb/package/Main.vue'
import SkbPackageForm from '../views/admin/skb/package/Form.vue'

// PPPK Category
import PppkCategoryMain from '../views/admin/pppk/category/Main.vue'
import PppkCategoryForm from '../views/admin/pppk/category/Form.vue'

// AKM Category
import AkmCategoryMain from '../views/admin/akm/category/Main.vue'
import AkmCategoryForm from '../views/admin/akm/category/Form.vue'

// AKM QuestionCategory
import AkmQuestionCategoryMain from '../views/admin/akm/question-category/Main.vue'
import AkmQuestionCategoryForm from '../views/admin/akm/question-category/Form.vue'

// Akm Theory 
import AkmTheoryMain from '../views/admin/akm/theory/Main.vue'
import AkmTheoryForm from '../views/admin/akm/theory/Form.vue'

// Akm Tryout 
import AkmTryoutMain from '../views/admin/akm/tryout/Main.vue'
import AkmTryoutForm from '../views/admin/akm/tryout/Form.vue'
import AkmTryoutQuestions from '../views/admin/akm/tryout/Questions.vue'

// PPPK Theory 
import PppkTheoryMain from '../views/admin/pppk/theory/Main.vue'
import PppkTheoryForm from '../views/admin/pppk/theory/Form.vue'

// PPPK Tryout 
import PppkTryoutMain from '../views/admin/pppk/tryout/Main.vue'
import PppkTryoutForm from '../views/admin/pppk/tryout/Form.vue'
import PppkTryoutQuestions from '../views/admin/pppk/tryout/Questions.vue'
import PppkTryoutQuestionsGenerate from '../views/admin/pppk/tryout/Generate.vue'
import PppkTryoutComponent from '../views/admin/pppk/tryout/Component.vue'
import PppkTryoutImport from '../views/admin/pppk/tryout/Import.vue'

// PPPK Package
import PppkPackageMain from '../views/admin/pppk/package/Main.vue'
import PppkPackageForm from '../views/admin/pppk/package/Form.vue'

// EnglishAcademy Category
import EnglishAcademyCategoryMain from '../views/admin/english_academy/category/Main.vue'
import EnglishAcademyCategoryForm from '../views/admin/english_academy/category/Form.vue'

// EnglishAcademy Theory 
import EnglishAcademyTheoryMain from '../views/admin/english_academy/theory/Main.vue'
import EnglishAcademyTheoryForm from '../views/admin/english_academy/theory/Form.vue'

// EnglishAcademy Tryout 
import EnglishAcademyTryoutMain from '../views/admin/english_academy/tryout/Main.vue'
import EnglishAcademyTryoutForm from '../views/admin/english_academy/tryout/Form.vue'
import EnglishAcademyTryoutQuestions from '../views/admin/english_academy/tryout/Questions.vue'
import EnglishAcademyTryoutImport from '../views/admin/english_academy/tryout/Import.vue'
import EnglishAcademyCertificate from '../views/admin/english_academy/tryout/Certificate.vue'

// EnglishAcademy Package
import EnglishAcademyPackageMain from '../views/admin/english_academy/package/Main.vue'
import EnglishAcademyPackageForm from '../views/admin/english_academy/package/Form.vue'

// UTBK Category
import UtbkCategoryMain from '../views/admin/utbk/category/Main.vue'
import UtbkCategoryForm from '../views/admin/utbk/category/Form.vue'

// UTBK Sub Category
import UtbkSubCategoryMain from '../views/admin/utbk/sub-category/Main.vue'
import UtbkSubCategoryForm from '../views/admin/utbk/sub-category/Form.vue'

// UTBK Theory 
import UtbkTheoryMain from '../views/admin/utbk/theory/Main.vue'
import UtbkTheoryForm from '../views/admin/utbk/theory/Form.vue'

// UTBK Tryout 
import UtbkTryoutMain from '../views/admin/utbk/tryout/Main.vue'
import UtbkTryoutForm from '../views/admin/utbk/tryout/Form.vue'
import UtbkTryoutComponent from '../views/admin/utbk/tryout/Component.vue'
import UtbkTryoutQuestions from '../views/admin/utbk/tryout/Questions.vue'
import UtbkTryoutImport from '../views/admin/utbk/tryout/Import.vue'
import UtbkTryoutQuestionsGenerate from '../views/admin/utbk/tryout/Generate.vue'

// UTBK Package
import UtbkPackageMain from '../views/admin/utbk/package/Main.vue'
import UtbkPackageForm from '../views/admin/utbk/package/Form.vue'

// UTBK Collage
import UtbkCollageMain from '../views/admin/utbk/collage/Main.vue'
import UtbkCollageForm from '../views/admin/utbk/collage/Form.vue'
import UtbkCollageManageProdi from '../views/admin/utbk/collage/ManageProdi.vue'

// SKD Collage
import SkdCollageMain from '../views/admin/skd/collage/Main.vue'
import SkdCollageForm from '../views/admin/skd/collage/Form.vue'
import SkdCollageManageProdi from '../views/admin/skd/collage/ManageProdi.vue'

// Member Package
import PackageCpns from '../views/member/cpns/package/Main.vue'
import PackageKedinasan from '../views/member/kedinasan/package/Main.vue'
import PackageUtbk from '../views/member/utbk/package/Main.vue'
import PackagePppk from '../views/member/pppk/package/Main.vue'
import PackageAkm from '../views/member/akm/package/Main.vue'
import PackageDetailPppk from '../views/member/pppk/package/Detail.vue'
import MyPackagePppk from '../views/member/pppk/package/My.vue'
import MyPackageDetailPppk from '../views/member/pppk/package/MyDetail.vue'
import PackageEnglishAcademy from '../views/member/english_academy/package/Main.vue'
import MyPackageEnglishAcademy from '../views/member/english_academy/package/My.vue'
import MyPackageDetailEnglishAcademy from '../views/member/english_academy/package/MyDetail.vue'

// English Academy Theory
import EnglishAcademyTheoryDetail from '../views/member/english_academy/theory/Detail.vue'

// English Academy Tryout
import EnglishAcademyTryoutOverview from '../views/member/english_academy/tryout/Overview.vue'
import EnglishAcademyTryoutStart from '../views/member/english_academy/tryout/Start.vue'
import EnglishAcademyTryoutResultDetail from '../views/member/english_academy/tryout/ResultDetail.vue'

// Member Materi CPNS
import MateriTextCpnsMain from '../views/member/cpns/materi/Main.vue'
import MateriTextCpnsDetail from '../views/member/cpns/materi/Detail.vue'
import MateriVideoCpnsMain from '../views/member/cpns/materi/MainVideo.vue'
import MateriVideoCpnsDetail from '../views/member/cpns/materi/DetailVideo.vue'

// Member Materi Kedinasan
import MateriTextKedinasanMain from '../views/member/kedinasan/materi/Main.vue'
import MateriTextKedinasanDetail from '../views/member/kedinasan/materi/Detail.vue'
import MateriVideoKedinasanMain from '../views/member/kedinasan/materi/MainVideo.vue'
import MateriVideoKedinasanDetail from '../views/member/kedinasan/materi/DetailVideo.vue'

// Member Materi Utbk
import MateriTextUtbkMainSaintek from '../views/member/utbk/materi/MainSaintek.vue'
import MateriVideoUtbkMainSaintek from '../views/member/utbk/materi/MainVideoSaintek.vue'

// Member Materi Utbk
import MateriTextUtbkMainSoshum from '../views/member/utbk/materi/MainSoshum.vue'
import MateriVideoUtbkMainSoshum from '../views/member/utbk/materi/MainVideoSoshum.vue'

import MateriTextUtbkDetail from '../views/member/utbk/materi/Detail.vue'
import MateriVideoUtbkDetail from '../views/member/utbk/materi/DetailVideo.vue'

// Tryout CPNS
import TryoutFreeCpns from '../views/member/cpns/tryout/MainFree.vue'
import TryoutPremium from '../views/member/cpns/tryout/MainPremium.vue'
import TryoutOverview from '../views/member/cpns/tryout/Overview.vue'
import TryoutStartQuiz from '../views/member/cpns/tryout/StartQuiz.vue'
import TryoutResultQuiz from '../views/member/cpns/tryout/ResultQuiz.vue'
import TryoutRank from '../views/member/cpns/tryout/RankQuiz.vue'
import TryoutResultQuizDetail from '../views/member/cpns/tryout/ResultQuizDetail.vue'

// Tryout Kedinasan
import KedinasanTryoutFree from '../views/member/kedinasan/tryout/MainFree.vue'
import KedinasanTryoutPremium from '../views/member/kedinasan/tryout/MainPremium.vue'
import KedinasanTryoutOverview from '../views/member/kedinasan/tryout/Overview.vue'
import KedinasanTryoutStartQuiz from '../views/member/kedinasan/tryout/StartQuiz.vue'
import KedinasanTryoutRank from '../views/member/kedinasan/tryout/RankQuiz.vue'
import KedinasanTryoutResultQuiz from '../views/member/kedinasan/tryout/ResultQuiz.vue'
import KedinasanTryoutResultQuizDetail from '../views/member/kedinasan/tryout/ResultQuizDetail.vue'
import KedinasanTryoutSchool from '../views/member/kedinasan/tryout/MainSchool.vue'
import KedinasanTryoutRankSchool from '../views/member/kedinasan/tryout/RankQuizSchool.vue'

// Tryout SNBT
import UtbkTryoutFree from '../views/member/utbk/tryout/MainFree.vue'
import UtbkTryoutPremium from '../views/member/utbk/tryout/MainPremium.vue'
import UtbkTryoutSchool from '../views/member/utbk/tryout/MainSchool.vue'
import UtbkTryoutOverview from '../views/member/utbk/tryout/Overview.vue'
import UtbkTryoutBreak from '../views/member/utbk/tryout/Break.vue'
import UtbkTryoutStartQuiz from '../views/member/utbk/tryout/StartQuiz.vue'
import UtbkTryoutRank from '../views/member/utbk/tryout/RankQuiz.vue'
import UtbkTryoutRankSchool from '../views/member/utbk/tryout/RankQuizSchool.vue'
import UtbkTryoutResultQuiz from '../views/member/utbk/tryout/ResultQuiz.vue'
import UtbkTryoutResultQuizDetail from '../views/member/utbk/tryout/ResultQuizDetail.vue'

// Exercises CPNS
import ExercisesCpns from '../views/member/cpns/exercises/Main.vue'
import ExercisesOverview from '../views/member/cpns/exercises/Overview.vue'
import ExercisesStartQuiz from '../views/member/cpns/exercises/StartQuiz.vue'
import ExercisesResultQuiz from '../views/member/cpns/exercises/ResultQuiz.vue'
import ExercisesResultQuizDetail from '../views/member/cpns/exercises/ResultQuizDetail.vue'

// Exercises CPNS
import ExercisesUtbk from '../views/member/utbk/exercises/Main.vue'
import ExercisesUtbkOverview from '../views/member/utbk/exercises/Overview.vue'
import ExercisesUtbkStartQuiz from '../views/member/utbk/exercises/StartQuiz.vue'
import ExercisesUtbkResultQuiz from '../views/member/utbk/exercises/ResultQuiz.vue'
import ExercisesUtbkResultQuizDetail from '../views/member/utbk/exercises/ResultQuizDetail.vue'

// Exercises Kedinasan
import ExercisesKedinasan from '../views/member/kedinasan/exercises/Main.vue'
import KedinasanExercisesResultQuiz from '../views/member/kedinasan/exercises/ResultQuiz.vue'

// Group Discuss
import SkdGroupCpns from '../views/admin/skd/group/FormCpns.vue'
import SkdGroupKedinasan from '../views/admin/skd/group/FormKedinasan.vue'
import SkdGroupUtbk from '../views/admin/utbk/group/FormUtbk.vue'
import GroupPppk from '../views/admin/pppk/group/Form.vue'

// Tryout Free Confirmation
import SkdConfirmFreeTryoutCpns from '../views/admin/free-tryout/Cpns.vue'
import SkdConfirmFreeTryoutKedinasan from '../views/admin/free-tryout/Kedinasan.vue'
import SkdConfirmFreeTryoutUtbk from '../views/admin/free-tryout/Utbk.vue'
import AkmConfirmFreeTryout from '../views/admin/free-tryout/Akm.vue'

// Exercises Free Confirmation
import ConfirmFreeExercisesUtbk from '../views/admin/free-exercises/Utbk.vue'
import ConfirmFreeExercisesKedinasan from '../views/admin/free-exercises/Kedinasan.vue'

// Disc Tryout 
import DiscTryoutMain from '../views/admin/psikotest/disc/tryout/Main.vue'
import DiscTryoutForm from '../views/admin/psikotest/disc/tryout/Form.vue'
import DiscTryoutQuestions from '../views/admin/psikotest/disc/tryout/Questions.vue'

// IST Tryout 
import IstTryoutMain from '../views/admin/psikotest/ist/tryout/Main.vue'
import IstTryoutForm from '../views/admin/psikotest/ist/tryout/Form.vue'
import IstTryoutComponent from '../views/admin/psikotest/ist/tryout/Component.vue'
import IstTryoutQuestions from '../views/admin/psikotest/ist/tryout/Questions.vue'
import IstTryoutImport from '../views/admin/psikotest/ist/tryout/Import.vue'

// Psikotest Package
import PsikotestPackageMain from '../views/admin/psikotest/package/Main.vue'
import PsikotestPackageForm from '../views/admin/psikotest/package/Form.vue'

import Notfound from '../views/static/Notfound.vue'
import UpgradePackage from '../views/static/UpgradePackage.vue'
import UsersCart from '../views/static/UsersCart.vue'
import Voucher from '../views/static/Voucher.vue'
import ProfileWeb from '../views/static/ProfileWeb.vue'
import UsersReferral from '../views/static/Referral.vue'
import UsersReferralRedeem from '../views/static/ReferralRedeem.vue'

import InvoiceList from '../views/member/invoice/Main.vue'
import InvoiceDetail from '../views/member/invoice/Detail.vue'
import InvoiceInfo from '../views/member/invoice/Info.vue'

// Faq Form
import FaqMain from '../views/admin/faq/Main.vue'
import FaqForm from '../views/admin/faq/Form.vue'

// Testimoni Form
import TestimoniMain from '../views/admin/testimoni/Main.vue'
import TestimoniForm from '../views/admin/testimoni/Form.vue'

// Banner Form
import BannerMain from '../views/admin/banner/Main.vue'
import BannerForm from '../views/admin/banner/Form.vue'

// SKD Exercises CPNS
import SkdExercisesCpnsMain from '../views/admin/skd/cpns-exercises/Main.vue'
import SkdExercisesCpnsForm from '../views/admin/skd/cpns-exercises/Form.vue'
import SkdExercisesCpnsQuestions from '../views/admin/skd/cpns-exercises/Questions.vue'
import SkdExercisesCpnsImport from '../views/admin/skd/cpns-exercises/Import.vue'
import SkdExercisesCpnsGenerate from '../views/admin/skd/cpns-exercises/Generate.vue'

// Utbk Exercises 
import UtbkExercisesMain from '../views/admin/utbk/exercises/Main.vue'
import UtbkExercisesForm from '../views/admin/utbk/exercises/Form.vue'
import UtbkExercisesQuestions from '../views/admin/utbk/exercises/Questions.vue'
import UtbkExercisesImport from '../views/admin/utbk/exercises/Import.vue'
import UtbkExercisesGenerate from '../views/admin/utbk/exercises/Generate.vue'

// SKD Exercises Kedinasan
import SkdExercisesKedinasanMain from '../views/admin/skd/kedinasan-exercises/Main.vue'
import SkdExercisesKedinasanForm from '../views/admin/skd/kedinasan-exercises/Form.vue'
import SkdExercisesKedinasanQuestions from '../views/admin/skd/kedinasan-exercises/Questions.vue'
import SkdExercisesKedinasanImport from '../views/admin/skd/kedinasan-exercises/Import.vue'
import SkdExercisesKedinasanGenerate from '../views/admin/skd/kedinasan-exercises/Generate.vue'

// Pppk Exercises 
import PppkExercisesMain from '../views/admin/pppk/exercises/Main.vue'
import PppkExercisesForm from '../views/admin/pppk/exercises/Form.vue'
import PppkExercisesQuestions from '../views/admin/pppk/exercises/Questions.vue'
import PppkExercisesImport from '../views/admin/pppk/exercises/Import.vue'
import PppkExercisesGenerate from '../views/admin/pppk/exercises/Generate.vue'

// Member Theory PPPK
import MateriPppkDetail from '../views/member/pppk/theory/Detail.vue'

// Member Tryout PPPK
import PppkTryoutOverview from '../views/member/pppk/tryout/Overview.vue'
import PppkTryoutStartQuiz from '../views/member/pppk/tryout/StartQuiz.vue'
import PppkTryoutResultDetail from '../views/member/pppk/tryout/ResultQuizDetail.vue'
import PppkTryoutRank from '../views/member/pppk/tryout/RankQuiz.vue'
import PppkTryoutResult from '../views/member/pppk/tryout/ResultQuiz.vue'

// Member Tryout PPPK
import PppkExercisesOverview from '../views/member/pppk/exercises/Overview.vue'
import PppkExercisesStartQuiz from '../views/member/pppk/exercises/StartQuiz.vue'
import PppkExercisesResult from '../views/member/pppk/exercises/ResultQuiz.vue'
import PppkExercisesResultQuizDetail from '../views/member/pppk/exercises/ResultQuizDetail.vue'

// Akm Exercises 
import AkmExercisesMain from '../views/admin/akm/exercises/Main.vue'
import AkmExercisesForm from '../views/admin/akm/exercises/Form.vue'
import AkmExercisesQuestions from '../views/admin/akm/exercises/Questions.vue'
import AkmExercisesImport from '../views/admin/akm/exercises/Import.vue'
import AkmExercisesGenerate from '../views/admin/akm/exercises/Generate.vue'

// Akm Package
import AkmPackageMain from '../views/admin/akm/package/Main.vue'
import AkmPackageForm from '../views/admin/akm/package/Form.vue'
import GroupAkm from '../views/admin/akm/group/Form.vue'

// Member Materi Sekolah
import MateriAkmMain from '../views/member/akm/theory/Main.vue'
import MateriAkmDetail from '../views/member/akm/theory/Detail.vue'

// Tryout AMM
import AkmTryoutFree from '../views/member/akm/tryout/MainFree.vue'
import AkmTryoutPremium from '../views/member/akm/tryout/MainPremium.vue'
import AkmTryoutOverview from '../views/member/akm/tryout/Overview.vue'
import AkmTryoutStart from '../views/member/akm/tryout/StartQuiz.vue'
import AkmTryoutResultQuiz from '../views/member/akm/tryout/ResultQuiz.vue'
import AkmTryoutResultQuizDetail from '../views/member/akm/tryout/ResultQuizDetail.vue'
import AkmTryoutRank from '../views/member/akm/tryout/RankQuiz.vue'

// Tryout CBT
import CbtTryoutPremium from '../views/member/cbt/tryout/MainPremium.vue'
import CbtTryoutOverview from '../views/member/cbt/tryout/Overview.vue'
import CbtTryoutStart from '../views/member/cbt/tryout/StartQuiz.vue'
import CbtTryoutResultQuiz from '../views/member/cbt/tryout/ResultQuiz.vue'
import CbtTryoutResultQuizDetail from '../views/member/cbt/tryout/ResultQuizDetail.vue'
import CbtTryoutRank from '../views/member/cbt/tryout/RankQuiz.vue'

// Theory CBT
import MemberCbtTheoryMain from '../views/member/cbt/theory/Main.vue'

// Member Exercises AKM
import ExercisesAkm from '../views/member/akm/exercises/Main.vue'
import ExercisesAkmOverview from '../views/member/akm/exercises/Overview.vue'
import ExercisesAkmStartQuiz from '../views/member/akm/exercises/StartQuiz.vue'
import ExercisesAkmResultQuiz from '../views/member/akm/exercises/ResultQuiz.vue'
import ExercisesAkmResultQuizDetail from '../views/member/akm/exercises/ResultQuizDetail.vue'

// Member Psikotest
import MemberPsikotestMain from '../views/member/psikotest/Main.vue'
import MemberPsikotestOverview from '../views/member/psikotest/Overview.vue'
import MemberPsikotestResult from '../views/member/psikotest/Result.vue'
import MemberPsikotestDiscTryout from '../views/member/psikotest/disc/Start.vue'
import MemberPsikotestIstOverview from '../views/member/psikotest/ist/Overview.vue'
import MemberPsikotestIstTryout from '../views/member/psikotest/ist/Start.vue'
import MemberPsikotestIstBreak from '../views/member/psikotest/ist/Break.vue'

// Disc Category
import DiscCategoryMain from '../views/admin/psikotest/disc/category/Main.vue'
import DiscCategoryForm from '../views/admin/psikotest/disc/category/Form.vue'

// CBT Category
import CbtCategoryMain from '../views/admin/cbt/category/Main.vue'
import CbtCategoryForm from '../views/admin/cbt/category/Form.vue'

// CBT Sub Category
import CbtSubCategoryMain from '../views/admin/cbt/sub_category/Main.vue'
import CbtSubCategoryForm from '../views/admin/cbt/sub_category/Form.vue'

// CBT Tryout 
import CbtTryoutMain from '../views/admin/cbt/tryout/Main.vue'
import CbtTryoutForm from '../views/admin/cbt/tryout/Form.vue'
import CbtTryoutQuestions from '../views/admin/cbt/tryout/Questions.vue'
import CbtTryoutMonitoring from '../views/admin/cbt/tryout/Monitoring.vue'
import CbtTryoutImport from '../views/admin/cbt/tryout/Import.vue'

// CBT Theory 
import CbtTheoryMain from '../views/admin/cbt/theory/Main.vue'
import CbtTheoryForm from '../views/admin/cbt/theory/Form.vue'

// CBT Assignments 
import CbtAssignmentsMain from '../views/admin/cbt/assignments/Main.vue'
import CbtAssignmentsForm from '../views/admin/cbt/assignments/Form.vue'
import CbtAssignmentsCollection from '../views/admin/cbt/assignments/Collection.vue'

// Cbt Exercises 
import CbtExercisesMain from '../views/admin/cbt/exercises/Main.vue'
import CbtExercisesForm from '../views/admin/cbt/exercises/Form.vue'
import CbtExercisesQuestions from '../views/admin/cbt/exercises/Questions.vue'
import CbtExercisesImport from '../views/admin/cbt/exercises/Import.vue'
import CbtExercisesGenerate from '../views/admin/cbt/exercises/Generate.vue'
import CbtExercisesMonitoring from '../views/admin/cbt/exercises/Monitoring.vue'
import CbtExercisesViewMonitoring from '../views/admin/cbt/exercises/MonitoringClass.vue'
import CbtTryoutViewMonitoring from '../views/admin/cbt/tryout/MonitoringClass.vue'

// Member Exercises CBT
import ExercisesCbt from '../views/member/cbt/exercises/Main.vue'
import ExercisesCbtOverview from '../views/member/cbt/exercises/Overview.vue'
import ExercisesCbtStartQuiz from '../views/member/cbt/exercises/StartQuiz.vue'
import ExercisesCbtResultQuiz from '../views/member/cbt/exercises/ResultQuiz.vue'
import ExercisesCbtResultQuizDetail from '../views/member/cbt/exercises/ResultQuizDetail.vue'

// Member CBT Assignments
import MemberCbtAssignments from '../views/member/cbt/assignments/Main.vue'
import MemberCbtAssignmentsDetail from '../views/member/cbt/assignments/Detail.vue'

// Attendance Routes
import AttendanceSchoolUsers from '../views/content/attendance/Users.vue'
import AttendanceScheduleMain from '../views/content/attendance/ScheduleMain.vue'
import AttendanceScheduleSettings from '../views/content/attendance/ScheduleSettings.vue'
import AttendanceScheduleStudents from '../views/content/attendance/ScheduleStudents.vue'
import AttendanceScheduleReport from '../views/content/attendance/ScheduleReport.vue'
import AttendanceScheduleReportJournal from '../views/content/attendance/ScheduleReportJournal.vue'

import StudentAttendanceScheduleMain from '../views/member/attendance/ScheduleMain.vue'
import StudentAttendanceHistory from '../views/member/attendance/History.vue'
import StudentAttendanceDaily from '../views/member/attendance/Daily.vue'

import MonitoringAttendanceDaily from '../views/member/attendance/ProctorDaily.vue'

// Headmaster Routes
import HeadmasterTeacherMain from '../views/headmaster/TeacherMain.vue'
import HeadmasterStudentMain from '../views/headmaster/StudentMain.vue'
import HeadmasterProctorMain from '../views/headmaster/ProctorMain.vue'

import Testing from '../views/Testing.vue'
import TestingUserAgent from '../views/static/testing_user_agent.vue'

import SyncSystems from '../views/static/sync_systems.vue'

import Main from '../layouts/Main.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member','proctor','content','headmaster'],
    }
  },
  {
    path: '/testing',
    name: 'testing',
    component: Testing,
    meta: {
      transition: 'fade',
      roles:['admin','member','proctor','content'],
    }
  },
  {
    path: '/testing-user-agent',
    name: 'testing_user_agent',
    component: TestingUserAgent,
    meta: {
      transition: 'fade',
      roles:['admin','member','proctor','content'],
    }
  },
  {
    path: '/download-griya-exam',
    name: 'griya_exam',
    component: GriyaExam,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member','proctor','content'],
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/users/add',
    name: 'users_add',
    component: UsersForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/users/edit/:id',
    name: 'users_edit',
    component: UsersForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/category',
    name: 'skd_category',
    component: SkdCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/sub-category',
    name: 'skd_sub_category',
    component: SkdSubCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/cpns',
    name: 'skd_theory_cpns',
    component: SkdTheoryCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/cpns/add',
    name: 'skd_theory_cpns_add',
    component: SkdTheoryCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/cpns/copy',
    name: 'skd_theory_cpns_copy',
    component: SkdTheoryCpnsCopy,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/cpns/edit/:id',
    name: 'skd_theory_cpns_edit',
    component: SkdTheoryCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns',
    name: 'skd_tryout_cpns',
    component: SkdTryoutCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns/add',
    name: 'skd_tryout_cpns_add',
    component: SkdTryoutCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns/:id',
    name: 'skd_tryout_cpns_edit',
    component: SkdTryoutCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns/questions/:id',
    name: 'skd_tryout_cpns_questions',
    component: SkdTryoutCpnsQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns/import/:id',
    name: 'skd_tryout_cpns_import',
    component: SkdTryoutCpnsImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/cpns/generate/:id',
    name: 'skd_tryout_cpns_generate',
    component: SkdTryoutCpnsGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns/generate/:id',
    name: 'skd_exercises_cpns_generate',
    component: SkdExercisesCpnsGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/kedinasan/generate/:id',
    name: 'skd_exercises_kedinasan_generate',
    component: SkdExercisesKedinasanGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan/generate/:id',
    name: 'skd_tryout_kedinasan_generate',
    component: SkdTryoutKedinasanGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/cpns',
    name: 'skd_package_cpns',
    component: SkdPackageCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/cpns/add',
    name: 'skd_package_cpns_add',
    component: SkdPackageCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/cpns/edit/:id',
    name: 'skd_package_cpns_edit',
    component: SkdPackageCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/kedinasan',
    name: 'skd_theory_kedinasan',
    component: SkdTheoryKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/kedinasan/add',
    name: 'skd_theory_kedinasan_add',
    component: SkdTheoryKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/kedinasan/copy',
    name: 'skd_theory_kedinasan_copy',
    component: SkdTheoryKedinasanCopy,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/theory/kedinasan/edit/:id',
    name: 'skd_theory_kedinasan_edit',
    component: SkdTheoryKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan',
    name: 'skd_tryout_kedinasan',
    component: SkdTryoutKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan/add',
    name: 'skd_tryout_kedinasan_add',
    component: SkdTryoutKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan/:id',
    name: 'skd_tryout_kedinasan_edit',
    component: SkdTryoutKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan/questions/:id',
    name: 'skd_tryout_kedinasan_questions',
    component: SkdTryoutKedinasanQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/tryout/kedinasan/import/:id',
    name: 'skd_tryout_kedinasan_import',
    component: SkdTryoutKedinasanImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/kedinasan',
    name: 'skd_package_kedinasan',
    component: SkdPackageKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/kedinasan/add',
    name: 'skd_package_kedinasan_add',
    component: SkdPackageKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/package/kedinasan/edit/:id',
    name: 'skd_package_kedinasan_edit',
    component: SkdPackageKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/formation',
    name: 'skb_formation',
    component: SkbFormationMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/formation/add',
    name: 'skb_formation_add',
    component: SkbFormationForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/formation/edit/:id',
    name: 'skb_formation_edit',
    component: SkbFormationForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/theory',
    name: 'skb_theory',
    component: SkbTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/theory/add',
    name: 'skb_theory_add',
    component: SkbTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/theory/edit/:id',
    name: 'skb_theory_edit',
    component: SkbTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/tryout/',
    name: 'skb_tryout',
    component: SkbTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/tryout/add',
    name: 'skb_tryout_add',
    component: SkbTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/tryout/:id',
    name: 'skb_tryout_edit',
    component: SkbTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/tryout/questions/:id',
    name: 'skb_tryout_questions',
    component: SkbTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/tryout/import/:id',
    name: 'skb_tryout_import',
    component: SkbTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/package',
    name: 'skb_package',
    component: SkbPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/package/add',
    name: 'skb_package_add',
    component: SkbPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skb/package/edit/:id',
    name: 'skb_package_edit',
    component: SkbPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/category',
    name: 'pppk_category',
    component: PppkCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/category/add',
    name: 'pppk_category_add',
    component: PppkCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/category/edit/:id',
    name: 'pppk_category_edit',
    component: PppkCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/theory',
    name: 'pppk_theory',
    component: PppkTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/theory/add',
    name: 'pppk_theory_add',
    component: PppkTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/theory/edit/:id',
    name: 'pppk_theory_edit',
    component: PppkTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/',
    name: 'pppk_tryout',
    component: PppkTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/add',
    name: 'pppk_tryout_add',
    component: PppkTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/:id',
    name: 'pppk_tryout_edit',
    component: PppkTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/questions/:pppk_tryout_id/:id',
    name: 'pppk_tryout_questions',
    component: PppkTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/generate/:pppk_tryout_id/:id',
    name: 'pppk_tryout_questions_generate',
    component: PppkTryoutQuestionsGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/component/:id',
    name: 'pppk_tryout_component',
    component: PppkTryoutComponent,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/tryout/import/:id',
    name: 'pppk_tryout_import',
    component: PppkTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/package',
    name: 'pppk_package',
    component: PppkPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/package/add',
    name: 'pppk_package_add',
    component: PppkPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/package/edit/:id',
    name: 'pppk_package_edit',
    component: PppkPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/category',
    name: 'english_academy_category',
    component: EnglishAcademyCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/category/add',
    name: 'english_academy_category_add',
    component: EnglishAcademyCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/category/edit/:id',
    name: 'english_academy_category_edit',
    component: EnglishAcademyCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/theory',
    name: 'english_academy_theory',
    component: EnglishAcademyTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/theory/add',
    name: 'english_academy_theory_add',
    component: EnglishAcademyTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/theory/edit/:id',
    name: 'english_academy_theory_edit',
    component: EnglishAcademyTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/tryout/',
    name: 'english_academy_tryout',
    component: EnglishAcademyTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/certificate/',
    name: 'english_academy_certificate',
    component: EnglishAcademyCertificate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/tryout/add',
    name: 'english_academy_tryout_add',
    component: EnglishAcademyTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/tryout/:id',
    name: 'english_academy_tryout_edit',
    component: EnglishAcademyTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/tryout/questions/:id',
    name: 'english_academy_tryout_questions',
    component: EnglishAcademyTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/tryout/import/:id',
    name: 'english_academy_tryout_import',
    component: EnglishAcademyTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/package',
    name: 'english_academy_package',
    component: EnglishAcademyPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/package/add',
    name: 'english_academy_package_add',
    component: EnglishAcademyPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/english_academy/package/edit/:id',
    name: 'english_academy_package_edit',
    component: EnglishAcademyPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/category',
    name: 'utbk_category',
    component: UtbkCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/category/add',
    name: 'utbk_category_add',
    component: UtbkCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/category/edit/:id',
    name: 'utbk_category_edit',
    component: UtbkCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/sub_category',
    name: 'utbk_sub_category',
    component: UtbkSubCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/sub_category/add',
    name: 'utbk_sub_category_add',
    component: UtbkSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/sub_category/edit/:id',
    name: 'utbk_sub_category_edit',
    component: UtbkSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/theory',
    name: 'utbk_theory',
    component: UtbkTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/theory/add',
    name: 'utbk_theory_add',
    component: UtbkTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/theory/edit/:id',
    name: 'utbk_theory_edit',
    component: UtbkTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/',
    name: 'utbk_tryout',
    component: UtbkTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/add',
    name: 'utbk_tryout_add',
    component: UtbkTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/:id',
    name: 'utbk_tryout_edit',
    component: UtbkTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/manage/:id',
    name: 'utbk_tryout_component',
    component: UtbkTryoutComponent,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/questions/:utbk_tryout_id/:id',
    name: 'utbk_tryout_questions',
    component: UtbkTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/generate/:utbk_tryout_id/:id',
    name: 'utbk_tryout_questions_generate',
    component: UtbkTryoutQuestionsGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/tryout/import/:id',
    name: 'utbk_tryout_import',
    component: UtbkTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },  
  {
    path: '/utbk/package',
    name: 'utbk_package',
    component: UtbkPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/package/add',
    name: 'utbk_package_add',
    component: UtbkPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/package/edit/:id',
    name: 'utbk_package_edit',
    component: UtbkPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },  
  {
    path: '/member/materi/cpns/text',
    name: 'member_materi_cpns_text',
    component: MateriTextCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/cpns/text/detail/:slug',
    name: 'member_materi_cpns_text_detail',
    component: MateriTextCpnsDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/cpns/video',
    name: 'member_materi_cpns_video',
    component: MateriVideoCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/cpns/video/detail/:slug',
    name: 'member_materi_cpns_video_detail',
    component: MateriVideoCpnsDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket/cpns',
    name: 'member_paket_cpns',
    component: PackageCpns,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket/utbk',
    name: 'member_paket_utbk',
    component: PackageUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cpns/free',
    name: 'member_tryout_cpns_free',
    component: TryoutFreeCpns,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/overview/:type_tryout/:slug',
    name: 'member_tryout_skd_overview',
    component: TryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/rank/:type_tryout/:slug',
    name: 'member_tryout_skd_rank',
    component: TryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/result-detail/:result_id/:tryout_answer_id',
    name: 'member_tryout_skd_result_detail',
    component: TryoutResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/start/:type_tryout/:slug/:token',
    name: 'member_tryout_skd_start',
    component: TryoutStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/invoice/detail/:invoice',
    name: 'invoice_detail',
    component: InvoiceDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/invoice/info/:order_id',
    name: 'invoice_detail',
    component: InvoiceInfo,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/invoice/list',
    name: 'invoice_list',
    component: InvoiceList,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member','proctor','content','headmaster']
    }
  },
  {
    path: '/headmaster/data-teacher',
    name: 'headmaster_teacher',
    component: HeadmasterTeacherMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['headmaster']
    }
  },
  {
    path: '/headmaster/data-student',
    name: 'headmaster_student',
    component: HeadmasterStudentMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['headmaster']
    }
  },
  {
    path: '/headmaster/data-proctor',
    name: 'headmaster_proctor',
    component: HeadmasterProctorMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['headmaster']
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member','proctor','content']
    }
  },
  {
    path: '/users-members',
    name: 'users_members',
    component: UsersMainProctor,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['proctor'],
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/login-cbt/:school_id',
    name: 'signin_cbt',
    component: SigninCBT,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/confirm/password/:token',
    name: 'confirm_password',
    component: ConfirmPassword,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: Notfound,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/member/upgrade/:type',
    name: 'upgrade_package',
    component: UpgradePackage,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member']
    }
  },
  {
    path: '/skd/group/cpns',
    name: 'skd_grup_cpns',
    component: SkdGroupCpns,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/group/kedinasan',
    name: 'skd_grup_kedinasan',
    component: SkdGroupKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/group/utbk',
    name: 'skd_grup_utbk',
    component: SkdGroupUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/confirm/free/tryout/cpns',
    name: 'skd_confirm_free_tryout_cpns',
    component: SkdConfirmFreeTryoutCpns,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/confirm/free/tryout/utbk',
    name: 'skd_confirm_free_tryout_utbk',
    component: SkdConfirmFreeTryoutUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/confirm/free/tryout/kedinasan',
    name: 'skd_confirm_free_tryout_kedinasan',
    component: SkdConfirmFreeTryoutKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/tryout/cpns/premium',
    name: 'member_tryout_cpns_premium',
    component: TryoutPremium,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/users/cart',
    name: 'users_cart',
    component: UsersCart,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/tryout/result/:type_tryout',
    name: 'member_tryout_skd_result',
    component: TryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/tryout/result/cpns',
    name: 'member_tryout_cpns_result',
    component: TryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/tryout/result/kedinasan',
    name: 'member_tryout_kedinasan_result',
    component: KedinasanTryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/voucher',
    name: 'admin_voucher',
    component: Voucher,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin']
    }
  },
  {
    path: '/admin/profile/web',
    name: 'admin_profile_web',
    component: ProfileWeb,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin']
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/faq/add',
    name: 'faq_add',
    component: FaqForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/faq/edit/:id',
    name: 'faq_edit',
    component: FaqForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/testimoni',
    name: 'testimoni',
    component: TestimoniMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/testimoni/edit/:id',
    name: 'testimoni_edit',
    component: TestimoniForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/testimoni/add',
    name: 'testimoni_add',
    component: TestimoniForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/banner',
    name: 'banner',
    component: BannerMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/banner/add',
    name: 'banner_add',
    component: BannerForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/banner/edit/:id',
    name: 'banner_edit',
    component: BannerForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns',
    name: 'skd_exercises_cpns',
    component: SkdExercisesCpnsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns/add',
    name: 'skd_exercises_cpns_add',
    component: SkdExercisesCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns/:id',
    name: 'skd_exercises_cpns_edit',
    component: SkdExercisesCpnsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns/questions/:id',
    name: 'skd_exercises_cpns_questions',
    component: SkdExercisesCpnsQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/cpns/import/:id',
    name: 'skd_exercises_cpns_import',
    component: SkdExercisesCpnsImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/exercises/cpns',
    name: 'member_exercises_cpns',
    component: ExercisesCpns,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/overview/:type_exercises/:slug',
    name: 'member_exercises_skd_overview',
    component: ExercisesOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/start/:type_exercises/:slug/:token',
    name: 'member_exercises_skd_start',
    component: ExercisesStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/result/:type_exercises',
    name: 'member_exercises_skd_result',
    component: ExercisesResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/exercises/result/cpns',
    name: 'member_exercises_cpns_skd',
    component: ExercisesResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/exercises/result-detail/:result_id/:exercises_answer_id',
    name: 'member_exercises_skd_result_detail',
    component: ExercisesResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket/kedinasan',
    name: 'member_paket_kedinasan',
    component: PackageKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/kedinasan/text',
    name: 'member_materi_kedinasan_text',
    component: MateriTextKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/kedinasan/text/detail/:slug',
    name: 'member_materi_kedinasan_text_detail',
    component: MateriTextKedinasanDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/kedinasan/video',
    name: 'member_materi_kedinasan_video',
    component: MateriVideoKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/kedinasan/video/detail/:slug',
    name: 'member_materi_kedinasan_video_detail',
    component: MateriVideoKedinasanDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/kedinasan/free',
    name: 'member_tryout_kedinasan_free',
    component: KedinasanTryoutFree,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/overview/:type_tryout/:slug',
    name: 'member_tryout_skd_overview',
    component: KedinasanTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/rank/:type_tryout/:slug',
    name: 'member_tryout_skd_rank',
    component: KedinasanTryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/result-detail/:result_id/:tryout_answer_id',
    name: 'member_tryout_skd_result_detail',
    component: KedinasanTryoutResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/start/:type_tryout/:slug/:token',
    name: 'member_tryout_skd_start',
    component: KedinasanTryoutStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/kedinasan/premium',
    name: 'member_tryout_kedinasan_premium',
    component: KedinasanTryoutPremium,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/skd/exercises/kedinasan',
    name: 'skd_exercises_kedinasan',
    component: SkdExercisesKedinasanMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/kedinasan/add',
    name: 'skd_exercises_kedinasan_add',
    component: SkdExercisesKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/kedinasan/:id',
    name: 'skd_exercises_kedinasan_edit',
    component: SkdExercisesKedinasanForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/kedinasan/questions/:id',
    name: 'skd_exercises_kedinasan_questions',
    component: SkdExercisesKedinasanQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/exercises/kedinasan/import/:id',
    name: 'skd_exercises_kedinasan_import',
    component: SkdExercisesKedinasanImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/exercises/kedinasan',
    name: 'member_exercises_kedinasan',
    component: ExercisesKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/result/kedinasan',
    name: 'member_exercises_kedinasan_skd',
    component: KedinasanExercisesResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/tryout/utbk/free',
    name: 'member_tryout_utbk_free',
    component: UtbkTryoutFree,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/utbk/school',
    name: 'member_tryout_utbk_school',
    component: UtbkTryoutSchool,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/kedinasan/school',
    name: 'member_tryout_kedinasan_school',
    component: KedinasanTryoutSchool,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member','proctor'],
    }
  },
  {
    path: '/member/tryout/kedinasan/rank-school/:slug',
    name: 'member_tryout_kedinasan_rank_school',
    component: KedinasanTryoutRankSchool,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member','proctor'],
    }
  },  
  {
    path: '/member/tryout/utbk/premium',
    name: 'member_tryout_utbk_premium',
    component: UtbkTryoutPremium,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout_utbk/overview/:slug',
    name: 'member_tryout_utbk_overview',
    component: UtbkTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout_utbk/break/:slug',
    name: 'member_tryout_utbk_break',
    component: UtbkTryoutBreak,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout_utbk/start/:slug/:id',
    name: 'member_tryout_utbk_start',
    component: UtbkTryoutStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/users/referral',
    name: 'users_referral',
    component: UsersReferral,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/users/referral/redeem',
    name: 'users_referral_redeem',
    component: UsersReferralRedeem,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/tryout/utbk/result-detail/:result_id',
    name: 'member_tryout_utbk_result_detail',
    component: UtbkTryoutResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/utbk/result',
    name: 'member_tryout_utbk_result',
    component: UtbkTryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/utbk/rank/:slug',
    name: 'member_tryout_utbk_rank',
    component: UtbkTryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/utbk/rank-school/:slug',
    name: 'member_tryout_utbk_rank_school',
    component: UtbkTryoutRankSchool,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member','proctor'],
    }
  },
  {
    path: '/utbk/collage',
    name: 'utbk_collage',
    component: UtbkCollageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/collage/add',
    name: 'utbk_collage_add',
    component: UtbkCollageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/collage/edit/:id',
    name: 'utbk_collage_edit',
    component: UtbkCollageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/collage/prodi/:id',
    name: 'utbk_collage_manage_prodi',
    component: UtbkCollageManageProdi,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/materi/utbk/saintek/text',
    name: 'member_materi_saintek_text',
    component: MateriTextUtbkMainSaintek,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/utbk/text/detail/:program/:slug',
    name: 'member_materi_utbk_text_detail',
    component: MateriTextUtbkDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/utbk/saintek/video',
    name: 'member_materi_saintek_video',
    component: MateriVideoUtbkMainSaintek,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/utbk/video/detail/:program/:slug',
    name: 'member_materi_utbk_video_detail',
    component: MateriVideoUtbkDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/utbk/soshum/text',
    name: 'member_materi_soshum_text',
    component: MateriTextUtbkMainSoshum,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/utbk/soshum/video',
    name: 'member_materi_soshum_video',
    component: MateriVideoUtbkMainSoshum,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/skd/collage',
    name: 'skd_collage',
    component: SkdCollageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/collage/add',
    name: 'skd_collage_add',
    component: SkdCollageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/collage/edit/:id',
    name: 'skd_collage_edit',
    component: SkdCollageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/skd/collage/prodi/:id',
    name: 'skd_collage_manage_prodi',
    component: SkdCollageManageProdi,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises',
    name: 'utbk_exercises',
    component: UtbkExercisesMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises/utbk/add',
    name: 'utbk_exercises_add',
    component: UtbkExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises/utbk/:id',
    name: 'utbk_exercises_edit',
    component: UtbkExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises/utbk/questions/:id',
    name: 'utbk_exercises_questions',
    component: UtbkExercisesQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises/utbk/import/:id',
    name: 'utbk_exercises_import',
    component: UtbkExercisesImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/utbk/exercises/generate/:id',
    name: 'utbk_exercises_generate',
    component: UtbkExercisesGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/exercises/utbk',
    name: 'member_exercises_utbk',
    component: ExercisesUtbk,
    meta: {
        transition: 'fade',
        layout: Main,
        roles:['member'],
    }
  },
  {
    path: '/member/exercises/utbk/overview/:slug',
    name: 'member_exercises_utbk_overview',
    component: ExercisesUtbkOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/utbk/start/:slug/:token',
    name: 'member_exercises_utbk_start',
    component: ExercisesUtbkStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/utbk/result',
    name: 'member_exercises_utbk_result',
    component: ExercisesUtbkResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/exercises/utbk/result/detail/:result_id/:utbk_exercises_answer_id',
    name: 'member_exercises_utbk_result_detail',
    component: ExercisesUtbkResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/pppk/exercises',
    name: 'pppk_exercises',
    component: PppkExercisesMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/exercises/pppk/add',
    name: 'pppk_exercises_add',
    component: PppkExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/exercises/pppk/:id',
    name: 'pppk_exercises_edit',
    component: PppkExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/exercises/pppk/questions/:id',
    name: 'pppk_exercises_questions',
    component: PppkExercisesQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/exercises/pppk/import/:id',
    name: 'pppk_exercises_import',
    component: PppkExercisesImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/exercises/generate/:id',
    name: 'pppk_exercises_generate',
    component: PppkExercisesGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/pppk/group',
    name: 'pppk_group',
    component: GroupPppk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/paket/pppk',
    name: 'member_paket_pppk',
    component: PackagePppk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket/pppk/detail/:code',
    name: 'member_paket_pppk_detail',
    component: PackageDetailPppk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/pppk',
    name: 'member_paket_saya_pppk',
    component: MyPackagePppk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/pppk/detail/:id',
    name: 'member_paket_saya_pppk_detail',
    component: MyPackageDetailPppk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/pppk/text/detail/:id/:slug',
    name: 'member_materi_pppk_detail',
    component: MateriPppkDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/pppk/overview/:id/:slug',
    name: 'member_tryout_pppk_overview',
    component: PppkTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/pppk/start/:package_id/:slug/:id',
    name: 'member_tryout_pppk_start',
    component: PppkTryoutStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/pppk/result/detail/:result_id',
    name: 'member_tryout_pppk_result_detail',
    component: PppkTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/pppk/rank/:slug',
    name: 'member_tryout_pppk_rank',
    component: PppkTryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/pppk/result',
    name: 'member_pppk_result_tryout',
    component: PppkTryoutResult,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/pppk/overview/:id/:slug',
    name: 'member_exercises_pppk_overview',
    component: PppkExercisesOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/pppk/start/:id/:slug/:token',
    name: 'member_exercises_pppk_start',
    component: PppkExercisesStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/pppk/result',
    name: 'member_exercises_pppk_result',
    component: PppkExercisesResult,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/pppk/result/detail/:result_id/:pppk_exercises_answer_id',
    name: 'member_exercises_pppk_result_detail',
    component: PppkExercisesResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/akm/category',
    name: 'akm_category',
    component: AkmCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/category/add',
    name: 'akm_category_add',
    component: AkmCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/category/edit/:id',
    name: 'akm_category_edit',
    component: AkmCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/question/category',
    name: 'akm_question_category',
    component: AkmQuestionCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/question/category/add',
    name: 'akm_question_category_add',
    component: AkmQuestionCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/question/category/edit/:id',
    name: 'akm_question_category_edit',
    component: AkmQuestionCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/theory',
    name: 'akm_theory',
    component: AkmTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/theory/add',
    name: 'akm_theory_add',
    component: AkmTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/theory/edit/:id',
    name: 'akm_theory_edit',
    component: AkmTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/theory',
    name: 'cbt_theory',
    component: CbtTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/theory/add',
    name: 'cbt_theory_add',
    component: CbtTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/theory/edit/:id',
    name: 'cbt_theory_edit',
    component: CbtTheoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/assignments',
    name: 'cbt_assignments',
    component: CbtAssignmentsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/assignments/add',
    name: 'cbt_assignments_add',
    component: CbtAssignmentsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/assignments/edit/:id',
    name: 'cbt_assignments_edit',
    component: CbtAssignmentsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/assignments/collection/:id',
    name: 'cbt_assignments_collection',
    component: CbtAssignmentsCollection,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/akm/tryout/',
    name: 'akm_tryout',
    component: AkmTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/tryout/add',
    name: 'akm_tryout_add',
    component: AkmTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/tryout/:id',
    name: 'akm_tryout_edit',
    component: AkmTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/tryout/questions/:id',
    name: 'akm_tryout_questions',
    component: AkmTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises',
    name: 'akm_exercises',
    component: AkmExercisesMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises/akm/add',
    name: 'akm_exercises_add',
    component: AkmExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises/akm/:id',
    name: 'akm_exercises_edit',
    component: AkmExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises/akm/questions/:id',
    name: 'akm_exercises_questions',
    component: AkmExercisesQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises/akm/import/:id',
    name: 'akm_exercises_import',
    component: AkmExercisesImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/exercises/generate/:id',
    name: 'akm_exercises_generate',
    component: AkmExercisesGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/package',
    name: 'akm_package',
    component: AkmPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/package/add',
    name: 'akm_package_add',
    component: AkmPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/package/edit/:id',
    name: 'akm_package_edit',
    component: AkmPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/akm/group',
    name: 'akm_group',
    component: GroupAkm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/paket/akm',
    name: 'member_paket_akm',
    component: PackageAkm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/akm',
    name: 'member_materi_akm',
    component: MateriAkmMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/akm/detail/:id/:slug',
    name: 'member_materi_akm_detail',
    component: MateriAkmDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/akm/confirm/free/tryout',
    name: 'akm_confirm_free_tryout',
    component: AkmConfirmFreeTryout,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/tryout/akm/free',
    name: 'member_tryout_akm_free',
    component: AkmTryoutFree,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/overview/:slug',
    name: 'member_tryout_akm_overview',
    component: AkmTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/start/:slug',
    name: 'member_tryout_akm_start',
    component: AkmTryoutStart,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/result',
    name: 'member_tryout_akm_result',
    component: AkmTryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/result/detail/:result_id',
    name: 'member_tryout_akm_result_detail',
    component: AkmTryoutResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/rank/:slug',
    name: 'member_tryout_akm_rank',
    component: AkmTryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/akm/premium',
    name: 'member_tryout_akm_premium',
    component: AkmTryoutPremium,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/akm',
    name: 'member_exercises_akm',
    component: ExercisesAkm,
    meta: {
        transition: 'fade',
        layout: Main,
        roles:['member'],
    }
  },
  {
    path: '/member/exercises/akm/overview/:slug',
    name: 'member_exercises_akm_overview',
    component: ExercisesAkmOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/akm/start/:slug/:token',
    name: 'member_exercises_akm_start',
    component: ExercisesAkmStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/akm/result',
    name: 'member_exercises_akm_result',
    component: ExercisesAkmResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/exercises/akm/result/detail/:result_id/:akm_exercises_answer_id',
    name: 'member_exercises_akm_result_detail',
    component: ExercisesAkmResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/school',
    name: 'school',
    component: SchoolMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/school/add',
    name: 'school_add',
    component: SchoolForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/school/edit/:id',
    name: 'school_edit',
    component: SchoolForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/school/disc/:school_id',
    name: 'school_disc',
    component: SchoolDiscMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/school/disc/add/:school_id',
    name: 'school_disc_add',
    component: SchoolDiscForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/school/disc/edit/:school_id/:id',
    name: 'school_disc_edit',
    component: SchoolDiscForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest_subject/:school_id',
    name: 'psikotest_subject',
    component: PsikotestSubjectMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/subjects/:school_id',
    name: 'subjects',
    component: SubjectsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/subjects-general',
    name: 'subjects_general',
    component: SubjectsGeneral,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },

  {
    path: '/subjects/add/:school_id',
    name: 'subjects_add',
    component: SubjectsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/subjects/edit/:school_id/:id',
    name: 'subjects_edit',
    component: SubjectsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest_subject/add/:school_id',
    name: 'psikotest_subject_add',
    component: PsikotestSubjectForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest_subject/edit/:school_id/:id',
    name: 'psikotest_subject_edit',
    component: PsikotestSubjectForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/voucher-school',
    name: 'voucher_school',
    component: VoucherSchoolMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/voucher-school/add',
    name: 'voucher_school_add',
    component: VoucherSchoolForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/voucher-school/edit/:id',
    name: 'voucher_school_edit',
    component: VoucherSchoolForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/voucher-school-sessions',
    name: 'voucher_school_sessions',
    component: VoucherSchoolSessionsMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/voucher-school-sessions/add',
    name: 'voucher_school_sessions_add',
    component: VoucherSchoolSessionsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/voucher-school-sessions/edit/:id',
    name: 'voucher_school_sessions_edit',
    component: VoucherSchoolSessionsForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/voucher-school-sessions/detail/:id',
    name: 'voucher_school_sessions_detail',
    component: VoucherSchoolSessionsDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/voucher-school-sessions/log/:id',
    name: 'voucher_school_sessions_log',
    component: VoucherSchoolSessionsLog,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/utbk',
    name: 'monitoring_tryout_utbk',
    component: MonitoringTryoutUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/kedinasan',
    name: 'monitoring_tryout_kedinasan',
    component: MonitoringTryoutKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/utbk/detail/:id',
    name: 'monitoring_tryout_utbk_detail',
    component: MonitoringTryoutUtbkDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/akm',
    name: 'monitoring_tryout_akm',
    component: MonitoringTryoutAKM,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/akm/detail/:id',
    name: 'monitoring_tryout_akm_detail',
    component: MonitoringTryoutAKMDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/kedinasan/detail/:id',
    name: 'monitoring_tryout_kedinasan_detail',
    component: MonitoringTryoutKedinasanDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/confirm/free/exercise/utbk',
    name: 'confirm_free_tryout_utbk',
    component: ConfirmFreeExercisesUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/confirm/free/exercise/kedinasan',
    name: 'confirm_free_tryout_kedinasan',
    component: ConfirmFreeExercisesKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/paket/english-academy',
    name: 'member_paket_english_academy',
    component: PackageEnglishAcademy,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy',
    name: 'member_paket_saya_english_academy',
    component: MyPackageEnglishAcademy,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy/detail/:id',
    name: 'member_paket_saya_english_academy_detail',
    component: MyPackageDetailEnglishAcademy,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy/theory/:id/:code',
    name: 'member_theory_english_academy_detail',
    component: EnglishAcademyTheoryDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy/tryout/overview/:id/:code',
    name: 'member_tryout_overview_english_academy',
    component: EnglishAcademyTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy/tryout/start/:id/:code/:token',
    name: 'member_tryout_start_english_academy',
    component: EnglishAcademyTryoutStart,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/paket-saya/english-academy/tryout/result/:id/:code/:id_answer',
    name: 'member_tryout_result_english_academy',
    component: EnglishAcademyTryoutResultDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/disc/tryout/',
    name: 'disc_tryout',
    component: DiscTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/disc/tryout/add',
    name: 'disc_tryout_add',
    component: DiscTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/disc/tryout/:id',
    name: 'disc_tryout_edit',
    component: DiscTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/disc/tryout/questions/:id',
    name: 'disc_tryout_questions',
    component: DiscTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'], 
    }
  },
  {
    path: '/ist/tryout/',
    name: 'ist_tryout',
    component: IstTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/ist/tryout/add',
    name: 'ist_tryout_add',
    component: IstTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/ist/tryout/:id',
    name: 'ist_tryout_edit',
    component: IstTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/ist/tryout/manage/:id',
    name: 'ist_tryout_component',
    component: IstTryoutComponent,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/ist/tryout/questions/:ist_tryout_id/:id',
    name: 'ist_tryout_questions',
    component: IstTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/ist/tryout/import/:id',
    name: 'ist_tryout_import',
    component: IstTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest',
    name: 'psikotest_package',
    component: PsikotestPackageMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest/add',
    name: 'psikotest_package_add',
    component: PsikotestPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/psikotest/:id',
    name: 'psikotest_package_edit',
    component: PsikotestPackageForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/member/psikotest',
    name: 'member_psikotest',
    component: MemberPsikotestMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/overview/:id',
    name: 'member_psikotest_overview',
    component: MemberPsikotestOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/result/:id',
    name: 'member_psikotest_result',
    component: MemberPsikotestResult,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/disc/tryout/:id/:slug',
    name: 'member_psikotest_disc_tryout',
    component: MemberPsikotestDiscTryout,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/ist/overview/:id/:slug',
    name: 'member_psikotest_ist_overview',
    component: MemberPsikotestIstOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/ist/tryout/:psikotest_id/:slug/:id',
    name: 'member_psikotest_ist_tryout_start',
    component: MemberPsikotestIstTryout,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/psikotest/ist/break/:psikotest_id/:slug',
    name: 'member_psikotest_ist_tryout_break',
    component: MemberPsikotestIstBreak,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/disc/category',
    name: 'disc_category',
    component: DiscCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/disc/category/add',
    name: 'disc_category_add',
    component: DiscCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/disc/category/edit/:id',
    name: 'disc_category_edit',
    component: DiscCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/monitoring_psikotest',
    name: 'monitoring_psikotest',
    component: MonitoringPsikotest,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring_psikotest/detail/:id',
    name: 'monitoring_psikotest_detail',
    component: MonitoringPsikotestDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring_psikotest/class/:id',
    name: 'monitoring_psikotest_class',
    component: MonitoringPsikotestClass,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring_psikotest/detail/members/:id/:user_id',
    name: 'monitoring_psikotest_detail_members',
    component: MonitoringPsikotestDetailMembers,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring_psikotest/class-detail/:id',
    name: 'monitoring_psikotest_class_detail',
    component: MonitoringPsikotestClassDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/cbt/category',
    name: 'cbt_category',
    component: CbtCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/category/add',
    name: 'cbt_category_add',
    component: CbtCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/category/edit/:id',
    name: 'cbt_category_edit',
    component: CbtCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/sub-category',
    name: 'cbt_sub_category',
    component: CbtSubCategoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/sub-category/add',
    name: 'cbt_sub_category_add',
    component: CbtSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/sub-category/edit/:id',
    name: 'cbt_sub_category_edit',
    component: CbtSubCategoryForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/tryout/import/:id',
    name: 'cbt_tryout_import',
    component: CbtTryoutImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },  
  {
    path: '/cbt/tryout/',
    name: 'cbt_tryout',
    component: CbtTryoutMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/tryout/add',
    name: 'cbt_tryout_add',
    component: CbtTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/tryout/:id',
    name: 'cbt_tryout_edit',
    component: CbtTryoutForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/tryout/questions/:id',
    name: 'cbt_tryout_questions',
    component: CbtTryoutQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/tryout/monitoring/:id',
    name: 'cbt_tryout_monitoring',
    component: CbtTryoutMonitoring,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises',
    name: 'cbt_exercises',
    component: CbtExercisesMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises/cbt/add',
    name: 'cbt_exercises_add',
    component: CbtExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises/cbt/:id',
    name: 'cbt_exercises_edit',
    component: CbtExercisesForm,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises/cbt/questions/:id',
    name: 'cbt_exercises_questions',
    component: CbtExercisesQuestions,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises/monitoring/:id',
    name: 'cbt_exercises_monitoring',
    component: CbtExercisesMonitoring,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/exercises/cbt/import/:id',
    name: 'cbt_exercises_import',
    component: CbtExercisesImport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/exercises/generate/:id',
    name: 'cbt_exercises_generate',
    component: CbtExercisesGenerate,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin'],
    }
  },
  {
    path: '/cbt/exercises/monitoring-class',
    name: 'cbt_exercises_view_monitoring',
    component: CbtExercisesViewMonitoring,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/cbt/tryout-monitoring-class',
    name: 'cbt_tryout_view_monitoring',
    component: CbtTryoutViewMonitoring,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','content'],
    }
  },
  {
    path: '/member/tryout/cbt',
    name: 'member_tryout_cbt',
    component: CbtTryoutPremium,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cbt/overview/:slug',
    name: 'member_tryout_cbt_overview',
    component: CbtTryoutOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cbt/start/:slug',
    name: 'member_tryout_cbt_start',
    component: CbtTryoutStart,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cbt/result',
    name: 'member_tryout_cbt_result',
    component: CbtTryoutResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cbt/result/detail/:result_id',
    name: 'member_tryout_cbt_result_detail',
    component: CbtTryoutResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/tryout/cbt/rank/:slug',
    name: 'member_tryout_cbt_rank',
    component: CbtTryoutRank,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/materi/cbt',
    name: 'member_materi_cbt',
    component: MemberCbtTheoryMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/monitoring/tryout/cbt_overview',
    name: 'monitoring_tryout_cbt_overview',
    component: MonitoringTryoutCBTOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/tryout/cbt/:class',
    name: 'monitoring_tryout_cbt',
    component: MonitoringTryoutCBT,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/voucher',
    name: 'monitoring_voucher',
    component: MonitoringVoucher,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/voucher_school',
    name: 'monitoring_voucher_school',
    component: MonitoringVoucherSchool,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor'],
    }
  },
  {
    path: '/monitoring/voucher_school_content',
    name: 'monitoring_voucher_school_content',
    component: MonitoringVoucherSchoolContent,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/view/naskah/tryout/cbt',
    name: 'view_naskah_tryout_cbt',
    component: ViewNaskahTryoutCBT,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/view/naskah/tryout/cbt/detail/:id',
    name: 'view_naskah_tryout_cbt_detail',
    component: ViewDetailNaskahTryoutCBT,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/view/naskah/exercises/cbt/detail/:id',
    name: 'view_naskah_exercises_cbt_detail',
    component: ViewDetailNaskahExercisesCBT,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/member/exercises/cbt',
    name: 'member_exercises_cbt',
    component: ExercisesCbt,
    meta: {
        transition: 'fade',
        layout: Main,
        roles:['member'],
    }
  },
  {
    path: '/member/exercises/cbt/overview/:slug',
    name: 'member_exercises_cbt_overview',
    component: ExercisesCbtOverview,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/cbt/start/:slug/:token',
    name: 'member_exercises_cbt_start',
    component: ExercisesCbtStartQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/exercises/cbt/result',
    name: 'member_exercises_cbt_result',
    component: ExercisesCbtResultQuiz,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member']
    }
  },
  {
    path: '/member/exercises/cbt/result/detail/:result_id/:cbt_exercises_answer_id',
    name: 'member_exercises_cbt_result_detail',
    component: ExercisesCbtResultQuizDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/cbt/assignments',
    name: 'member_cbt_assignments',
    component: MemberCbtAssignments,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/member/cbt/assignments/detail/:code',
    name: 'member_cbt_assignments_detail',
    component: MemberCbtAssignmentsDetail,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/attendance/school-users',
    name: 'school_users_attendance',
    component: AttendanceSchoolUsers,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/attendance/schedule',
    name: 'schedule_attendance_main',
    component: AttendanceScheduleMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/attendance/schedule-settings',
    name: 'schedule_attendance_form',
    component: AttendanceScheduleSettings,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/attendance/schedule-students/:id',
    name: 'schedule_attendance_student',
    component: AttendanceScheduleStudents,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/attendance/schedule-report',
    name: 'schedule_attendance_report',
    component: AttendanceScheduleReport,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/attendance/schedule-report-journal',
    name: 'schedule_attendance_report_journal',
    component: AttendanceScheduleReportJournal,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['content'],
    }
  },
  {
    path: '/student/attendance/schedule',
    name: 'student_schedule_attendance',
    component: StudentAttendanceScheduleMain,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/student/attendance/history',
    name: 'student_history_attendance',
    component: StudentAttendanceHistory,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/student/attendance/daily',
    name: 'student_daily_attendance',
    component: StudentAttendanceDaily,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['member'],
    }
  },
  {
    path: '/monitoring/attendance/daily',
    name: 'monitoring_daily_attendance',
    component: MonitoringAttendanceDaily,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['proctor'],
    }
  },
  {
    path: '/sync-systems',
    name: 'sync_systems',
    component: SyncSystems,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','member','proctor','content'],
    }
  },
  {
    path: '/view/naskah/tryout/kedinasan/detail/:id',
    name: 'view_naskah_tryout_kedinasan_detail',
    component: ViewDetailNaskahTryoutKedinasan,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  },
  {
    path: '/view/naskah/tryout/utbk/detail/:id',
    name: 'view_naskah_tryout_utbk_detail',
    component: ViewDetailNaskahTryoutUtbk,
    meta: {
      transition: 'fade',
      layout: Main,
      roles:['admin','proctor','content'],
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin','signup','signout','notfound','forgot_password','confirm_password','signin_cbt','testing','testing_user_agent'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('auth_token');

  if (authRequired && token) {
      Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
          headers: {
              'Authorization': token
          }
      }).then(response => {
        if(response.data.logged_in) {
          let roles = response.data.type;
                  
          if(to.meta.roles.includes(roles)) {
              next();
          } else {
              alert("Akses tidak diperbolehkan");
              next({ name: 'home' });
          }
        } else {
          alert("Sesi login telah habis !");
          localStorage.removeItem('id');
          localStorage.removeItem('auth_token');
          next({ name: 'signin' });
        }
    }).catch(function (error) {
        console.debug(error);
    });
  }

  else if (authRequired && !token) {
    next({ name: 'signin' });
  }

  else {
    if(to.name == 'signin' || to.name == 'signup' || to.name == 'signin_cbt') {
        if(token) {
            next({ name: 'home' });
        } else {
            next();
        }
    }
    else {
        if(!token) {
            if(to.name == 'signout') {
                next({ name: 'signin' });
            } else {
                next();
            }
        } else {
            next();
        }
    }
  }
});

export default router