<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header" v-if="!dataFetch && dataDetail.show_result == 0">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4>Hasil Tes masih ditutup untuk saat ini</h4>
                            <img style="width:50%" src="assets/images/break.jpg" alt="" class="img-fluid mb-4">
                            <h4 class="m-t-10">
                                Hasil dapat dilihat pada tanggal
                            </h4>
                            <h4 class="m-t-10">
                                {{dataDetail.text_result_date}}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch && dataDetail.show_result == 1">
            <div v-if="!dataFetch && dataDetail.finished" class="alert alert-info mb-4" role="alert">
                <b>Keterangan Umum</b>
                <br/>
                <ul class="mb-0">
                    <li>
                        Penilaian ini, berdasarkan pada perpekstif intelektual. Ketika memiliki data rendah, bukan berati siswa tidak punya potensi. Siswa punya potensi, namun dominan tidak pada sisi IQ (Intelektual Qoetions). Bisa jadi dari segi EQ (Emotional Quoetion), SQ (Spritual Queotion) maupun AQ (Adversity Queotion), Maupun TQ (Trancedental Quoetion).                                            
                    </li>
                    <li>
                        Data Hasil siswa secara spesifik sangat berpengaruh kondisi Diri siswa ketika sedang pelaksanaan test. Maka, upayakan ketika test kondisi anak stabil serta sehat jasmani dan rohani dalam kondisi baik.                                            
                    </li>
                    <li>
                        Disetiap diri siswa memiliki potensi yang luar biasa sebagai hamba ciptaaNYA. Data ini bagian kewajiban manusia untuk ikhtiyar mencari potensi diri sebagai landasan masa depan.                                            
                    </li>
                </ul>
            </div>

            <div v-if="!dataFetch" class="page-header">
                <div v-if="!dataDetail.finished" class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title text-center">
                                <img style="width:40%" src="assets/images/horay.jpg" alt="" class="img-fluid mb-4">
                                <h5 class="m-t-10">
                                    Selamat, {{fullname}}!<br/>
                                    Kamu telah berhasil mengerjakan semua tes.
                                </h5>
                                <div>
                                    <p>Silahkan klik tombol dibawah ini untuk melihat hasil</p>
                                    <button class="btn btn-outline-primary rounded" type="button" data-toggle="modal" data-target="#modalForm"><i class="fa fa-list-alt"></i> Lihat Hasil Tes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="dataDetail.finished" class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Hasil Tes Psikotes </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Nama Lengkap</div>
                                    <div class="font-weight-600">{{this.form.fullname}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Jenis Kelamin</div>
                                    <div class="font-weight-600">{{dataDetail.gender == 'L' ? 'Laki - laki':'Perempuan'}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Kelas</div>
                                    <div class="font-weight-600">{{dataDetail.class}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">NISN</div>
                                    <div class="font-weight-600">{{dataDetail.identity_number}}</div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2" v-if="school_info.activate_department_plan == '1'">
                                    <div class="mr-3">Rencana Pilihan Jurusan Setelah Lulus</div>
                                    <div class="font-weight-600">{{dataDetail.department_plan}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 text-center">
                            <div class="card card-theory">
                                <div class="card-body">
                                    <h4 v-if="school_info.activate_ist == '1'" class="job-card-desc text-center m-t-30">
                                        Skor IQ Total
                                    </h4>
                                    
                                    <div v-if="school_info.activate_ist == '1'" class="text-center m-t-10">
                                        <h3>{{dataDetail.ist.iq}}</h3>
                                    </div>

                                    <div v-if="school_info.activate_ist == '1'" class="text-center m-b-30">
                                        <p>
                                            <b>({{dataDetail.ist.category.name}})</b>
                                        </p>
                                    </div>
                                    <hr v-if="school_info.activate_ist == '1'"/>
                                    <button
                                    :disabled="isFetchCertificate"
                                    class="btn btn-primary rounded btn-block" type="button" v-on:click="downloadCertificate">
                                        <i v-if="isFetchCertificate" class="fa fa-spin fa-spinner"></i>
                                        <i v-if="!isFetchCertificate" class="fa fa-download"></i>
                                        {{ isFetchCertificate ? 'Loading...' : 'Unduh Sertifikat' }}
                                    </button>
                                    <a class="btn btn-outline-primary rounded btn-block" :href="'assets/download/lampiran-bakat-minat-ikm.pdf'" target="_blank">
                                        <i class="fas fa-file-pdf"></i>
                                        Unduh Lampiran Data IKM
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch && dataDetail.finished && school_info.activate_ist == '1'" class="page-header">
                <div v-if="school_info.activate_ist == '1'" class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Hasil Tes IST (Intelligenz Struktur Test)</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th style="font-size:13px !important;padding:7px !important">#</th>
                                    <th style="font-size:13px !important;padding:7px !important">RW</th>
                                    <th style="font-size:13px !important;padding:7px !important">SW</th>
                                    <th style="font-size:13px !important;padding:7px !important">Kategori</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataDetail.ist.list" :key="index">
                                    <td style="font-size:13px !important;padding:7px !important">{{item.code}}</td>
                                    <td style="font-size:13px !important;padding:7px !important">{{item.total_correct}}</td>
                                    <td style="font-size:13px !important;padding:7px !important">{{item.total_score}}</td>
                                    <td style="font-size:13px !important;padding:7px !important">{{item.category}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style="font-size:13px !important;padding:7px !important;border-bottom: 1px solid #e2e5e8;color: #222;background: #f6f7fb;text-transform: uppercase;font-weight: bolder !important;">
                                        IQ
                                    </td>
                                    <td colspan="2" style="font-size:13px !important;padding:7px !important;border-bottom: 1px solid #e2e5e8;color: #222;background: #f6f7fb;text-transform: uppercase;font-weight: bolder !important;">
                                        {{dataDetail.ist.iq}}
                                    </td>
                                    <td style="font-size:13px !important;padding:7px !important;border-bottom: 1px solid #e2e5e8;color: #222;background: #f6f7fb;text-transform: uppercase;font-weight: bolder !important;">
                                        {{dataDetail.ist.category.name}}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-theory h-100">
                            <div class="card-body h-100">
                                <h5 class="job-card-desc text-center">
                                    Grafik Hasil Tes IST
                                </h5>

                                <div>
                                    <apexchart type="line" :options="chartOptionsIST" :series="seriesIST"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch && dataDetail.finished" class="page-header">
                <div v-if="school_info.activate_disc == '1'" class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Hasil Tes DISC (Dominance, Influence, Conscitieous dan Steadiness)</h5>
                        </div>
                    </div>
                </div>

                <div v-if="school_info.activate_disc == '1'" class="row">
                    <div class="col-md-6 table-responsive">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kamu termasuk kedalam kategori:</div>
                                <div class="font-weight-600">{{dataDetail.disc.category}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Gaya Belajar:</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="font-weight-600" v-html="dataDetail.disc.analysis.learning_style"></div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Kepribadian:</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="font-weight-600" v-html="dataDetail.disc.analysis.personality"></div>
                            </div>

                            <div v-if="dataDetail.disc.analysis.passion" class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Minat:</div>
                            </div>
                            <div v-if="dataDetail.disc.analysis.passion" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="font-weight-600" v-html="dataDetail.disc.analysis.passion"></div>
                            </div>

                            <div v-if="dataDetail.disc.analysis.talent" class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3">Bakat:</div>
                            </div>
                            <div v-if="dataDetail.disc.analysis.talent" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="font-weight-600" v-html="dataDetail.disc.analysis.talent"></div>                            
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-theory h-100">
                            <div class="card-body h-100">
                                <h5 class="job-card-desc text-center">
                                    Grafik Hasil Tes DISC
                                </h5>

                                <div>
                                    <apexchart type="line" :options="chartOptionsDISC" :series="seriesDisc"></apexchart>
                                </div>

                                <hr/>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="font-size:14px !important;padding:10px !important">Keterangan</th>
                                            <th style="font-size:14px !important;padding:10px !important;width:15%;text-align:center">Poin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataDetail.disc.list" :key="index">
                                            <td style="font-size:14px !important;padding:10px !important">{{item.description}}</td>
                                            <td style="font-size:14px !important;padding:10px !important;width:15%;text-align:center">{{item.value}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Harap lengkapi form terlebih dahulu</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12">
                                <div class="m-t-10">
                                    <label>Nama: </label>
                                    <input disabled type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan nama kamu..." v-model="form.fullname"
                                    :class="{ 'is-invalid': submitted && $v.form.fullname.$error }"
                                    >
                                    <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback d-block">
                                    Nama Wajib Diisi</div>
                                </div>

                                <div class="m-t-10">
                                    <label>Jenis Kelamin: </label>
                                    <select class="form-control" v-model="form.gender" :class="{ 'is-invalid': submitted && $v.form.gender.$error }">
                                        <option value="">Pilih Jenis Kelamin</option>
                                        <option value="L">Laki - laki</option>
                                        <option value="P">Perempuan</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.gender.required" class="invalid-feedback">Harap pilih jenis kelamin!</div>
                                </div>

                                <div class="m-t-10" v-if="school_info.activate_department_plan == '1'">
                                    <label>Rencana pilihan jurusan setelah lulus: </label>
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Rencana pilihan jurusan setelah lulus kamu..." v-model="form.department_plan">
                                </div>

                                <div class="alert alert-warning m-t-10" role="alert">
                                    <b>Perhatian : </b> Pastikan data yang dimasukan telah sesuai.
                                </div>
                            </div>
                        </div>
                    </div>

                    <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan & Lihat Hasil' }}</button>
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'MemberPsikotestResult',
        metaInfo: {
            title: 'Hasil Psikotest',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                progress             : 0,
                dataFetch            : true,
                isFetchCertificate   : false,
                dataDetail  : {
                    ist  : {
                        iq : '',
                        category : {
                            name : ''
                        }
                    },
                    disc : {
                        analysis : {
                            learning_style: '',
                            personality: '',
                            passion: '',
                            talent:''                      
                        }
                    }
                },
                school_info : '',
                fullname    : '',
                form : {
                    fullname           : '',
                    gender             : '',
                    class              : '',
                    identity_number    : '',
                    department_plan    : '',
                },
                submitted   : false,
                waiting     : false,
                seriesIST: [{
                    name: "Score",
                    data: [96, 116, 109, 126, 100, 114, 113, 113, 110]
                }],
                chartOptionsIST: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#eb8c12'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                    categories: ['SE', 'WA', 'AN', 'GE', 'ME', 'RA', 'ZR', 'FA', 'WU'],
                    }
                },
                chartOptionsDISC: {
                    chart: {
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    colors: ['#eb8c12'],
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'],
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                    categories: ['Dominance', 'Influence', 'Conscitieous', 'Steadiness'],
                    }
                },
                seriesDisc: [{
                    name: "Score",
                    data: []
                }]
            }
        },
        validations: {
            form: {
                fullname: {
                    required
                },
                gender: {
                    required
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/psikotest/result';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id          : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;                
                        this.dataDetail     = res.data.data;
                        this.fullname       = res.data.fullname;
                        this.school_info       = res.data.school_info;
                        this.form.fullname  = this.fullname;

                        this.chartOptionsIST = {
                            chart: {
                                type: 'line',
                                zoom: {
                                    enabled: false
                                }
                            },
                            colors: ['#eb8c12'],
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'],
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: this.dataDetail.ist.label,
                            }
                        },

                        this.seriesIST      = [{
                            name: "Score",
                            data: this.dataDetail.ist.value
                        }];

                        this.chartOptionsDISC = {
                            chart: {
                                type: 'line',
                                zoom: {
                                    enabled: false
                                }
                            },
                            colors: ['#eb8c12'],
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'],
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: this.dataDetail.disc.label,
                            }
                        },

                        this.seriesDisc      = [{
                            name: "Score",
                            data: this.dataDetail.disc.value
                        }];

                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_psikotest_overview', 
                                    params : {
                                        id : this.$route.params.id
                                    }
                                });
                            }
                        });                        
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'member/psikotest/save-identity', {
                        id  : this.$route.params.id,
                        fullname : this.form.fullname,
                        gender : this.form.gender,
                        department_plan : this.form.department_plan
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            },
            downloadResult() {
                this.$refs.html2Pdf.generatePdf()
            },
            downloadCertificate() {
                this.isFetchCertificate = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'member/psikotest/download-certificate', {
                    params : {
                        id          : this.$route.params.id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchCertificate = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Sertifikat gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Sertifikat Hasil Tes - '+this.form.fullname}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            onProgress(progress) {
                this.progress = progress;
                console.log(`PDF generation progress: ${progress}%`)
            }
        }
    }
</script>