<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">
                                Monitoring Kehadiran Harian Siswa
                            </h5>
                            <div v-if="(!dataFetch & dataList.length > 0)" class="float-right" style="margin-top:-65px">
                                <button type="button" class="btn btn-primary rounded" v-on:click="downloadExcel()" :disabled="waiting"><i class="fa fa-file-excel"></i> {{waiting ? 'Harap tunggu...':'Download ke Excel'}}</button>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tanggal Awal</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="start_date" v-on:change="getByKeywords">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tanggal Akhir</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="end_date" v-on:change="getByKeywords">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kelas</label>
                                    <select class="form-control" v-model="school_class" v-on:change="getByKeywords">
                                        <option value="">Pilih Kelas</option>
                                        <option v-for="(item, index) in dataSchool.list_class" :key="index" :value="item">{{ item }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Cari berdasarkan nama</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:20%">Nama Siswa</th>
                                                    <th style="width:15%">Tanggal Presensi</th>
                                                    <th style="width:10%">Jam Masuk</th>
                                                    <th style="width:10%">Jam Pulang</th>
                                                    <th style="width:10%">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        {{ item.fullname }}<br/>
                                                        Kelas: {{ item.school_class ? item.school_class : '-' }}
                                                    </td>
                                                    <td>
                                                        {{item.present_date}}
                                                    </td>
                                                    <td>
                                                        {{item.jam_masuk}}
                                                    </td>
                                                    <td>
                                                        {{item.jam_keluar}}
                                                    </td>
                                                    <td>
                                                        <b>
                                                            <span v-if="item.status == 'Terlambat'" class="text-danger">{{ item.status }}</span>
                                                            <span v-if="item.status == 'Ontime'" class="text-success">{{ item.status }}</span>
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>


                                <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'DailyAttendance',
        metaInfo: {
            title: 'Kehadiran Harian Siswa',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting          : false,
                emptyState       : false,
                dataFetch        : false,
                dataList         : [],
                dataSchool       : [],
                dataFetchSchool  : false,
                start_date       : "",
                end_date         : "",
                school_class     : "",
                keywords         : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 100,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getSchool();
        },
        methods: {
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/attendance/daily';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords        : this.keywords,
                        start_date      : this.start_date,
                        end_date        : this.end_date,
                        school_class    : this.school_class,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            downloadExcel: function() {
                this.waiting = true;
                let formData = new FormData();

                formData.append('keywords', this.keywords);
                formData.append('school_class', this.school_class);
                formData.append('start_date', this.start_date);
                formData.append('end_date', this.end_date);
                
                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/attendance/daily-excel', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Tidak ada data presensi!",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Rekap Absensi Harian - '+this.school_class+' - '+this.start_date+'_'+this.end_date}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    }

                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });                
            }
        }
    }
</script>