<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Kelola Komponen - {{dataTryout.utbk_tryout.title}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'utbk_tryout'}">Tryout SNBT</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Kelola Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Data Tryout</h5>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book m-r-5"></i> Jurusan</td>
                                    <td style="border-top:0px !important; text-transform:uppercase;" class="text-right">{{dataTryout.utbk_tryout.program}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i> Judul Tryout</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataTryout.utbk_tryout.title}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-tag m-r-5"></i> Tipe</td>
                                    <td class="text-right">
                                        <span v-if="dataTryout.utbk_tryout.type == 'free' " class="badge badge-info">Free (Gratis)</span>
                                        <span v-if="dataTryout.utbk_tryout.type == 'premium' " class="badge badge-success">Premium (Berbayar)</span>
                                        <span v-if="dataTryout.utbk_tryout.type == 'school' " class="badge badge-warning">Sekolah (Tryout Khusus)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-money-bill m-r-5"></i> Harga</td>
                                    <td class="text-right" v-if="dataTryout.utbk_tryout.type == 'free'">
                                        Rp. 0
                                    </td>
                                    <td class="text-right" v-if="dataTryout.utbk_tryout.type == 'school'">
                                        -
                                    </td>
                                    <td class="text-right" v-if="dataTryout.utbk_tryout.type == 'premium'">
                                        <s>{{dataTryout.utbk_tryout.text_price_out}}</s> {{dataTryout.utbk_tryout.text_price}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-thermometer-half m-r-5"></i> Status:</td>
                                    <td class="text-right">
                                        <span v-if="dataTryout.utbk_tryout.status == 'publish' " class="badge badge-success">Publish</span>
                                        <span v-if="dataTryout.utbk_tryout.status == 'draft' " class="badge badge-secondary">Draft</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Komponen Soal</h5>
                        <div class="float-right">
                            <div class="btn-group btn-block">
                                <button :disabled="isFetchDownload" type="button" class="btn btn-primary rounded btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i v-if="isFetchDownload" class="fa fa-spin fa-spinner"></i>
                                    <i v-if="!isFetchDownload" class="fa fa-list"></i>    
                                    
                                    {{ isFetchDownload ? 'Loading...' : 'Download Soal' }}
                                </button>
                                <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 35px, 0px);">
                                    <a href="javascript:void(0)" class="dropdown-item" v-on:click="downloadQuestions(1)"><i class="fa fa-diwnload"></i> Soal & Kunci</a>
                                    <a href="javascript:void(0)" class="dropdown-item" v-on:click="downloadQuestions(0)"><i class="fa fa-diwnload"></i> Hanya Soal </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered" v-for="(item, index) in dataTryout.utbk_tryout.list_component" :key="index">
                            <thead>
                                <tr>
                                    <th bgcolor="#e2e5e8" colspan="5"><b>{{item.name}}</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(itemDetail, indexDetail) in item.list" :key="indexDetail">
                                    <td style="width:5%" class="text-center">{{++indexDetail}}</td>
                                    <td style="width:40%">{{itemDetail.sub_category}}</td>
                                    <td style="width:15%">{{itemDetail.timer}} Menit</td>
                                    <td style="width:15%">{{itemDetail.total_questions}} Soal</td>
                                    <td style="width:20%">
                                        <button type="button" v-on:click="aturDurasi(itemDetail.sub_category, itemDetail.id)" class="btn btn-sm btn-outline-primary rounded"><i class="fa fa-clock"></i> Atur Ulang Durasi</button>
                                        <button type="button" v-on:click="kelolaSoal(itemDetail.id)" class="btn btn-sm btn-primary rounded m-l-10"><i class="fas fa-list"></i> Kelola Soal</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                    </div>
                </div>
            </div>
         </div>
    </div>
</template>

<script>    
    export default {
        name: 'KomponenUTBK',
        metaInfo: {
            title: 'Kelola Komponen Tryout SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                dataTryout          : {
                    utbk_tryout : [],
                    list_questions : []
                },
                isFetchDownload   : false,
                submitted       : false,
                waiting         : false,
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/utbk/tryout/manage_component';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataTryout = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'utbk_tryout' });
                                }
                            });
                        }
                    });
                }
            },
            kelolaSoal: function(id) {
                this.$router.push({
                    name : 'utbk_tryout_questions',
                    params : {
                        utbk_tryout_id : this.$route.params.id,
                        id: id
                    }
                });
            },
            aturDurasi: function (title, id) {
                this.$swal({
                    input: 'number',
                    title: 'Atur Ulang Durasi',
                    text: title,
                    confirmButtonText: 'Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    inputPlaceholder: "Masukan menit"
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/utbk/tryout/reset_timer', {
                            id    : id,
                            timer : result.value
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((resultDetail) => {
                                    if (resultDetail.value) {
                                        this.getDataByID(this.$route.params.id);                                        
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })                        
                    }
                });
            },
            downloadQuestions(params=0) {
                this.isFetchDownload = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/utbk/tryout/download_questions', {
                    params : {
                        id     : this.$route.params.id,
                        show_answer : params
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchDownload = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Lembar Soal gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        let show_answer = "";
                        if(params === 0) {
                            show_answer = "[Hanya Soal]";
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Lembar Soal - '+this.dataTryout.utbk_tryout.title+" "+show_answer}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            }
        }
    }
</script>