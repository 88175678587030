<template>
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="m-t-10">Statistik Hasil Latihan</h5>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 m-t-10">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Nilai
                        </h4>
                        <div class="d-block mt-4">
                            <div v-for="(item, index) in this.$parent.$parent.dataDetail.grafik_nilai.list" :key="index" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">{{item.name}} : </div>
                                <div class="font-weight-600">
                                    {{item.value}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-10">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Grafik Nilai
                        </h4>
                        <div>
                            <apexchart type="pie" :options="chartOptionsTKP" :series="seriesTKP"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Statistik Latihan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                currentTabComponent : 'StatisticTryout',
                chartOptionsTKP: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#2ed8b6', '#ff5370', '#F78812', '#D77FA1', '#F6D860', '#F2FFE9', '#B1D0E0', "#97BFB4"],
                    labels: this.$parent.$parent.dataDetail.grafik_nilai.labels,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                seriesTKP: this.$parent.$parent.dataDetail.grafik_nilai.series,
            }
        }
    }
</script>