<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <p>Sedang memuat data soal...</p>
        </div>
        <div v-if="!dataFetch">
            <div class="alert alert-primary alert-dismissible fade show" role="alert">
                Saat ini kamu sedang mengerjakan <strong>{{dataList.title}}</strong>.
                <button style="margin-top:-3px" type="button" class="close" data-dismiss="alert"
                    aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
            <div class="row nocopy">
                <div class="col-lg-8">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:18px" class="m-t-10">Soal Nomor #{{number_questions}} </b>
                                <hr />

                                <div class="m-b-20" v-html="form.questions"></div>

                                <!-- audio element -->
                                <div v-if="form.attachment">
                                    <vue-plyr>
                                        <audio controls crossorigin playsinline>
                                            <source :src="form.attachment" type="audio/mp3" />
                                            <source :src="form.attachment" type="audio/ogg" />
                                        </audio>
                                    </vue-plyr>
                                    <hr />
                                </div>

                                <div v-if="dataList.type_id != '4'" class="form-check">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_a"
                                        value="a" :checked="currentSaveAnswer[currentQuestions]  == 'a'"
                                        v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('a')">
                                    <label class="form-check-label" for="options_a">
                                        <div v-html="form.options_a"></div>
                                    </label>
                                </div>

                                <div v-if="dataList.type_id != '4'" class="form-check">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_b"
                                        value="b" :checked="currentSaveAnswer[currentQuestions]  ==  'b'"
                                        v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('b')">
                                    <label class="form-check-label" for="options_b">
                                        <div v-html="form.options_b"></div>
                                    </label>
                                </div>

                                <div v-if="dataList.type_id != '4'" class="form-check">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_c"
                                        value="c" :checked="currentSaveAnswer[currentQuestions]  ==  'c'"
                                        v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('c')">
                                    <label class="form-check-label" for="options_c">
                                        <div v-html="form.options_c"></div>
                                    </label>
                                </div>

                                <div v-if="dataList.type_id != '4'" class="form-check">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_d"
                                        value="d" :checked="currentSaveAnswer[currentQuestions]  ==  'd'"
                                        v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('d')">
                                    <label class="form-check-label" for="options_d">
                                        <div v-html="form.options_d"></div>
                                    </label>
                                </div>

                                <div v-if="dataList.type_id != '4'" class="form-check">
                                    <input class="form-check-input" type="radio" name="options_radio" id="options_e"
                                        value="e" :checked="currentSaveAnswer[currentQuestions]  ==  'e'"
                                        v-model="currentSaveAnswer[currentQuestions] " v-on:click="saveAnswer('e')">
                                    <label class="form-check-label" for="options_e">
                                        <div v-html="form.options_e"></div>
                                    </label>
                                </div>

                                <div v-if="dataList.type_id == '4'" class="form-check text-center">
                                    <span v-if="!isRecord">Klik tombol dibawah ini untuk merekam suara :</span>
                                    <span v-if="isRecord">Bicara sekarang...</span>
                                    <br />
                                    <button type="button" v-on:click="toogleMic" class="btn mt-2 button-rec" :class="isRecord ? 'rec-active':'rec-disabled'">
                                        <i class="fa fa-microphone" v-if="!isRecord"></i> 
                                        <i class="fa fa-stop" v-if="isRecord"></i>
                                        {{!isRecord ? 'Rekam':'Berhenti'}}
                                    </button>
                                    <div class="mt-3">
                                        <span><b>{{form.your_answer}}</b></span>
                                    </div>
                                </div>

                                <hr v-if="!isRecord" />
                                <div v-if="!isRecord" class="row">
                                    <div class="col-md-12 d-flex justify-content-center">
                                        <button class="text-left btn m-t-10 btn-outline-primary rounded m-r-10"
                                            type="button" v-on:click="prev(number_questions)"> <i
                                                class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                        <button class="text-right btn m-t-10 btn-outline-primary rounded" type="button"
                                            v-on:click="next(number_questions)">Soal Selanjutnya <i
                                                class="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:15px" class="m-t-10">Nomor Soal</b>
                                <hr />
                                <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                    <button style="width:100%;max-width: 55px;"
                                        v-for="(item, index) in dataList.list_questions" :key="index" type="button"
                                        class="text-center btn rounded m-l-10 m-t-10"
                                        v-on:click="setQuestions(item.id, item.number)"
                                        :class="currentSaveQuestions[item.id] == true ? 'btn-success': item.status == 'true' ? 'btn-success':item.id == currentQuestions ? 'btn-primary':'btn-outline-primary' ">{{item.number}}
                                    </button>
                                </div>
                                <button class="m-t-10 btn btn-primary rounded btn-block" type="button"
                                    v-on:click="finishQuiz">Selesai</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100% !important;
        height: auto !important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100% !important;
            height: auto !important;
        }
    }

    .start-quiz ::v-deep .btn-complete,
    .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #04bd04 !important;
        border-color: #000 !important;
    }
</style>


<script>
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new window.SpeechRecognition();

    export default {
        name: 'StartQuiz',
        metaInfo: {
            title: 'Mengerjakan Soal',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch: true,
                dataList: [],
                hours: 0,
                minutes: 0,
                seconds: 0,
                currentQuestions: '',
                number_questions: 1,
                form: {
                    tryout_answer: '',
                    questions: '',
                    options_a: '',
                    options_b: '',
                    options_c: '',
                    options_d: '',
                    options_e: '',
                    your_answer: '',
                    tryout_question_id: '',
                    attachment: ''
                },
                isRecord : false,
                currentSaveQuestions: [],
                currentSaveAnswer: [],
                changeAnswer: [],
                countDown: 0,
                timer: [],  
            }
        },
        mounted() {
            recognition.lang            = 'id';
            recognition.interimResults  = true;
            recognition.continuous      = true;

            recognition.onstart = () => {
                console.log("start recording...");
                this.isRecord = true;
            }

            recognition.onend = () => {
                console.log("stop recording...");
                this.isRecord = false;
            }

            recognition.onresult = (event) => {
                var text = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join("");

                this.form.your_answer = text;
            }
        },
        created() {
            this.startQuiz();
            this.timer[this.number_questions] = 0;
            this.countDownTimer();
        },
        methods: {
            countDownTimer() {
                setTimeout(() => {
                    let waktu_mengerjakan = 0;

                    if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this
                            .number_questions] !== null) {
                        waktu_mengerjakan += this.timer[this.number_questions];
                    } else {
                        this.timer[this.number_questions] += waktu_mengerjakan;
                    }

                    this.countDown += 1
                    this.timer[this.number_questions] = this.countDown
                    this.countDownTimer()
                }, 1000)
            },
            startQuiz: function () {
                let formData = new FormData();

                formData.append('id', this.$route.params.id);
                formData.append('code', this.$route.params.code);
                formData.append('token', this.$route.params.token);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/english_academy/tryout/start', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            let getResponse = response.data;
                            this.dataList = getResponse.data.data;
                            this.tryout_answer = this.dataList.tryout_answer;
                            this.setQuestions(this.dataList.list_questions[0].id, 1);
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goToBack();
                                }
                            });
                        }

                        this.dataFetch = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
            },
            setQuestions: function (id, number) {
                var item = this.dataList.list_questions.find(item => item.id === id);
                this.currentQuestions = id;
                this.number_questions = number;

                this.form.tryout_question_id = item.id;
                this.form.questions = item.question;
                this.form.options_a = item.options.a;
                this.form.options_b = item.options.b;
                this.form.options_c = item.options.c;
                this.form.options_d = item.options.d;
                this.form.options_e = item.options.e;

                this.form.attachment = '';
                setTimeout(() => {
                    this.form.attachment = item.attachment;
                }, 100);

                if (this.changeAnswer[item.id]) {
                    this.changeAnswer[item.id] = this.currentSaveAnswer[item.id];
                    this.form.your_answer = this.currentSaveAnswer[item.id];
                } else {
                    this.form.your_answer = item.your_answer;
                }

                if (this.form.your_answer) {
                    this.currentSaveQuestions[item.id] = true;
                    this.currentSaveAnswer[item.id] = this.form.your_answer;
                }

                this.timer[this.number_questions] = item.timer;

                if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this
                    .number_questions] !== null) {
                    this.countDown = this.timer[this.number_questions];
                } else {
                    this.timer[this.number_questions] = 0;
                    this.countDown = 0;
                }

                this.scrollToTop();
            },
            stop: function () {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/english_academy/tryout/stop', {
                        tryout_answer_id: this.tryout_answer
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'member_tryout_result_english_academy' , params: {
                                        id      : this.$route.params.id,
                                        code    : this.$route.params.code,
                                        id_answer   : response.data.data.tryout_answer_id
                                    }});
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
            },
            next: function (number = "") {
                let next_number = number + 1;
                let total_questions = this.dataList.list_questions.length;

                if (next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id = this.dataList.list_questions[number].id;
                    this.number_questions = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function (number = "") {
                let next_number = number - 1;

                if (next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id = this.dataList.list_questions[next_number - 1].id;
                    this.number_questions = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveAnswer: function (your_answer = "") {
                this.form.your_answer = your_answer;
                let formData = new FormData();
                formData.append('answer', this.form.your_answer);
                formData.append('tryout_question_id', this.form.tryout_question_id);
                formData.append('tryout_answer_id', this.tryout_answer);
                formData.append('timer', this.timer[this.number_questions]);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/english_academy/tryout/answer/save',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.currentSaveQuestions[this.form.tryout_question_id] = true;
                            this.currentSaveAnswer[this.form.tryout_question_id] = your_answer;
                            this.changeAnswer[this.form.tryout_question_id] = true;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                        }
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
            },
            finishQuiz: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Selesai mengerjakan ?',
                    html: 'Jawaban yang telah di submit tidak dapat diubah.<br/>Harap periksa kembali jawaban yang telah di simpan',
                    confirmButtonText: 'Selesai',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        this.stop();
                    }
                });
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            goToBack: function () {
                this.$router.push({
                    name: 'member_tryout_overview_english_academy',
                    params: {
                        id: this.$route.params.id,
                        code: this.$route.params.code
                    }
                });
            },
            record: function () {                
                window.SpeechRecognition =
                window.SpeechRecognition || 
                window.webkitSpeechRecognition;
                const recognition = new window.SpeechRecognition();

                recognition.lang            = 'id';
                recognition.interimResults  = true;
                recognition.continuous      = true;

                // event current voice reco word
                recognition.addEventListener("result", event => {      
                    var text = Array.from(event.results)
                    .map(result => result[0])
                    .map(result => result.transcript)
                    .join("");

                    this.form.your_answer = text;
                });

                recognition.addEventListener("end", () => {
                    recognition.stop();
                    this.form.your_answer = "";
                    this.isRecord = false;
                    console.log("Stop ....");
                });
                
                recognition.start();
                console.log("Start ....");
                this.isRecord = true;
            },
            toogleMic: function() {
                if(this.isRecord) {
                    recognition.stop();
                    this.saveAnswer(this.form.your_answer.toLowerCase());
                } else {
                    recognition.start();
                }
            }
        }
    }
</script>