<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Monitoring Tryout CBT</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Nama Kelas</th>
                                                    <th class="text-center">Total Siswa</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">{{++index}}</td>
                                                    <td>{{item.class}}</td>
                                                    <td class="text-center">{{item.total_users}} Orang</td>
                                                    <td>
                                                        <button v-on:click="setClassName(item.class)" data-toggle="modal" data-target="#modalForm" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fa fa-edit"></i> Lihat Detail</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Silahkan pilih tingkat</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12">
                                <div class="m-t-10 mb-3">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Tingkat</label>
                                        <select class="form-control" v-model="grade">
                                            <option value="">Pilih Tingkat</option>
                                            <option v-for="(item, index) in dataGrade" :key="index" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button v-on:click="goToPage" style="margin-top:-30px" type="button" class="btn btn-primary btn-block">Lihat Monitoring Kelas <i class="fa fa-arrow-right"></i> </button>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                dataGrade       : [
                    {
                        "id": "1",
                        "name": "1"
                    },
                    {
                        "id": "2",
                        "name": "2"
                    },
                    {
                        "id": "3",
                        "name": "3"
                    },
                    {
                        "id": "4",
                        "name": "4"
                    },
                    {
                        "id": "5",
                        "name": "5"
                    },
                    {
                        "id": "6",
                        "name": "6"
                    },
                    {
                        "id": "7",
                        "name": "7"
                    },
                    {
                        "id": "8",
                        "name": "8"
                    },
                    {
                        "id": "9",
                        "name": "9"
                    },
                    {
                        "id": "10",
                        "name": "10"
                    },
                    {
                        "id": "11",
                        "name": "11"
                    },
                    {
                        "id": "12",
                        "name": "12"
                    }
                ],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                grade: '',
                classname:""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-class-cbt';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            setClassName: function(params) {
                this.classname = params;
            },
            goToPage: function() {
                if(this.grade == '') {
                    alert("silahkan pilih tingkat terlebih dahulu");
                } else {
                    document.getElementById("btnCloseModal").click();
                    this.$router.push( {
                        name : 'monitoring_tryout_cbt',
                        params : {
                            class : this.classname
                        },
                        query : {
                            grade : this.grade
                        }
                    })
                }
            }
        }
    }
</script>