<template>
    <div>
        <div v-if="dataOwned" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataOwned" >
            <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
                <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">{{dataGroup.data.text_link}}</span>
                <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                    Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
            </div>

            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Materi Text UTBK</h5>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="margin-top:-10px">
                                    <button v-for="(item, index) in dataCategory" :key="index" class="mt-2 btn rounded m-r-10 m-t-10" :class="activeTabs == item.id ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id)" type="button">{{item.name}}</button>

                                    <div class="input-group m-t-10">
                                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                        <div class="input-group-append">
                                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                    class="fa fa-search"></i> Cari </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top:-10px !important" class="row" v-if="!dataFetch">
                <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                    <div class="card border">
                        <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                        <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-materi.png" alt="cover">
                        <div class="card-body d-flex flex-column">
                            <h5 class="card-title text-short">
                                <a data-toggle="modal" data-target="#modalAttachment" href="#" v-on:click="goToView(item.attachment)">{{item.title}}</a>
                            </h5>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                                <div class="mr-3">Kategori</div>
                                <div class="font-weight-600 text-right text-short">
                                    {{item.category}}
                                </div>
                            </div>
                            <p v-if="item.description" class="card-text mt-2">
                                {{item.description}}
                            </p>
                            <div class="d-grid mb-0">
                                <button data-toggle="modal" data-target="#modalAttachment" v-on:click="goToView(item.attachment)" type="button" class="btn btn-outline-primary rounded btn-block">
                                    <i class="fas fa-file-pdf"></i> Lihat Materi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                    <hr/>
                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                </nav>

                <div v-if="emptyState">
                    <div class="card border p-3">
                        <empty-state></empty-state>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modalAttachment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchFile">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>
                <div class="modal-content" v-if="!fetchFile">
                    <div v-if="!dataDetail.attachment">
                        <p class="text-center mb-3 mt-3">File tidak tersedia</p>
                    </div>
                    <object v-if="dataDetail.attachment" type="application/pdf" :data="dataDetail.attachment" width="100%" height="500" style="height: 85vh;">
                        <p class="text-center mt-3">
                            <embed :src="dataDetail.attachment" width="100%" height="600px">
                        </p>                        
                    </object>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal">Tutup</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MateriUTBK',
        metaInfo: {
            title: 'Materi Text SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                fetchFile       : true,
                dataOwned       : true,
                dataFetch       : true,
                dataList        : [],
                emptyState      : false,
                dataCategory    : [],
                dataGroup       : [],
                keywords        : "",
                activeTabs      : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                dataDetail : {
                    attachment : ''
                }
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getCategory();
        },
        methods: {
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/utbk/category/list';
                this.$http.get(uri, {
                    params : {
                        program : 'saintek'
                    }
                }).then(res => {
                    this.dataCategory   = res.data.data;
                    this.activeTabs     = this.dataCategory[0].id;
                    this.getData();
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/utbk/materi/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type                : 'text',
                        program             : 'saintek',
                        keywords            : this.keywords,
                        utbk_category_id    : this.activeTabs,
                        page                : this.pagination.currentPage,
                        limit               : this.pagination.limit
                    }
                }).then(res => {
                    if(res.data.owned) {
                        this.emptyState             = false;
                        this.dataFetch              = false;                
                        this.dataOwned              = false;                
                        this.dataList               = res.data.data;
                        this.pagination.totalPages  = res.data.total_page;

                        if(this.dataList.length < 1) {
                            this.emptyState = true;
                        }                        
                    }
                    else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Halaman ini tidak bisa di akses oleh pengguna gratis !',
                        });
                        this.$router.push({ name: 'upgrade_package', params: { type: 'utbk' } });
                    }
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            },
            getDiscuss: function () {
                this.dataFetch = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'utbk'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            },
            goToView: function(attachment="") {
                this.fetchFile = true;
                setTimeout(() => {
                    this.dataDetail.attachment = attachment;
                    this.fetchFile = false;
                }, 1000);
            }
        }
    }
</script>
