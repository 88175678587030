<template>
    <div class="text-center justify-align-center align-items-center mt-5">
        Cek User Agent ({{ check_iframe }}): <br/>{{ user_agent }}
        <br/>
        <b>{{ check_android }}</b>
        <br/>
        <b>Status: {{ check_web_view }}</b>
        <br/>
        <b style="color:green" v-if="allow_exam"> Diijinkan mengikuti ujian</b>
        <b style="color:red" v-if="!allow_exam"> Tidak diijinkan mengikuti ujian</b>
        <br/>
        <b v-if="is_ios">Kamu sedang menggunakan perangkat IOS</b>
        <b v-if="!is_ios">Kamu tidak sedang menggunakan perangkat IOS</b>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                user_agent : '-',
                check_iframe : '',
                check_android : '',
                check_web_view : '',
                allow_exam : true,
                is_android : false,
                is_ios     : false
            }
        },
        created() {
            this.user_agent = navigator.userAgent;

            var isAndroid = /Android/.test(navigator.userAgent);
            if (isAndroid) {
                this.check_android = 'Pengguna sedang menggunakan perangkat Android';
                this.is_android = true;
            } else {
                this.check_android = 'Pengguna tidak menggunakan perangkat Android';
            }            

            this.check_iframe = 'Aplikasi tidak dibuka di dalam iframe Webview';

            this.checkWebView();

            if(this.is_android) {
                if (this.check_web_view == 'iOS webview' || this.check_web_view == 'Android webview') {
                    this.check_iframe = 'Aplikasi dibuka di dalam iframe Webview';
                } else {
                    this.allow_exam = false;
                }
            }

            if(this.ios) {
                if (this.check_web_view == 'iOS webview') {
                    this.check_iframe = 'Aplikasi dibuka di dalam iframe Webview';
                } else {
                    this.allow_exam = false;
                }

                alert("OPENED IN IOS");
            }
        },
        methods: {
            checkWebView: function() {
                var standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

                if (ios) {
                    if (!standalone && safari) {
                        this.check_web_view = 'Safari Browser'
                    } else if (!standalone && !safari) {
                        this.check_web_view = 'iOS webview';
                    }
                    this.is_ios = true;
                } else {
                    if (userAgent.includes('wv')) {
                        this.check_web_view = 'Android webview'
                    } else {
                        this.check_web_view = 'Chrome Browser'
                    }
                }
            }
        },
    }
</script>