<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Invoice</h5>
                        </div>
                        <div class="row" style="margin-top:-10px">
                            <div class="col-md-12">

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center">No</th>
                                                <th>No Invoice</th>
                                                <th class="text-center">Batas Pembayaran</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="dataList.length > 0">
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td class="text-center">{{++index}}</td>
                                                <td>{{item.invoice}}</td>
                                                <td class="text-center">{{item.expired_at}}</td>
                                                <td class="text-center">
                                                    <span v-if="item.transaction_status == 'cancel'" class="badge badge-yellow">
                                                        Dibatalkan
                                                    </span>

                                                    <span v-if="item.transaction_status == 'expire'" class="badge badge-danger">
                                                        Kadaluarsa
                                                    </span>

                                                    <span v-if="item.transaction_status == 'pending'" class="badge badge-secondary">
                                                        Menunggu Pembayaran
                                                    </span>

                                                    <span v-if="item.transaction_status == 'settlement'" class="badge badge-success">
                                                        Lunas
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <router-link :to="{name : 'invoice_detail', params : {invoice : item.invoice} }">Lihat Detail</router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="dataList.length < 1">
                                            <tr colspan="5">
                                                <td>Data tidak ditemukan</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InvoiceList',
        metaInfo: {
            title: 'Data Invoice',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                keywords    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/invoice/list';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            }            
        }
    }
</script>
