<template>
    <div>
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Sedang memuat...
            </span>
        </div>
        
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="!dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Saat ini kamu terdaftar sebagai guru dari sekolah <b>{{ dataSchool.name }}</b>.
            </span>
        </div>

        <div class="page-header" v-if="!dataFetchSchool">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Laporan Jurnal</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Laporan Jurnal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:-10px !important" v-if="!dataFetchSchool">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Atur Kelas, Mata Pelajaran & Tanggal</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Kelas</label>
                                        <div class="col-sm-9">
                                            <select class="form-control" v-model="school_class">
                                                <option value="">Pilih Kelas</option>
                                                <option v-for="(itemClass, indexClass) in dataSchool.list_class" :key="indexClass" :value="itemClass">{{ itemClass }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Mata Pelajaran</label>
                                        <div class="col-sm-9">
                                            <select class="form-control" v-model="subjects_id">
                                                <option value="">Pilih Mata Pelajaran</option>
                                                <option v-for="(itemSubject, indexSubject) in dataSchool.subject_list" :key="indexSubject" :value="itemSubject.id">{{ itemSubject.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Dari Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal awal..."  v-model="start_date">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hingga Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal akhir..." v-model="end_date">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="alert alert-warning alert-dismissible fade show d-flex" role="alert">
                                <strong><i class="fa fa-info-circle"></i></strong>
                                    <span class="m-l-10">Pilih kelas, mata pelajaran dan tanggal diatas untuk mendownload Laporan Jurnal. Setelah itu tekan tombol 'Download Laporan' dibawah ini..</span>
                            </div>

                            <button v-on:click="journalProcess()" type="button" class="btn btn-outline-primary rounded btn-block" :disabled="waiting"> <i class="fa fa-calendar-check"></i> {{ waiting ? 'Sedang memproses...' : 'Download Laporan (Excel)' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScheduleReportJournal',
        metaInfo: {
            title: 'Laporan Jurnal',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getSchool();
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                dataFetchSchool : false,
                dataSchool      : [],
                subjects_id     : '',
                school_class    : '',
                start_date      : '',
                end_date        : ''           
            }
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;
                });
            },
            journalProcess: function() {
                if(this.school_class && this.subjects_id && this.start_date && this.end_date) {
                    if(this.end_date < this.start_date) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Tanggal akhir tidak boleh kurang dari tanggal awal.',
                        });
                    } else {
                        this.waiting = true;
                        let formData = new FormData();

                        formData.append('school_class', this.school_class);
                        formData.append('subjects_id', this.subjects_id);
                        formData.append('start_date', this.start_date);
                        formData.append('end_date', this.end_date);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/schedule-recap-journal-excel', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            responseType: 'blob'
                        })
                        .then(response => {
                            if(response.data.type == 'application/json') {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: "Tidak ada data presensi!",
                                });       
                            } else {
                                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `${'Rekap Jurnal Kelas - '+this.school_class+' - '+this.start_date+'_'+this.end_date}.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                            }

                            this.waiting = false;
                        })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });                        
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih kelas, mata pelajaran & rentang tanggal!',
                    });
                }
            }
        }
    }
</script>