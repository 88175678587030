<template>
    <div>
        <div v-if="dataFetchTryout" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchTryout" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{ name : 'monitoring_tryout_utbk'}"><i class="fa fa-arrow-left m-r-10"></i></router-link> Monitoring Tryout - {{dataTryout.title}}</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <button v-if="dataList.length > 0" class="m-l-10 btn waves-effect waves-light btn-primary btn-icon btn-msg-send rounded" type="button" v-on:click="downloadNilai" :disabled="download_fetch"><i
                                        class="fas fa-file-export"></i> {{ download_fetch ? 'Harap tunggu...':'Download Nilai' }}</button>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Status Mengerjakan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="status" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option value="sudah">Sudah Mengerjakan</option>
                                        <option value="sedang">Sedang Mengerjakan</option>
                                        <option value="belum">Belum Mengerjakan</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="font-size:11px !important;width:5%" class="text-center">No</th>
                                                    <th style="font-size:11px !important">Peserta</th>
                                                    <th style="font-size:11px !important" v-for="(item, index) in dataTryout.lessons" :key="index" class="text-center">{{ item.sub_category }}</th>
                                                    <th style="font-size:11px !important" class="text-center">Nilai Rata-rata</th>
                                                    <th class="text-center" style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.fullname}}<br/>{{item.email}}<br/>
                                                        <span v-if="item.working_status == 'notyet'" class="badge badge-danger">Belum Mengerjakan</span>
                                                        <span v-if="item.working_status == 'ongoing'" class="badge badge-warning">Sedang Mengerjakan</span>
                                                        <span v-if="item.working_status == 'finished'" class="badge badge-success">Sudah Mengerjakan</span>
                                                    </td>
                                                    <td v-for="(itemLessons, indexLessons) in dataTryout.lessons" :key="indexLessons" class="text-center" style="width:15%">
                                                        {{ item.answer_detail[itemLessons.id] !== undefined ? item.answer_detail[itemLessons.id] : '-' }}
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="!item.status_tryout">-</span>
                                                        <span v-if="item.status_tryout">{{item.total_score ? item.total_score : '-'}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <button
                                                            type="button"                                                            
                                                            class="btn btn-sm btn-danger rounded"
                                                            v-on:click="resetTest(item.status_tryout, item.id, item.fullname)"
                                                            v-if="item.status_tryout"
                                                        >
                                                        <i class="fa fa-trash"></i>
                                                        Reset
                                                        </button>
                                                    </td>                                                
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>

                                <div v-if="!dataFetch">
                                    <nav v-if="dataList.length > 0" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState          : false,
                dataFetch           : false,
                dataFetchTryout     : true,
                dataList            : [],
                dataTryout          : [],
                keywords            : "",
                download_fetch      : false,
                status     : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getTryout();
        },
        methods: {
            getTryout: function() {
                this.dataFetchTryout = true;
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/detail-utbk';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataTryout         = res.data.data;
                        this.dataFetchTryout    = false;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'monitoring_tryout_utbk'
                                });
                            }
                        });                        
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring-tryout/list-status-utbk';
                
                if(this.pagination.currentPage == 0) {
                    this.pagination.currentPage = 1;
                }
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        status      : this.status,
                        keywords    : this.keywords,
                        id          : this.$route.params.id,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            resetTest: function (tryout_start_id, user_id, fullname) {
                this.$swal({
                icon: 'info',
                title: 'Reset Hasil Tes',
                html: 'Apakah kamu yakin ingin melakukan tes ulang<br/>pada peserta: <b>'+fullname+'</b><br/>Data yang sudah direset tidak dapat dikembalikan.',
                confirmButtonText: 'Reset Hasil',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/reset-member-utbk', {
                        tryout_start_id: tryout_start_id,
                        user_id: user_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            downloadNilai: function () {                    
                let formData = new FormData();
                this.download_fetch = true;
                formData.append('status', this.status);
                formData.append('keywords', this.keywords);
                formData.append('id', this.$route.params.id);
                formData.append('page', 1);
                formData.append('limit', 1000);
                formData.append('tryout', JSON.stringify(this.dataTryout));

                this.$http.post(process.env.VUE_APP_APIHOST + 'proctor/monitoring/download-nilai-utbk-excel',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Hasil Nilai - ${this.dataTryout.title}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.download_fetch = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.download_fetch = false;
                    return;
                });
            }
        }
    }
</script>
