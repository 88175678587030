<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-b-10">Kelola Program Studi </h5>
                            </div>
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link :to="{name : 'skd_collage'}">Perguruan Tinggi</router-link>
                                </li>
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Kelola Program Studi</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8">
                    <div class="card card-hover-none">
                        <div class="card-header">
                            <h5>Data Perguruan Tinggi</h5>
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td style="border-top:0px !important"><i class="fas fa-book m-r-5"></i>Nama</td>
                                        <td style="border-top:0px !important; text-transform:uppercase;" class="text-right">{{dataCollage.name}}</td>
                                    </tr>
                                    <tr>
                                        <td style="border-top:0px !important"><i class="fa fa-globe m-r-5"></i>Website</td>
                                        <td style="border-top:0px !important" class="text-right">
                                            <a :href="dataCollage.website" target="blank">{{dataCollage.website}}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i>Deskripsi</td>
                                        <td style="border-top:0px !important" class="text-right">{{dataCollage.description}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <router-link :to="{name : 'skd_collage_edit', params: { id: dataCollage.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-outline-primary btn-sm btn-block rounded m-b-10"><i class="fas fa-edit"></i> Ubah</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 text-center">
                    <div class="card card-hover-none">
                        <div class="card-header">
                            <h5>Logo</h5>
                        </div>
                        <div class="thumbnail mb-4" v-if="dataCollage.logo">
                            <div class="thumb">
                                <a :href="dataCollage.logo" data-lightbox="1" target="blank">
                                    <img :src="dataCollage.logo" alt="" class="img-fluid img-thumbnail" style="width:30%">
                                </a>
                            </div>
                        </div>

                        <div class="thumbnail mb-4" v-if="!dataCollage.logo">
                            <div class="thumb">
                                <a :href="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'" data-lightbox="1" target="blank">
                                    <img :src="'https://griyabelajar.com/assets/img/favicon-griyabelajar.png'" alt="" class="img-fluid img-thumbnail" style="width:30%">
                                </a>
                            </div>
                        </div>
                        <hr/>
                        <div class="card-block">
                            <button v-on:click="addData" data-toggle="modal" data-target="#modalReferral" type="button" class="btn btn-primary btn-block rounded"><i class="fa fa-plus"></i> Tambah Program Studi</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-hover-none">
                        <div class="card-header">
                            <h5>Data Program Studi</h5>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    </div>

                                    <div v-if="dataFetch">
                                        <p>Sedang mengambil data...</p>
                                    </div>

                                    <div v-if="!dataFetch">
                                        <div v-if="dataList.length > 0" >
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center" style="width:5%">No</th>
                                                            <th>Kode Prodi</th>
                                                            <th>Nama</th>
                                                            <th style="width:15%">Aksi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="dataList.length > 0">
                                                        <tr v-for="(item, index) in dataList" :key="index">
                                                            <td class="text-center">
                                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                            </td>
                                                            <td>{{item.code}}</td>
                                                            <td>{{item.name}}</td>
                                                            <td>
                                                                <button v-on:click="editData(item.id)" data-toggle="modal" data-target="#modalReferral" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fa fa-edit"></i> Ubah</button>
                                                                <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-if="dataList.length < 1">
                                                        <tr colspan="4">
                                                            <td>Data tidak ditemukan</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <nav aria-label="navigation" class="m-t-20">
                                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                            </nav>
                                        </div>

                                        <div v-if="emptyState">
                                            <empty-state></empty-state>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div style="margin-left:10px" class="modal" id="modalReferral" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <form v-on:submit.prevent="onSubmit">
                        <div class="modal-content" style="overflow: hidden;">
                            <div class="d-block px-3 pt-3 pb-0">
                                <div class="d-flex align-items-center justify-content-between text-sm">
                                    <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> {{this.label}} Prodi</div>
                                    <div>
                                        <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                            data-dismiss="modal">Tutup</button>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="d-block p-3">
                                <div class="d-flex align-items-center pb-3 row">
                                    <div class="col-md-12">
                                        <input type="hidden" v-model="form.id">

                                        <div class="m-t-10">
                                            <label>Kode Prodi : </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan data..."
                                            v-model="form.code" :class="{ 'is-invalid': submitted && $v.form.code.$error }">

                                            <div v-if="submitted && !$v.form.code.required" class="invalid-feedback d-block">
                                            Kode Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Nama : </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan data..."
                                            v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }">

                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback d-block">
                                            Nama Wajib Diisi</div>
                                        </div>

                                        <div class="alert alert-success m-t-10" role="alert">
                                            <b>Kode Prodi</b> harus berupa <b>huruf</b>. tidak boleh mengandung karakter unik.
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <button style="margin-top:-30px" type="submit" class="btn btn-primary" :disabled="waiting">
                                <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vPagination from 'vue-plain-pagination'
    
    export default {
        name: 'collageDetail',
        metaInfo: {
            title: 'Kelola Perguruan Tinggi',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataCollage : [],
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                label       : 'Tambah',
                form        : {
                    id : '',
                    name : '',
                    code : '',
                    passing_grade : ''
                },
                submitted : false,
                waiting : false,
            }
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        validations: {
            form: {
                name: {
                    required
                },
                code: {
                    required
                }
            }
        },
        methods: {
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    let uri     = process.env.VUE_APP_APIHOST+'admin/collage/detail_skd';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data;
                            this.dataCollage    = getResponse;
                            this.getData();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'skd_collage' });
                                }
                            });
                        }
                    });
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/collage/list_prodi_skd';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        skd_collage_id  : this.$route.params.id,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('name', this.form.name);
                    formData.append('code', this.form.code);
                    formData.append('skd_collage_id', this.$route.params.id);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/collage/save_prodi_skd', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        document.getElementById("btnCloseModal").click();
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            addData: function() {
                this.form.id = '';
                this.form.name = '';
                this.form.code = '';
                this.submitted  = false;
                this.waiting    = false;
            },
            editData: function(id) {
                this.label = 'Ubah';
                var item = this.dataList.find(item => item.id === id);
                this.form.id = item.id;
                this.form.name = item.name;
                this.form.code = item.code;
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus data ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/collage/delete_prodi_skd', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }
        }
    }
</script>