<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div v-if="emptyState"  class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title text-center">
                                <img style="width:50%" src="assets/images/referral.jpg" alt="" class="img-fluid mb-4">
                                <h5 class="m-t-10">Ayo ajak teman dan keluarga untuk bergabung<br/>menggunakan kode referral kamu!</h5>
                                <button class="btn btn-outline-primary rounded" type="button" data-toggle="modal" data-target="#modalReferral"><i class="fa fa-edit"></i> Buat Kode Referral</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!emptyState"  class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Referral. </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-info" role="alert">
                                <b>Informasi : </b>
                                <ul class="m-t-10">
                                    <li>Silakan bagikan kode referal (Salin link kode referal) Anda kepada temanmu untuk bergabung dengan Kami nanti akan mendapatkan voucher senilai 5.000 rupiah per siswa.</li>
                                    <li>Jika sudah mencapai senilai Rp100.000 maka dana bisa dicairkan.</li>
                                    <li>Silakan klik ajukan pencairan untuk mencairkan dana atau voucher yang sudah anda dapatkan minimal Rp 100.000</li>
                                    <li>Jadi, silakan ajak teman-temanmu sebanyak mungkin di lain sisi mengajak pada kebaikan untuk belajar, dilain sisi kamu akan mendapatkan tambahan uang saku. Assyik kan..😊</li>
                                </ul>
                            </div>
                        </div>                        

                        <div class="col-xl-4 col-md-4 col-lg-6">
                            <div class="card card-theory prod-p-card bg-c-green">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                            <h6 class="m-b-5 text-white">Total Pemakai Referral</h6>
                                            <h3 class="m-b-0 text-white">{{this.dataList.total_referral}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-wallet text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-lg-6">
                            <div class="card card-theory prod-p-card bg-c-yellow">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                            <h6 class="m-b-5 text-white">Total Pendapatan</h6>
                                            <h3 class="m-b-0 text-white">{{this.dataList.total_income}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-wallet text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-lg-6">
                            <div class="card card-theory prod-p-card bg-c-green">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                            <h6 class="m-b-5 text-white">Pendapatan Dicairkan</h6>
                                            <h3 class="m-b-0 text-white">{{this.dataList.total_balance}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-wallet text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="col-md-6 m-t-10">
                            <label>Kode Referral Kamu: </label>
                            <input type="text" disabled autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Kode Referral kamu..." :value="dataList.code">
                            <div class="m-t-10">
                                <button data-toggle="modal" data-target="#modalReferral" class="btn btn-primary rounded" type="button"><i class="fa fa-edit"></i> Ubah Kode Referral</button>
                                <router-link :to="{name : 'users_referral_redeem'}" class="btn btn-primary rounded m-l-10" type="button"><i class="fa fa-database"></i> Cairkan Pendapatan</router-link>
                            </div>
                        </div>

                        <div class="col-md-6 m-t-10">
                            <label>Link Referral Kamu: </label>
                            <input type="text" disabled autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Kode Referral kamu..." :value="form.url+'#/signup?ref='+dataList.code">
                            <div class="m-t-10">
                                <button class="btn btn-mini btn-primary rounded" type="button"
                                v-clipboard:copy="dataList.code"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                
                                ><i class="fa fa-copy"></i> Salin Kode</button>

                                <button class="btn btn-mini btn-primary rounded m-l-10" type="button"
                                v-clipboard:copy="form.url+'#/signup?ref='+dataList.code"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError"
                                
                                ><i class="fa fa-copy"></i> Salin Link</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!emptyState"  class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Riwayat Transaksi Referral. </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group mb-3 m-t-10">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getTransactions">
                                <div class="input-group-append">
                                    <button class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                            class="fa fa-search"></i> Cari </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="dataFetchTransactions">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <div class="col-md-12" v-if="!dataFetchTransactions">
                            <div v-if="dataTranscations.length == 0">
                                <empty-state></empty-state>
                            </div>

                            <div v-if="dataTranscations.length > 0" class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">No</th>
                                            <th>Nama</th>
                                            <th>Kode Referral</th>
                                            <th>Tanggal Transaksi</th>
                                            <th>Pendapatan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataTranscations" :key="index">
                                            <td style="width:5%" class="text-center">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>{{item.fullname}}</td>
                                            <td>{{item.voucher_code}}</td>
                                            <td>{{item.created_at}}</td>
                                            <td>{{item.balance}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <nav aria-label="navigation" class="m-t-10">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalReferral" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> {{this.label}} Kode Referral</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Kode Referral : </label>
                                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Kode Referral kamu..." v-model="form.code"
                                        :class="{ 'is-invalid': submitted && $v.form.code.$error }"
                                        >

                                        <div v-if="submitted && !$v.form.code.required" class="invalid-feedback d-block">
                                        Kode Wajib Diisi</div>
                                    </div>

                                    <div class="m-t-10">
                                        <label>Link Referral : </label>
                                        <input type="text" disabled autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Kode Referral kamu..." :value="form.url+'#/signup?ref='+form.code">
                                    </div>

                                    <div class="alert alert-success m-t-10" role="alert">
                                        <b>Kode referral</b> harus berupa <b>angka atau huruf</b>. tidak boleh mengandung karakter unik.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Kode Referral' }}</button>
                        </form>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Referral',
        metaInfo: {
            title: 'Referral',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetchTransactions   : true,
                dataTranscations        : [],
                label       : 'Buat',
                submitted   : false,
                waiting     : false,
                emptyState  : true,
                dataFetch   : true,
                form : {
                    code    : '',
                    url     : process.env.VUE_APP_APIHOST == 'https://api.griyabelajar.com/' ? 'https://app.griyabelajar.com/':'https://dev-app.griyabelajar.com/',
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                keywords : ''
            }
        },
        validations: {
            form: {
                code: {
                    required
                },
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/account/save_referral', {
                        code : this.form.code,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/account/referral';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.emptyState             = false;
                        this.dataList               = res.data.data;
                        this.label                  = 'Ubah';
                        this.form.code              = this.dataList.code;
                        this.getTransactions();
                    }
                    else {
                        this.emptyState             = true;
                        this.dataFetch              = false;                
                    }

                    this.dataFetch              = false;                
                });
            },
            onCopy: function (e) {
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    html: 'Berhasil di salin : <br/><b>'+e.text+'</b>',
                });
            },
            onError: function (e) {
                alert('Failed to copy the text to the clipboard')
                console.log(e);
            },
            getTransactions: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/account/transactions_referral';

                this.dataFetchTransactions  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetchTransactions  = false;                
                    this.dataTranscations       = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
        }
    }
</script>