<template>
    <div>
        <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
            <strong><i class="fa fa-info-circle"></i></strong>
            <span class="m-l-10">{{dataGroup.data.text_link}}</span>
            <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Paket PPPK Saya</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!upgradeState">
                <div class="col-md-12">
                    <div class="input-group mb-3">
                        <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                        <div class="input-group-append">
                            <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                    class="fa fa-search"></i> Cari </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="dataFetch">
                <div class="col-lg-4" v-for="(item,index) in [1,2,3]" :key="index">
                    <div class="d-block border rounded-lg p-3 m-t-10">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="mr-3 w-100">
                                <div class="d-flex align-items-center">
                                    <div class="w-100">
                                        <div class="skeleton-bar text-center">
                                            <div class="sb-sm mb-2"></div>
                                            <hr/>
                                            <div class="sb-lg sb-thin mb-2"></div>
                                            <div class="sb-lg sb-thin mb-4"></div>
                                            <div class="sb-lg"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="!dataFetch">
                <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                    <div class="card card-theory">
                        <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                        <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-pppk-new.png" alt="cover">
                        <div class="card-body">
                            <div class="d-block mb-1">
                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.name}}</div>
                                </div>
                            </div>

                            <div class="d-block mb-1">
                                <div v-if="item.total_tryout" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Tryout yang tersedia</div>
                                    <div class="font-weight-600">{{item.total_tryout}} Paket</div>
                                </div>

                                <div v-if="item.total_exercises" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Latihan yang tersedia</div>
                                    <div class="font-weight-600">{{item.total_exercises}} Paket</div>
                                </div>

                                <div v-if="item.total_theory" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                    <div class="mr-3">Materi yang tersedia</div>
                                    <div class="font-weight-600">{{item.total_theory}} Paket</div>
                                </div>

                                <router-link v-if="item.status == 'opened'" :to="{name : 'member_paket_saya_pppk_detail', params : {id : item.id}}" class="btn btn-primary rounded btn-block m-b-10"><i class="fa fa-edit"></i> Mulai Belajar</router-link>

                                <div class="alert text-center" :class="item.status == 'opened' ? 'alert-info':'alert-danger'" role="alert">
                                    {{item.status == 'expired' ? 'Paket ini sudah kadaluarsa. Hanya Berlaku hingga : ':'Berlaku hingga : '}}
                                    <br/><b>{{item.expired_text}}</b>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch">
                <div v-if="emptyState">
                    <empty-state></empty-state>
                </div>

                <div v-if="upgradeState">
                    <div class="text-center">
                        <img style="width:500px;max-width:100%" src="assets/images/landing-upgrade.jpg" alt=""
                            class="img-fluid mb-4">
                        <h4 class="m-t-10">Saat ini kamu belum memiliki paket <b>PPPK</b></h4>
                        <p>
                            Silahkan beli paket pppk untuk mendapatkan benefit yang lebih baik.
                        </p>
                        <router-link :to="{name : 'member_paket_pppk'}" class="btn btn-outline-primary rounded" type="button"><i class="fa fa-rocket"></i> Beli Paket PPPK</router-link>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaketPppkSaya',
        metaInfo: {
            title: 'Paket PPPK Saya',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                keywords        :'',
                dataFetch       : true,
                dataList        : [],
                emptyState      : false,
                upgradeState    : false,
                dataGroup       : []
            }
        },
        created() {
            this.firstFetch();
            this.getDiscuss();
        },
        methods: {
            firstFetch: function() {
                this.getData("first");
            },
            getData: function(info="") {
                let uri         = process.env.VUE_APP_APIHOST+'member/package/my';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords
                    }
                }).then(res => {
                    this.emptyState = false;
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;

                    if(this.dataList.length < 1) {
                        if(info == 'first') {
                            this.upgradeState = true;
                        } else {
                            this.emptyState = true;
                        }
                    }
                });
            },
            getDiscuss: function () {
                this.dataFetchGroup = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'pppk'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            }
        }
    }
</script>