<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Rumus DISC</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'disc_category_add'}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <table style="width:100%;table-layout: fixed;" class="table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width:10%">Kode</th>
                                                <th style="width:20%">Gaya Belajar</th>
                                                <th style="width:20%">Kepribadian</th>
                                                <th style="width:20%">Minat</th>
                                                <th style="width:20%">Bakat</th>
                                                <th style="width:15%">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="dataList.length > 0">
                                            <tr v-for="(item, index) in dataList" :key="index">
                                                <td style="vertical-align: top !important;padding:10px 10px 10px 10px !important">{{item.code}}</td>
                                                <td style="width:10%; vertical-align: top !important;padding:10px 10px 10px 10px !important" v-html="item.learning_style"></td>
                                                <td style="width:10%; vertical-align: top !important;padding:10px 10px 10px 10px !important" v-html="item.personality"></td>
                                                <td style="width:10%; vertical-align: top !important;padding:10px 10px 10px 10px !important" v-html="item.passion"></td>
                                                <td style="width:10%; vertical-align: top !important;padding:10px 10px 10px 10px !important" v-html="item.talent"></td>
                                                <td>
                                                    <router-link :to="{name : 'disc_category_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-t-10 m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                    <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Hapus</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="dataList.length < 1">
                                            <tr colspan="7">
                                                <td>Data tidak ditemukan</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'category',
        metaInfo: {
            title: 'Data Rumus DISC',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataList    : [],
                keywords    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/disc/category/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/disc/category/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }            
        }
    }
</script>
