<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><i class="fa fa-shopping-cart"></i> Keranjang</h5>
                        </div>

                        <div class="row" v-if="dataFetch">
                            <div class="col-sm-12" v-for="(item,index) in [1]" :key="index">
                                <div class="d-block border rounded-lg p-3 m-t-10">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="mr-3 w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="w-100">
                                                    <div class="skeleton-bar text-center">
                                                        <div class="sb-lg mb-2"></div>
                                                        <hr/>
                                                        <div class="sb-lg sb-thin mb-2"></div>
                                                        <div class="sb-lg sb-thin mb-4"></div>
                                                        <div class="sb-lg"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="!dataFetch">
                            <div v-if="dataList.list_cart.length > 0">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table invoice-detail-table">
                                                <thead>
                                                    <tr class="thead-default">
                                                        <th>Nama Item</th>
                                                        <th style="width:15%" class="text-right">Harga</th>
                                                        <th style="width:5%" class="text-center">Hapus</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.list_cart.length > 0">
                                                    <tr v-for="(item, index) in dataList.list_cart" :key="index">
                                                        <td>
                                                            <h6>{{item.item_name}}</h6>
                                                            <p class="m-0">Program : {{item.item_type.toUpperCase()}}</p>
                                                        </td>
                                                        <td class="text-right">
                                                            <s class='text-muted' v-if="item.text_price_out">{{item.text_price_out}}<br/></s>
                                                            {{item.text_price}}
                                                        </td>
                                                        <td class="text-center">
                                                            <button :disabled="waitingCartDelete" type="button" class="btn btn-sm btn-danger rounded btn-block" v-on:click="deleteCart(item.entity_id, item.item_type)">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row text-right">
                                    <div class="col-sm-12">
                                        <table class="table rounded table-responsive invoice-table invoice-total">
                                            <tbody>
                                                <tr>
                                                    <th>Sub Total :</th>
                                                    <td>{{dataList.text_total_price}}</td>
                                                </tr>
                                                <tr v-if="text_discount">
                                                    <th>Diskon :</th>
                                                    <td style="color:#43ab43"><b class="m-l-10"><u>{{text_discount}}</u></b></td>
                                                </tr>
                                                <tr>
                                                    <th>Kode Voucher :<br/>
                                                    <small>Gunakan Kode untuk mendapatkan potongan harga</small></th>
                                                    <td v-if="voucher">
                                                        <b>{{voucher}}</b>
                                                        <br/>
                                                        <a class="m-l-10" href="javascript:void(0)" v-on:click="gunakanKode">Ganti Kode</a>
                                                    </td>
                                                    <td v-if="!voucher">
                                                        <a class="m-l-10" href="javascript:void(0)" v-on:click="gunakanKode">Gunakan Kode</a>
                                                    </td>
                                                </tr>
                                                <tr class="text-info">
                                                    <td>
                                                        <hr>
                                                        <h4 class="text-primary m-r-10">Total Harga:</h4>
                                                    </td>
                                                    <td>
                                                        <hr>
                                                        <h4 class="text-primary">
                                                            <s style="color:red" v-if="voucher" class=m-r-10>{{text_before_price}}</s>
                                                            <span v-if="voucher" style="color:#43ab43">{{text_total_overall_price}}</span>
                                                            <span v-if="!voucher">{{text_total_overall_price}}</span>
                                                        </h4>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-sm-12">
                                        <button :disabled="processFetch" v-on:click="prosesPembayaran" type="button" class="btn btn-outline-primary rounded"><i class="fa fa-shopping-cart"></i>
                                            {{ processFetch ? 'Sedang membuat invoice ...' : 'Proses Pembayaran' }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UsersCart',
        metaInfo: {
            title: 'Data Keranjang',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                waitingCartDelete   : false,
                processFetch        : false,
                emptyState   : false,
                dataFetch   : false,
                dataList    : {
                    list_cart : []
                },
                voucher : '',
                text_discount : '',
                text_total_overall_price : '',
                text_before_price : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/cart';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.emptyState = false;
                    this.dataList   = res.data.data;
                    
                    this.text_total_overall_price = this.dataList.text_total_price;
                    if(this.dataList.list_cart.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteCart: function(id, type) {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus item ini dari keranjang ?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.waitingCartDelete    = true;

                        let formData    = new FormData();
                        formData.append('id', id);
                        formData.append('type', type);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/delete-cart', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.getData();
                                            this.$parent.$parent.getUsersCart();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                                this.waitingCartDelete = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waitingCartDelete = false;
                            return;
                        });
                    }
                });
            },
            prosesPembayaran: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Pembayaran',
                    text: 'Apakah kamu yakin ingin melakukan proses pembayaran ?',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if(result.value) {
                        this.processFetch = true;
                        let formData    = new FormData();
                        formData.append('category', 'tryout');
                        formData.append('voucher', this.voucher);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'user/checkout', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.$router.push({
                                                name: 'invoice_detail',
                                                params: {
                                                    invoice : response.data.data
                                                }
                                            });
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                                this.processFetch = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.processFetch = false;
                            return;
                        });
                    }
                });
            },
            gunakanKode: function() {
                this.$swal({
                    input: 'text',
                    text: 'Masukan kode',
                    inputPlaceholder: 'Masukan Kode Voucher...',
                    confirmButtonText: 'Gunakan Kode',
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        let formData    = new FormData();
                        formData.append('category', 'tryout');
                        formData.append('voucher', result.value);
                        this.$http.post(process.env.VUE_APP_APIHOST + 'user/check-voucher', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        html: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.voucher                    = response.data.data.voucher_code;
                                            this.text_total_overall_price   = response.data.data.text_total_price;
                                            this.text_discount              = response.data.data.text_discount;
                                            this.text_before_price          = response.data.data.text_before_price;
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            return;
                        });
                    }
                });                
            }
        }
    }
</script>
