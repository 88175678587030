<template>
    <div class="auth-wrapper">
        <div class="auth-content">
            <div class="card">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center">
                        <div class="card-body">
                            <img src="assets/images/logo.png" alt="" class="img-fluid mb-4">
                            <h3 class="mb-3 f-w-400">Daftar</h3>
                            <hr />
                            <form v-on:submit.prevent="onSubmit">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-mail"></i></span>
                                    </div>
                                    <input type="email" class="form-control" :class="{ 'is-invalid': submitted && $v.email.$error }" v-model="email" placeholder="Masukan email">
                                    <div v-if="submitted && !$v.email.required" class="invalid-feedback">Email wajib diisi!</div>    
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-user"></i></span>
                                    </div>
                                    <input type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.fullname.$error }" v-model="fullname" placeholder="Masukan nama">
                                    <div v-if="submitted && !$v.fullname.required" class="invalid-feedback">Nama wajib diisi!</div>    
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                    </div>
                                    <input type="password" class="form-control" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Masukan password">
                                    <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div>    
                                </div>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                    </div>
                                    <input type="password" class="form-control" :class="{ 'is-invalid': submitted && $v.re_password.$error }" v-model="re_password" placeholder="Ulangi password">
                                    <div v-if="submitted && !$v.re_password.required" class="invalid-feedback">Ulangi Password wajib diisi!</div>    
                                </div>

                                <div v-if="referral" class="alert alert-success text-center" role="alert">
                                    Kamu mendaftar menggunakan kode referral<br/>
                                    <b>{{this.referral}}</b>
                                </div>

                                <button type="submit" class="btn btn-block btn-primary mb-4" :disabled="waiting">
                                    {{ waiting ? 'Harap tunggu ...' : 'Daftar Ke Aplikasi' }}</button>

                            </form>
                            <p class="mb-0 text-muted">Sudah Punya Akun? <router-link :to="{name: 'signin'}"
                                    class="f-w-400">Login Sekarang!</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Signup',
        metaInfo: {
            title: 'Daftar',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                email: "",
                fullname: "",
                password: "",
                re_password: "",
                referral: this.$route.query.ref,
                submitted: false,
                waiting: false,
            }
        },
        validations: {
            email: {
                required
            },
            fullname: {
                required
            },
            password: {
                required
            },
            re_password: {
                required
            },
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form daftar',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST+'auth/signup', {
                        email       : this.email,
                        fullname    : this.fullname,
                        password    : this.password,
                        re_password : this.re_password,
                        referral    : this.referral,
                    })
                    .then(response => {
                        if(response.data.status) {
                          const token = response.data.user.key;
                          localStorage.setItem('auth_token', token);
                          location.href = location.origin + location.pathname + location.search
                        } else {
                            this.waiting = false;

                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }
        }
    }
</script>