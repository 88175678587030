<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Ajukan Pencairan Dana. </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-info" role="alert">
                                <b>Ketentuan Pencairan : </b>
                                <ul class="m-t-10">
                                    <li>Dana akan bisa dicairkan kalau terdapat saldo minimal Rp100.000 dalam voucher anda.</li>
                                    <li>Kalau sudah ada dana minimal Rp100.000 silakan klik ajukan pencairan dan ikuti prosesnya dalam mengajukan pencairan voucher nominal yang anda dapatkan.</li>
                                    <li>Isilah data-data dengan lengkap dalam proses pengajuan pencairan lalu klik kirim supaya kami bisa memproses dengan cepat. </li>
                                    <li>Untuk memproses cepat pencairan selain klik kirim, pastikan anda screenshot hasil form yang sudah di  diisi lalu kirim ke nomor WA kami untuk mempercepat proses pencairan.</li>
                                </ul>
                            </div>
                        </div>                        
                        <hr/>
                        <div class="col-md-6">
                            <div class="m-t-10">
                                <button data-toggle="modal" data-target="#modalReferral" class="btn btn-primary rounded" type="button"><i class="fa fa-database"></i> Ajukan Pencairan</button>
                                <router-link :to="{name : 'users_referral'}" class="m-l-10 btn btn-outline-primary rounded" type="button"> Kembali</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5 class="m-t-10">Riwayat Pengajuan Pencairan. </h5>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 d-none">
                            <div class="input-group mb-3 m-t-10">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama..." v-model="keywords" v-on:keyup.enter="getTransactions">
                                <div class="input-group-append">
                                    <button class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                            class="fa fa-search"></i> Cari </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="dataFetchTransactions">
                            <p>Sedang mengambil data...</p>
                        </div>

                        <div class="col-md-12" v-if="!dataFetchTransactions">
                            <div v-if="dataTranscations.length == 0">
                                <empty-state></empty-state>
                            </div>

                            <div v-if="dataTranscations.length > 0" class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">No</th>
                                            <th>Bank</th>
                                            <th>No Rekening</th>
                                            <th>Atas Nama</th>
                                            <th>Email</th>
                                            <th>Nomor Handphone</th>
                                            <th>Tanggal Pengajuan</th>
                                            <th class="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataTranscations" :key="index">
                                            <td style="width:5%" class="text-center">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>{{item.bank}}</td>
                                            <td>{{item.account_number}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.email}}</td>
                                            <td>{{item.phone}}</td>
                                            <td>{{item.redeem_date}}</td>
                                            <td class="text-center">
                                                <span v-if="item.status == 'approve' " class="badge badge-success">Disetujui</span>
                                                <span v-if="item.status == 'rejected' " class="badge badge-danger">Ditolak</span>
                                                <span v-if="item.status == 'waiting' " class="badge badge-secondary">Menunggu</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <nav aria-label="navigation" class="m-t-10">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalReferral" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ajukan Pencairan</div>
                                <div>
                                    <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        

                        <form v-on:submit.prevent="onSubmit">
                            <div class="d-block p-3">
                                <div class="d-flex align-items-center pb-3 row">
                                    <div class="col-md-12">
                                        <div class="m-t-10">
                                            <label>Nominal :  </label>
                                            <input type="number" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Nominal..." v-model="form.nominal"
                                            :class="{ 'is-invalid': submitted && $v.form.nominal.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.nominal.required" class="invalid-feedback d-block">
                                            Nominal Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Bank :  </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Contoh : BCA" v-model="form.bank"
                                            :class="{ 'is-invalid': submitted && $v.form.bank.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.bank.required" class="invalid-feedback d-block">
                                            Bank Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Nomor Rekening :  </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Contoh : 32713XXX" v-model="form.account_number"
                                            :class="{ 'is-invalid': submitted && $v.form.account_number.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.account_number.required" class="invalid-feedback d-block">
                                            Nomor Rekening Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Atas Nama :  </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Nama Pemilik Rekening..." v-model="form.name"
                                            :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback d-block">
                                            Atas Nama Wajib Diisi</div>
                                        </div>

                                        <div class="m-t-10">
                                            <label>Email :  </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Masukan Email..." v-model="form.email"
                                            :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.email.required" class="invalid-feedback d-block">
                                            Email Wajib Diisi</div>
                                        </div>


                                        <div class="m-t-10">
                                            <label>Nomor Handphone :  </label>
                                            <input type="text" autocomplete="off" class="form-control" id="exampleInputphone1" aria-describedby="phoneHelp" placeholder="Masukan Nomor Handphone..." v-model="form.phone"
                                            :class="{ 'is-invalid': submitted && $v.form.phone.$error }"
                                            >

                                            <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback d-block">
                                            Nomor Handphone Wajib Diisi</div>
                                        </div>

                                        <div class="alert alert-success m-t-10" role="alert">
                                            <b>Nominal</b> harus berupa <b>angka</b>. tidak boleh mengandung karakter unik.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Ajukan Pencairan Dana' }}</button>
                        </form>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Referral',
        metaInfo: {
            title: 'Referral',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetchTransactions   : true,
                dataTranscations        : [],
                label       : 'Buat',
                submitted   : false,
                waiting     : false,
                emptyState  : true,
                dataFetch   : true,
                form : {
                    nominal    : '',
                    url     : process.env.VUE_APP_APIHOST == 'https://api.griyabelajar.com/' ? 'https://app.griyabelajar.com/':'https://dev-app.griyabelajar.com/',
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                keywords : ''
            }
        },
        validations: {
            form: {
                nominal: {
                    required
                },
                bank: {
                    required
                },
                account_number: {
                    required
                },
                name: {
                    required
                },
                email: {
                    required
                },
                phone: {
                    required
                }
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/account/save_redeem', {
                        nominal : this.form.nominal,
                        bank : this.form.bank,
                        account_number : this.form.account_number,
                        name : this.form.name,
                        email : this.form.email,
                        phone : this.form.phone,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                html: response.data.message,
                            });

                            this.waiting    = false;
                            this.submitted  = false;
                        }
                    })
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/account/referral';

                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.emptyState             = false;
                        this.dataList               = res.data.data;
                        this.label                  = 'Ubah';
                        this.form.nominal              = this.dataList.nominal;
                        this.getTransactions();
                    }
                    else {
                        this.emptyState             = true;
                        this.dataFetch              = false;                
                    }

                    this.dataFetch              = false;                
                });
            },
            onCopy: function (e) {
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    html: 'Berhasil di salin : <br/><b>'+e.text+'</b>',
                });
            },
            onError: function (e) {
                alert('Failed to copy the text to the clipboard')
                console.log(e);
            },
            getTransactions: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/account/list_redeem';

                this.dataFetchTransactions  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetchTransactions  = false;                
                    this.dataTranscations       = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
        }
    }
</script>