<template>
    <div>
        <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
            <strong><i class="fa fa-info-circle"></i></strong>
            <span class="m-l-10">{{dataGroup.data.text_link}}</span>
            <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Tryout Gratis kedinasan</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input-group mb-3 m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        <div v-if="dataFetch" class="row">
                            <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                                    <div class="d-block border rounded-lg p-3 m-t-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar text-center">
                                                            <div class="sb-sm mb-2"></div>
                                                            <hr/>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-4"></div>
                                                            <div class="sb-lg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div v-if="!dataFetch">
                            <div v-if="dataList.length > 0" class="row">
                                <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                                    <div class="card card-theory">
                                        <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                        <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/default-cover.png" alt="cover">

                                        <div class="card-body">
                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                                </div>
                                            </div>

                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Waktu</div>
                                                    <div class="font-weight-600">{{item.timer}} Menit</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Jumlah Soal</div>
                                                    <div class="font-weight-600">{{item.total_questions}} Soal</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Status</div>
                                                    <div class="font-weight-600">
                                                        <span v-if="!item.owned" class="badge badge-danger">Belum dimiliki</span>
                                                        <span v-if="item.owned" class="badge badge-success">Sudah dimiliki</span>
                                                    </div>
                                                </div>

                                                <div v-if="!item.owned && !item.requirement && !item.requirement_status " class="alert alert-info text-center" role="alert">
                                                    Upload <b>persyaratan</b> untuk mendapatkan tryout gratis.
                                                </div>

                                                <div v-if="item.requirement_status == 'rejected'" class="alert alert-danger text-center" role="alert">
                                                    <b>Persyaratan</b> yang kamu upload <b>Tidak Disetujui</b>
                                                </div>

                                                <div v-if="!item.owned">
                                                    <button v-if="!item.requirement" type="button" class="btn btn-outline-primary rounded btn-block m-t-10" data-toggle="modal" data-target="#modalUpload" v-on:click="showItem(item.id)"><i class="fa fa-upload"></i> Upload Persyaratan</button>

                                                    <div v-if="item.requirement">
                                                        <div class="alert alert-warning text-center" role="alert">
                                                            Menunggu verifikasi persyaratan dari admin
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="item.owned">
                                                    <router-link :to="{name : 'member_tryout_skd_overview', params: { type_tryout: 'kedinasan', slug: item.code }}" v-if="!item.answer" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Kerjakan Soal</router-link>
                                                    <router-link :to="{name : 'member_tryout_skd_result_detail', params: { result_id: item.answer_id, tryout_answer_id: item.tryout_answer_id }}" v-if="item.answer" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-bars"></i> Lihat Hasil</router-link>
                                                </div>
                                                
                                                <router-link :to="{name : 'member_tryout_skd_rank', params: { type_tryout: 'kedinasan', slug: item.code }}" v-if="item.owned" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-trophy"></i> Rangking Peserta</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <nav aria-label="navigation">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                </div>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal" id="modalUpload" tabindex="-1" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file-image"></i> Upload Bukti Persyaratan</div>
                                <div>
                                    <button type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                                <div>
                                    <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Tryout</div>
                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{form.title}}</div>
                                </div>
                            </div>
                            <div v-if="dataRequirement.status != 'waiting'">
                                <div class="d-block mb-1 form-group">
                                    <label for="exampleInputEmail1">Tag 5 teman kamu di kolom komentar postingan Try Out</label>
                                    <div class="input-group mb-3">
                                        <input :value="dataRequirement.attachment_one" v-on:click="viewImage(dataRequirement.attachment_one)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Tag 5 teman kamu di kolom komentar postingan Try Out..." style="cursor:pointer">
                                        <input class="d-none" type="file" name="file_attachment_1" id="file_attachment_1" @change="previewFilesOne" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append" v-if="!dataRequirement.attachment_one">
                                            <button :disabled="uploadFileOne" v-on:click="uploadOne()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileOne ? 'Uploading...' : 'Upload Gambar' }}
                                            </button>
                                        </div>

                                        <div class="input-group-append" v-if="dataRequirement.attachment_one">
                                            <button :disabled="uploadFileOne" v-on:click="uploadOne()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileOne ? 'Uploading...' : 'Upload Ulang' }}
                                            </button>
                                        </div>

                                        <progress v-if="uploadFileOne" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                    </div>
                                </div>

                                <div class="d-block mb-1 form-group">
                                <label for="exampleInputEmail1">Share postingan/gambar Try Out ke grup WA/Telegram</label>
                                    <div class="input-group mb-3">
                                        <input :value="dataRequirement.attachment_two" v-on:click="viewImage(dataRequirement.attachment_two)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Share postingan/gambar Try Out ke grup WA/Telegram..." style="cursor:pointer">
                                        <input class="d-none" type="file" name="file_attachment_2" id="file_attachment_2" @change="previewFilesTwo" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append" v-if="!dataRequirement.attachment_two">
                                            <button :disabled="uploadFileTwo" v-on:click="uploadTwo()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileTwo ? 'Uploading...' : 'Upload Gambar' }}
                                            </button>
                                        </div>

                                        <div class="input-group-append" v-if="dataRequirement.attachment_two">
                                            <button :disabled="uploadFileTwo" v-on:click="uploadTwo()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileTwo ? 'Uploading...' : 'Upload Ulang' }}
                                            </button>
                                        </div>

                                        <progress v-if="uploadFileTwo" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                    </div>
                                </div>

                                <div class="d-block mb-1 form-group">
                                    <label for="exampleInputEmail1">Bukti Follow Instagram & Subscribe Youtube lembaga NHSC</label>
                                    <div class="input-group mb-3">
                                        <input :value="dataRequirement.attachment_three" v-on:click="viewImage(dataRequirement.attachment_three)" type="text" readonly class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Bukti Follow Instagram & Subscribe Youtube lembaga NHSC" style="cursor:pointer">
                                        <input class="d-none" type="file" name="file_attachment_3" id="file_attachment_3" @change="previewFilesThree" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append" v-if="!dataRequirement.attachment_three">
                                            <button :disabled="uploadFileThree" v-on:click="uploadThree()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileThree ? 'Uploading...' : 'Upload Gambar' }}
                                            </button>
                                        </div>

                                        <div class="input-group-append" v-if="dataRequirement.attachment_three">
                                            <button :disabled="uploadFileThree" v-on:click="uploadThree()" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">
                                                <i class="fa fa-upload"></i> {{ uploadFileThree ? 'Uploading...' : 'Upload Ulang' }}
                                            </button>
                                        </div>

                                        <progress v-if="uploadFileThree" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                                    </div>
                                </div>

                                <button :disabled="!requirement_id" type="button" v-on:click="kirimBukti" class="btn btn-primary rounded btn-block m-t-10"><i class="fas fa-paper-plane"></i> Kirim Bukti Persyaratan</button>
                            </div>

                            <div v-if="dataRequirement.status == 'waiting'">
                                <div class="alert alert-warning text-center" role="alert">
                                    Menunggu verifikasi persyaratan dari admin
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'TryoutFreekedinasan',
        metaInfo: {
            title: 'Tryout Gratis kedinasan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataFetchGroup  : false,
                dataList        : [],
                dataGroup       : [],
                emptyState      : false,
                keywords        : "",
                files            : [],
                preview_image_1    : "",
                file_attachment_1  : "",
                uploadPercentage : 0,
                uploadFileOne       : false,
                uploadFileTwo       : false,
                uploadFileThree       : false,
                form            : {
                    id : '',
                    title : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                dataRequirement:[],
                requirement_id:""
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
            this.getDiscuss();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'free',
                        type_tryout : 'kedinasan',
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            showItem: function(id) {
                var item    = this.dataList.find(item => item.id === id);

                this.form.id                = item.id;
                this.form.title             = item.title;

                let uri         = process.env.VUE_APP_APIHOST+'member/check/requirement';
      
                this.$http.get(uri,{
                    params : {
                        entity_id   : this.form.id,
                        type : 'kedinasan'
                    }
                }).then(res => {
                    this.dataRequirement = res.data.data;
                    this.requirement_id  = this.dataRequirement.id;
                });

            },
            uploadOne: function () {
                document.getElementById("file_attachment_1").click()
            },
            previewFilesOne(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileOne      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_one', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'kedinasan');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileOne = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadTwo: function () {
                document.getElementById("file_attachment_2").click()
            },
            previewFilesTwo(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileTwo      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_two', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'kedinasan');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileTwo = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            uploadThree: function () {
                document.getElementById("file_attachment_3").click()
            },
            previewFilesThree(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.uploadFileThree      = true;
                    
                    let formData    = new FormData();
                    formData.append('attachment_three', file);
                    formData.append('entity_id', this.form.id);
                    formData.append('type', 'kedinasan');

                    this.$http.post(process.env.VUE_APP_APIHOST+'member/upload/requirement', formData , {
                        onUploadProgress: function(progressEvent) {
                            this.uploadPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                        }.bind(this)
                    })
                    .then(response => {
                        this.uploadFileThree = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.showItem(this.form.id);
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            viewImage: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            kirimBukti: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kirim bukti ?',
                    text: 'Apakah kamu yakin akan mengirim bukti persyaratan ? ',
                    confirmButtonText: 'Kirim',
                    showCancelButton: true,
                    cancelButtonText: 'Cek kembali',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'member/submit/requirement', {
                            id: this.requirement_id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.showItem(this.form.id);
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });                
            },
            getDiscuss: function () {
                this.dataFetchGroup = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'kedinasan'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            }
        }
    }
</script>
