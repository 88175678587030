<template>
    <div>
        <div v-if="dataFetchUsers" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!dataFetchUsers && dataUsers.type == 'content' && dataUsers.subjects_id != '')" class="alert alert-info alert-dismissible fade show d-flex" role="alert">
            <strong><i class="fa fa-info-circle"></i></strong>
            <span class="m-l-10">Saat ini kamu terdaftar sebagai guru dari sekolah <b>{{ dataUsers.name }}</b> pada mata pelajaran :
                <ul class="mb-0 mt-2">
                    <li class="mb-0" v-for="(itemSubject, indexSubject) in dataUsers.subject_list" :key="indexSubject">{{ itemSubject.name }}</li>
                </ul>                
            </span>
            <!-- Untuk mengubah mata pelajaran, silahkan <a href="javascript:void(0)" data-toggle="modal" data-target="#modalForm" class="font-weight-bold" target="blank">klik disini</a></span> -->
        </div>

        <div v-if="(!dataFetchUsers && dataUsers.type == 'admin')" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Tryout CBT</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'cbt_tryout_add'}">Tambah Data</router-link>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="school_id" v-on:change="getData">
                                        <option value="">Lihat Semua</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-8">
                                <label for="exampleFormControlSelect1">Kata kunci</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="m-t-20">
                                    <div class="table-responsive" v-if="dataList.length > 0">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="width:5%">No</th>
                                                    <th>Kategori</th>
                                                    <th style="width:45%">Nama</th>
                                                    <th style="width:15%" class="text-center">Status</th>
                                                    <th style="width:15%">Cover</th>
                                                    <th style="width:10%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.category}}</td>
                                                    <td>{{item.title}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                                        <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                                    </td>
                                                    <td v-if="!item.cover">-</td>
                                                    <td v-if="item.cover">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <a :href="item.cover" data-lightbox="1" target="blank">
                                                                    <img :src="item.cover" alt="" class="img-fluid img-thumbnail" style="width:100%">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link :to="{name : 'cbt_tryout_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                        <router-link :to="{name : 'cbt_tryout_questions', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Kelola Soal" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-list"></i> Kelola Soal</router-link>
                                                        <router-link :to="{name : 'cbt_tryout_monitoring', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Kelola Soal" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-list"></i> Monitoring</router-link>
                                                        <router-link :to="{name : 'view_naskah_tryout_cbt_detail', params: { id: item.id}}" class="btn btn-primary rounded btn-block m-t-10">
                                                            <i class="fa fa-bars"></i> Lihat Naskah
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataList.length < 1">
                                                <tr colspan="5">
                                                    <td>Data tidak ditemukan</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav aria-label="navigation" class="m-t-20" v-if="dataList.length > 0">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!dataFetchUsers && dataUsers.type == 'content' && dataUsers.subjects_id != '')" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Tryout CBT</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'cbt_tryout_add'}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="m-t-20">
                                    <div class="table-responsive" v-if="dataList.length > 0">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center" style="width:5%">No</th>
                                                    <th>Kategori</th>
                                                    <th style="width:45%">Nama</th>
                                                    <th style="width:15%" class="text-center">Status</th>
                                                    <th style="width:15%">Cover</th>
                                                    <th style="width:10%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.category}}</td>
                                                    <td>{{item.title}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                                        <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                                    </td>
                                                    <td v-if="!item.cover">-</td>
                                                    <td v-if="item.cover">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <a :href="item.cover" data-lightbox="1" target="blank">
                                                                    <img :src="item.cover" alt="" class="img-fluid img-thumbnail" style="width:100%">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <router-link :to="{name : 'cbt_tryout_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded m-r-10"><i class="fas fa-trash"></i> Hapus</button>
                                                        <router-link :to="{name : 'cbt_tryout_questions', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Kelola Soal" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-list"></i> Kelola Soal</router-link>
                                                        <router-link :to="{name : 'cbt_tryout_monitoring', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Kelola Soal" type="button" class="btn btn-primary btn-sm rounded"><i class="fas fa-list"></i> Monitoring</router-link>
                                                        <router-link :to="{name : 'view_naskah_tryout_cbt_detail', params: { id: item.id}}" class="btn btn-primary rounded btn-block m-t-10">
                                                            <i class="fa fa-bars"></i> Lihat Naskah
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataList.length < 1">
                                                <tr colspan="5">
                                                    <td>Data tidak ditemukan</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <nav aria-label="navigation" class="m-t-20" v-if="dataList.length > 0">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!dataFetchUsers && dataUsers.type == 'content' && dataUsers.subjects_id == '')" class="page-header">
            <div class="page-block">
                <div class="row align-items-center h-100">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Ayo bergabung dan dapatkan tryout dari sekolah! </h5>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="alert alert-info" role="alert">
                            <b>Informasi : </b>
                            <ul class="m-t-10">
                                <li>Harap pilih mata pelajaran sesuai dengan mata pelajaran kamu pada inputan dibawah ini.</li>
                                <li>Mata pelajaran <b>hanya dapat dipilih 1x.</b></li>
                                <li>Harap hubungi admin untuk info lebih lanjut.
                                    <a href="https://web.whatsapp.com/send?phone=6287837850461" class="font-weight-bold" target="blank">Hubungi Admin</a>
                                </li>
                                <li>Good luck! 😊</li>
                            </ul>
                        </div>
                    </div>                        

                </div>
                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8 col-sm-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Pilih Mata Pelajaran (Bisa lebih dari 1)</label>
                                <multiselect
                                    v-model="subject" tag-placeholder="Add this as new tag"
                                    placeholder="Pilih Mata Pelajaran" label="name" track-by="id" :options="dataSubjects"
                                    :multiple="true" :taggable="true"></multiselect>

                            </div>                        
                        </div>                        
                        <div class="col-md-3 col-sm-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">&nbsp;</label>
                                <button type="submit" class="btn btn-primary btn-block rounded"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>                
        </div>
        <!-- Modal -->
        <!--div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Asal Sekolah</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Pilih Mata Pelajaran</label>
                                        <select class="form-control" v-model="subjects_id" :class="{ 'is-invalid': submitted && $v.subjects_id.$error }">
                                            <option value="">Pilih Mata Pelajaran</option>
                                            <option v-for="(item, index) in dataSubjects" :key="index" :value="item.id">{{ item.name }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.subjects_id.required" class="invalid-feedback d-block">Mata Pelajaran Wajib Dipilih</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                    </form>
                </div> 
            </div>
        </div-->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    // import {
    //     required
    // } from "vuelidate/lib/validators";

    export default {
        name: 'TryoutCbt',
        metaInfo: {
            title: 'Data Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                submitted   : false,
                waiting     : false,
                emptyState  : false,
                dataFetch   : false,
                dataList    : [],
                dataSchool  : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                dataFetchUsers: false,
                dataUsers     : [],
                dataSubjects     : [],
                subjects_id   : '',
                subject       : [],
                school_id   : "",
            }
        },
        created() {
            this.getUsers();
            this.getSchool();
        },
        // validations: {
        //     subject_id: {
        //         required
        //     },
        // },
        methods: {
            getSubjects: function(id, name) {
                let school_id = [{
                    id : id,
                    name : name
                }];

                let uri         = process.env.VUE_APP_APIHOST+'admin/subjects-list';
                this.$http.get(uri, {
                    params : {
                        school_id : JSON.stringify(school_id)
                    }
                }).then(res => {
                    this.dataSubjects   = res.data.data;
                });
            },
            getUsers: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/school';

                this.dataFetchUsers  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchUsers = false;                
                    this.dataUsers = res.data.data;
                    if(this.dataUsers.type == 'content') {
                        this.subjects_id = this.dataUsers.subjects_id;

                        this.getSubjects(this.dataUsers.id, this.dataUsers.name);
                        this.getData()
                    } else {
                        this.getData();
                    }
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        school_id   : this.school_id,
                        subjects_id : this.subjects_id,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/tryout/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                 if (this.subject.length < 1) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {
                    let subject_id = [];
                    this.subject.forEach(element => {
                        subject_id.push(element.id)
                    });

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        subjects_id : subject_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: "Data berhasil disimpan!",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.getUsers();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }
        }
    }
</script>
