<template>
    <div>
        <div v-if="userSubscriberFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!userSubscriberFetch">
            <div v-if="dataFetch" class="page-header">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-3 w-100">
                        <div class="d-flex align-items-center">
                            <div class="w-100">
                                <div class="skeleton-bar">
                                    <div class="sb-sm m-b-2"></div>
                                    <hr/>
                                    <div class="sb-sm sb-thin mb-2"></div>
                                    <div class="sb-md sb-thin mb-2"></div>
                                    <div class="sb-md sb-thin mb-4"></div>

                                    <div class="sb-md sb-thin mb-2"></div>
                                    <div class="sb-lg sb-thin mb-2"></div>
                                    <div class="sb-lg sb-thin mb-4"></div>

                                    <div class="sb-md sb-thin mb-2"></div>
                                    <div class="sb-lg sb-thin mb-2"></div>
                                    <div class="sb-lg sb-thin mb-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!dataFetch" class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="page-header-title">
                                <h5><router-link :to="{name : 'member_materi_saintek_text'}"><i class="fa fa-arrow-left m-r-10"></i></router-link>
                                    Materi - {{dataDetail.category}} - {{dataDetail.title}}
                                </h5>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-html="dataDetail.content">
                            </div>
                        </div>
                    </div>
                </div>

                <a v-if="dataDetail.attachment" :href="dataDetail.attachment" class="rounded btn btn-primary floating-button">
                    <i class="fa fa-download"></i> Unduh File
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MateriUtbk',
        metaInfo: {
            title: 'Materi Text SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                userSubscriberFetch : false,
                dataFetch           : false,
                dataDetail          : []
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/utbk/materi/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'text',
                        program     : this.$route.params.program,
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.owned) {
                        this.dataFetch  = false;         
                        this.dataDetail   = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Halaman ini tidak bisa di akses oleh pengguna gratis !',
                        });
                        this.$router.push({ name: 'upgrade_package', params: { type: 'utbk' } });
                    }
                });
            }
        }
    }
</script>