<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <h4 class="m-t-10 m-b-30">
                                Hafalkan kata kata dibawah ini selama 3 menit!
                            </h4>
                            <div class="alert alert-warning" >
                                <table style="margin-left: auto !important; margin-right: auto !important;">
                                    <tr>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important; width:25%">BUNGA</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;">:</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">SOKA, LARAT, FLAMBOYAN, YASMIS, DAHLIA</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">PERKAKAS</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;">:</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">WAJAN, JARUM, KIKIR, CANGKUL, PALU</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">BURUNG</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;">:</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">ITIK, ELANG, WALET, TEKUKUR, NURI</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">KESENIAN</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;">:</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">QUINTET, ARCA, OPERA, UKIRAN, GAMELAN</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">BINATANG</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;">:</td>
                                        <td style="vertical-align: top !important; font-size: 15px; color: black;text-align:left !important;">RUSA, MUSANG, BERUANG, HARIMAU, ZEBRA</td>
                                    </tr>
                                </table>
                            </div>
                            <!-- <img style="width:60%;pointer-events: none;" src="assets/images/me.png" alt="" class="img-fluid mb-4 image-responsive"> -->
                            <h4 class="m-t-10">
                                Waktu Tersisa

                                <vue-countdown-timer
                                    @start_callback="startCallBack"
                                    @end_callback="endCallBack"
                                    :start-time="startTime"
                                    :end-time="endTime"
                                    :interval="1000">

                                    <div class="text-center m-t-10" slot="countdown" slot-scope="scope">
                                        <span>{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>  
                                    </div>
                                </vue-countdown-timer>
                            </h4>
                            <!-- <button class="btn btn-outline-primary rounded" v-on:click="skip" type="button">Lewati Waktu <i class="m-l-10 fa fa-arrow-right"></i> </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BreakQuiz',
        metaInfo: {
            title: 'Mengerjakan Soal ME',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : true,
                startTime   : 1,
                endTime     : 5,
                id_start    : ""
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                this.dataFetch  = true;
                let uri         = process.env.VUE_APP_APIHOST+'member/ist/tryout/check-break';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug        : this.$route.params.slug,
                    }
                }).then(res => {
                    const response = res.data;
                    if(response.status) {
                        this.dataFetch  = false;
                        this.startTime  = response.data.currentTime;
                        this.endTime    = response.data.expireDate;
                        this.id_start   = response.data.id_start;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_psikotest_overview',
                                    params : {
                                        id : this.$route.params.psikotest_id,
                                        slug : this.$route.params.slug
                                    }
                                });
                            }
                        });                        
                    }
                });
            },
            skip: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Lewati Waktu',
                    text: 'Apakah kamu yakin ingin melewati Waktu ?',
                    confirmButtonText: 'Ya Lewati',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.stopBreak();
                    }
                });
            },
            startCallBack: function () {
                console.log("waktu di mulai");
            },
            endCallBack: function () {
                console.log("waktu di selesai");
                this.stopBreak();
            },
            stopBreak: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/ist/tryout/stop-break', {
                    id: this.id_start
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Waktu selesai!',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_psikotest_overview',
                                    params : {
                                        id : this.$route.params.psikotest_id,
                                        slug : this.$route.params.slug
                                    }
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            }
        }        
    }
</script>