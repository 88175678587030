<template>
    <div class="auth-wrapper">
        <div class="auth-content">
            <div class="card">
                <div class="row align-items-center">
                    <div class="col-md-12 text-center" v-if="dataFetch">
                        <div class="card-body">
                            <p>Loading...</p>
                        </div>
                    </div>
                    <div class="col-md-12 text-center" v-if="!dataFetch">
                        <div class="card-body">
                            <img src="assets/images/logo.png" alt="" class="img-fluid mb-4" v-if="!dataSchool.logo">
                            <img :src="dataSchool.logo" alt="" class="img-fluid mb-4" v-if="dataSchool.logo" style="width:150px">
                            <h4 class="mb-3 f-w-400">Login CBT<br/><b>{{ dataSchool.name }}</b></h4>
                            <hr />
                            <form v-on:submit.prevent="onSubmit">

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-mail"></i></span>
                                    </div>
                                    <input type="email" class="form-control" :class="{ 'is-invalid': submitted && $v.email.$error }" v-model="email" placeholder="Masukan email">
                                    <div v-if="submitted && !$v.email.required" class="invalid-feedback">Email wajib diisi!</div>    
                                </div>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                    </div>
                                    <input :type="showPassword ? 'text' : 'password'" class="form-control" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" placeholder="Masukan password">
                                    <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password wajib diisi!</div> 

                                    <div class="input-group-append">
                                        <button class="btn btn-primary" @click="showPassword = !showPassword" type="button">
                                            <i :class="[showPassword ? 'feather icon-eye-off' : 'feather icon-eye']"></i>
                                        </button>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-block btn-primary" :disabled="waiting">
                                    {{ waiting ? 'Harap tunggu ...' : 'Masuk Ke Aplikasi' }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'SigninCBT',
        metaInfo: {
            title: 'Login',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                email: "",
                password: "",
                submitted: false,
                waiting: false,
                dataFetch : true,
                dataSchool : [],
                showPassword: false
            }
        },
        validations: {
            email: {
                required
            },
            password: {
                required
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST+'auth/signin', {
                        email    : this.email,
                        password : this.password,
                        cbt_active : 1
                    })
                    .then(response => {
                        if(response.data.status) {
                          const token = response.data.user.key;
                          localStorage.setItem('auth_token', token);
                          location.href = location.origin + location.pathname + location.search
                        } else {
                            this.waiting = false;
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'website/check-school';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        code     : this.$route.params.school_id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        this.dataSchool   = res.data.data;
                    } else {
                        this.$router.push({ name: 'notfound' });
                    }
                });
            }
        }
    }
</script>