<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Kelola Soal - {{dataExercises.utbk_exercises.title}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'utbk_exercises'}">Latihan SNBT</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Kelola Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-7">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Data Latihan</h5>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i> Kategori</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataExercises.utbk_exercises.category}}</td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important"><i class="fas fa-book-open m-r-5"></i> Judul Latihan</td>
                                    <td style="border-top:0px !important" class="text-right">{{dataExercises.utbk_exercises.title}}</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-clock m-r-5"></i> Durasi Soal</td>
                                    <td class="text-right">{{dataExercises.utbk_exercises.timer}} Menit</td>
                                </tr>
                                <tr>
                                    <td><i class="fas fa-thermometer-half m-r-5"></i> Status:</td>
                                    <td class="text-right">
                                        <span v-if="dataExercises.utbk_exercises.status == 'publish' " class="badge badge-success">Publish</span>
                                        <span v-if="dataExercises.utbk_exercises.status == 'draft' " class="badge badge-secondary">Draft</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Nomor Soal</h5>
                    </div>
                    <div class="card-body">
                        <div v-if="dataExercises.list_questions.length == 0">
                            <div class="alert alert-secondary text-center" role="alert">
                                Anda belum membuat soal pada latihan ini
                            </div>
                        </div>

                        <div v-if="dataExercises.list_questions.length > 0" class="text-center" style="padding-top:0px !important;overflow: auto;max-height: 150px;padding-bottom:5px;scrollbar-width: thin;">
                            <button type="button" v-on:click="setQuestions(item.id, index)" style="width:55px;" v-for="(item, index) in dataExercises.list_questions" :key="index" class="btn rounded m-r-10 m-t-10" :class="currentQuestions == item.id ? 'btn-primary':'btn-outline-primary'"> {{++index}}</button>
                        </div>
                        <hr/>
                        <button type="button" v-on:click="addQuestions" class="btn btn-primary btn-block"><i class="fa fa-plus"></i> Tambah Soal</button>
                        <button v-if="form.id" type="button" class="btn btn-danger btn-block" v-on:click="deleteQuestions"><i class="fa fa-trash"></i> Hapus Soal</button>
                        <div class="btn-group btn-block">
                            <button type="button" class="btn btn-light btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-list"></i> Lainnya</button>
                            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 35px, 0px);">
                                <a href="javascript:void(0)" class="dropdown-item" v-on:click="resetQuestions"><i class="fa fa-trash"></i> Reset Soal </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12" v-if="showQuestions">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>{{form_title}}</h5>
                    </div>
                    <div class="card-body">
                            <form v-on:submit.prevent="onSubmit">
                                <input type="hidden" class="form-control" id="exampleInputEmail1" placeholder="Masukan poin..." v-model="form.id">
                                <div class="row">
                                    
                                    <div class="form-group col-md-6">
                                        <label for="exampleFormControlSelect1">Sub Kategori Soal</label>
                                        <select class="form-control" id="exampleFormControlSelect1" v-model="form.utbk_sub_category_id">
                                            <option value="">Pilih Sub Kategori</option>
                                            <option v-for="(item, index) in dataSubCategory" :key="index" :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Poin</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan poin..." v-model="form.point">
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Pertanyaan</label>
                                    <ckeditor :config="editorConfig" v-model="form.question"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi A</label>
                                    <ckeditor :config="editorConfig" v-model="form.options_a"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi B</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_b"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi C</label>
                                    <ckeditor :config="editorConfig" v-model="form.options_c"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi D</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_d"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Opsi E</label>
                                   <ckeditor :config="editorConfig" v-model="form.options_e"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleFormControlSelect1">Jawaban Benar</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.answer">
                                        <option value="">Pilih Jawaban</option>
                                        <option value="a">A</option>
                                        <option value="b">B</option>
                                        <option value="c">C</option>
                                        <option value="d">D</option>
                                        <option value="e">E</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Pembahasan</label>
                                    <ckeditor :config="editorConfig" v-model="form.discussion"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Video Pembahasan</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url youtube..." v-model="form.discussion_video">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Soal' }}</button>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Questions',
        metaInfo: {
            title: 'Kelola Soal Latihan UTBK',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label               : 'Tambah',
                form_title          : 'Tambah Soal',
                currentQuestions    : '',
                showQuestions       : false,
                dataExercises          : {
                    utbk_exercises : [],
                    list_questions : []
                },
                dataCategory        : [],
                dataSubCategory     : [],
                form : {
                    id                  : "",
                    utbk_exercises_id       : "",
                    utbk_category_id        : "",
                    utbk_sub_category_id    : "",
                    point               : "",
                    question            : "",
                    options_a           : "",
                    options_b           : "",
                    options_c           : "",
                    options_d           : "",
                    options_e           : "",
                    answer              : "",
                    discussion          : "",
                    discussion_video    : "",
                },
                submitted       : false,
                waiting         : false,
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false                    
                }
            }
        },
        validations: {
            form: {
                utbk_category_id: {
                    required
                }
            }
        },
        methods: {
            getSubCategory: function() {
                if(this.form.utbk_category_id != '') {
                    let uri         = process.env.VUE_APP_APIHOST+'admin/utbk/sub_category/list';
                    
                    this.$http.get(uri,{
                        params : {
                            utbk_category_id : this.form.utbk_category_id
                        }
                    }).then(res => {
                        this.dataSubCategory   = res.data.data;
                    });
                } else {
                    this.dataSubCategory   = [];
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();
                    
                    formData.append('id', this.form.id);
                    formData.append('utbk_exercises_id', this.dataExercises.utbk_exercises.id);
                    formData.append('utbk_sub_category_id', this.form.utbk_sub_category_id);
                    formData.append('point', this.form.point);
                    formData.append('question', this.form.question);
                    formData.append('options_a', this.form.options_a);
                    formData.append('options_b', this.form.options_b);
                    formData.append('options_c', this.form.options_c);
                    formData.append('options_d', this.form.options_d);
                    formData.append('options_e', this.form.options_e);
                    formData.append('answer', this.form.answer);
                    formData.append('discussion', this.form.discussion);
                    formData.append('discussion_video', this.form.discussion_video);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/utbk/exercises/save_questions', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/utbk/exercises/manage_questions';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.dataExercises = getResponse;

                            this.form.utbk_category_id = this.dataExercises.utbk_exercises.utbk_category_id;
                            this.getSubCategory();
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'utbk_exercises' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.attachment = file;
                }
            },
            addQuestions : function() {
                this.form_title = "Tambah Soal";
                this.showQuestions = true;
                this.form.id                  = "";
                this.form.utbk_sub_category_id     = "";
                this.form.point               = "";
                this.form.question            = "";
                this.form.options_a           = "";
                this.form.options_b           = "";
                this.form.options_c           = "";
                this.form.options_d           = "";
                this.form.options_e           = "";
                this.form.answer              = "";
                this.form.discussion          = "";
                this.form.discussion_video          = "";

            },
            setQuestions: function(id, index) {
                this.form_title         = "Edit Soal - Nomor "+index;
                this.currentQuestions   = id;

                var item = this.dataExercises.list_questions.find(item => item.id === id);

                this.form.id                  = item.id;
                this.form.utbk_category_id         = this.dataExercises.utbk_exercises.utbk_category_id;

                this.getSubCategory(item.utbk_category_id);

                setTimeout(() => {
                    this.form.utbk_sub_category_id     = item.utbk_sub_category_id;                    
                }, 100);
                
                this.form.point               = item.point;
                this.form.question            = item.question;
                this.form.options_a           = item.options_a;
                this.form.options_b           = item.options_b;
                this.form.options_c           = item.options_c;
                this.form.options_d           = item.options_d;
                this.form.options_e           = item.options_e;
                this.form.answer              = item.answer;
                this.form.discussion        = item.discussion;
                this.form.discussion_video  = item.discussion_video;

                this.form.id = id;
                this.showQuestions = true;
            },
            deleteQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Hapus Data',
                    text: 'Apakah kamu yakin ingin menghapus soal ini?',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/utbk/exercises/delete_questions', {
                            id: this.form.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
            resetQuestions: function () {
                this.$swal({
                    icon: 'info',
                    title: 'Reset Soal',
                    text: 'Apakah kamu yakin ingin melakukan reset soal ? Semua data yang sudah di reset tidak dapat dikembalikan',
                    confirmButtonText: 'Hapus',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/utbk/exercises/reset_questions', {
                            id: this.dataExercises.utbk_exercises.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getDataByID(this.$route.params.id);    
                                        this.addQuestions();
                                        this.showQuestions = false;                                    
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
        }
    }
</script>