<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h4 class="m-t-10">
                                Hasil Tryout
                            </h4>
                            <p>Berikut adalah hasil tryout yang telah dikerjakan</p>
                            <hr/>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th rowspan="2" style="width:5%" class="text-center">No</th>
                                                        <th rowspan="2" >Nama Tryout</th>
                                                        <th rowspan="2" style="width:10%">Tanggal<br/>Mengerjakan</th>
                                                        <th colspan="3" class="text-center" style="width:10%">Skor</th>
                                                        <th rowspan="2" class="text-center" style="width:10%">Skor<br/>Akhir</th>
                                                        <th rowspan="2" class="text-center" style="width:10%">Status</th>
                                                        <th rowspan="2" class="text-center" style="width:10%">Opsi</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-center" style="width:10%">TWK</th>
                                                        <th class="text-center" style="width:10%">TIU</th>
                                                        <th class="text-center" style="width:10%">TKP</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">{{++index}}</td>
                                                        <td>{{item.title}}</td>
                                                        <td>{{item.start}}</td>
                                                        <td class="text-center">
                                                            {{item.nilai_twk}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.nilai_tiu}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.nilai_tkp}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.skor_akhir}}
                                                        </td>
                                                        <td class="text-center">
                                                            <span v-if="item.status == 'lulus'" class="badge badge-success">Lulus</span>
                                                            <span v-if="item.status == 'tidak'" class="badge badge-danger">Tidak Lulus</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <router-link :to="{name : 'member_tryout_skd_result_detail', params: { result_id: item.id, tryout_answer_id: item.tryout_answer_id }}" class="btn btn-outline-primary btn-sm rounded">Statistik dan pembahasan</router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr/>
                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ResulTryout',
        metaInfo: {
            title: 'Hasil Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState   : false,
                dataFetch   : false,
                dataList    : [],
                keywords    : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/result';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        type_tryout : 'cpns',
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            changeStatus: function (id, status) {
                let text = "";
                if(status == 'rejected') {
                    text = "Apakah kamu yakin akan menolak data ini ?";
                } else {
                    text = "Apakah kamu yakin akan menkonfirmasi data ini ?";
                }
                this.$swal({
                    icon: 'info',
                    title: 'Konfirmasi Status',
                    text: text,
                    confirmButtonText: 'Iya',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/tryout/free/confirm', {
                        id      : id,
                        status  : status
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            }            
        }
    }
</script>
