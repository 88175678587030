<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5><router-link :to="{name : 'member_materi_akm', params : {id : $route.params.id}}"><i class="fa fa-arrow-left m-r-10"></i></router-link>
                                Materi - {{dataDetail.category}} - {{dataDetail.title}}
                            </h5>
                        </div>
                    </div>
                    <div v-if="dataDetail.type == 'text'" class="col-md-12">
                        <div v-html="dataDetail.content">
                        </div>
                    </div>

                    <div v-if="dataDetail.type == 'youtube'" class="col-md-12">
                        <vue-plyr>
                        <div class="plyr__video-embed">
                            <iframe
                            :src="dataDetail.content"
                            allowfullscreen
                            allowtransparency
                            allow="autoplay"
                            ></iframe>
                        </div>
                        </vue-plyr>
                    </div>
                </div>
            </div>

            <a v-if="dataDetail.attachment" :href="dataDetail.attachment" class="rounded btn btn-primary floating-button">
                <i class="fa fa-download"></i> Unduh File
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Materiakm',
        metaInfo: {
            title: 'Materi Detail AKM',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch           : false,
                dataDetail          : []
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/akm/theory/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id      : this.$route.params.id,
                        slug    : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;         
                        this.dataDetail   = res.data.data;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'upgrade_package', params: { type: 'akm' } });
                            }
                        });
                    }
                });
            }
        }
    }
</script>