<template>
    <div class="page-header">
        <div class="page-block">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="page-header-title text-center">
                        <img style="width:50%" src="assets/images/maintenance.jpg" alt="" class="img-fluid mb-4">
                        <h4 class="m-t-10">Halaman ini sedang dalam tahap pengembangan, harap tunggu beberapa saat lagi...</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Maintenance',
        metaInfo: {
            title: 'Maintenance',
            titleTemplate: '%s - Griya Belajar'
        },
    }
</script>