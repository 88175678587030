<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Voucher Sekolah</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item" v-if="users.type == 'admin'">
                                <router-link :to="{name : 'voucher_school'}">Voucher Sekolah</router-link>
                            </li>
                            <li class="breadcrumb-item" v-if="(users.type == 'proctor')">
                                <router-link :to="{name : 'monitoring_voucher_school'}">Voucher Sekolah</router-link>
                            </li>
                            <li class="breadcrumb-item" v-if="(users.type == 'content')">
                                <router-link :to="{name : 'monitoring_voucher_school_content'}">Voucher Sekolah</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode Voucher</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.code" :class="{ 'is-invalid': submitted && $v.form.code.$error }">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Kode Voucher wajib diisi!</div>    
                                    <small><i>Kode voucher tidak boleh sama.</i></small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kuota</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.quota" :class="{ 'is-invalid': submitted && $v.form.quota.$error }">
                                    <div v-if="submitted && !$v.form.quota.required" class="invalid-feedback">Kuota wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Berlaku Hingga (Masukan Waktu Awal)</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.expired_at" :class="{ 'is-invalid': submitted && $v.form.expired_at.$error }">
                                    <div v-if="submitted && !$v.form.expired_at.required" class="invalid-feedback">Masukan batas berlaku!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Berlaku Hingga (Masukan Waktu Akhir)</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.expired_end" :class="{ 'is-invalid': submitted && $v.form.expired_end.$error }">
                                    <div v-if="submitted && !$v.form.expired_end.required" class="invalid-feedback">Masukan batas berlaku!</div>    
                                </div>

                                <div class="form-group col-md-6" v-if="users.type == 'admin'">
                                    <label for="exampleFormControlSelect1">Sekolah</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.school_id" :class="{ 'is-invalid': submitted && $v.form.school_id.$error }" v-on:change="getTryout">
                                        <option value="">Pilih Sekolah</option>
                                        <option v-for="(item, index) in dataSchool" :key="index" :value="item.id">{{item.name}}</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.school_id.required" class="invalid-feedback">Sekolah wajib dipilih!</div>    
                                </div>
                                <div class="form-group col-md-6" v-if="(users.type != 'content')">
                                    <label for="exampleFormControlSelect1">Paket</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }" v-on:change="getTryout">
                                        <option value="">Pilih Paket</option>
                                        <option value="utbk">SNBT</option>
                                        <option value="kedinasan">Kedinasan</option>
                                        <option value="psikotest">Psikotest</option>
                                        <option value="akm">Sekolah (AKM)</option>
                                        <option value="cbt">CBT (Tryout)</option>
                                        <option value="cbt_theory">CBT (Materi)</option>
                                        <option value="cbt_exercises">CBT (Latihan)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Paket wajib dipilih!</div>    
                                </div>
                                <div class="form-group col-md-6" v-if="(users.type == 'content')">
                                    <label for="exampleFormControlSelect1">Paket</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }" v-on:change="getTryout">
                                        <option value="">Pilih Paket</option>
                                        <option value="cbt_exercises">CBT (Latihan)</option>
                                        <option value="cbt_theory">CBT (Materi)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Paket wajib dipilih!</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">{{form.type == 'psikotest' ? 'Paket Psikotest' : form.type == 'cbt_theory' ? 'Materi':form.type == 'cbt_exercises' ? 'Latihan':'Tryout'}}</label>
                                    <multiselect
                                        @input="getSubjects"
                                        v-model="form.tryout_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Data" label="title" track-by="id" :options="dataTryout"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link v-if="users.type == 'admin'" :to="{name : 'voucher_school'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                            <router-link v-if="users.type == 'proctor'" :to="{name : 'monitoring_voucher_school'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                            <router-link v-if="users.type == 'content'" :to="{name : 'monitoring_voucher_school_content'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'VoucherSchool',
        metaInfo: {
            title: 'Form Voucher Sekolah',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getUsers();
        },
        data() {
            return {
                label   : 'Tambah',
                form : {
                    id          : "",
                    school_id   : "",
                    type        : "",
                    code        : "",
                    quota       : "",
                    expired_at  : "",
                    expired_end : "",
                    tryout_id   : ""
                },
                dataSchool      : [],
                dataTryout      : [],
                submitted       : false,
                waiting         : false,
                users           : [],
                fetch_users     : true

            }
        },
        validations: {
            form: {
                code: {
                    required
                },
                quota: {
                    required
                },
                expired_at: {
                    required
                },
                expired_end: {
                    required
                },
                school_id: {
                    required
                },
                type: {
                    required
                }
            }
        },
        methods: {
            getUsers: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/info';
                
                this.fetch_users = true;
                this.$http.get(uri).then(res => {
                    this.users = res.data;
                    this.fetch_users = false;

                    this.getSchool();
                    this.getDataByID(this.$route.params.id);

                    if(this.users.school_id && (this.users.type == 'proctor' || this.users.type == 'content')) {
                        this.form.school_id = this.users.school_id;
                        setTimeout(() => {
                            this.getTryout();
                        }, 500);
                    } else {
                        this.getTryout();
                    }

                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getTryout: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/voucher-school/tryout';
                this.$http.get(uri, {
                    params : {
                        type : this.form.type,
                        school_id : this.form.school_id
                    }
                }).then(res => {
                    this.dataTryout   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('school_id', this.form.school_id);
                    formData.append('type', this.form.type);
                    formData.append('code', this.form.code);
                    formData.append('quota', this.form.quota);
                    formData.append('expired_at', this.form.expired_at);
                    formData.append('expired_end', this.form.expired_end);
                    formData.append('tryout_id', JSON.stringify(this.form.tryout_id));

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/voucher-school/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    if(this.users.type == 'admin') {
                                        this.$router.push({ name: 'voucher_school' });
                                    } else if(this.users.type == 'proctor'){
                                        this.$router.push({ name: 'monitoring_voucher_school' });
                                    } else {
                                        this.$router.push({ name: 'monitoring_voucher_school_content' });
                                    }
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/voucher-school/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id            = getResponse.id;
                            this.form.code          = getResponse.code;
                            this.form.school_id     = getResponse.school_id;
                            this.form.type          = getResponse.type;
                            this.form.quota         = getResponse.quota;
                            this.form.expired_at    = getResponse.expired_at;
                            this.form.expired_end   = getResponse.expired_end;

                            this.getTryout();
                            this.form.tryout_id     = getResponse.list_tryout;                                
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    if(this.users.type == 'admin') {
                                        this.$router.push({ name: 'voucher_school' });
                                    } else if(this.users.type == 'proctor') {
                                        this.$router.push({ name: 'monitoring_voucher_school' });
                                    } else {
                                        this.$router.push({ name: 'monitoring_voucher_school_content' });                                        
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }
    }
</script>