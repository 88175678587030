
<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <p>Sedang memuat data soal...</p>
        </div>
        <div v-if="!dataFetch">
            <div class="alert alert-primary alert-dismissible fade show" role="alert">
                <button style="margin-top:-3px" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                <p class="mb-0">
                    <b>Intruksi Mengerjakan Soal DISC <i>(Dominan, Influence, Steadiness, Compliant)</i></b>
                    <ul class="mb-0 mt-2">
                        <li>Silahkan pilih pada kolom di bawah <b>huruf [P]</b> di samping kalimat yang <b>PALING</b> menggambarkan diri anda</li>
                        <li>Silahkan pilih pada kolom di bawah <b>huruf [K]</b> di samping kalimat yang <b>PALING TIDAK</b> menggambarkan diri anda</li>
                        <li>
                            PERHATIKAN : Setiap nomor <b>hanya ada 1 (satu) pilihan</b> di bawah masing-masing kolom P dan K.
                        </li>
                    </ul>
                </p>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="page-header nocopy">
                        <div class="page-block">
                            <div class="page-header-title">                                
                                <b style="font-size:18px" class="m-t-10">Soal Nomor #{{number_questions}} </b>
                                <hr/>

                                <table class="table  table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width:10%">P</th>
                                            <th class="text-center" style="width:10%">K</th>
                                            <th>Gambaran Diri</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item, index) in form.dataOptions" :key="index++">
                                        <tr v-if="item">
                                            <td class="text-center" style="width:10%">
                                                <input type="radio" id="html" :name="'answer_'+index" style="height:20px; width:20px; vertical-align: middle;" v-model="form.your_answer_p[number_questions]" :value="index" v-on:change="checkAnswer(number_questions)">
                                            </td>
                                            <td class="text-center" style="width:10%">
                                                <input type="radio" id="html" :name="'answer_'+index" style="height:20px; width:20px; vertical-align: middle;" v-model="form.your_answer_k[number_questions]" :value="index" v-on:change="checkAnswer(number_questions)">
                                            </td>
                                            <td>
                                                <label for="html" style="margin-left: 10px;">{{item}}</label><br>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-center">
                                        <button v-if="number_questions > 1" class="text-left btn m-t-10 btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                        <button :disabled="disabledNext" class="text-right btn m-t-10 btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Simpan
                                            {{number_questions == dataList.tryout.list_questions.length ? "Jawaban Terakhir":" & Soal Selanjutnya"}}
                                            <i v-if="number_questions != dataList.tryout.list_questions.length" class='fa fa-arrow-right'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:15px" class="m-t-10">Sisa Waktu</b>
                                <hr/>
                                <vue-countdown-timer
                                    @start_callback="startCallBack"
                                    @end_callback="endCallBack"
                                    :start-time="startTime"
                                    :end-time="endTime"
                                    :interval="1000">

                                    <div class="text-center" slot="countdown" slot-scope="scope">
                                        <span style="font-size:25px">{{scope.props.hours}} : {{scope.props.minutes}} : {{scope.props.seconds}}</span>
                                    </div>
                                </vue-countdown-timer>                        

                            </div>
                        </div>
                    </div>

                    <div class="page-header">
                        <div class="page-block">
                            <div class="page-header-title">
                                <b style="font-size:15px" class="m-t-10">Nomor Soal</b>
                                <hr/>
                                <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                    <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataList.tryout.list_questions" :key="index"
                                        type="button"
                                        class="text-center btn rounded m-l-10 m-t-10" v-on:click="setQuestions(item.id, item.number)"
                                        :class="currentSaveQuestions[item.id] == true ? 'btn-success': item.status == 'true' ? 'btn-success':item.id == currentQuestions ? 'btn-primary':'btn-outline-primary' "
                                        >{{item.number}} 
                                    </button>                                    
                                </div>
                                <button class="m-t-10 btn btn-primary rounded btn-block" type="button" v-on:click="finishQuiz">Selesai</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #04bd04 !important;
        border-color: #000 !important;
    }
</style>

<script>
    export default {
        name: 'StartQuiz',
        metaInfo: {
            title: 'Mengerjakan Soal',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : [], 
                hours       : 0,
                minutes     : 0,
                seconds     : 0,
                startTime   : '',
                endTime     : '',
                currentQuestions : '',
                number_questions : 1,
                form : {
                    tryout_answer        : '',
                    questions            : '',
                    type_questions       : '',
                    dataOptions          : '',
                    options_a            : '',
                    options_b            : '',
                    options_c            : '',
                    options_d            : '',
                    options_e            : '',
                    tryout_question_id   : '',
                    your_answer_p        : [],
                    your_answer_k        : []
                },
                currentSaveQuestions : [],
                changeAnswer         : [],
                countDown            : 0,
                timer                : [],
                disabledNext : true,
            }
        },
        created() {
            this.startQuiz();
            this.timer[this.number_questions] = 0;
            this.countDownTimer();
        },
        methods: {
            countDownTimer() {
                setTimeout(() => {    
                    let waktu_mengerjakan = 0;

                    if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                        waktu_mengerjakan += this.timer[this.number_questions];
                    } else {
                        this.timer[this.number_questions] += waktu_mengerjakan;
                    }

                    this.countDown += 1
                    this.timer[this.number_questions] = this.countDown
                    this.countDownTimer()
                }, 1000)
            },            
            startQuiz: function() {
                let formData = new FormData();

                formData.append('id', this.$route.params.id);
                formData.append('code', this.$route.params.slug);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/disc/tryout/start', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        let getResponse = response.data;
                        this.startTime  = getResponse.data.tryout.currentTime;
                        this.endTime    = getResponse.data.tryout.expireDate;
                        this.dataList   = getResponse.data;
                        this.tryout_answer = this.dataList.tryout.tryout_answer;

                        this.setQuestions(this.dataList.tryout.list_questions[0].id, 1);
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'member_psikotest'
                                });
                            }
                        });                        
                    }

                    this.dataFetch = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            startCallBack: function () {
                console.log('waktu dimulai');
            },
            endCallBack: function () {
                this.stop();
            },
            setQuestions: function(id, number) {
                this.disabledNext        = true;
                var item                 = this.dataList.tryout.list_questions.find(item => item.id === id);
                this.currentQuestions    = id;
                this.number_questions    = number;

                if(item.your_answer_k && item.your_answer_p) {
                    this.disabledNext        = false;
                }

                if(this.form.your_answer_p[number] == null) {
                    if(item.your_answer_p) {
                        this.form.your_answer_p[number] = item.your_answer_p;
                        this.form.your_answer_k[number] = item.your_answer_k;
                    }
                }
                 
                if(this.form.your_answer_p[number]) {
                    this.disabledNext        = false;
                }

                this.form.tryout_question_id    = item.id;
                this.form.type_questions        = item.type_questions;
                this.form.questions             = item.question;
                this.form.dataOptions           = item.dataOptions;
                this.form.options_a             = item.options.a;
                this.form.options_b             = item.options.b;
                this.form.options_c             = item.options.c;
                this.form.options_d             = item.options.d;
                this.form.options_e             = item.options.e;                
                
                this.timer[this.number_questions] = item.timer;
                
                if (typeof (this.timer[this.number_questions]) !== 'undefined' && this.timer[this.number_questions] !== null) {
                    this.countDown = this.timer[this.number_questions];                
                } else {
                    this.timer[this.number_questions] = 0;
                    this.countDown = 0;
                }

                this.scrollToTop();
            },
            stop: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/disc/tryout/stop', {
                    tryout_answer_id: this.tryout_answer,
                    psikotest_id    : this.$route.params.id,
                    tryout_id       : this.dataList.tryout.id
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            html: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name : 'member_psikotest_overview',
                                    params : {
                                        id : this.$route.params.id
                                    }
                                });
                            }
                        });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataList.tryout.list_questions.length;
                let last            = "";
                let id              = "";
                if(next_number > total_questions) {
                    last = 1;
                    id  = this.dataList.tryout.list_questions[number-1].id;
                } else {
                    id  = this.dataList.tryout.list_questions[next_number-1].id;
                }

                this.saveAnswer(id, number, 'next', last);
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id                  = this.dataList.tryout.list_questions[next_number-1].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveAnswer : function(id, number, type="", last="") {
                let formData = new FormData();

                formData.append('answer_p', this.form.your_answer_p[number]);
                formData.append('answer_k', this.form.your_answer_k[number]);
                formData.append('tryout_question_id', this.form.tryout_question_id);
                formData.append('tryout_answer_id', this.tryout_answer);
                formData.append('timer', this.timer[number]);

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/disc/tryout/answer/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.currentSaveQuestions[this.form.tryout_question_id] = true;
                        this.changeAnswer[this.form.tryout_question_id]         = true;

                        let number = this.number_questions;
                        if(type == 'next') {
                            if(last) {
                                alert("Kamu sudah berada di soal terakhir");
                            } else {
                                this.setQuestions(id, number+1);
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            finishQuiz: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Selesai mengerjakan ?',
                    html: 'Jawaban yang telah di submit tidak dapat diubah.<br/>Harap periksa kembali jawaban yang telah di simpan',
                    confirmButtonText: 'Selesai',
                    showCancelButton: true,
                    cancelButtonText: 'Cek Kembali',
                }).then((result) => {
                    if (result.value) {
                        this.stop();
                    }
                });
            },
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            checkAnswer: function(number_questions) {
                let answer_p = this.form.your_answer_p[number_questions];
                let answer_k = this.form.your_answer_k[number_questions];

                if(answer_p === undefined) {
                    answer_p = 0;
                }

                if(answer_k === undefined) {
                    answer_k = 0;
                }

                console.log(answer_p+" -- "+answer_k);
                if(answer_p != 0 && answer_k != 0) {
                    if(answer_p != answer_k) {
                        this.disabledNext = false
                    } else {
                        this.disabledNext = true;
                    }
                } else {
                    this.disabledNext = true;
                }
            }
        }
    }
</script>