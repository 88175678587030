<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Salin Soal - {{exercises_name}}</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_exercises_cpns'}">Latihan SKD CPNS</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'skd_exercises_cpns_questions', params: { id: exercises_id }}">Kelola Soal</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Salin Soal</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-7">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Salin Soal Dari</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="exampleFormControlSelect1">Tipe Latihan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type_exercises" :class="{ 'is-invalid': submitted && $v.form.type_exercises.$error }" v-on:change="getListExercises">
                                        <option value="cpns">CPNS</option>
                                        <option value="kedinasan">Kedinasan</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type_exercises.required" class="invalid-feedback">Tipe wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-12">
                                    <label class="font-weight-bold">Nama Latihan</label>
                                    <v-select
                                        placeholder="Pilih Latihan"
                                        label="title"
                                        :options="dataList"
                                        v-model="form.entity_id"
                                        :class="{ 'is-invalid': submitted && $v.form.entity_id.$error }"
                                        >                                    
                                    </v-select>

                                    <div v-if="submitted && !$v.form.entity_id.required" class="invalid-feedback d-block">
                                        Wajib Diisi</div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-copy"></i> {{ waiting ? 'Harap tunggu ...' : 'Salin Soal' }}</button>
                            <router-link :to="{name : 'skd_exercises_cpns_questions', params: { id: exercises_id }}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Ketentuan Salin</h5>
                    </div>
                    <div class="card-body">
                        <ol>
                            <li>Pastikan koneksi internet anda aman.</li>
                            <li>Silahkan untuk memilih tipe exercises dan nama exercises.</li>
                            <li>Setelah dipilih, maka klik button Salin soal.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'Import',
        metaInfo: {
            title: 'Salin Soal Latihan CPNS',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            'v-select' : vSelect,
        },
        created() {
            this.getDataByID(this.$route.params.id);
            this.getListExercises();
        },
        data() {
            return {
                label           : 'Tambah',
                exercises_id       : this.$route.params.id,
                exercises_name     : "",
                dataList        : [],
                form : {
                    type_exercises : 'cpns',
                    entity_id : ''
                },
                dataFetch       : false,
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                type_exercises: {
                    required
                },
                entity_id: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('exercises_id', this.exercises_id);
                    formData.append('entity_id', this.form.entity_id.id);
                    formData.append('entity_title', this.form.entity_id.title);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/generate/exercises/skd', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({name : 'skd_exercises_cpns_questions', params: { id: this.exercises_id }});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/skd/exercises/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type_exercises : 'cpns'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data[0];                            
                            this.exercises_name    = getResponse.title;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name : 'skd_exercises_cpns_questions', params: { id: this.exercises_id }});
                                }
                            });
                        }
                    });
                }
            },
            getListExercises: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/skd/exercises/list-options';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        exercises_id : this.exercises_id,
                        type_exercises : this.form.type_exercises,                        
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            }
        }
    }
</script>