<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:history.back()" ><i class="fa fa-arrow-left m-r-10"></i></a> Hasil - {{dataDetail.statistik.title}}</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{dataDetail.statistik.fullname}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Provinsi</div>
                                <div class="font-weight-600">{{dataDetail.statistik.province_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Asal Kota</div>
                                <div class="font-weight-600">{{dataDetail.statistik.city_name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Durasi Mengerjakan</div>
                                <div class="font-weight-600">{{dataDetail.statistik.waktu}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Mengerjakan</div>
                                <div class="font-weight-600">{{dataDetail.statistik.start}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 text-center">
                        <div v-if="dataDetail.statistik.status == 'tidak'" class="alert alert-danger" role="alert">
                            <b>Kamu belum lulus pada tryout ini! </b><br/>Tetap semangat dan coba lagi kembali ya...<br/>Silahkan untuk melihat hasil dan statistik tryout anda 
                        </div>
                        <div v-if="dataDetail.statistik.status == 'lulus'" class="alert alert-success" role="alert">
                            <b>Selamat kamu lulus pada tryout ini! </b> Jangan sampai berpuas diri ya! tetap konsisten & coba lagi kembali ya...<br/>Silahkan untuk melihat hasil dan statistik tryout anda 
                        </div>

                        <button v-on:click="changeTab('StatisticTryout')" type="button"  :class="currentTabComponent == 'StatisticTryout' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-bars"></i> Lihat Statistik</button>
                        <button v-on:click="changeTab('PembahasanTryout')" type="button" :class="currentTabComponent == 'PembahasanTryout' ? 'btn-primary':'btn-outline-primary'" class="text-left btn rounded btn-block m-t-10" ><i class="fa fa-file"></i> Lihat Pembahasan</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch">
            <vue-page-transition>
                <keep-alive>
                        <component :is="currentTabComponent"></component>
                </keep-alive>
            </vue-page-transition>
        </div>

    </div>
</template>

<script>
    import StatisticTryout from "@/views/member/cpns/tryout/StatisticTryout.vue"
    import PembahasanTryout from "@/views/member/cpns/tryout/PembahasanTryout.vue"

    export default {
        name: 'ResultTryoutDetail',
        metaInfo: {
            title: 'Hasil Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            StatisticTryout,
            PembahasanTryout,
        },
        data() {
            return {
                currentTabComponent : 'StatisticTryout',
                dataFetch       : false,
                dataDetail      : [],
                dataSubscriber  : [],
                emptyState      : false,
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/skd/tryout/result/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        result_id : this.$route.params.result_id,
                        tryout_answer_id        : this.$route.params.tryout_answer_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;

                        if(!res.data.status) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            this.dataFetch  = false;     
                            this.changeTab(this.currentTabComponent);    
                            this.getSubscriber();
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            changeTab: function(tabActive, enable=true) {
                if(enable) {
                    this.currentTabComponent = tabActive;
                }
            },
            getSubscriber: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/subscriber';
                this.$http.get(uri,{
                    params : {
                        type : this.dataDetail.statistik.type_tryout
                    }
                }).then(res => {
                    this.dataSubscriber         = res.data;
                });
            },
        }
    }
</script>