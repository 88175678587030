<template>
    <div class="card card-theory-no-hover" style="border-top:none !important">
        <div class="card-body">
            <div class="d-block mb-1">
                <form v-on:submit.prevent="onSubmit">
                    <h5>Ganti Password</h5>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Password Lama</label>
                            <input type="password" class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." v-model="form.old_password" :class="{ 'is-invalid': submitted && $v.form.old_password.$error }">
                            <div v-if="submitted && !$v.form.old_password.required" class="invalid-feedback">Password lama wajib diisi!</div>    
                        </div>

                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Password Baru</label>
                            <input type="password" class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." v-model="form.password_new" :class="{ 'is-invalid': submitted && $v.form.password_new.$error }">
                            <div v-if="submitted && !$v.form.password_new.required" class="invalid-feedback">Password baru wajib diisi!</div>    
                        </div>

                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Ulangi Password Baru</label>
                            <input type="password" class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." v-model="form.retype_password" :class="{ 'is-invalid': submitted && $v.form.retype_password.$error }">
                            <div v-if="submitted && !$v.form.retype_password.required" class="invalid-feedback">Ulangi password baru wajib diisi!</div>    
                        </div>
                    </div>

                    <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                    <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Ganti Password' }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {required} from "vuelidate/lib/validators";

    export default {
        name: 'MyProfileAccount',
        metaInfo: {
            title: 'Ganti Password',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                form : {
                    old_password       : '',
                    password_new       : '',
                    retype_password     : ''
                },

                submitted       : false,
                waiting         : false,
            }
        },
        validations: {
            form: {
                old_password: {
                    required
                },
                password_new: {
                    required
                },
                retype_password: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('old_password', this.form.old_password);
                    formData.append('password_new', this.form.password_new);
                    formData.append('retype_password', this.form.retype_password);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/account/change_password', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        localStorage.removeItem('auth_token');
                                        axios.defaults.headers.common['Authorization'] = '';        
                                        location.href = location.origin + location.pathname + location.search
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            }
        }
    }
</script>