<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Penugasan Kelas</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'cbt_assignments'}">Penugasan Kelas</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Judul Tugas</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Batas Pengumpulan</label>
                                    <input type="datetime-local" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.deadline" :class="{ 'is-invalid': submitted && $v.form.deadline.$error }">
                                        <div v-if="submitted && !$v.form.deadline.required" class="invalid-feedback">Batas Pengumpulan wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Deskripsi Tugas</label>
                                    <ckeditor v-model="form.description" :config="editorConfig"></ckeditor>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kelas</label>
                                    <select class="form-control" v-model="form.class">
                                        <option value="">Pilih Kelas</option>
                                        <option v-for="(item, index) in dataClass" :key="index" :value="item">{{item}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Status</label>
                                    <select class="form-control" v-model="form.status">
                                        <option value="draft">Draft</option>
                                        <option value="publish">Publish</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Lampiran</label>
                                    <select class="form-control" v-model="form.type">
                                        <option value="">Pilih Lampiran</option>
                                        <option value="text">File</option>
                                        <option value="youtube">Youtube</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'youtube'">
                                    <label for="exampleInputEmail1">Url Youtube</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.content">
                                </div>
                                <div class="form-group col-md-6" v-if="form.type == 'text'">
                                    <label for="exampleInputEmail1">File (PDF / PPT)</label>
                                    <input type="file" class="form-control" name="attachment" id="attachment" @change="previewFiles" accept="application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation">
                                    <small v-if="form.previousAttachment" >File sebelumnya : <a :href="form.previousAttachment" target="blank">Lihat</a> </small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'cbt_assignments'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                            <router-link v-if="form.id" :to="{name : 'cbt_assignments_collection', params: { id: this.$route.params.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-l-10"><i class="fas fa-list"></i> Lihat Tugas Siswa</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'PenugasanKelas',
        metaInfo: {
            title: 'Form Penugasan Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getClass();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label   : 'Tambah',
                form : {
                    id                  : "",
                    title               : "",
                    description         : "",
                    type                : "",
                    content             : "",
                    status              : "draft",
                    attachment          : "",
                    previousAttachment  : "",
                    deadline            : "",
                    class               : ""
                },
                submitted       : false,
                waiting         : false,
                dataClass : [],
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false
                }
            }
        },
        validations: {
            form: {
                title: {
                    required
                },
                deadline: {
                    required
                }
            }
        },
        methods: {
            getClass: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/cbt/assignments/list-class';
                this.$http.get(uri).then(res => {
                    this.dataClass   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('description', this.form.description);
                    formData.append('type', this.form.type);
                    formData.append('content', this.form.content);
                    formData.append('status', this.form.status);
                    formData.append('description', this.form.description);
                    formData.append('attachment', this.form.attachment);
                    formData.append('class', this.form.class);
                    formData.append('deadline', this.form.deadline);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/cbt/assignments/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    if(this.form.id) {
                                        this.$router.push({
                                            name: 'cbt_assignments_collection',
                                            params: { id: this.form.id }
                                        });
                                    } else {
                                        this.$router.push({ name: 'cbt_assignments' });
                                    }
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/cbt/assignments/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;

                            this.form.id            = getResponse.id;
                            this.form.code          = getResponse.code;
                            this.form.title         = getResponse.title;
                            this.form.description   = getResponse.description;

                            this.form.content               = getResponse.content;
                            this.form.status                = getResponse.status;
                            this.form.type                  = getResponse.type;
                            this.form.attachment            = getResponse.attachment;
                            this.form.class                 = getResponse.school_class;
                            this.form.deadline              = getResponse.deadline;
                            this.form.previousAttachment    = getResponse.preview_url;


                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'cbt_assignments' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form.attachment = file;
                }
            },            
        }
    }
</script>