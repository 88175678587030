<template>
    <div class="card card-theory-no-hover" style="border-top:none !important">
        <div class="card-body">
            <div class="d-block mb-1">
                <form v-on:submit.prevent="onSubmit">
                    <h5>Profil Sekolah</h5>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Asal Sekolah</label>
                            <input type="text" disabled class="form-control" id="exampleInputEmail1" placeholder="Masukan data..." :value="this.$parent.$parent.dataUsers.school_name">
                            <small><i>Asal sekolah tidak dapat diubah, jika ingin merubah asal sekolah silahkan hubungi admin.</i></small>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="exampleInputEmail1">Mata Pelajaran yang diampu oleh guru</label>
                            <multiselect
                                    v-model="subject" tag-placeholder="Add this as new tag"
                                    placeholder="Pilih Mata Pelajaran" label="name" track-by="id" :options="dataSubjects"
                                    :multiple="true" :taggable="true"></multiselect>
                        </div>
                    </div>

                    <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                    <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfilGuru',
        metaInfo: {
            title: 'Profil Sekolah',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                dataSubjects    : [],
                subject         : [],
            }
        },
        created() {
            this.getSubjects();

            if(this.$parent.$parent.dataUsers.subjects) {
                this.subject = this.$parent.$parent.dataUsers.subjects;
            }
        },
        methods: {
            getSubjects: function() {
                let school_id = [{
                    id : this.$parent.$parent.dataUsers.school_id,
                    name : this.$parent.$parent.dataUsers.school_name
                }];

                let uri         = process.env.VUE_APP_APIHOST+'admin/subjects-list';
                this.$http.get(uri, {
                    params : {
                        school_id : JSON.stringify(school_id)
                    }
                }).then(res => {
                    this.dataSubjects   = res.data.data;
                });
            },            
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                 if (this.subject.length < 1) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih mata pelajaran yang anda ampu!',
                    });

                    this.waiting = false;
                    return;
                } else {
                    let subject_id = [];
                    this.subject.forEach(element => {
                        subject_id.push(element.id)
                    });

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        subjects_id : subject_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: "Data berhasil disimpan!",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            }

        }
    }
</script>