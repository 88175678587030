<template>
    <div>
        <div v-if="dataFetchTryout" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchTryout" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{ name : 'monitoring_psikotest'}"><i class="fa fa-arrow-left m-r-10"></i></router-link> Data Peserta - {{dataTryout.name}}</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Kata kunci</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan nama dan email..." v-model="keywords" v-on:keyup.enter="getData">
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th>Kelas</th>
                                                    <th class="text-center" style="width:15%">Total Peserta</th>
                                                    <th class="text-center" style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}                                                        
                                                    </td>
                                                    <td>
                                                        {{item.class != '-' ? item.class : '(Belum Mengisi Kelas)'}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{item.total}} orang
                                                    </td>
                                                    <td class="text-center">
                                                        <router-link
                                                            v-if="item.status_finished != 'notyet' && dataTryout.type != 'admin'"
                                                            :to="({name : 'monitoring_psikotest_class_detail', params:{id : $route.params.id}, query: { school_id: item.school_id, class: item.class}})"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title=""
                                                            data-original-title="Edit"
                                                            type="button"
                                                            class="btn btn-primary btn-sm rounded m-r-10">
                                                                <i class="fa fa-stream"></i> Status Pengerjaan 
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AdminSchool',
        metaInfo: {
            title: 'Monitoring Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState          : false,
                dataFetch           : false,
                dataFetchTryout     : true,
                dataList            : [],
                dataTryout          : [],
                keywords            : "",
                school              : "",
                status     : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    totalData   : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getTryout();
        },
        methods: {
            getTryout: function() {
                this.dataFetchTryout = true;
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring/detail-psikotest';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataTryout         = res.data.data;
                        this.dataFetchTryout    = false;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'monitoring_psikotest'
                                });
                            }
                        });                        
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/monitoring/list-class-psikotest';
                
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords        : this.keywords,
                        school_id       : this.school.id,
                        psikotest_id    : this.$route.params.id,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                    this.pagination.totalData  = res.data.total_data;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>
