<template>
    <div>
        <div v-if="dataFetchSchool" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetchSchool">
            <div v-if="!SchoolInfo.status">
                <div class="page-header">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Ayo bergabung dan dapatkan materi dari sekolah! </h5>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="alert alert-info" role="alert">
                                    <b>Informasi : </b>
                                    <ul class="m-t-10">
                                        <li>Harap pilih sekolah sesuai dengan asal sekolah kamu pada inputan dibawah ini.</li>
                                        <li>Asal Sekolah hanya bisa dipilih <b>1x</b>, maka pilihlah sesuai dengan asal sekolah kamu.</li>
                                        <li>Harap hubungi admin untuk info lebih lanjut.
                                            <a href="https://web.whatsapp.com/send?phone=6287837850461" class="font-weight-bold" target="blank">Hubungi Admin</a>
                                        </li>
                                        <li>Good luck! 😊</li>
                                    </ul>
                                </div>
                            </div>                        

                        </div>
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <v-select
                                            placeholder="Pilih Sekolah"
                                            label="name"
                                            :options="dataSchool"
                                            v-model="school"
                                            :class="{ 'is-invalid': submitted && $v.school.$error }"
                                            @input="changeSchool"
                                            >                                    
                                        </v-select>

                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-12">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">&nbsp;</label>
                                        <button type="submit" class="btn btn-primary btn-block rounded"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Data' }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>                
                </div>
            </div>

            <div v-if="SchoolInfo.status">
                <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="SchoolInfo.data.name">
                    <strong><i class="fa fa-info-circle"></i></strong>
                    <span class="m-l-10">Saat ini kamu terdaftar sebagai siswa dari sekolah <b>{{SchoolInfo.data.name}}</b>.<br/>
                    Untuk mengubah asal sekolah, silahkan <a href="javascript:void(0)" data-toggle="modal" data-target="#modalForm" class="font-weight-bold" target="blank">klik disini</a></span>
                </div>

                <div class="page-header">
                    <div class="page-block">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <div class="page-header-title">
                                    <h5 class="m-t-10">Data Tugas Kelas</h5>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                        </div>

                                        <div v-if="dataFetch">
                                            <p>Sedang mengambil data...</p>
                                        </div>

                                        <div v-if="!dataFetch">
                                            <div v-if="dataList.length > 0" class="table-responsive m-t-20">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th style="width:5%" class="text-center">No</th>
                                                            <th>Judul Tugas</th>
                                                            <th>Batas Waktu Mengerjakan</th>
                                                            <th class="text-center">Nilai</th>
                                                            <th class="text-center">Status</th>
                                                            <th style="width:5%">Aksi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in dataList" :key="index">
                                                            <td class="text-center">{{++index}}</td>
                                                            <td>{{item.title}}<br/>
                                                                <small>Dibuat oleh: {{ item.created_by }}</small>
                                                            </td>
                                                            <td>{{item.deadline}}</td>
                                                            <td class="text-center">
                                                                {{ item.is_collect == 1 ? item.collection.score:'-' }}
                                                            </td>
                                                            <td class="text-center">
                                                                <span v-if="item.is_collect == 0" class="badge badge-danger">Belum Mengerjakan</span>
                                                                <span v-if="item.is_collect == 1" class="badge badge-success">Sudah Mengerjakan</span>
                                                                <div v-if="item.is_collect == 1">
                                                                    <small>Waktu Mengumpulkan:<br/><strong>{{ item.collection.completed_at }}</strong></small>
                                                                    <br v-if="(item.status_collect == 'late' || item.status_collect == 'done')"/>
                                                                    <small v-if="item.status_collect == 'late'" class="text-warning font-weight-bold">Telat Mengerjakan</small>
                                                                    <small v-if="item.status_collect == 'done'" class="text-success font-weight-bold">Tepat Waktu</small>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <!-- <router-link :to="{name : 'member_cbt_assignments_detail', params: { code: item.code }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Lihat Tugas</router-link> -->
                                                                <a href="javacript:void(0)" v-on:click="viewAssignment(item)" data-toggle="modal" data-target="#modalViewAssignment" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Lihat Tugas</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div v-if="dataList.length < 1">
                                                <empty-state></empty-state>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalForm" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Asal Sekolah</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label for="exampleFormControlSelect1">Pilih Sekolah</label>
                                        <select class="form-control" v-model="school" :class="{ 'is-invalid': submitted && $v.school.$error }" v-on:change="changeSchool">
                                            <option value="">Pilih Asal Sekolah</option>
                                            <option v-for="(item, index) in dataSchool" :key="index" :value="item">{{ item.name }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.school.required" class="invalid-feedback d-block">
                                            Sekolah Wajib Dipilih</div>
                                    </div>

                                    <div class="m-t-10 mb-3">
                                        <label for="exampleFormControlSelect1">Pilih Kelas</label>
                                        <select class="form-control" v-model="classname" :class="{ 'is-invalid': submitted && $v.classname.$error }">
                                            <option value="">Pilih Kelas</option>
                                            <option v-for="(item, index) in dataClass" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.classname.required" class="invalid-feedback d-block">
                                            Kelas Wajib Dipilih</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" style="margin-top:-30px" type="submit" class="btn btn-primary btn-block"><i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                    </form>
                </div> 
            </div>
        </div>

        <div class="modal" id="modalViewAssignment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file-alt"></i> {{dataAssignment.title}} </div>
                            <div>
                                <button v-on:click="closeModalView()" id="btnCloseModalAssignment" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                                <button id="btnCloseModalAssignment2" type="button" class="d-none btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12" v-if="dataAssignment.is_collect == 0">
                                <div role="alert" class="alert alert-warning m-t-10 text-center"><b>Kamu belum mengerjakan tugas ini</b>. <a href="javascript:void(0)" data-toggle="modal" data-target="#modalSendAssignment" v-on:click="openAssignmentModal()">Klik disini untuk mengerjakan.</a> <button data-toggle="modal" data-target="#modalSendAssignment" v-on:click="openAssignmentModal()" class="btn btn-primary btn-sm rounded" type="button">Kerjakan Tugas</button> </div>
                            </div>
                            <div class="col-md-12" v-if="dataAssignment.is_collect == 1">
                                <div role="alert" class="alert alert-success m-t-10 text-center"><b>Kamu sudah mengerjakan tugas ini</b>. <a href="javascript:void(0)"  data-toggle="modal" data-target="#modalSendAssignment" v-on:click="openAssignmentModal()">Klik disini untuk melihat jawaban yang kamu kirim.</a></div>
                            </div>
                            <div class="col-md-12">
                                <div class="m-t-10">
                                    <label><strong>Deskripsi Tugas: </strong></label>
                                    <div v-html="dataAssignment.description">
                                    </div>
                                </div>
                                
                                <div class="m-t-10">
                                    <label><strong>Batas Pengumpulan: </strong></label>
                                    <div v-html="dataAssignment.deadline">
                                    </div>
                                </div>

                                <div class="m-t-10">
                                    <label><strong>Lampiran: </strong></label>
                                    <div v-if="dataAssignment.type == 'text'">
                                        <div v-if="!preview_url">
                                            <p class="text-center mb-3 mt-3">File tidak tersedia</p>
                                        </div>
                                        <object v-if="preview_url" type="application/pdf" :data="preview_url" width="100%" height="500" style="height: 85vh;">
                                            <p class="text-center mt-3">
                                                <embed :src="preview_url" width="100%" height="600px">
                                            </p>                        
                                        </object>
                                    </div>
                                    <div v-if="dataAssignment.type == 'youtube'">
                                        <div v-if="!youtube_url">
                                            <p class="text-center mb-3 mt-3">Video tidak tersedia</p>
                                        </div>
                                        <vue-plyr v-if="youtube_url">
                                            <div class="plyr__video-embed">
                                                <iframe
                                                :src="youtube_url"
                                                allowfullscreen
                                                allowtransparency
                                                allow="autoplay"
                                                ></iframe>
                                            </div>
                                        </vue-plyr>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div class="modal" id="modalSendAssignment" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file-alt"></i> {{dataAssignment.title}} </div>
                            <div>
                                <button v-on:click="closeModalSend()" id="btnCloseModalSend" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <form v-on:submit.prevent="onSubmitAttachment">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12" v-if="dataAssignment.is_collect == 1">
                                    <div role="alert" class="alert alert-success m-t-10 text-center">
                                        <b>Kamu sudah mengerjakan tugas ini</b>. 
                                        <br/>
                                        <span v-if="dataAssignment.collection.score == null">Guru yang bersangkutan belum memberikan penilaian</span>
                                        <span v-if="dataAssignment.collection.score != null">Nilai Tugas: <b>{{ dataAssignment.collection.score }}</b></span>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label><strong>Deskripsi/Catatan untuk guru: </strong></label>
                                        <ckeditor v-model="form_attachment.description" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>
                                    
                                <div class="col-md-6">                                    
                                    <div class="m-t-10">
                                        <label><strong>Lampiran</strong><br/><small><i>Silahkan pilih format lampiran file</i></small></label>
                                        <select class="form-control" v-model="form_attachment.type">
                                            <option value="text">File</option>
                                            <option value="youtube">Youtube</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6" v-if="form_attachment.type == 'text'">
                                    <div class="m-t-10">
                                        <label><strong>Lampiran (PDF): </strong><br/><small><i>Max upload : 5 MB</i></small>
                                        </label>
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept="application/pdf">
                                        <small v-if="form_attachment.preview_attachment" >File sebelumnya : <a :href="form_attachment.preview_attachment" target="blank">Lihat</a> </small>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="form_attachment.type == 'youtube'">
                                    <div class="m-t-10">
                                        <label><strong>Url Youtube</strong><br/><small><i>Contoh : https://www.youtube.com/watch?v=EngW7tLk6R8</i></small>
                                        </label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url tyouube..." v-model="form_attachment.content">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button v-if="dataAssignment.collection.score == null" :disabled="waiting_send_attachment" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting_send_attachment ? 'Harap tunggu ...' : 'Simpan dan Kirim Tugas' }}</button>                    
                    </form>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'CbtAssignmentsMembers',
        metaInfo: {
            title: 'Data Tugas Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                submitted_send_attachment   : false,
                waiting_send_attachment     : false,
                dataFetch   : false,
                dataFetchSchool : false,
                dataClass       : [],
                dataList    : [],
                keywords    : "",
                school : '',
                classname : '',
                dataSchool : [],
                form            : {
                    id : '',
                    title : ''
                },
                dataAssignment : {
                    collection : {
                        score : null
                    }
                },
                preview_url : "",
                youtube_url : "",
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false
                },
                form_attachment : {
                    id : '',
                    type : 'text',
                    content : '',
                    description : '',
                    attachment : '',
                    preview_attachment : ''
                }
            }
        },
        created() {
            this.schoolInfo();
            this.getSchool();
        },
        components: {
            'v-select' : vSelect
        },
        validations: {
            school: {
                required
            },
            classname: {
                required
            },
        },
        methods: {
            schoolInfo: function () {
                this.dataFetchSchool = true;
                let uri = this.$apiconfig + 'user/school';
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool = false;

                    let getResponse = res.data;
                    this.SchoolInfo = getResponse;

                    if(this.SchoolInfo.status) {
                        this.getData();
                    }
                });
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/assignments/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords
                    }
                }).then(res => {
                    this.dataFetch  = false;                
                    this.dataList   = res.data.data;
                });
            },
            changeSchool: function() {
                this.classname = "";
                if(this.school.id) {
                    this.dataSchool.forEach(element => {
                        if(this.school.id == element.id) {
                            this.dataClass = element.data_class;
                        }
                    });
                } else {                    
                    this.dataClass = [];
                }
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/save_school', {
                        school_id : this.school.id,
                        school_class : this.classname,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    this.schoolInfo();
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            },
            viewAssignment: function(item) {
                this.preview_url = item.preview_url;
                this.youtube_url = item.content;

                this.dataAssignment = item;
            },
            closeModalView: function() {
                setTimeout(() => {
                    this.preview_url = "";
                    this.youtube_url = "";
                }, 500);
            },
            openAssignmentModal: function() {
                if(this.dataAssignment.is_collect == 1) {
                    this.form_attachment.id = this.dataAssignment.collection.id;
                    this.form_attachment.type = this.dataAssignment.collection.type;
                    this.form_attachment.content = this.dataAssignment.collection.content;
                    this.form_attachment.description = this.dataAssignment.collection.description;
                    this.form_attachment.preview_attachment = this.dataAssignment.collection.preview_url;
                } else {
                    this.form_attachment.id                 = "";
                    this.form_attachment.type               = "text";
                    this.form_attachment.content            = "";
                    this.form_attachment.description        = "";
                    this.form_attachment.attachment         = "";
                    this.form_attachment.preview_attachment = ""; 
                }
                
                document.getElementById("btnCloseModalAssignment2").click();
            },
            closeModalSend: function() {
                this.form_attachment.id                 = "";
                this.form_attachment.type               = "text";
                this.form_attachment.content            = "";
                this.form_attachment.description        = "";
                this.form_attachment.attachment         = "";
                this.form_attachment.preview_attachment = ""; 
            },
            onSubmitAttachment: function() {
                if(this.form_attachment.description == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });                    
                } else {
                    // this.waiting_send_attachment = true;
                    let formData = new FormData();

                    formData.append('id', this.form_attachment.id);
                    formData.append('cbt_assignments_id', this.dataAssignment.id);
                    formData.append('type', this.form_attachment.type);
                    formData.append('content', this.form_attachment.content);
                    formData.append('description', this.form_attachment.description);
                    formData.append('attachment', this.form_attachment.attachment);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'member/cbt/assignments/send-assignment', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            let res = response.data;
                            if(res.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil!',
                                    text: res.message,
                                });
                                
                                this.getData();                                
                                document.getElementById("btnCloseModalSend").click();
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: res.message,
                                });
                            }
                            this.waiting_send_attachment = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });

                        this.waiting_send_attachment = false;
                        return;
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.form_attachment.attachment = file;
                }
            }
        }
    }
</script>
