<template>
    <div>
        <div v-if="dataGroup.status" class="alert alert-info alert-dismissible fade show" role="alert">
            <strong><i class="fa fa-info-circle"></i></strong>
            <span class="m-l-10">{{dataGroup.data.text_link}}</span>
            <strong class="m-l-5"><a :href="dataGroup.data.link" target="blank">
                Klik disini <i class="m-l-5 fas fa-external-link-alt"></i></a></strong>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Tryout Premium SNBT</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button v-for="(item, index) in dataJurusan" :key="index" class="btn rounded m-r-10 m-t-10" :class="activeTabs == item.id ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item.id)" type="button">{{item.name}}</button>

                                <div class="input-group mb-3 m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        <div v-if="dataFetch" class="row">
                            <div class="col-sm-4" v-for="(item,index) in [1,2,3]" :key="index">
                                    <div class="d-block border rounded-lg p-3 m-t-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="mr-3 w-100">
                                                <div class="d-flex align-items-center">
                                                    <div class="w-100">
                                                        <div class="skeleton-bar text-center">
                                                            <div class="sb-sm mb-2"></div>
                                                            <hr/>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-thin mb-4"></div>
                                                            <div class="sb-lg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div v-if="!dataFetch">
                            <div v-if="dataList.length > 0" class="row">
                                <div class="col-md-4" v-for="(item, index) in dataList" :key="index">
                                    <div class="card card-theory">
                                        <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                        <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/default-cover.png" alt="cover">

                                        <div class="card-body">
                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                                                    <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{item.title}}</div>
                                                </div>
                                            </div>

                                            <div class="d-block mb-1">
                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Waktu</div>
                                                    <div class="font-weight-600">{{item.timer}} Menit</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Jumlah Soal</div>
                                                    <div class="font-weight-600">{{item.total_questions}} Soal</div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Harga</div>
                                                    <div class="font-weight-600">
                                                        <span v-if="item.text_price_out" class="text-muted"><s>{{item.text_price_out}}</s></span>
                                                        {{item.text_price}}
                                                    </div>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                                    <div class="mr-3">Status</div>
                                                    <div class="font-weight-600">
                                                        <span v-if="!item.owned" class="badge badge-danger">Belum dimiliki</span>
                                                        <span v-if="item.owned" class="badge badge-success">Sudah dimiliki</span>
                                                    </div>
                                                </div>

                                                <div v-if="!item.owned">
                                                    <button v-if="!item.cart" :disabled="waitingCart" type="button" class="btn btn-outline-primary rounded btn-block m-t-10" v-on:click="addToCart(item.id)">
                                                        <i class="fa fa-cart-plus"></i> {{ waitingCart ? 'Sedang menambahkan ...' : 'Tambahkan Ke Keranjang' }}
                                                    </button>

                                                    <button v-if="item.cart" :disabled="waitingCartDelete" type="button" class="btn btn-danger rounded btn-block m-t-10" v-on:click="deleteCart(item.id)">
                                                        <i class="fa fa-trash"></i> {{ waitingCartDelete ? 'Sedang menambahkan ...' : 'Hapus dari Keranjang' }}
                                                    </button>

                                                    <router-link v-if="item.cart" :to="{name : 'users_cart'}" type="button" class="btn btn-outline-primary rounded btn-block m-t-10">
                                                        <i class="fa fa-shopping-cart"></i> Lihat Keranjang
                                                    </router-link>

                                                </div>

                                                <div v-if="item.owned">
                                                    <router-link :to="{name : 'member_tryout_utbk_overview', params: { slug: item.code }}" v-if="!item.answer" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Kerjakan Soal</router-link>
                                                    <router-link :to="{name : 'member_tryout_utbk_result_detail', params: { result_id: item.answer_id}}" v-if="item.answer" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-bars"></i> Lihat Hasil</router-link>
                                                </div>
                                                
                                                <router-link :to="{name : 'member_tryout_utbk_rank', params: { slug: item.code }}" v-if="item.owned" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-trophy"></i> Rangking Peserta</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <nav aria-label="navigation">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>
                                </div>
                            </div>

                            <div v-if="emptyState">
                                <empty-state></empty-state>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'TryoutPremiumUtbk',
        metaInfo: {
            title: 'Tryout Premium SNBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataFetchGroup  : false,
                dataList        : [],
                dataGroup       : [],
                emptyState      : false,
                keywords        : "",
                form            : {
                    id : '',
                    title : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                waitingCart : false,
                waitingCartDelete : false,
                dataJurusan : [
                    {
                        id : '',
                        name : 'Semua Jurusan'
                    },
                    {
                        id : 'saintek',
                        name : 'Saintek'
                    },
                    {
                        id : 'soshum',
                        name : 'Soshum'
                    }
                ],
                activeTabs      : "",
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
            this.getDiscuss();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/utbk/tryout/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        type        : 'premium',
                        program     : this.activeTabs,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            addToCart: function(id) {
                this.waitingCart    = true;
                var item            = this.dataList.find(item => item.id === id);

                let formData    = new FormData();
                this.form.id    = item.id;

                formData.append('id', this.form.id);
                formData.append('type', 'utbk');

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/add-cart', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.$parent.$parent.getUsersCart();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waitingCart = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waitingCart = false;
                    return;
                });

            },
            deleteCart: function(id) {
                this.waitingCartDelete    = true;
                var item            = this.dataList.find(item => item.id === id);

                let formData    = new FormData();
                this.form.id    = item.id;

                formData.append('id', this.form.id);
                formData.append('type', 'utbk');

                this.$http.post(process.env.VUE_APP_APIHOST + 'member/skd/tryout/delete-cart', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                    this.$parent.$parent.getUsersCart();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waitingCartDelete = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waitingCartDelete = false;
                    return;
                });

            },
            getDiscuss: function () {
                this.dataFetchGroup = true;
                let uri = this.$apiconfig + 'user/group/discuss';
                this.$http.get(uri, {
                    params: {
                        type: 'utbk'
                    }
                }).then(res => {
                    let getResponse = res.data;
                    this.dataGroup = getResponse;
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            },
        }
    }
</script>
